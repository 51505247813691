import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import Label from 'components/Label';
import Text from 'components/Text';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function HeaderColumns({ components, activeComponentValues, valueFilter, onFilterValueChange }) {
  if (components.length > 0) {
    const componentColumns = components.map(componentRef => {
      const component = componentRef.data;
      const activeValues = activeComponentValues[componentRef.id];

      // calculate colspan
      const activeValueCount =
        activeValues.waterValues.length * valueFilter.waterValues +
        activeValues.operatingValues.length * valueFilter.operatingValues +
        activeValues.meterValues.length * valueFilter.meterValues;

      // {intl.get(message.id)} {component.componentNumber}
      if (activeValueCount > 0) {
        return (
          <th colSpan={activeValueCount} key={`component-header-${componentRef.id}`}>
            {component.internalName}
          </th>
        );
      }

      return null;
    });

    const renderFilter = () => {
      return (
        <Box background="white" noElevation paddingY={5} paddingX={7}>
          <Text size="md">Filter Optionen</Text>

          <Box marginTop={5}>
            <Box alignItems="center" direction="row" display="flex">
              <Label htmlFor="checkbox-water-values">
                <Checkbox
                  id="checkbox-water-values"
                  checked={valueFilter.waterValues}
                  onChange={e => onFilterValueChange('waterValues', e.target.checked)}
                />
                <span style={{ marginLeft: 8 }}>{intl.get(messagesCommon.waterValues.id)}</span>
              </Label>
            </Box>
            <Box alignItems="center" direction="row" display="flex">
              <Label htmlFor="checkbox-op-values">
                <Checkbox
                  id="checkbox-op-values"
                  checked={valueFilter.operatingValues}
                  onChange={e => onFilterValueChange('operatingValues', e.target.checked)}
                />
                <span style={{ marginLeft: 8 }}>{intl.get(messagesCommon.operatingValues.id)}</span>
              </Label>
            </Box>
            <Box alignItems="center" direction="row" display="flex">
              <Label htmlFor="checkbox-meter-values">
                <Checkbox
                  id="checkbox-meter-values"
                  checked={valueFilter.meterValues}
                  onChange={e => onFilterValueChange('meterValues', e.target.checked)}
                />
                <span style={{ marginLeft: 8 }}>{intl.get(messagesCommon.meterValues.id)}</span>
              </Label>
            </Box>
            <Box alignItems="center" direction="row" display="flex">
              <Label htmlFor="checkbox-with-comments">
                <Checkbox
                  id="checkbox-with-comments"
                  checked={valueFilter.withComments}
                  onChange={e => onFilterValueChange('withComments', e.target.checked)}
                />
                <span style={{ marginLeft: 8 }}>{intl.get(messagesCommon.withComments.id)}</span>
              </Label>
            </Box>
          </Box>
        </Box>
      );
    };

    return [
      <td colSpan="3" rowSpan="2" key="test">
        {renderFilter()}
      </td>,
      ...componentColumns
    ];
  }

  return null;
}

HeaderColumns.propTypes = {
  valueFilter: PropTypes.any.isRequired,
  onFilterValueChange: PropTypes.func.isRequired,
  components: PropTypes.any,
  activeComponentValues: PropTypes.any
};

HeaderColumns.defaultProps = {
  components: [],
  activeComponentValues: []
};

export default HeaderColumns;
