import Box from 'components/Box';
import Panel from 'components/Panel';
import TabList from 'components/TabList';
import { ComponentTypeConfig, ComponentTypes } from 'constants/SteamComponent';
import useComponentParameterChange from 'hooks/useComponentParameterChange';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon, messagesComponents, messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import ParameterService from 'services/ParameterService';
import DataCounter from './DataCounter';
import DataGeneral from './DataGeneral';
import DataOperational from './DataOperational';
import DataWater from './DataWater';

function PanelCustom({ type, component, componentCounts, onClose }) {
  const componentParamHandler = useComponentParameterChange();
  const formValidator = useFormValidation();
  const [componentData, setComponentData] = useState({});
  const [tabListIndex, setTabListIndex] = useState(0);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isCreateMode] = useState(!component);
  const [valid, setValid] = useState(false);

  const operationalParams = ParameterService.getParametersByTypeAndComponent(
    'operatingValues',
    ComponentTypes.Custom
  );
  const meterParams = ParameterService.getParametersByTypeAndComponent(
    'meterValues',
    ComponentTypes.Custom
  );
  const waterParams = ParameterService.getParametersByTypeAndComponent(
    'waterValues',
    ComponentTypes.Custom
  );

  // Bootstrap component data
  useEffect(() => {
    if (!component) {
      const name = ComponentTypeConfig[type].name;
      const intlName = intl.get(messagesComponents[`component${name}`].id);
      setComponentData({
        internalName: intlName
      });
    } else {
      setComponentData(component.data);
    }
  }, [component, type]);

  useEffect(() => {
    const validData = formValidator.validateComponent(type, componentData);
    setValid(validData);
  }, [componentData, formValidator, type]);

  const handleTabListItemChange = ({ activeIndex }) => {
    setTabListIndex(activeIndex);
  };

  const handleFormFieldChange = (key, value) => {
    const data = { ...componentData };
    data[key] = value;
    setComponentData(data);
  };

  const onSave = async () => {
    setSaveInProgress(true);
    const dataToSave = { ...componentData, isDeleted: false };
    const id = isCreateMode ? null : component.id;

    if (isCreateMode) {
      // make sure data field is present even when not filled with param configs
      if (dataToSave.waterValues === undefined) {
        dataToSave.waterValues = [];
      }

      if (dataToSave.meterValues === undefined) {
        dataToSave.meterValues = [];
      }

      if (dataToSave.operatingValues === undefined) {
        dataToSave.operatingValues = [];
      }

      dataToSave.location = AppStateService.location.ref;
      dataToSave.type = type;
      dataToSave.componentNumber = componentCounts[type] + 1;
    }

    await FirebaseService.updateComponent(id, dataToSave);
    setSaveInProgress(false);

    onClose();
  };

  const onArchive = async () => {
    if (!component.id) {
      throw new Error('No component ID provided');
    }

    setSaveInProgress(true);
    const dataToSave = { ...componentData };
    dataToSave.isDeleted = true;
    await FirebaseService.updateComponent(component.id, dataToSave);
    setSaveInProgress(false);
    onClose();
  };

  const handleParameterChange = paramUpdate => {
    const data = componentParamHandler.update(paramUpdate, componentData);
    setComponentData(data);
  };

  const renderTabContentByIndex = () => {
    let componentToRender = null;
    switch (tabListIndex) {
      case 1:
        componentToRender = (
          <DataWater
            type={type}
            isCreateMode={isCreateMode}
            onChange={handleParameterChange}
            component={componentData}
            parameters={waterParams}
          />
        );
        break;
      case 2:
        componentToRender = (
          <DataOperational
            type={type}
            isCreateMode={isCreateMode}
            onChange={handleParameterChange}
            component={componentData}
            parameters={operationalParams}
          />
        );
        break;
      case 3:
        componentToRender = (
          <DataCounter
            type={type}
            isCreateMode={isCreateMode}
            onChange={handleParameterChange}
            component={componentData}
            parameters={meterParams}
          />
        );
        break;
      default:
        componentToRender = (
          <DataGeneral
            type={type}
            isCreateMode={isCreateMode}
            onChange={handleFormFieldChange}
            component={componentData}
          />
        );
        break;
    }
    return componentToRender;
  };

  return (
    <Panel
      title={
        isCreateMode ? intl.get(messagesCrud.customAdd.id) : intl.get(messagesCrud.customEdit.id)
      }
      buttonPrimaryAction={onSave}
      buttonPrimaryDisabled={!valid}
      onArchive={isCreateMode ? null : onArchive}
      onClose={onClose}
      showSpinner={saveInProgress}
      isOpen
      width={720}
    >
      <Box marginTop={7}>
        <TabList
          width={720}
          variant="minimal"
          items={[
            { index: 0, title: intl.get(messagesCommon.general.id) },
            { index: 1, title: intl.get(messagesCommon.waterValues.id) },
            { index: 2, title: intl.get(messagesCommon.operatingValues.id) },
            { index: 3, title: intl.get(messagesCommon.counter.id) }
          ]}
          selectedItemIndex={tabListIndex}
          onChange={handleTabListItemChange}
        />
      </Box>

      <Box marginTop={5} style={{ overflowY: 'auto', height: 'calc(100% - 130px)' }}>
        {renderTabContentByIndex()}
      </Box>
    </Panel>
  );
}

PanelCustom.propTypes = {
  component: PropTypes.any,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  componentCounts: PropTypes.array.isRequired
};

PanelCustom.defaultProps = {
  component: null
};

export default PanelCustom;
