import Box from 'components/Box';
import Label from 'components/Label';
import Select from 'components/Select';
import Toggle from 'components/Toggle';
import { REQUIRED_FIELDS } from 'constants/Form';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';

function LocationConfigFormEdit({ location, onChange }) {
  const formOptions = useFormOptions();
  const formConfig = REQUIRED_FIELDS.location;
  const formValidator = useFormValidation();

  return (
    <>
      <Box marginY={3}>
        <Label
          htmlFor="steamProductionInterference"
          valid={formValidator.isFieldValid(formConfig, 'steamProductionInterference', location)}
          required={formValidator.isFieldRequired(formConfig, 'steamProductionInterference')}
        >
          {intl.get(messagesCommon.steamProductionInterference.id)}
        </Label>
        <Select
          id="steamProductionInterference"
          options={formOptions.interference}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={location?.steamProductionInterference}
          onChange={e => onChange('steamProductionInterference', e.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label htmlFor="thermicGasLoss">
          {intl.get(messagesCommon.thermicGasLoss.id)}
          <Box marginY={1}>
            <Toggle
              enabled={location && location.thermicGasLoss ? location.thermicGasLoss : false}
              onChange={active => onChange('thermicGasLoss', active)}
            />
          </Box>
        </Label>
      </Box>
      <Box marginY={3}>
        <Label htmlFor="degree-of-hardness">{intl.get(messagesCommon.hardnessUnit.id)}</Label>
        <Select
          id="degree-of-hardness"
          options={formOptions.hardness}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={location?.hardnessUnit}
          onChange={e => onChange('hardnessUnit', e.value)}
        />
      </Box>
      {AppStateService.isSuperAdmin() && (
        <Box marginY={3}>
          <Label
            htmlFor="package"
            valid={formValidator.isFieldValid(formConfig, 'package', location)}
            required={formValidator.isFieldRequired(formConfig, 'package')}
          >
            {intl.get(messagesCommon.package.id)}
          </Label>
          <Select
            id="package"
            options={formOptions.package}
            placeholder={intl.get(messagesCommon.choose.id)}
            value={location?.package}
            onChange={e => onChange('package', e.value)}
          />
        </Box>
      )}
    </>
  );
}

LocationConfigFormEdit.propTypes = {
  location: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

LocationConfigFormEdit.defaultProps = {
  location: null
};

export default LocationConfigFormEdit;
