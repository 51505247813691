import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function Logout() {
  const history = useHistory();

  useEffect(() => {
    FirebaseService.signout();
    AppStateService.removeUser();
    history.push('/login');
  }, [history]);

  return <div />;
}

export default Logout;
