import Box from 'components/Box';
import Number from 'components/Number';
import Text from 'components/Text';
import {
  messagesCommon,
  messagesCostFactor,
  messagesDashboard,
  messagesReport
} from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function SteamCostOverTimeTooltip({ payload }) {
  if (payload.length !== 2) {
    return null;
  }

  const prevYearText = intl.get(messagesCommon.previousYearShort.id);
  const year = payload[0].dataKey !== prevYearText ? payload[0].dataKey : payload[1].dataKey;
  const priceParts = payload[0].payload.priceParts;
  const monthName = payload[0].payload.name;

  let waterDiff = 0;
  let wasteWaterDiff = 0;
  let dosingDiff = 0;
  let burnMaterialDiff = 0;
  let priceSumDiff = 0;

  try {
    waterDiff =
      (1 - priceParts[payload[0].dataKey].water / priceParts[payload[1].dataKey].water) * 100;
  } catch (e) {
    // nope
    waterDiff = 0;
  }

  try {
    wasteWaterDiff =
      (1 - priceParts[payload[0].dataKey].wasteWater / priceParts[payload[1].dataKey].wasteWater) *
      100;
  } catch (e) {
    // nope
    wasteWaterDiff = 0;
  }

  try {
    dosingDiff =
      (1 - priceParts[payload[0].dataKey].dosing / priceParts[payload[1].dataKey].dosing) * 100;
  } catch (e) {
    // nope
    dosingDiff = 0;
  }

  try {
    burnMaterialDiff =
      (1 -
        priceParts[payload[0].dataKey].burnMaterial / priceParts[payload[1].dataKey].burnMaterial) *
      100;
  } catch (e) {
    // nope
    burnMaterialDiff = 0;
  }

  try {
    priceSumDiff =
      (1 - priceParts[payload[0].dataKey].priceSum / priceParts[payload[1].dataKey].priceSum) * 100;
  } catch (e) {
    // nope
    priceSumDiff = 0;
  }

  return (
    <Box background="white" padding={4}>
      <Box display="flex" justify="space-between" alignItems="center" marginBottom={1} noElevation>
        <table>
          <thead>
            <tr>
              <th>
                <Text size="sm">
                  {monthName} {year}
                </Text>
              </th>
              <th>
                <Text size="sm" opacity=".65">
                  {intl.get(messagesCommon.previousYearShort.id)}
                </Text>
              </th>
              <th>
                <Text size="sm">{intl.get(messagesReport.reportCurrentHeadline.id)}</Text>
              </th>
              <th>
                <Text size="sm">&Delta;{intl.get(messagesCommon.previousYearShort.id)}</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorWater.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].water}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].water}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  bold
                />
              </td>
              <td>
                <Number number={waterDiff} digits={0} round={true} suffix="%" size="sm" />
              </td>
            </tr>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorWaterDirty.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].wasteWater}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].wasteWater}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  bold
                />
              </td>
              <td>
                <Number number={wasteWaterDiff} digits={0} round={true} suffix="%" size="sm" />
              </td>
            </tr>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorDosing.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].dosing}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].dosing}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  bold
                />
              </td>
              <td>
                <Number number={dosingDiff} digits={0} round={true} suffix="%" size="sm" />
              </td>
            </tr>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorBurnMaterial.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].burnMaterial}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].burnMaterial}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  bold
                />
              </td>
              <td>
                <Number number={burnMaterialDiff} digits={0} round={true} suffix="%" size="sm" />
              </td>
            </tr>
            <tr>
              <td>{intl.get(messagesDashboard.total.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].priceSum}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].priceSum}
                  digits={0}
                  round={true}
                  suffix="€"
                  size="sm"
                  bold
                />
              </td>
              <td>
                <Number number={priceSumDiff} digits={0} round={true} suffix="%" size="sm" />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

SteamCostOverTimeTooltip.propTypes = {
  payload: PropTypes.array
};

SteamCostOverTimeTooltip.defaultProps = {
  payload: []
};

export default SteamCostOverTimeTooltip;
