import Box from 'components/Box';
import Label from 'components/Label';
import LoadingBox from 'components/LoadingBox';
import Select from 'components/Select';
import Text from 'components/Text';
import TextArea from 'components/TextArea';
import TextField from 'components/TextField';
import Toggle from 'components/Toggle';
import { REQUIRED_FIELDS } from 'constants/Form';
import { ComponentTypes } from 'constants/SteamComponent';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon, messagesComponentFields } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function DataGeneral({ type, component, onChange }) {
  const formConfig = REQUIRED_FIELDS.component[type];
  const formValidator = useFormValidation();
  const formOptions = useFormOptions();
  const [steamBoilerComponents, setSteamBoilerComponents] = useState([]);
  const [steamBoilerOptions, setSteamBoilerOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSteamBoilerComponents = async () => {
      setLoading(true);
      const docs = [];
      const options = [];

      const snapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );

      snapshot.forEach(doc => {
        const data = doc.data();
        if (data.type === ComponentTypes.SteamBoiler) {
          docs.push({
            id: doc.id,
            ref: doc.ref,
            data
          });
          options.push({
            label: data.internalName,
            value: doc.id
          });
        }
      });

      setSteamBoilerComponents(docs);
      setSteamBoilerOptions(options);
      setLoading(false);
    };

    if (type === ComponentTypes.Burner || type === ComponentTypes.FeedWater) {
      loadSteamBoilerComponents();
    }

    setLoading(false);
  }, [type]);

  const updateBurnerSteamBoiler = update => {
    const steamBoilerComponent = steamBoilerComponents.filter(
      component => component.id === update.value
    )[0];
    onChange('steamBoiler', steamBoilerComponent.ref);
  };

  const renderExtraFieldsByType = () => {
    switch (type) {
      case ComponentTypes.WaterTreatment:
        return null;
      case ComponentTypes.FeedWater:
        return renderFeedWaterFields();
      case ComponentTypes.SteamBoiler:
        return renderSteamBoilerFields();
      case ComponentTypes.Dosing:
        return null;
      case ComponentTypes.Burner:
        return renderBurnerFields();
      default:
        return null;
    }
  };

  const renderSteamBoilerFields = () => {
    return (
      <>
        <Box marginY={3}>
          <Label htmlFor="size">{intl.get(messagesComponentFields.size.id)}</Label>
          <TextField
            id="size"
            type="text"
            placeholder=""
            value={component?.size}
            onChange={e => onChange('size', e.currentTarget.value)}
          />
        </Box>
        <Box marginY={3}>
          <Label htmlFor="power">{intl.get(messagesComponentFields.power.id)}</Label>
          <TextField
            id="power"
            type="text"
            placeholder=""
            value={component?.power}
            onChange={e => onChange('power', e.currentTarget.value)}
          />
        </Box>
        <Box marginY={3}>
          <Label htmlFor="allowed-operating-pressure">
            {intl.get(messagesComponentFields.allowedOperatingPressure.id)}
          </Label>
          <TextField
            id="allowed-operating-pressure"
            type="text"
            placeholder=""
            value={component?.operatingPressure}
            onChange={e => onChange('operatingPressure', e.currentTarget.value)}
          />
        </Box>
        <Box marginY={3}>
          <Label htmlFor="build-year">{intl.get(messagesComponentFields.buildYear.id)}</Label>
          <TextField
            id="build-year"
            type="text"
            placeholder=""
            value={component?.buildYear}
            onChange={e => onChange('buildYear', e.currentTarget.value)}
          />
        </Box>
      </>
    );
  };

  const renderFeedWaterFields = () => {
    const multipleSteamBoiler = steamBoilerComponents.length > 1;
    return (
      <>
        <Box
          marginY={3}
          display="flex"
          direction="row"
          wrap="no-wrap"
          title={
            multipleSteamBoiler ? null : intl.get(messagesCommon.steamComponentMultipleNotice.id)
          }
        >
          <Box marginRight={6}>
            <Label htmlFor="steamBoilersHaveMeter" noMarginBottom={true}>
              {intl.get(messagesCommon.steamComponentMultipleLabel.id)}
            </Label>
          </Box>

          <Toggle
            enabled={component?.steamBoilersHaveMeter}
            interactable={multipleSteamBoiler}
            onChange={active => onChange('steamBoilersHaveMeter', active)}
          />
        </Box>
        {multipleSteamBoiler && component.steamBoilersHaveMeter && (
          <Box>
            <Text size="xs">{intl.get(messagesCommon.steamComponentMultipleWarning.id)}</Text>
          </Box>
        )}
      </>
    );
  };

  const renderBurnerFields = () => {
    return (
      <>
        <Box marginY={3}>
          <Label
            htmlFor="steamBoiler"
            required={formValidator.isFieldRequired(formConfig, 'steamBoiler')}
            valid={formValidator.isFieldValid(formConfig, 'steamBoiler', component)}
          >
            {intl.get(messagesComponentFields.steamBoiler.id)}
          </Label>
          <Select
            id="steamBoiler"
            options={steamBoilerOptions}
            placeholder={intl.get(messagesCommon.choose.id)}
            value={component && component.steamBoiler ? component.steamBoiler.id : ''}
            onChange={e => updateBurnerSteamBoiler(e)}
          />
        </Box>
        <Box marginY={3}>
          <Label
            htmlFor="burnMaterial1"
            required={formValidator.isFieldRequired(formConfig, 'burnMaterial1')}
            valid={formValidator.isFieldValid(formConfig, 'burnMaterial1', component)}
          >
            {intl.get(messagesComponentFields.burnMaterial1.id)}
          </Label>
          <Select
            id="burnMaterial1"
            options={formOptions.fuel}
            placeholder={intl.get(messagesCommon.choose.id)}
            value={component?.burnMaterial1}
            onChange={e => onChange('burnMaterial1', e.value)}
          />
        </Box>
        <Box marginY={3}>
          <Label htmlFor="burnMaterial2">
            {intl.get(messagesComponentFields.burnMaterial2.id)}
          </Label>
          <Select
            id="burnMaterial2"
            options={formOptions.fuel}
            placeholder={intl.get(messagesCommon.choose.id)}
            value={component?.burnMaterial2}
            onChange={e => onChange('burnMaterial2', e.value)}
            showResetOption={true}
            emptyLabel={`=> ${intl.get(messagesCommon.reset.id)}`}
          />
        </Box>
      </>
    );
  };

  return (
    <LoadingBox loading={loading} renderChildren={!loading}>
      <Box marginY={3}>
        <Label htmlFor="manufacturer-type">{intl.get(messagesCommon.manufacturerOrType.id)}</Label>
        <TextField
          id="manufacturer-type"
          type="text"
          placeholder=""
          value={component?.productType}
          onChange={e => onChange('productType', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="internalName"
          required={formValidator.isFieldRequired(formConfig, 'internalName')}
          valid={formValidator.isFieldValid(formConfig, 'internalName', component)}
        >
          {intl.get(messagesCommon.internalLabel.id)}
        </Label>
        <TextField
          id="internalName"
          type="text"
          placeholder=""
          value={component?.internalName}
          onChange={e => onChange('internalName', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label htmlFor="notes">{intl.get(messagesCommon.notes.id)}</Label>
        <TextArea
          id="notes"
          placeholder=""
          value={component?.note}
          onChange={e => onChange('note', e.currentTarget.value)}
        />
      </Box>
      {renderExtraFieldsByType()}
    </LoadingBox>
  );
}

DataGeneral.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  component: PropTypes.any
};

DataGeneral.defaultProps = {
  component: null
};

export default DataGeneral;
