import BarChartBox from 'components/BarChartBox';
import Box from 'components/Box';
import GaugeChartBox from 'components/GaugeChartBox';
import Heading from 'components/Heading';
import InfoIcon from 'components/InfoIcon';
import LoadingBox from 'components/LoadingBox';
import StatisticBox from 'components/StatisticBox';
import useFlattenFirebaseDoc from 'hooks/useFlattenFirebaseDoc';
import { messagesCommon, messagesDashboard } from 'messages/messages';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import DashboardService from 'services/DashboardService';
import FirebaseService from 'services/FirebaseService';
import SteamConsumptionBarChart from './SteamConsumptionBarChart';
import SteamCostBarChart from './SteamCostBarChart';
import SteamCostOverTimeBarChart from './SteamCostOverTimeBarChart';

function Dashboard() {
  const { flattenMeasurement, flattenComponent } = useFlattenFirebaseDoc();

  const fullYearString = new Date().getFullYear();
  const prevYearText = intl.get(messagesCommon.previousYearShort.id);

  const [loading, setLoading] = useState(true);
  const [barChartDataKeys] = useState([prevYearText, fullYearString]);
  const [dashboardData, setDashboardData] = useState(null);

  // Effect to load components
  useEffect(() => {
    const populateAndFlatten = async queryDocs => {
      const loadLastData = queryDocs.map(async queryDoc => {
        const doc = queryDoc.data();
        let lastMeasurement = null;

        if (doc.lastMeasurement) {
          const lastMeasurementRef = await doc.lastMeasurement.get();
          lastMeasurement = flattenMeasurement({
            id: lastMeasurementRef.id,
            ...lastMeasurementRef.data()
          });
        }

        const item = flattenMeasurement({ ...doc });
        item.lastMeasurement = lastMeasurement;

        return item;
      });

      let measurementsWithLastData = await Promise.all(loadLastData);
      // only use measurements that actually have last data
      measurementsWithLastData = measurementsWithLastData.filter(
        item => item.lastMeasurement !== null
      );

      return measurementsWithLastData;
    };

    const load = async () => {
      setLoading(true);
      const componentDocs = [];
      const componentSnapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );
      componentSnapshot.forEach(doc => {
        const item = flattenComponent({
          id: doc.id,
          ...doc.data()
        });
        componentDocs.push(item);
      });

      const measurementSnapshot = await FirebaseService.getMeasurementsOfLocationsRef(
        AppStateService.location.ref,
        {
          dateFrom: moment()
            .startOf('year')
            .toDate(),
          dateTo: moment().toDate()
        },
        'asc'
      ).get();

      const measurementsSnapShotLastYear = await FirebaseService.getMeasurementsOfLocationsRef(
        AppStateService.location.ref,
        {
          dateFrom: moment()
            .subtract(1, 'years')
            .startOf('year')
            .toDate(),
          dateTo: moment()
            .subtract(1, 'years')
            .endOf('year')
            .toDate()
        },
        'asc'
      ).get();

      const measuementDocs = await populateAndFlatten(measurementSnapshot.docs);
      const measuementLastYearDocs = await populateAndFlatten(measurementsSnapShotLastYear.docs);
      const data = await DashboardService.getDashboardData(
        measuementDocs,
        measuementLastYearDocs,
        componentDocs,
        prevYearText,
        fullYearString
      );

      setDashboardData(data);
      setLoading(false);
    };

    load();
  }, []);

  return (
    <Box maxWidth="1600px">
      <LoadingBox loading={loading} renderChildren={!loading}>
        <>
          {dashboardData && (
            <>
              <Box marginX={7}>
                <Heading level="1" size="xl">
                  {intl.get(messagesDashboard.gaugeHeadline.id)}
                  <InfoIcon infoText={intl.get(messagesDashboard.gaugeInfo.id)} />
                </Heading>
              </Box>
              <Box display="flex" flexFlow="row wrap" justify="flex-start" marginX={5}>
                <GaugeChartBox
                  title={intl.get(messagesDashboard.gaugeSteam.id)}
                  unit="&euro;/t"
                  segments={[20, 30, 40, 50]}
                  segmentColors={['#37d597', '#ebcb8b', '#dc3961']}
                  value={dashboardData.gaugeData.steamCost.last}
                  diffValue={dashboardData.gaugeData.steamCost.compare}
                  maxValue={50}
                  minValue={20}
                  moodWhenHigher="negative"
                  moodWhenLower="positive"
                />
                <GaugeChartBox
                  title={intl.get(messagesDashboard.gaugeEnergy.id)}
                  unit="kWh/t"
                  segments={[500, 700, 900, 1100]}
                  segmentColors={['#37d597', '#ebcb8b', '#dc3961']}
                  value={dashboardData.gaugeData.energyConsumption.last}
                  diffValue={dashboardData.gaugeData.energyConsumption.compare}
                  digits={0}
                  round={true}
                  maxValue={1100}
                  minValue={500}
                  moodWhenHigher="negative"
                  moodWhenLower="positive"
                />
                <GaugeChartBox
                  title={intl.get(messagesDashboard.gaugeDosing.id)}
                  unit="ppm"
                  segments={[0, 50, 100, 200]}
                  segmentColors={['#37d597', '#ebcb8b', '#dc3961']}
                  value={dashboardData.gaugeData.dosingRate.last}
                  diffValue={dashboardData.gaugeData.dosingRate.compare}
                  digits={0}
                  round={true}
                  maxValue={200}
                  minValue={0}
                  moodWhenHigher="negative"
                  moodWhenLower="positive"
                />
                <GaugeChartBox
                  title={intl.get(messagesDashboard.gaugeThicc.id)}
                  unit="x"
                  segments={[0, 10, 50, 100]}
                  segmentColors={['#dc3961', '#ebcb8b', '#37d597']}
                  value={dashboardData.gaugeData.thickeningFactor.last}
                  diffValue={dashboardData.gaugeData.thickeningFactor.compare}
                  digits={0}
                  round={true}
                  maxValue={100}
                  minValue={0}
                />
              </Box>

              <Box marginX={7} marginTop={7}>
                <Heading level="1" size="xl">
                  {intl.get(messagesDashboard.steamChartsHeadline.id)}
                  <InfoIcon infoText={intl.get(messagesDashboard.steamChartsInfo.id)} />
                </Heading>
              </Box>
              <Box display="flex" flexFlow="row wrap" justify="flex-start" marginX={5}>
                <BarChartBox
                  title={intl.get(messagesDashboard.steamChartsCosts.id)}
                  subtitle={intl.get(messagesDashboard.steamChartsSince.id)}
                  width="25%"
                  showDiff={true}
                  value={dashboardData.steamCostBarChartConfig[0][fullYearString]}
                  diffValue={dashboardData.steamCostBarChartConfig[0][prevYearText]}
                >
                  <SteamCostBarChart
                    data={dashboardData.steamCostBarChartConfig}
                    dataKeys={barChartDataKeys}
                  />
                </BarChartBox>
                <BarChartBox
                  title={intl.get(messagesDashboard.steamChartsCunsumption.id)}
                  subtitle={intl.get(messagesDashboard.steamChartsSince.id)}
                  width="25%"
                  showDiff={true}
                  value={dashboardData.steamConsumptionBarChartConfig[0][fullYearString]}
                  diffValue={dashboardData.steamConsumptionBarChartConfig[0][prevYearText]}
                >
                  <SteamConsumptionBarChart
                    data={dashboardData.steamConsumptionBarChartConfig}
                    dataKeys={barChartDataKeys}
                  />
                </BarChartBox>
                <BarChartBox
                  title={intl.get(messagesDashboard.steamChartsCostOverTime.id)}
                  subtitle={intl.get(messagesDashboard.steamChartsSince.id)}
                  width="50%"
                >
                  <SteamCostOverTimeBarChart
                    data={dashboardData.steamCostOverYearBarChartConfig}
                    dataKeys={barChartDataKeys}
                  />
                </BarChartBox>
              </Box>

              <Box marginX={7} marginTop={7}>
                <Heading level="1" size="xl">
                  {intl.get(messagesDashboard.performanceHeadline.id)}
                  <InfoIcon infoText={intl.get(messagesDashboard.performanceInfo.id)} />
                </Heading>
              </Box>
              <Box display="flex" flexFlow="row no-wrap" justify="space-between" marginX={5}>
                <StatisticBox
                  title={intl.get(messagesDashboard.performanceCondensate.id)}
                  value={dashboardData.performanceData.condensateSavings.current}
                  diffValue={dashboardData.performanceData.condensateSavings.diff}
                  unit="€/t"
                  digits={2}
                  round={true}
                />
                <StatisticBox
                  title={intl.get(messagesDashboard.performanceFreshWater.id)}
                  value={dashboardData.performanceData.freshWaterCosts.current}
                  diffValue={dashboardData.performanceData.freshWaterCosts.diff}
                  unit="€/m3"
                  digits={2}
                  round={true}
                  moodWhenHigher="negative"
                  moodWhenLower="positive"
                />
                <StatisticBox
                  title={intl.get(messagesDashboard.performanceFeedWater.id)}
                  value={dashboardData.performanceData.feedWaterCosts.current}
                  diffValue={dashboardData.performanceData.feedWaterCosts.diff}
                  unit="€/m3"
                  digits={2}
                  round={true}
                  moodWhenHigher="negative"
                  moodWhenLower="positive"
                />
                <StatisticBox
                  title={intl.get(messagesDashboard.performanceCondensateReturn.id)}
                  value={dashboardData.performanceData.condensateReturn.current}
                  diffValue={dashboardData.performanceData.condensateReturn.diff}
                  unit="%"
                  digits={0}
                  round={true}
                />
                <StatisticBox
                  title={intl.get(messagesDashboard.performanceEmission.id)}
                  value={dashboardData.performanceData.emission.current}
                  diffValue={dashboardData.performanceData.emission.diff}
                  unit="t"
                  digits={0}
                  round={true}
                  moodWhenHigher="negative"
                  moodWhenLower="positive"
                />
              </Box>
            </>
          )}
        </>
      </LoadingBox>
    </Box>
  );
}

export default Dashboard;
