import Box from 'components/Box';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';
import FloatyBox from 'react-floatybox';

const InfoIcon = ({ infoText }) => {
  // const [showInfo, setShowInfo] = useState(false);

  const infoBubble = () => {
    return (
      <Box background="white" width={250} paddingX={5} paddingY={4}>
        <Box direction="column" display="flex">
          <Text size="sm">{infoText}</Text>
        </Box>
      </Box>
    );
  };

  return (
    <FloatyBox bubble={infoBubble} open="hover" side="bottom" align="left" alignInner="right">
      <Box
        display="inline-block"
        background="blue"
        borderRadius="50"
        width="20px"
        height="20px"
        cursor="pointer"
        marginLeft={3}
      >
        <Box display="flex" justify="center" alignItems="center" height="20px" cursor="pointer">
          <Text size="sm" color="white" bold inline>
            i
          </Text>
        </Box>
      </Box>
    </FloatyBox>
  );
};

InfoIcon.propTypes = {
  infoText: PropTypes.string
};

InfoIcon.defaultProps = {
  infoText: ''
};

export default InfoIcon;
