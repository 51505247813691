import Panel from 'components/Panel';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import NotificationService from 'services/NotificationService';
import CheckFormEdit from './CheckFormEdit';

function PanelCheck({ check, onClose }) {
  const formValidator = useFormValidation();
  const [checkData, setCheckData] = useState(check && check.data);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isCreateMode] = useState(!check);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const validData = formValidator.validateExternalCheck(checkData);
    setValid(validData);
  }, [checkData, formValidator]);

  const handleFormFieldChange = (key, value) => {
    const data = { ...checkData };
    data[key] = value;
    setCheckData(data);
  };

  const save = async () => {
    try {
      setSaveInProgress(true);
      const dataToSave = { ...checkData };
      const id = isCreateMode ? null : check.id;
      if (isCreateMode) {
        dataToSave.location = AppStateService.location.ref;
        dataToSave.active = true;
        dataToSave.nextDueDate = dataToSave.startDate;
      }

      const result = await FirebaseService.updateExternalCheck(id, dataToSave);

      if (isCreateMode) {
        // Create initial notification for external check
        await NotificationService.addExternalCheckNotification(dataToSave, result);
      }

      setSaveInProgress(false);
      onClose();
    } catch (e) {
      console.log('External check could not be inserted or updated');
      console.log(e);
      setSaveInProgress(false);
    }
  };

  return (
    <Panel
      title={
        isCreateMode ? intl.get(messagesCrud.checkAdd.id) : intl.get(messagesCrud.checkEdit.id)
      }
      buttonPrimaryAction={save}
      buttonPrimaryDisabled={!valid}
      onClose={onClose}
      showSpinner={saveInProgress}
      isOpen
    >
      <CheckFormEdit
        externalCheck={checkData}
        isCreateMode={isCreateMode}
        onChange={handleFormFieldChange}
      />
    </Panel>
  );
}

PanelCheck.propTypes = {
  check: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

PanelCheck.defaultProps = {
  check: null
};

export default PanelCheck;
