import { ReactComponent as ArrowDown } from 'images/ArrowSmallDown.svg';
import { ReactComponent as ArrowUp } from 'images/ArrowSmallUp.svg';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const StyledBadge = styled.span`
  background-color: ${props => props.theme.colors[props.moodColor]};
  color: ${props =>
    props.moodColor === 'white' ? props.theme.colors.blue : props.theme.colors.white};
  border-radius: 17px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  max-height: 34px;
  margin-top: -4px;
  padding: 9px 11px;
  white-space: nowrap;
  ${props => (props.position === 'middle' ? 'vertical-align: middle' : 'vertical-align: super')};
  ${props => (props.moodColor === 'white' ? 'border: 1px solid' : '')};
  ${props => (props.moodColor === 'white' ? `border-color: ${props.theme.colors.blue}` : '')};
`;

function Badge({ mood, position = 'middle', text, arrow }) {
  let moodColor = 'white';

  if (mood === 'positive') {
    moodColor = 'green';
  } else if (mood === 'negative') {
    moodColor = 'red';
  }

  return (
    <StyledBadge moodColor={moodColor} position={position}>
      {arrow === 'up' && <ArrowUp />}
      {arrow === 'down' && <ArrowDown />} {text}
    </StyledBadge>
  );
}

/*
  ${props =>
    !props.positive &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
      {arrow}
*/

Badge.propTypes = {
  mood: PropTypes.oneOf(['positive', 'negative', 'neutral']).isRequired,
  position: PropTypes.oneOf(['middle', 'top']),
  text: PropTypes.string.isRequired,
  arrow: PropTypes.oneOf(['up', 'down', false])
};

Badge.defaultProps = {
  position: 'middle',
  arrow: false
};

export default Badge;
