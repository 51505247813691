export const NOTIFICATION_TYPES = {
  ValueOutOfBoundsMax: 'VALUE_OUT_OF_BOUNDS_MAX',
  ValueOutOfBoundsMin: 'VALUE_OUT_OF_BOUNDS_MIN',
  IntervalCheck: 'INTERVAL_CHECK_MESSAGE'
};

export const NOTIFICATIONS = {
  VALUE_OUT_OF_BOUNDS_MAX: {
    key: 'valueOutOfBoundsMax',
    color: 'red',
    linkToMeasurement: true,
    actionText: 'statusActionRequired'
  },
  VALUE_OUT_OF_BOUNDS_MIN: {
    key: 'valueOutOfBoundsMax',
    color: 'red',
    linkToMeasurement: true,
    actionText: 'statusActionRequired'
  },
  INTERVAL_CHECK_MESSAGE: {
    key: 'intervalCheckMessage',
    color: 'orange',
    linkToMeasurement: false,
    actionText: 'statusNotice'
  }
};

export const NOTIFICATION_ACTION_MESSAGE = [
  {
    componentType: 'WATER_TREATMENT',
    parameterName: 'hardness',
    actionText: 'notification.action.waterTreatment.hardness'
  },
  {
    componentType: 'WATER_TREATMENT',
    parameterName: 'conductivity',
    actionText: 'notification.action.waterTreatment.conductivity'
  },
  {
    componentType: 'FEED_WATER',
    parameterName: 'ph',
    actionText: 'notification.action.feedWater.ph'
  },
  {
    componentType: 'FEED_WATER',
    parameterName: 'conductivity',
    actionText: 'notification.action.feedWater.conductivity'
  },
  {
    componentType: 'FEED_WATER',
    parameterName: 'hardness',
    actionText: 'notification.action.feedWater.hardness'
  },
  {
    componentType: 'FEED_WATER',
    parameterName: 'pAlkal',
    actionText: 'notification.action.feedWater.p'
  },
  {
    componentType: 'FEED_WATER',
    parameterName: 'phosphat',
    actionText: 'notification.action.waterTreatment.phosphat'
  },
  {
    componentType: 'FEED_WATER',
    parameterName: 'iron',
    actionText: 'notification.action.waterTreatment.iron'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'ph',
    actionText: 'notification.action.steamBoiler.ph'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'conductivity',
    actionText: 'notification.action.steamBoiler.conductivity'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'hardness',
    actionText: 'notification.action.steamBoiler.hardness'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'pAlkal',
    actionText: 'notification.action.steamBoiler.p'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'mAlkal',
    actionText: 'notification.action.steamBoiler.m'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'phosphat',
    actionText: 'notification.action.steamBoiler.phosphat'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'sulfit',
    actionText: 'notification.action.steamBoiler.sulfit'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'tannin',
    actionText: 'notification.action.steamBoiler.tannin'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'amin',
    actionText: 'notification.action.steamBoiler.amin'
  },
  {
    componentType: 'STEAM_BOILER',
    parameterName: 'iron',
    actionText: 'notification.action.steamBoiler.iron'
  },
  {
    componentType: 'CONDENSATE',
    parameterName: 'conductivity',
    actionText: 'notification.action.condensate.conductivity'
  }
];
