import { messagesNavigation } from 'messages/messages';
import React from 'react';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import styled, { css } from 'styled-components/macro';
import { renderMenuItemByPackage } from 'utils/RenderByPackage';

const StyledNavigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: ${props => props.theme.colors.white};
  box-shadow: 4px 4px 17px 0 rgba(0, 0, 0, 0.05);
  min-width: 200px;
  max-width: 200px;
  min-height: 100%;
  padding-top: 26px;
  z-index: 100;

  .bottom {
    margin-top: auto;
    margin-bottom: 30px;
  }
`;

const StyledList = styled.ul`
  width: 100%;
  list-style-type: none;
`;

const StyledListItem = styled.li`
  .text {
    color: ${props => props.theme.colors.black};
    display: block;
    font-size: 14px;
    padding: 12px 0 12px 30px;
    text-decoration: none;

    ${props =>
      props.highlight &&
      css`
        color: rgba(5, 16, 20, 0.25);
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        padding-top: 25px;
        padding-bottom: 0;
      `};

    &:hover {
      background: ${props => !props.highlight && props.theme.colors.blue};
      color: ${props => !props.highlight && props.theme.colors.white};
    }

    &.disabled-link {
      pointer-events: none;
      cursor: not-allowed;
      color: ${props => props.theme.colors.grey};
      background: none;

      &:hover {
        background: none;
        color: ${props => props.theme.colors.grey};
      }
    }
  }

  .selected {
    background: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
  }
`;

function Navigation() {
  const hasCustomer = () => {
    return AppStateService.customer !== null;
  };

  const hasLocation = () => {
    return AppStateService.location !== null;
  };

  return (
    <StyledNavigation>
      <StyledList>
        {(AppStateService.isSuperAdmin() ||
          renderMenuItemByPackage(AppStateService.location, 'dashboard')) && (
          <StyledListItem>
            <NavLink
              exact
              to="/dashboard"
              className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
              activeClassName="selected"
            >
              {intl.get(messagesNavigation.dashboard.id)}
            </NavLink>
          </StyledListItem>
        )}

        {(AppStateService.isSuperAdmin() ||
          renderMenuItemByPackage(AppStateService.location, 'boiler-book')) && (
          <StyledListItem>
            <NavLink
              to="/boiler-book"
              className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
              activeClassName="selected"
              isActive={(match, location) => {
                return location.pathname === '/' || location.pathname === '/boiler-book';
              }}
            >
              {intl.get(messagesNavigation.boilerBook.id)}
            </NavLink>
          </StyledListItem>
        )}

        {!AppStateService.isServicePartner() && (
          <>
            <StyledListItem highlight={true}>
              <span className="text">{intl.get(messagesNavigation.analyses.id)}</span>
            </StyledListItem>

            {(AppStateService.isSuperAdmin() ||
              renderMenuItemByPackage(AppStateService.location, 'reports')) && (
              <StyledListItem>
                <NavLink
                  to="/reports"
                  className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
                  activeClassName="selected"
                >
                  {intl.get(messagesNavigation.reporting.id)}
                </NavLink>
              </StyledListItem>
            )}

            {(AppStateService.isSuperAdmin() ||
              renderMenuItemByPackage(AppStateService.location, 'analysis-operational-data')) && (
              <StyledListItem>
                <NavLink
                  to="/analysis-operational-data"
                  className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
                  activeClassName="selected"
                >
                  {intl.get(messagesNavigation.factoryData.id)}
                </NavLink>
              </StyledListItem>
            )}

            {(AppStateService.isSuperAdmin() ||
              renderMenuItemByPackage(AppStateService.location, 'analysis-water-parameter')) && (
              <StyledListItem>
                <NavLink
                  to="/analysis-water-parameter"
                  className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
                  activeClassName="selected"
                >
                  {intl.get(messagesNavigation.waterParameter.id)}
                </NavLink>
              </StyledListItem>
            )}
          </>
        )}

        {!AppStateService.isServicePartner() && (
          <>
            <StyledListItem highlight={true}>
              <span className="text">{intl.get(messagesNavigation.settings.id)}</span>
            </StyledListItem>

            {(AppStateService.isSuperAdmin() ||
              renderMenuItemByPackage(AppStateService.location, 'steam-plant')) && (
              <StyledListItem>
                <NavLink
                  to="/steam-plant"
                  className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
                  activeClassName="selected"
                >
                  {intl.get(messagesNavigation.steamPlant.id)}
                </NavLink>
              </StyledListItem>
            )}

            {(AppStateService.isSuperAdmin() ||
              renderMenuItemByPackage(AppStateService.location, 'external-checks')) && (
              <StyledListItem>
                <NavLink
                  to="/external-checks"
                  className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
                  activeClassName="selected"
                >
                  {intl.get(messagesNavigation.externalChecks.id)}
                </NavLink>
              </StyledListItem>
            )}

            {(AppStateService.isSuperAdmin() ||
              renderMenuItemByPackage(AppStateService.location, 'steam-costs')) && (
              <StyledListItem>
                <NavLink
                  to="/steam-costs"
                  className={`text ${!hasLocation() ? 'disabled-link' : ''}`}
                  activeClassName="selected"
                >
                  {intl.get(messagesNavigation.steamCosts.id)}
                </NavLink>
              </StyledListItem>
            )}
          </>
        )}

        {(AppStateService.isSuperAdmin() || AppStateService.isAdmin()) && (
          <StyledListItem>
            <NavLink
              to="/company"
              className={`text ${!hasCustomer() ? 'disabled-link' : ''}`}
              activeClassName="selected"
            >
              {intl.get(messagesNavigation.company.id)}
            </NavLink>
          </StyledListItem>
        )}

        {AppStateService.isSuperAdmin() && (
          <>
            <StyledListItem />
            <StyledListItem highlight={true}>
              <span className="text">Super {intl.get(messagesNavigation.admin.id)}</span>
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/parameters" className="text" activeClassName="selected">
                {intl.get(messagesNavigation.parameters.id)}
              </NavLink>
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/customer-overview" className="text" activeClassName="selected">
                {intl.get(messagesNavigation.customers.id)}
              </NavLink>
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/service-partner-overview" className="text" activeClassName="selected">
                {intl.get(messagesNavigation.servicePartners.id)}
              </NavLink>
            </StyledListItem>
          </>
        )}
      </StyledList>
    </StyledNavigation>
  );
}

export default Navigation;
