import Box from 'components/Box';
import Label from 'components/Label';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { REQUIRED_FIELDS } from 'constants/Form';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function CustomerFormEdit({ customer, isServicePartner, onChange }) {
  const formOptions = useFormOptions();
  const formConfig = isServicePartner ? REQUIRED_FIELDS.servicePartner : REQUIRED_FIELDS.customer;
  const formValidator = useFormValidation();

  const handleOnChange = (key, value) => {
    onChange(key, value);
  };

  return (
    <>
      <Box marginY={3}>
        <Label
          htmlFor="company-name"
          required={formValidator.isFieldRequired(formConfig, 'name')}
          valid={formValidator.isFieldValid(formConfig, 'name', customer)}
        >
          {intl.get(messagesCommon.companyName.id)}
        </Label>
        <TextField
          id="company-name"
          type="text"
          placeholder={intl.get(messagesCommon.companyName.id)}
          value={customer?.name}
          onChange={e => handleOnChange('name', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="street"
          required={formValidator.isFieldRequired(formConfig, 'street')}
          valid={formValidator.isFieldValid(formConfig, 'street', customer)}
        >
          {intl.get(messagesCommon.street.id)}
        </Label>
        <TextField
          id="street"
          type="text"
          placeholder={intl.get(messagesCommon.street.id)}
          value={customer?.street}
          onChange={e => handleOnChange('street', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="zip"
          required={formValidator.isFieldRequired(formConfig, 'zip')}
          valid={formValidator.isFieldValid(formConfig, 'zip', customer)}
        >
          {intl.get(messagesCommon.zip.id)}
        </Label>
        <TextField
          id="zip"
          type="text"
          placeholder={intl.get(messagesCommon.zip.id)}
          value={customer?.zip}
          onChange={e => handleOnChange('zip', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="city"
          required={formValidator.isFieldRequired(formConfig, 'city')}
          valid={formValidator.isFieldValid(formConfig, 'city', customer)}
        >
          {intl.get(messagesCommon.city.id)}
        </Label>
        <TextField
          id="city"
          type="text"
          placeholder={intl.get(messagesCommon.city.id)}
          value={customer?.city}
          onChange={e => handleOnChange('city', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="country"
          required={formValidator.isFieldRequired(formConfig, 'country')}
          valid={formValidator.isFieldValid(formConfig, 'country', customer)}
        >
          {intl.get(messagesCommon.country.id)}
        </Label>
        <Select
          id="country"
          options={formOptions.country}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={customer?.country}
          onChange={e => handleOnChange('country', e.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="business"
          required={formValidator.isFieldRequired(formConfig, 'business')}
          valid={formValidator.isFieldValid(formConfig, 'business', customer)}
        >
          {intl.get(messagesCommon.business.id)}
        </Label>
        <Select
          id="business"
          options={isServicePartner ? formOptions.businessSP : formOptions.business}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={customer && customer.business ? customer.business : ''}
          onChange={e => onChange('business', e.value)}
        />
      </Box>
    </>
  );
}

CustomerFormEdit.propTypes = {
  customer: PropTypes.any,
  isServicePartner: PropTypes.bool,
  onChange: PropTypes.func
};

CustomerFormEdit.defaultProps = {
  customer: null,
  isServicePartner: false,
  onChange: PropTypes.func
};

export default CustomerFormEdit;
