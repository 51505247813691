import Panel from 'components/Panel';
import { messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import LocationAddForm from './LocationAddForm';

function PanelLocation({ onLocationAdd, selectedLocations, onClose }) {
  const addLocation = async location => {
    onLocationAdd(location);
    onClose();
  };

  return (
    <Panel title={intl.get(messagesCrud.locationAdd.id)} onClose={onClose} isOpen>
      <LocationAddForm onSelect={addLocation} selectedLocations={selectedLocations} />
    </Panel>
  );
}

PanelLocation.propTypes = {
  selectedLocations: PropTypes.array.isRequired,
  onLocationAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PanelLocation;
