import Box from 'components/Box';
import CountWithText from 'components/CountWithText';
import Text from 'components/Text';
import { ComponentTypes } from 'constants/SteamComponent';
import useFormOptions from 'hooks/useFormOptions';
import { messagesCommon, messagesCostFactor } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function SteamComponentSpecifics({ componentDoc, activeValues, externalChecks }) {
  const formOptions = useFormOptions();
  let renderBlock = null;

  if (componentDoc === null) {
    return null;
  }

  switch (componentDoc.data.type) {
    case ComponentTypes.WaterTreatment:
    case ComponentTypes.FeedWater:
    case ComponentTypes.Custom:
    case ComponentTypes.SteamBoiler: {
      renderBlock = (
        <Box marginTop={5}>
          <CountWithText
            text={intl.get(messagesCommon.waterAnalysis.id)}
            count={activeValues[componentDoc.id].waterValues?.length}
          />
          <CountWithText
            text={intl.get(messagesCommon.operatingValues.id)}
            count={activeValues[componentDoc.id].operatingValues?.length}
          />
          <CountWithText
            text={intl.get(messagesCommon.counter.id)}
            count={activeValues[componentDoc.id].meterValues?.length}
          />
          <CountWithText
            text={intl.get(messagesCommon.externalCheck.id)}
            count={externalChecks[componentDoc.id].length}
          />
        </Box>
      );
      break;
    }
    case ComponentTypes.Dosing: {
      renderBlock = (
        <>
          <br />
          <Text size="sm" bold opacity=".5">
            {intl.get(messagesCostFactor.costFactorDosing.id)}
          </Text>
          <Text size="sm">
            {componentDoc.dosingChemical ? componentDoc.dosingChemical.data.name : '-'}
          </Text>

          <Box marginTop={6}>
            <CountWithText
              text={intl.get(messagesCommon.externalCheck.id)}
              count={externalChecks[componentDoc.id].length}
            />
          </Box>
        </>
      );
      break;
    }
    case ComponentTypes.Burner: {
      const burnMaterial1 = formOptions.fuel.filter(
        options => options.value === componentDoc.data.burnMaterial1
      )[0];
      const burnMaterial2 = formOptions.fuel.filter(
        options => options.value === componentDoc.data.burnMaterial2
      )[0];

      renderBlock = (
        <>
          <br />
          <Text size="sm" bold opacity=".5">
            {intl.get(messagesCostFactor.costFactorBurnMaterial.id)}
          </Text>
          <Text size="sm">
            {burnMaterial1?.label}
            {burnMaterial2 ? ` + ${burnMaterial2.label}` : ''}
          </Text>

          <Box marginTop={6}>
            <CountWithText
              text={intl.get(messagesCommon.externalCheck.id)}
              count={externalChecks[componentDoc.id].length}
            />
          </Box>
        </>
      );
      break;
    }
    default: {
      renderBlock = (
        <Box marginTop={5}>
          <CountWithText
            text={intl.get(messagesCommon.externalCheck.id)}
            count={externalChecks[componentDoc.id].length}
          />
        </Box>
      );
    }
  }

  return renderBlock;
}

SteamComponentSpecifics.propTypes = {
  componentDoc: PropTypes.any,
  activeValues: PropTypes.any,
  externalChecks: PropTypes.any
};

SteamComponentSpecifics.defaultProps = {
  externalChecks: [],
  activeValues: [],
  componentDoc: null
};

export default SteamComponentSpecifics;
