import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: ${props => props.display};
  color: ${props => (props.valid === false ? props.theme.colors.red : props.theme.colors.black)};
  font-size: 0.875rem;
  font-weight: ${props => (props.regular ? 300 : 500)};
  margin-bottom: ${props => (props.noMarginBottom ? '0px' : '7px')};
`;

function Label({ required, valid, children, htmlFor, display, regular, noMarginBottom }) {
  return (
    <StyledLabel
      display={display}
      htmlFor={htmlFor}
      regular={regular}
      required={required}
      valid={valid}
      noMarginBottom={noMarginBottom}
    >
      {children}
      {required ? ' *' : ''}
    </StyledLabel>
  );
}

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  htmlFor: PropTypes.string.isRequired,
  display: PropTypes.string,
  regular: PropTypes.bool,
  required: PropTypes.bool,
  valid: PropTypes.bool,
  noMarginBottom: PropTypes.bool
};

Label.defaultProps = {
  display: 'block',
  regular: false,
  noMarginBottom: false,
  required: false,
  valid: null
};

export default Label;
