import { ParamCategoryTypes, REQUIRED_FIELDS } from 'constants/Form';

function useFormValidation() {
  const validateDataWithConfig = (config, data) => {
    let valid = true;

    const requiredKeys = Object.keys(config);

    for (let i = 0; i < requiredKeys.length; i += 1) {
      const key = requiredKeys[i];
      if (config[key] === true) {
        if (data !== null && data !== undefined) {
          const val = data[key];

          if (val === undefined || val === null || val === '' || val.length === 0) {
            valid = false;
          }
        } else {
          valid = false;
        }
      }
    }
    return valid;
  };

  const validateComponent = (type, data) => {
    const requiredFields = REQUIRED_FIELDS.component[type];
    const valid = validateDataWithConfig(requiredFields, data);

    // @NOTE perform extra steps for different components after the required field check here
    // => dosing => diluted => containerSize

    return valid;
  };

  const validateUser = data => {
    const requiredFields = REQUIRED_FIELDS.user;
    const valid = validateDataWithConfig(requiredFields, data);
    return valid;
  };

  const validateCustomer = data => {
    const requiredFields = REQUIRED_FIELDS.customer;
    const valid = validateDataWithConfig(requiredFields, data);
    return valid;
  };

  const validateParameter = data => {
    const requiredFields = REQUIRED_FIELDS.parameter;
    let valid = validateDataWithConfig(requiredFields, data);

    // make app form steps a required field when data = parameter and paramter.category is plus minus
    if (data && data.appFormElement === ParamCategoryTypes.PlusMinus) {
      const val = data.appFormSteps;

      if (val === undefined || val === null || val === '' || val.length === 0) {
        valid = false;
      }
    }

    // make app form steps a required field when data = parameter and paramter.category is plus minus
    if (data && data.appFormElement === ParamCategoryTypes.Spinner) {
      const val = data.appNumberRange;

      if (val === undefined || val === null || val === '' || val.length === 0) {
        valid = false;
      }
    }

    // @TODO
    // for form element dropdown
    // NO unit is required
    // has to have at least 2 options

    return valid;
  };

  const validateExternalCheck = data => {
    const requiredFields = REQUIRED_FIELDS.externalCheck;
    const valid = validateDataWithConfig(requiredFields, data);
    return valid;
  };

  const validateServicePartner = data => {
    const requiredFields = REQUIRED_FIELDS.servicePartner;
    const valid = validateDataWithConfig(requiredFields, data);
    return valid;
  };

  const validateLocation = data => {
    const requiredFields = REQUIRED_FIELDS.location;
    const valid = validateDataWithConfig(requiredFields, data);
    return valid;
  };

  const validateCostFactor = data => {
    const requiredFields = REQUIRED_FIELDS.costFactor;
    let valid = validateDataWithConfig(requiredFields, data);

    if (valid) {
      // value must be greater then 0
      if (data.value <= 0) {
        valid = false;
      }
    }

    return valid;
  };

  const isRequiredFieldValid = (config, field, data) => {
    let valid = true;

    if (config[field] && config[field] === true) {
      if (data !== null && data !== undefined) {
        const val = data[field];

        if (val === undefined || val === null || val === '' || val.length === 0) {
          valid = false;
        }
      } else {
        valid = false;
      }
    }

    return valid;
  };

  const isFieldRequired = (config, field, data) => {
    if (config[field] && config[field] === true) {
      return true;
    }

    // @NOTE perform extra checks for conditional required fields here

    // make app form steps a required field when data = parameter and paramter.category is plus minus
    if (field === 'appFormSteps' && data !== undefined) {
      if (data.appFormElement === ParamCategoryTypes.PlusMinus) {
        return true;
      }
    }

    // make app form steps a required field when data = parameter and paramter.category is spinner
    if (field === 'appNumberRange' && data !== undefined) {
      if (data.appFormElement === ParamCategoryTypes.Spinner) {
        return true;
      }
    }

    return false;
  };

  const isFieldValid = (config, field, data) => {
    let valid = false;
    const requiredValid = isRequiredFieldValid(config, field, data);

    // @NOTE perform extra validations here
    // field = email => email validation
    valid = true;

    // make app form steps a required field when data = parameter and paramter.category is plus minus
    if (field === 'appFormSteps' && data !== undefined) {
      if (data.appFormElement === ParamCategoryTypes.PlusMinus) {
        const val = data[field];

        if (val === undefined || val === null || val === '' || val.length === 0) {
          valid = false;
        }
      }
    }

    // make app form steps a required field when data = parameter and paramter.category is spinner
    if (field === 'appNumberRange' && data !== undefined) {
      if (data.appFormElement === ParamCategoryTypes.Spinner) {
        const val = data[field];

        if (val === undefined || val === null || val === '' || val.length === 0) {
          valid = false;
        }
      }
    }

    return valid && requiredValid;
  };

  return {
    validateParameter,
    validateComponent,
    validateUser,
    validateCustomer,
    validateExternalCheck,
    validateServicePartner,
    validateLocation,
    validateCostFactor,
    isFieldRequired,
    isFieldValid
  };
}

export default useFormValidation;
