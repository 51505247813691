import { NOTIFICATION_TYPES } from 'constants/Notification';
import moment from 'moment';
import FirebaseService from './FirebaseService';

const NotificationService = {
  getDaysToNextDue(externalCheck) {
    const now = moment();
    const nextDue = moment(externalCheck.nextDueDate.toDate());
    const daysToNextDueDate = now.diff(nextDue, 'days');

    return daysToNextDueDate;
  },

  isExternalCheckDue(externalCheck) {
    const now = moment();
    const nextDue = moment(externalCheck.nextDueDate.toDate());
    const daysToNextDueDate = now.diff(nextDue, 'days');
    const isDue = daysToNextDueDate > 0;

    return isDue;
  },

  getNextFutureDueDate(date, intervalConfig) {
    const now = moment();
    const dueDate = moment(date);
    const newDueDate = dueDate.clone();

    newDueDate.add(intervalConfig.inDays, 'days');

    const nowDiffInDays = newDueDate.diff(now, 'days');

    // if this is a "future" date return
    if (nowDiffInDays > 0) {
      return newDueDate.toDate();
    }

    // else - recursive calls with the interval to increase the date until in future
    return NotificationService.getNextFutureDueDate(newDueDate.toDate(), intervalConfig);
  },

  async addExternalCheckNotification(data, externalCheck) {
    return NotificationService.createNotification({
      componentRef: data.component,
      locationRef: data.location,
      type: NOTIFICATION_TYPES.IntervalCheck,
      activeAt: data.nextDueDate,
      externalCheckRef: externalCheck
    });
  },

  async createNotification({
    componentRef,
    locationRef,
    measurementRef,
    externalCheckRef,
    param,
    value,
    type,
    activeAt
  }) {
    const notification = {
      component: componentRef,
      location: locationRef,
      measurement: measurementRef !== undefined ? measurementRef : null,
      externalCheck: externalCheckRef !== undefined ? externalCheckRef : null,
      param: param !== undefined ? param : null,
      value: value !== undefined ? value : null,
      type,
      archived: false,
      createdAt: FirebaseService.getTimestamp(),
      activeAt: activeAt !== undefined ? activeAt : FirebaseService.getTimestamp()
    };
    return FirebaseService.updateNotification(null, notification);
  },

  async archiveNotification(notificationRef) {
    let data = notificationRef.data;

    if (typeof notificationRef.data === 'function') {
      data = notificationRef.data();
    }

    return FirebaseService.updateNotification(notificationRef.id, {
      ...data,
      archived: true,
      archivedAt: FirebaseService.getTimestamp()
    });
  }
};

export default NotificationService;
