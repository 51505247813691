import Box from 'components/Box';
import GaugeChart from 'components/GaugeChart';
import Text from 'components/Text';
import useNumberFormat from 'hooks/useNumberFormat';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Badge from './Badge';
import Spinner from './Spinner';

function GaugeChartBox({
  title,
  unit,
  segments,
  segmentColors,
  value,
  diffValue,
  minValue,
  maxValue,
  round,
  digits,
  moodWhenHigher,
  moodWhenLower
}) {
  const [renderChart, setRenderChart] = useState(true);
  const [chartHeight, setChartHeight] = useState(Math.min(250, window.innerWidth / 7));

  // Effect to handle the re-render / resize of speedometer
  useEffect(() => {
    const handleResize = () => {
      setRenderChart(false);
      setChartHeight(Math.min(250, window.innerWidth / 7));

      setTimeout(() => {
        setRenderChart(true);
      }, 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculatePercentageDiff = () => {
    let percent = null;

    if (value !== null && diffValue !== null) {
      try {
        percent = ((value - diffValue) / diffValue) * 100;
      } catch {
        percent = null;
      }
    }
    return percent;
  };

  let valueInBounds = Math.min(Math.max(value, minValue), maxValue);
  let formattedValue = useNumberFormat(value, digits, round);
  let formattedDiffValue = useNumberFormat(diffValue !== null ? diffValue : 0, digits, round);

  if (!isFinite(value) || isNaN(value)) {
    formattedValue = '-';
    valueInBounds = minValue;
  }

  if (!isFinite(diffValue) || isNaN(diffValue)) {
    formattedDiffValue = '-';
  }

  const percentageDiff = calculatePercentageDiff();
  const percentageDiffDisplay = percentageDiff !== null ? parseFloat(percentageDiff.toFixed(0)) : 0;
  let smallDiff = false;

  if (percentageDiff !== null) {
    if (Math.abs(percentageDiff) < 1) {
      smallDiff = true;
    }
  }

  return (
    <Box padding={2} width="25%" justify="center">
      <Box background="white" padding={4}>
        <Box>
          <Text size="sm" bold marginBottom={2} align="center">
            {title}
          </Text>
        </Box>

        {renderChart ? (
          <GaugeChart
            height={chartHeight}
            segments={segments}
            segmentColors={segmentColors}
            value={valueInBounds}
            maxValue={maxValue}
            minValue={minValue}
            currentValueText={`${formattedValue} ${unit}`}
          />
        ) : (
          <Box display="flex" height={chartHeight} justify="center" alignItems="center">
            <Spinner noMargin />
          </Box>
        )}

        {diffValue !== null && (
          <Box
            display="flex"
            flex
            marginTop={7}
            marginBottom={3}
            paddingX={3}
            direction="row"
            justify="space-between"
          >
            <Badge
              mood={percentageDiff > 0 ? moodWhenHigher : moodWhenLower}
              text={smallDiff ? '< 1%' : `${percentageDiffDisplay}%`}
              arrow={percentageDiff > 0 ? 'up' : 'down'}
            />
            <Badge mood="neutral" text={`${formattedDiffValue} ${unit}`} arrow={false} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

GaugeChartBox.propTypes = {
  title: PropTypes.string,
  unit: PropTypes.string,
  round: PropTypes.bool,
  digits: PropTypes.number,
  segments: PropTypes.array.isRequired,
  segmentColors: PropTypes.array.isRequired,
  diffValue: PropTypes.number,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  moodWhenHigher: PropTypes.oneOf(['positive', 'negative']),
  moodWhenLower: PropTypes.oneOf(['positive', 'negative'])
};

GaugeChartBox.defaultProps = {
  title: '',
  unit: '',
  round: true,
  digits: 2,
  diffValue: null,
  moodWhenHigher: 'positive',
  moodWhenLower: 'negative'
};

export default GaugeChartBox;
