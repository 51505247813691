import Box from 'components/Box';
import Label from 'components/Label';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { CostFactorTypes } from 'constants/CostFactor';
import { REQUIRED_FIELDS } from 'constants/Form';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon, messagesCostFactor } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';

function CostFactorFormEdit({ costFactor, onChange, isCreateMode }) {
  const [gasConvertValue, setGasConvertValue] = useState('');
  const formConfig = REQUIRED_FIELDS.costFactor;
  const formValidator = useFormValidation();
  const formOptions = useFormOptions();

  const isNoneFixFactor = () =>
    isCreateMode ||
    costFactor.type === CostFactorTypes.CUSTOM ||
    costFactor.type === CostFactorTypes.DOSING;

  const isGasFactor = () => costFactor.type.indexOf('GAS') >= 0;

  const updateConvertGasValue = value => {
    setGasConvertValue(value);
    const converted = value * costFactor.heatFactor;
    onChange('value', parseFloat(converted.toFixed(3)));
  };

  return (
    <>
      <Box marginY={3}>
        <Label
          htmlFor="panel-type"
          required={formValidator.isFieldRequired(formConfig, 'type')}
          valid={formValidator.isFieldValid(formConfig, 'type', costFactor)}
        >
          {intl.get(messagesCommon.type.id)}
        </Label>
        <Select
          id="panel-type"
          options={isCreateMode ? formOptions.costFactorAddable : formOptions.costFactor}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={costFactor?.type}
          disabled={!isCreateMode}
          isRequired
          onChange={e => onChange('type', e.value)}
        />
      </Box>
      {isNoneFixFactor() && (
        <Box marginY={3}>
          <Label
            htmlFor="panel-name"
            required={formValidator.isFieldRequired(formConfig, 'name')}
            valid={formValidator.isFieldValid(formConfig, 'name', costFactor)}
          >
            {intl.get(messagesCommon.name.id)}
          </Label>
          <TextField
            id="panel-name"
            type="text"
            value={costFactor.name}
            onChange={e => onChange('name', e.currentTarget.value)}
            isRequired
          />
        </Box>
      )}
      {isNoneFixFactor() && (
        <Box marginY={3}>
          <Label
            htmlFor="panel-unit"
            required={formValidator.isFieldRequired(formConfig, 'unit')}
            valid={formValidator.isFieldValid(formConfig, 'unit', costFactor)}
          >
            {intl.get(messagesCommon.unit.id)}
          </Label>
          <TextField
            id="panel-unit"
            type="text"
            value={costFactor.unit}
            disabled={!isNoneFixFactor()}
            onChange={e => onChange('unit', e.currentTarget.value)}
            isRequired
          />
        </Box>
      )}

      <Box marginY={3}>
        <Label
          htmlFor="panel-value"
          required={formValidator.isFieldRequired(formConfig, 'value')}
          valid={formValidator.isFieldValid(formConfig, 'value', costFactor)}
        >
          {intl.get(messagesCostFactor.value.id)}/{costFactor.unit}
        </Label>
        <TextField
          id="panel-value"
          type="number"
          isRequired
          disabled={isGasFactor()}
          value={costFactor.value}
          onChange={e => onChange('value', e.currentTarget.value)}
        />
      </Box>
      {isGasFactor() && (
        <Box marginY={3}>
          <Label
            htmlFor="panel-value-gas-convert"
            required={formValidator.isFieldRequired(formConfig, 'value')}
            valid={formValidator.isFieldValid(formConfig, 'value', costFactor)}
          >
            {intl.get(messagesCommon.priceInEuroKwh.id)}
          </Label>
          <TextField
            id="panel-value-gas-convert"
            type="number"
            isRequired
            value={gasConvertValue}
            onChange={e => updateConvertGasValue(e.currentTarget.value)}
          />
        </Box>
      )}
    </>
  );
}

CostFactorFormEdit.propTypes = {
  costFactor: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool.isRequired
};

export default CostFactorFormEdit;
