import { messagesReport } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import ReportRow from './ReportRow';

function DosingRows({ reportData, reportDataLastYear }) {
  const dosingSumsByChemical = [];

  for (let i = 0; i < reportData.length; i += 1) {
    const reportItem = reportData[i];
    const { dosingSums } = reportItem;

    for (let j = 0; j < dosingSums.length; j += 1) {
      const dosingSum = dosingSums[j];
      const dosingTypeIndex = dosingSumsByChemical.findIndex(
        dosingItem => dosingItem.chemicalId === dosingSum.chemicalId
      );

      if (dosingTypeIndex < 0) {
        dosingSumsByChemical.push({
          chemicalId: dosingSum.chemicalId,
          chemical: dosingSum.chemical,
          currentValue: dosingSum.value,
          lastValue: undefined
        });
      } else {
        dosingSumsByChemical[dosingTypeIndex].currentValue += dosingSum.value;
      }
    }
  }

  for (let i = 0; i < reportDataLastYear.length; i += 1) {
    const reportItemLast = reportDataLastYear[i];
    const { dosingSums } = reportItemLast;

    for (let j = 0; j < dosingSums.length; j += 1) {
      const dosingSum = dosingSums[j];
      const dosingTypeIndex = dosingSumsByChemical.findIndex(
        dosingItem => dosingItem.chemicalId === dosingSum.chemicalId
      );

      if (dosingTypeIndex < 0) {
        dosingSumsByChemical.push({
          chemicalId: dosingSum.chemicalId,
          chemical: dosingSum.chemical,
          currentValue: undefined,
          lastValue: dosingSum.value
        });
      } else {
        if (dosingSumsByChemical[dosingTypeIndex].lastValue === undefined) {
          dosingSumsByChemical[dosingTypeIndex].lastValue = 0;
        }
        dosingSumsByChemical[dosingTypeIndex].lastValue += dosingSum.value;
      }
    }
  }

  return dosingSumsByChemical.map(dosingItem => (
    <ReportRow
      label={intl.get(messagesReport.reportDosingWithName.id, { chemical: dosingItem.chemical })}
      sum={dosingItem.currentValue}
      sumLast={dosingItem.lastValue}
      key={`report-row-dosing-${dosingItem.chemicalId}`}
      unit="kg"
      round={true}
      digits={1}
      reportData={reportData}
      reportDataLastYear={reportDataLastYear}
    />
  ));
}

DosingRows.propTypes = {
  reportData: PropTypes.any.isRequired,
  reportDataLastYear: PropTypes.any.isRequired
};

export default DosingRows;
