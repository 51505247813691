import Box from 'components/Box';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';

const pixelOrGivenValue = value => {
  if (value === 'auto') {
    return 'auto';
  }
  return typeof value === 'number' ? `${value}px` : value;
};

const StyledTabList = styled(Box)`
  width: ${props => `${pixelOrGivenValue(props.width)}`};
  padding: 2px;
  min-height: 40px;

  ${props =>
    props.variant === 'minimal' &&
    css`
      box-shadow: none;
      padding: 0;
      border-bottom: 1px solid ${props.theme.colors.border};
      position: relative;
      left: -20px;
      padding: 0 20px 0px 20px;
    `}
`;

const StyledButton = styled.button`
  background-color: ${props => props.theme.colors.white};
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 4px 14px;

  ${props =>
    props.selected && props.variant === '' && `background-color: ${props.theme.colors.blueLight}`};

  ${props =>
    props.variant === 'minimal' &&
    css`
      border-bottom: 1px solid ${props.theme.colors.border};
      position: relative;
      top: 4px;
      flex: inherit;
      padding: 0;
      opacity: 0.5;
      border-radius: 0;
      padding: 0 0 13px 0;
    `}

  ${props =>
    props.variant === 'minimal' &&
    props.selected &&
    css`
      opacity: 1;
      font-weight: ${props => (props.selected ? '500' : '300')};
      border-bottom: 1px solid ${props => props.theme.colors.black};
    `}
`;

export default function TabList({ variant, width, items, onChange, selectedItemIndex }) {
  const buttonWidth = `${Math.floor(100 / Math.max(1, items.length))}%`;

  return (
    <StyledTabList
      background="white"
      display="flex"
      justify="space-between"
      role="tablist"
      width={width}
      variant={variant}
    >
      {items.map(item => {
        const isSelected = item.index === selectedItemIndex;

        return (
          <StyledButton
            key={`tabListIndex-${item.index}`}
            onClick={event => onChange({ event, activeIndex: item.index })}
            role="tab"
            type="button"
            style={{ width: variant === '' ? buttonWidth : 'auto' }}
            selected={isSelected}
            variant={variant}
          >
            <Text
              color={isSelected && variant === '' ? 'white' : 'black'}
              align="center"
              size="sm"
              weight="bold"
            >
              {item.title}
            </Text>
          </StyledButton>
        );
      })}
    </StyledTabList>
  );
}

TabList.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.shape({ index: PropTypes.number, title: PropTypes.string }))
    .isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItemIndex: PropTypes.number.isRequired
};

TabList.defaultProps = {
  variant: '',
  width: 'auto'
};
