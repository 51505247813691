import Badge from 'components/Badge';
import Box from 'components/Box';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';

function BarChartBox({ children, width, title, subtitle, value, diffValue, showDiff }) {
  const calculatePercentageDiff = () => {
    let percent = null;

    if (value !== null && diffValue !== null) {
      try {
        percent = ((value - diffValue) / diffValue) * 100;
      } catch {
        percent = null;
      }
    }

    if (isNaN(percent) || !isFinite(percent)) {
      percent = null;
    }

    return percent;
  };

  const percentageDiff = calculatePercentageDiff();

  return (
    <Box padding={2} width={width}>
      <Box background="white" padding={4}>
        <Box display="flex" justify="space-between" alignItems="center" alignSelf="stretch">
          <Box>
            <Text size="sm" bold>
              {title}
            </Text>
            <Text size="sm" opacity=".65" marginBottom={2}>
              {subtitle}
            </Text>
          </Box>

          {showDiff && percentageDiff !== null && (
            <Box>
              <Badge
                mood="neutral"
                text={`${percentageDiff > 0 ? '+' : '-'}${Math.abs(percentageDiff.toFixed(1))}%`}
                arrow={false}
              />
            </Box>
          )}
        </Box>
        <Box marginTop={3}>{children}</Box>
      </Box>
    </Box>
  );
}
// <BarChart height={height} data={data} dataKeys={dataKeys} />
BarChartBox.propTypes = {
  children: PropTypes.element.isRequired,
  diffValue: PropTypes.number,
  showDiff: PropTypes.bool,
  value: PropTypes.number,
  width: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

BarChartBox.defaultProps = {
  width: '100%',
  title: '',
  subtitle: '',
  diffValue: 0,
  value: 0,
  showDiff: false
};

export default BarChartBox;
