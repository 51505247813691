import useGetParameterUnit from 'hooks/useGetParameterUnit';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function ValueUnitRangeColumns({ components, activeComponentValues, valueFilter, costFactors }) {
  const { getUnitForParameter } = useGetParameterUnit();
  if (components.length > 0) {
    const activeComponentValuesColumns = components.map(componentRef => {
      const activeValues = activeComponentValues[componentRef.id];
      const valueTypes = ['waterValues', 'operatingValues', 'meterValues'].filter(
        type => valueFilter[type]
      );

      const activeValueColumns = valueTypes.map(type => {
        const valuesOfType = activeValues[type];
        return valuesOfType.map(value => {
          const unit = getUnitForParameter(value.name, {
            component: componentRef.data,
            costFactors
          });
          return (
            <td key={`measured-value-unit-${value.name}-${componentRef.id}`}>
              <span>{unit !== null ? unit : '-'}</span>
              {value.minValue && value.maxValue && (
                <span>
                  {value.minValue} - {value.maxValue}
                </span>
              )}
              {value.minValue && !value.maxValue && <span>&gt; {value.minValue}</span>}
              {!value.minValue && value.maxValue && <span>&lt; {value.maxValue}</span>}
              {!value.minValue && !value.maxValue && <span>-</span>}
            </td>
          );
        });
      });

      return activeValueColumns;
    });

    const flatColumns = [].concat(...activeComponentValuesColumns);

    return [
      <td key="measurement-date-headline" className="has-padding">
        {intl.get(messagesCommon.date.id)}
      </td>,
      <td key="measurement-tag-headline" className="has-padding">
        {intl.get(messagesCommon.abbreviation.id)}
      </td>,
      <td key="measurement-comment-space" className="has-padding">
        {intl.get(messagesCommon.comment.id)}
      </td>,
      ...flatColumns
    ];
  }

  return null;
}

ValueUnitRangeColumns.propTypes = {
  valueFilter: PropTypes.any.isRequired,
  components: PropTypes.any,
  activeComponentValues: PropTypes.any,
  costFactors: PropTypes.array
};

ValueUnitRangeColumns.defaultProps = {
  components: [],
  activeComponentValues: [],
  costFactors: []
};

export default ValueUnitRangeColumns;
