import Box from 'components/Box';
import Text from 'components/Text';
import TextField from 'components/TextField';
import Toggle from 'components/Toggle';
import useComponentAlwaysOnParam from 'hooks/useComponentAlwaysOnParams';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import ParameterService from 'services/ParameterService';

function DataCounter({ component, parameters, onChange }) {
  const { isParamAlwaysOn } = useComponentAlwaysOnParam();

  const getName = paramName => {
    const param = ParameterService.getParameterByName(paramName);
    const trans = localStorage.getItem('locale') === 'de-DE' ? param.labelDE : param.labelEN;
    return trans;
  };

  const renderRow = parameter => {
    let parameterData = null;

    // try to get parameter values out of designated param container
    try {
      [parameterData] = component.meterValues.filter(value => value.name === parameter.name);
    } catch (e) {
      parameterData = null;
    }

    // send an update with the type and the unit if no data are present
    if (!parameterData) {
      onChange({
        paramSource: 'meterValues',
        param: parameter,
        key: 'name',
        value: parameter.name
      });
    }

    return (
      <tr key={parameter.name}>
        <td width="200">
          <Text size="sm">{getName(parameter.name)}</Text>
        </td>
        <td width="200">
          {parameter.minValue && (
            <TextField
              insideTable={true}
              id={`${parameter.name}-minValue`}
              type="number"
              placeholder="Sollwert Min"
              value={parameterData?.minValue}
              addOn={parameter.unit}
              onChange={e =>
                onChange({
                  paramSource: 'meterValues',
                  param: parameter,
                  key: 'minValue',
                  value: e.currentTarget.value
                })
              }
            />
          )}
        </td>
        <td width="200">
          {parameter.maxValue && (
            <TextField
              insideTable={true}
              id={`${parameter.name}-maxValue`}
              type="number"
              placeholder="Sollwert Max"
              value={parameterData?.maxValue}
              addOn={parameter.unit}
              onChange={e =>
                onChange({
                  paramSource: 'meterValues',
                  param: parameter,
                  key: 'maxValue',
                  value: e.currentTarget.value
                })
              }
            />
          )}
        </td>
        <td width="50">
          <Toggle
            enabled={parameterData?.active}
            interactable={!isParamAlwaysOn(parameter.name)}
            onChange={active =>
              onChange({
                paramSource: 'meterValues',
                param: parameter,
                key: 'active',
                value: active
              })
            }
          />
        </td>
      </tr>
    );
  };

  const renderRowsForParameters = () => {
    if (parameters.length) {
      return parameters.map(parameter => renderRow(parameter));
    }

    return null;
  };

  return (
    <>
      <Box>
        <Text size="sm" bold>
          {intl.get(messagesCommon.steamComponentFormNotice.id)}
        </Text>
      </Box>
      <Box marginTop={5}>
        <table className="table-panel">
          <tbody>{renderRowsForParameters()}</tbody>
        </table>
      </Box>
    </>
  );
}

DataCounter.propTypes = {
  parameters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  component: PropTypes.any
};

DataCounter.defaultProps = {
  component: null
};

export default DataCounter;
