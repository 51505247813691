import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import TextField from 'components/TextField';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon, messagesNavigation } from 'messages/messages';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';
import ParameterService from 'services/ParameterService';
import { sortParameter } from 'utils/SortParameters';
import PanelParameter from './PanelParameter';
import ParameterList from './ParameterList';

function Parameter() {
  const [loading, setLoading] = useState(true);
  const [parameters, setParameters] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelData, setPanelData] = useState(null);

  // Effect to load users and keep them up to date
  useEffect(() => {
    const updateCollection = querySnapshot => {
      setLoading(true);
      const docs = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();

        if (data.isDeleted !== true) {
          docs.push({
            id: doc.id,
            ref: doc,
            data
          });
        }
      });

      ParameterService.update(docs);

      const sorted = sortParameter(docs);

      setParameters(sorted);
      setFiltered(sorted);
      setLoading(false);
    };

    const unsub = FirebaseService.getParametersRef().onSnapshot(updateCollection);

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (filterText.length >= 2) {
      const all = [...parameters];
      const update = all.filter(p => {
        if (p.data.name.toLowerCase().includes(filterText.toLowerCase())) {
          return p;
        }

        if (p.data.labelDE.toLowerCase().includes(filterText.toLowerCase())) {
          return p;
        }

        if (p.data.labelEN.toLowerCase().includes(filterText.toLowerCase())) {
          return p;
        }

        return null;
      });

      setFiltered(update);
    } else {
      setFiltered(parameters);
    }
  }, [filterText]);

  const addParameter = () => {
    openPanel(null);
  };

  const editParameter = param => {
    openPanel(param);
  };

  const openPanel = data => {
    setPanelData(data);
    setPanelOpen(true);
  };

  const closePanel = () => {
    setPanelOpen(false);
    setPanelData(null);
  };

  return (
    <LoadingBox loading={loading}>
      <Box maxWidth="1600px">
        <Box marginX={7} display="flex">
          <Heading level="1" size="xl">
            {intl.get(messagesNavigation.parameters.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => addParameter()}
            />
          </Box>
        </Box>

        <Box marginX={7} marginTop={3} display="flex">
          <TextField
            id="param-filter"
            type="text"
            placeholder={intl.get(messagesCommon.paramSearchPlaceholder.id)}
            value={filterText}
            onChange={e => setFilterText(e.currentTarget.value)}
          />
        </Box>

        <Box background="white" marginX={7} marginTop={3} display="flex">
          <ParameterList parameters={filtered} onEdit={editParameter} />
        </Box>
      </Box>

      {panelOpen ? <PanelParameter parameter={panelData} onClose={closePanel} /> : null}
    </LoadingBox>
  );
}

export default Parameter;
