import { CostFactorTypes } from 'constants/CostFactor';
import { SteamProductionInterferenceTypes } from 'constants/SteamProductionInterference';
import {
  messagesAnalysisFilter,
  messagesCommon,
  messagesComponents,
  messagesCostFactor,
  messagesFormConstants,
  messagesSteamProductionInterference
} from 'messages/messages';
import { INTERVALS } from './Interval';
import { ROLES } from './Model';
import { PackageTypes } from './Packages';
import { ComponentTypeConfig, ComponentTypes } from './SteamComponent';

// boiler book default filter configuration
export const BOILER_BOOK_DEFAULT_FILTER = {
  waterValues: true,
  operatingValues: true,
  meterValues: true
};

export const ParamCategoryTypes = {
  Spinner: 'Spinner',
  PlusMinus: 'PlusMinus'
};

export const FIX_ANALYSIS_PARAMETER = [
  {
    value: 'steamConsumption',
    label: messagesAnalysisFilter.analysisFilterSteam,
    unit: 't'
  },
  {
    value: 'freshWater',
    label: messagesAnalysisFilter.analysisFilterFreshWater,
    unit: 'm3'
  },
  {
    value: 'treatedWater',
    label: messagesAnalysisFilter.analysisFilterClearedWater,
    unit: 'm3'
  },
  {
    value: 'feedWater',
    label: messagesAnalysisFilter.analysisFilterFeedWater,
    unit: 'm3'
  },
  {
    value: 'condensate',
    label: messagesAnalysisFilter.analysisFilterCondensate,
    unit: 't'
  }
];

export const FILTER_DATE_RANGE = [
  { value: 'last30', label: messagesFormConstants.dateRangeOptionsLast30 },
  { value: 'last180', label: messagesFormConstants.dateRangeOptionsLast180 },
  { value: 'last365', label: messagesFormConstants.dateRangeOptionsLast365 }
  // { value: 'customPeriod', label: messagesFormConstants.dateRangeOptionsCustom }
];

export const COUNTRY_OPTIONS = [
  { value: 'DE', label: messagesFormConstants.countryOptionsDE },
  { value: 'AT', label: messagesFormConstants.countryOptionsAT },
  { value: 'CH', label: messagesFormConstants.countryOptionsCH }
];

export const ROLE_OPTIONS = [
  { value: ROLES.Admin, label: messagesFormConstants.roleOptionsAdmin },
  { value: ROLES.User, label: messagesFormConstants.roleOptionsUser }
];

export const COSTFACTOR_OPTIONS = [
  { value: CostFactorTypes.GAS, label: messagesCostFactor.costFactorGas },
  { value: CostFactorTypes.GASN, label: messagesCostFactor.costFactorGas },
  { value: CostFactorTypes.BIOGAS, label: messagesCostFactor.costFactorGas },
  { value: CostFactorTypes.BIOGASN, label: messagesCostFactor.costFactorGas },
  { value: CostFactorTypes.OIL, label: messagesCostFactor.costFactorOil },
  { value: CostFactorTypes.WATER, label: messagesCostFactor.costFactorWater },
  { value: CostFactorTypes.WATER_DIRTY, label: messagesCostFactor.costFactorWaterDirty },
  { value: CostFactorTypes.CUSTOM, label: messagesCostFactor.costFactorCustom },
  { value: CostFactorTypes.DOSING, label: messagesCostFactor.costFactorDosing }
];

export const COSTFACTOR_ADDABLE_OPTIONS = [
  { value: CostFactorTypes.CUSTOM, label: messagesCostFactor.costFactorCustom },
  { value: CostFactorTypes.DOSING, label: messagesCostFactor.costFactorDosing }
];

export const HARDNESS_UNITS = [
  {
    value: 'UNIT_DH',
    label: messagesFormConstants.hardnessOptionsDE,
    labelShort: messagesFormConstants.hardnessOptionsDEShort
  },
  {
    value: 'UNIT_FH',
    label: messagesFormConstants.hardnessOptionsFR,
    labelShort: messagesFormConstants.hardnessOptionsFRShort
  },
  {
    value: 'UNIT_MMOL',
    label: messagesFormConstants.hardnessOptionsMMOL,
    labelShort: messagesFormConstants.hardnessOptionsMMOLShort
  }
];

export const STEAM_INTERFERENCE_TYPES = [
  {
    value: SteamProductionInterferenceTypes.COMPLETE,
    label: messagesSteamProductionInterference.complete
  },
  {
    value: SteamProductionInterferenceTypes.MAJORITY,
    label: messagesSteamProductionInterference.majority
  },
  {
    value: SteamProductionInterferenceTypes.MINORITY,
    label: messagesSteamProductionInterference.minority
  },
  { value: SteamProductionInterferenceTypes.NONE, label: messagesSteamProductionInterference.none }
];

export const FUEL_TYPES = [
  { value: CostFactorTypes.GAS, label: messagesCostFactor.costFactorGas },
  { value: CostFactorTypes.GASN, label: messagesCostFactor.costFactorGasN },
  { value: CostFactorTypes.BIOGAS, label: messagesCostFactor.costFactorBioGas },
  { value: CostFactorTypes.BIOGASN, label: messagesCostFactor.costFactorBioGasN },
  { value: CostFactorTypes.OIL, label: messagesCostFactor.costFactorOil }
];

export const CHECK_INTERVALS = [
  { value: INTERVALS.Weekly, label: messagesFormConstants.intervalOptionsWeekly },
  { value: INTERVALS.TwoWeekly, label: messagesFormConstants.intervalOptionsTwoWeekly },
  { value: INTERVALS.Monthly, label: messagesFormConstants.intervalOptionsMonthly },
  { value: INTERVALS.HalfYear, label: messagesFormConstants.intervalOptionsHalfYear },
  { value: INTERVALS.Year, label: messagesFormConstants.intervalOptionsYear },
  { value: INTERVALS.All3Years, label: messagesFormConstants.intervalOptionsAll3Years },
  { value: INTERVALS.All9Years, label: messagesFormConstants.intervalOptionsAll9Years }
];

export const BUSINESS_OPTIONS = [
  { value: 'BUSINESS_CAR', label: messagesFormConstants.businessOptionCar },
  { value: 'BUSINESS_BAKERY', label: messagesFormConstants.businessOptionBakery },
  { value: 'BUSINESS_BUILDING', label: messagesFormConstants.businessOptionBuilding },
  { value: 'BUSINESS_BREWERY', label: messagesFormConstants.businessOptionBrewery },
  { value: 'BUSINESS_CHEMISTRY', label: messagesFormConstants.businessOptionChemistry },
  { value: 'BUSINESS_FERTILIZER', label: messagesFormConstants.businessOptionFertilizer },
  { value: 'BUSINESS_ENERGY', label: messagesFormConstants.businessOptionEnergy },
  { value: 'BUSINESS_GOODS', label: messagesFormConstants.businessOptionGoods },
  { value: 'BUSINESS_FISH', label: messagesFormConstants.businessOptionFish },
  { value: 'BUSINESS_MEAT', label: messagesFormConstants.businessOptionMeat },
  { value: 'BUSINESS_JUICE', label: messagesFormConstants.businessOptionJuice },
  { value: 'BUSINESS_JELLY', label: messagesFormConstants.businessOptionJelly },
  { value: 'BUSINESS_WOOD', label: messagesFormConstants.businessOptionWood },
  { value: 'BUSINESS_HOTEL', label: messagesFormConstants.businessOptionHotel },
  { value: 'BUSINESS_CHEESE', label: messagesFormConstants.businessOptionCheese },
  { value: 'BUSINESS_PLASTIC', label: messagesFormConstants.businessOptionPlastic },
  { value: 'BUSINESS_HOSPITAL', label: messagesFormConstants.businessOptionHospital },
  { value: 'BUSINESS_METAL', label: messagesFormConstants.businessOptionMetal },
  { value: 'BUSINESS_MILK', label: messagesFormConstants.businessOptionMilk },
  { value: 'BUSINESS_FOOD', label: messagesFormConstants.businessOptionFood },
  { value: 'BUSINESS_PAPER', label: messagesFormConstants.businessOptionPaper },
  { value: 'BUSINESS_PHARMA', label: messagesFormConstants.businessOptionPharma },
  { value: 'BUSINESS_RAFFINERY', label: messagesFormConstants.businessOptionRaffinery },
  { value: 'BUSINESS_FABRIC', label: messagesFormConstants.businessOptionFabric },
  { value: 'BUSINESS_ANIMALFOOD', label: messagesFormConstants.businessOptionAnimalFood },
  { value: 'BUSINESS_LAUNDRY', label: messagesFormConstants.businessOptionLaundry },
  { value: 'BUSINESS_SUGAR', label: messagesFormConstants.businessOptionSugar },
  { value: 'BUSINESS_OTHER', label: messagesFormConstants.businessOptionOther }
];

export const BUSINESS_OPTIONS_SP = [
  { value: 'BUSINESS_SP_INSTALLATION', label: messagesFormConstants.businessOptionSpInstallation },
  { value: 'BUSINESS_SP_BURNER', label: messagesFormConstants.businessOptionSpBurner },
  { value: 'BUSINESS_SP_ENERGY', label: messagesFormConstants.businessOptionSpEnergy },
  { value: 'BUSINESS_SP_BOILER_M', label: messagesFormConstants.businessOptionSpBoilerM },
  { value: 'BUSINESS_SP_BOILER_S', label: messagesFormConstants.businessOptionSpBoilerS },
  { value: 'BUSINESS_SP_TESTING', label: messagesFormConstants.businessOptionSpTesting },
  { value: 'BUSINESS_SP_ELECTRICAL', label: messagesFormConstants.businessOptionSpElectrical },
  {
    value: 'BUSINESS_SP_WATERTREATMENT',
    label: messagesFormConstants.businessOptionSpWaterTreatment
  },
  { value: 'BUSINESS_SP_WATER', label: messagesFormConstants.businessOptionSpWater },
  { value: 'BUSINESS_SP_OTHER', label: messagesFormConstants.businessOptionSpOther }
];

export const STATUS_OPTIONS = [
  { value: 'ACTIVE', label: messagesFormConstants.statusOptionsActive },
  { value: 'INACTIVE', label: messagesFormConstants.statusOptionsInactive }
];

export const EXPORT_TYPE_OPTIONS = [
  { value: 'waterValues', label: messagesCommon.waterValues },
  { value: 'operatingValues', label: messagesCommon.operatingValues },
  { value: 'meterValues', label: messagesCommon.meterValues }
];

export const PACKAGE_OPTIONS = [
  { value: PackageTypes.BASIC, label: messagesCommon.packageBasic },
  { value: PackageTypes.ESSANTIAL, label: messagesCommon.packageEssential }
];

export const PARAM_FORM_ELEMENT_OPTIONS = [
  { value: ParamCategoryTypes.Spinner, label: messagesCommon.paramFormElementSpinner },
  { value: ParamCategoryTypes.PlusMinus, label: messagesCommon.paramFormElementPlusMinus }
];

export const PARAM_CATEGORY_OPTIONS = [
  { value: 'waterValues', label: messagesCommon.waterValues },
  { value: 'operatingValues', label: messagesCommon.operatingValues },
  { value: 'meterValues', label: messagesCommon.meterValues }
];

export const PARAM_COMPONENTS_OPTIONS = [
  {
    value: ComponentTypes.WaterTreatment,
    label: messagesComponents[`component${ComponentTypeConfig.WATER_TREATMENT.name}`]
  },
  {
    value: ComponentTypes.FeedWater,
    label: messagesComponents[`component${ComponentTypeConfig.FEED_WATER.name}`]
  },
  {
    value: ComponentTypes.SteamBoiler,
    label: messagesComponents[`component${ComponentTypeConfig.STEAM_BOILER.name}`]
  },
  {
    value: ComponentTypes.Burner,
    label: messagesComponents[`component${ComponentTypeConfig.BURNER.name}`]
  },
  {
    value: ComponentTypes.Dosing,
    label: messagesComponents[`component${ComponentTypeConfig.DOSING.name}`]
  },
  {
    value: ComponentTypes.Condensate,
    label: messagesComponents[`component${ComponentTypeConfig.CONDENSATE.name}`]
  },
  {
    value: ComponentTypes.Custom,
    label: messagesComponents[`component${ComponentTypeConfig.CUSTOM.name}`]
  }
];

export const REQUIRED_FIELDS = {
  component: {
    WATER_TREATMENT: {
      internalName: true
    },
    FEED_WATER: {
      internalName: true
    },
    STEAM_BOILER: {
      internalName: true
    },
    BURNER: {
      internalName: true,
      steamBoiler: true,
      burnMaterial1: true
    },
    DOSING: {
      internalName: true,
      density: true,
      chemical: true
    },
    CONDENSATE: {
      internalName: true
    },
    CUSTOM: {
      internalName: true
    }
  },
  customer: {
    name: true,
    business: true,
    zip: true,
    city: true,
    street: true,
    country: true
  },
  servicePartner: {
    name: true,
    business: true,
    zip: true,
    city: true,
    country: true
  },
  location: {
    name: true,
    zip: true,
    city: true,
    street: true,
    country: true,
    steamProductionInterference: true,
    package: true
  },
  user: {
    email: true,
    firstname: true,
    lastname: true,
    role: true,
    locations: true,
    tag: true
  },
  externalCheck: {
    interval: true,
    component: true,
    name: true,
    operatedBy: true,
    startDate: true
  },
  costFactor: {
    name: true,
    value: true,
    type: true,
    unit: true
  },
  parameter: {
    name: true,
    sorting: true,
    labelDE: true,
    labelEN: true,
    category: true,
    components: true,
    appFormElement: true,
    unit: false, // depending on type => see validaton and required form logic
    minValue: false,
    maxValue: false
  }
};
