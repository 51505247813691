import { ReactComponent as ArrowDown } from 'images/ArrowDown.svg';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { textSizes } from 'utils/Utils';
import Box from './Box';

/*
  background-color: ${props =>
    props.background !== '' ? props.background : props.theme.colors.white};
*/
const StyledSelect = styled.select`
  background-color: ${props =>
    props.background !== '' ? props.theme.colors[props.background] : 'transparent'};

  color: ${props =>
    props.color !== '' ? props.theme.colors[props.color] : props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.border};
  appearance: none;
  padding: 0 35px 0 14px;
  outline: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  height: 42px;
  width: 100%;
  font-size: ${props => (props.size ? textSizes(props.size) : textSizes('sm'))};

  ${props => props.noBorder && 'border: none'};

  &:disabled {
    background-color: ${props => props.theme.colors.background};
    cursor: not-allowed;
  }

  &::-ms-expand {
    display: none;
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  display: inline-block;

  path {
    fill: #728397;
    opacity: 1;
  }
`;

function Select({
  background,
  noBorder,
  color,
  size,
  disabled,
  id,
  name,
  onChange,
  options,
  placeholder,
  value,
  showResetOption,
  emptyLabel
}) {
  const selectRef = useRef();

  function handleOnChange(event) {
    if (event.target instanceof HTMLSelectElement && value !== event.target.value) {
      onChange({ event, value: event.target.value });
    }
  }

  return (
    <Box>
      <Box display="flex" position="relative" width="100%">
        <Box
          alignItems="center"
          bottom
          display="flex"
          position="absolute"
          right
          top
          style={{ paddingRight: 14, paddingTop: 2 }}
          zIndex={2}
          noPointer
        >
          <StyledArrowDown />
        </Box>
        <StyledSelect
          background={background}
          noBorder={noBorder}
          color={color}
          size={size}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={handleOnChange}
          onChange={handleOnChange}
          ref={selectRef}
          value={value}
        >
          {placeholder && value === '' && (
            <option disabled value="" hidden>
              {placeholder}
            </option>
          )}
          {showResetOption && value !== '' && (
            <option value="">{emptyLabel !== '' ? emptyLabel : placeholder}</option>
          )}
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </Box>
    </Box>
  );
}

Select.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      labelShort: PropTypes.string,
      unit: PropTypes.string,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  showResetOption: PropTypes.bool,
  emptyLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  noBorder: PropTypes.bool
};

Select.defaultProps = {
  showResetOption: false,
  disabled: false,
  name: null,
  placeholder: '',
  value: '',
  background: '',
  color: '',
  size: null,
  noBorder: false,
  emptyLabel: ''
};

export default Select;
