import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const LinkStyles = css`
  color: inherit;
  outline: none;
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};

  .underline {
    text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  }

  &:hover,
  &:focus {
    text-decoration: ${props => (props.underline ? 'none' : 'underline')};
  }
`;

const StyledLink = styled.a`
  ${LinkStyles}
`;

const StyledLinkRouter = styled(RouterLink)`
  ${LinkStyles}
`;

function Link({ children, routerLink, inline, underline, target, href, onClick }) {
  const rel = target === 'blank' ? 'noopener noreferrer' : null;
  const linkTarget = target ? `_${target}` : null;

  const handleClick = event => {
    if (onClick && href) {
      onClick({ event });
    }
  };

  return routerLink ? (
    <StyledLinkRouter to={href} className={`${underline ? 'underline' : ''}`}>
      {children}
    </StyledLinkRouter>
  ) : (
    <StyledLink
      inline={inline}
      underline={underline}
      href={href}
      onClick={handleClick}
      rel={rel}
      target={linkTarget}
    >
      {children}
    </StyledLink>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  routerLink: PropTypes.bool,
  href: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  underline: PropTypes.bool,
  onClick: PropTypes.func,
  target: PropTypes.oneOf([null, 'self', 'blank'])
};

Link.defaultProps = {
  routerLink: false,
  inline: false,
  underline: false,
  onClick: null,
  target: 'self'
};

export default Link;
