import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Label from 'components/Label';
import LoadingBox from 'components/LoadingBox';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { messagesChangePassword, messagesCommon } from 'messages/messages';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';

function ChangePassword() {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordRepeat, setNewPasswordRepeat] = useState();
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [newPasswordRepeatError, setNewPasswordRepeatError] = useState(null);

  // Effect to validate the change password form
  useEffect(() => {
    if (currentPassword && newPassword && newPasswordRepeat) {
      if (
        currentPassword.length > 0 &&
        newPassword.length >= 6 &&
        newPassword === newPasswordRepeat
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      setValid(false);
    }
  }, [currentPassword, newPassword, newPasswordRepeat]);

  // Effect to check for new password input errors
  useEffect(() => {
    if (newPassword) {
      if (newPassword.length < 6) {
        setNewPasswordError('toshort');
      } else {
        setNewPasswordError(null);
      }
    } else {
      setNewPasswordError(null);
    }
  }, [newPassword]);

  // Effect to check for new password repeat input errors
  useEffect(() => {
    if (newPassword && newPasswordRepeat) {
      if (newPassword !== newPasswordRepeat) {
        setNewPasswordRepeatError('nomatch');
      } else {
        setNewPasswordRepeatError(null);
      }
    } else {
      setNewPasswordRepeatError(null);
    }
  }, [newPassword, newPasswordRepeat]);

  const changePassword = async () => {
    setLoading(true);
    let validCurrentPassword = true;
    try {
      await FirebaseService.reAuth(currentPassword);
    } catch (e) {
      validCurrentPassword = false;
      console.log(e);
      console.log('Failed to re-auth user with current password');
      setLoading(false);
      setSubmitMessage('error-current-password');
    }

    if (validCurrentPassword) {
      try {
        const result = await FirebaseService.changePasswordOfUser(newPassword);

        if (result) {
          // reset & success
          setSubmitMessage('success');
        } else {
          setSubmitMessage('error');
        }

        setLoading(false);
        setValid(false);
        setCurrentPassword(undefined);
        setNewPassword(undefined);
        setNewPasswordRepeat(undefined);
      } catch (e) {
        console.log(e);
        console.log('Failed to update password of user');
      }
    }
  };

  return (
    <LoadingBox loading={loading} maxWidth="1600px">
      <Box marginX={7}>
        <Heading level="1" size="xl">
          {intl.get(messagesChangePassword.headline.id)}
        </Heading>
      </Box>

      <Box background="white" marginX={7} marginTop={4} display="flex">
        <form autoComplete="off" noValidate>
          <Box marginX={5} marginY={3} width={400}>
            <Label htmlFor="passwordchange-current">
              {intl.get(messagesChangePassword.currentPassword.id)}
            </Label>
            <TextField
              id="passwordchange-current"
              type="password"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.currentTarget.value)}
            />
          </Box>
          <Box marginX={5} marginY={3} width={400}>
            <Label htmlFor="passwordchange-new">
              {intl.get(messagesChangePassword.newPassword.id)}
            </Label>
            <TextField
              id="passwordchange-new"
              type="password"
              value={newPassword}
              onChange={e => setNewPassword(e.currentTarget.value)}
            />
            {newPasswordError === 'toshort' && (
              <Text color="red">{intl.get(messagesChangePassword.invalidLength.id)}</Text>
            )}
          </Box>
          <Box marginX={5} marginY={3} width={400}>
            <Label htmlFor="passwordchange-newrepeat">
              {intl.get(messagesChangePassword.newPasswordRepeat.id)}
            </Label>
            <TextField
              id="passwordchange-newrepeat"
              type="password"
              value={newPasswordRepeat}
              onChange={e => setNewPasswordRepeat(e.currentTarget.value)}
            />
            {newPasswordRepeatError === 'nomatch' && (
              <Text color="red">{intl.get(messagesChangePassword.invalidNoMatch.id)}</Text>
            )}
          </Box>
          <Box marginX={5} marginY={3} width={200}>
            <Button
              text={intl.get(messagesCommon.save.id)}
              disabled={!valid}
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => changePassword()}
            />
          </Box>
          <Box marginX={5} marginY={3} width={400}>
            {submitMessage === 'success' && (
              <Text color="green">{intl.get(messagesChangePassword.success.id)}</Text>
            )}

            {submitMessage === 'error-current-password' && (
              <Text color="red">{intl.get(messagesChangePassword.invalidCurrent.id)}</Text>
            )}

            {submitMessage === 'error' && (
              <Text color="red">{intl.get(messagesChangePassword.error.id)}</Text>
            )}
          </Box>
        </form>
      </Box>
    </LoadingBox>
  );
}

export default ChangePassword;
