import { ComponentTypes } from 'constants/SteamComponent';
import moment from 'moment';
import WorkerService from 'services/WorkerService';
import ParameterService from './ParameterService';

const DashboardService = {
  async getDashboardData(
    measurements,
    measurementsLastYear,
    components,
    prevYearText,
    fullYearString
  ) {
    const dashboardData = {
      gaugeData: {
        steamCost: {
          last: 0,
          compare: null
        },
        energyConsumption: {
          last: 0,
          compare: null
        },
        dosingRate: {
          last: 0,
          compare: null
        },
        thickeningFactor: {
          last: 0,
          compare: null
        }
      },
      performanceData: {
        condensateSavings: {
          current: null,
          diff: null
        },
        freshWaterCosts: {
          current: null,
          diff: null
        },
        feedWaterCosts: {
          current: null,
          diff: null
        },
        condensateReturn: {
          current: null,
          diff: null
        },
        emission: {
          current: null,
          diff: null
        }
      },
      steamCostBarChartConfig: [],
      steamConsumptionBarChartConfig: [],
      steamCostOverYearBarChartConfig: []
    };

    // Calculate Gauge Values
    // First => get the correct measurements (sorted asc so we need the last one and the prior 3 measurements)
    // Second => get the reports
    // Last => calculate the values
    const lastEntry = measurements[measurements.length - 1];
    const diffMeasurements = DashboardService.getLast3Measurements(
      measurements,
      measurementsLastYear
    );

    if (lastEntry) {
      const lastEntryReport = await DashboardService.spawnReportWorker([lastEntry], components);

      if (lastEntryReport.length > 0) {
        dashboardData.gaugeData.steamCost.last = lastEntryReport[0].steamCost;
        dashboardData.gaugeData.energyConsumption.last = lastEntryReport[0].energyConsumption;
        dashboardData.gaugeData.dosingRate.last = lastEntryReport[0].dosingRate;
        dashboardData.gaugeData.thickeningFactor.last = lastEntryReport[0].thickeningFactor;
      }

      if (diffMeasurements.length > 0) {
        const diffReport = await DashboardService.spawnReportWorker(diffMeasurements, components);
        const diffValues = DashboardService.calculateGaugeData(diffReport, components);

        dashboardData.gaugeData.steamCost.compare = diffValues.steamCostCompareSum;
        dashboardData.gaugeData.energyConsumption.compare = diffValues.energyConsumptionSum;
        dashboardData.gaugeData.dosingRate.compare = diffValues.dosingRateSum;
        dashboardData.gaugeData.thickeningFactor.compare = diffValues.thickeningFactorSum;
      }
    }

    // Generate performance data
    // First => get the correct measurements
    // Second => get the reports
    // Last => calculate the values
    const performanceMeasurements = DashboardService.filterMeasurementsForPerformance(measurements);
    const performanceMeasurementsLY = DashboardService.filterMeasurementsForPerformance(
      measurementsLastYear,
      true
    );

    const performanceReport = await DashboardService.spawnReportWorker(
      performanceMeasurements,
      components
    );
    const performanceReportLastYear = await DashboardService.spawnReportWorker(
      performanceMeasurementsLY,
      components
    );

    const performance = DashboardService.calculatePerformanceValues(
      performanceReport,
      performanceReportLastYear
    );

    dashboardData.performanceData.condensateSavings.current =
      performance.currentYearCondensateSavingSum;
    dashboardData.performanceData.condensateSavings.diff = performance.lastYearCondensateSavingSum;

    dashboardData.performanceData.feedWaterCosts.current = performance.currentYearFeedWaterCostSum;
    dashboardData.performanceData.feedWaterCosts.diff = performance.lastYearFeedWaterCostSum;

    dashboardData.performanceData.freshWaterCosts.current =
      performance.currentYearFreshWaterCostSum;
    dashboardData.performanceData.freshWaterCosts.diff = performance.lastYearFreshWaterCostSum;

    dashboardData.performanceData.condensateReturn.current =
      performance.currentYearCondensateReturnSum;
    dashboardData.performanceData.condensateReturn.diff = performance.lastYearCondensateReturnSum;

    if (performance.currentYearEmissionSumSum > 0) {
      dashboardData.performanceData.emission.current =
        (performance.currentYearEmissionSumSum / 30) * 365;
    }

    if (performance.lastYearEmissionSumSum > 0) {
      dashboardData.performanceData.emission.diff = (performance.lastYearEmissionSumSum / 30) * 365;
    }

    // Generate steam cost bar chart config
    // First => get the correct measurements
    // Second => get the reports
    // Last => generate the chart config
    const barChartsMeasurementsLY = DashboardService.filterMeasurementsForCharts(
      measurementsLastYear
    );

    const barChartsReport = await DashboardService.spawnReportWorker(measurements, components);
    const barChartsReportLastYear = await DashboardService.spawnReportWorker(
      barChartsMeasurementsLY,
      components
    );

    const chartConfigs = DashboardService.generateBarChartConfigs(
      barChartsReport,
      barChartsReportLastYear,
      prevYearText,
      fullYearString
    );

    // Generate steam cost over time bar chart config
    // First => get the reports
    // Last => generate the chart config
    const overTimeReport = await DashboardService.spawnReportWorker(measurements, components);
    const overTimeReportLastYear = await DashboardService.spawnReportWorker(
      measurementsLastYear,
      components
    );
    const steamCostOverTimeConfig = DashboardService.generatePriceOverTimeConfig(
      overTimeReport,
      overTimeReportLastYear,
      prevYearText,
      fullYearString
    );

    dashboardData.steamCostBarChartConfig = chartConfigs.steamCostChartConfig;
    dashboardData.steamConsumptionBarChartConfig = chartConfigs.steamConsumptionChartConfig;
    dashboardData.steamCostOverYearBarChartConfig = steamCostOverTimeConfig;

    return dashboardData;
  },

  calculateGaugeData(report) {
    let steamCostCompareSum = 0;
    let energyConsumptionSum = 0;
    let dosingRateSum = 0;
    let thickeningFactorSum = 0;

    for (let i = 0; i < report.length; i += 1) {
      const reportRow = report[i];

      const rowSteamCost =
        !isNaN(reportRow.steamCost) && isFinite(reportRow.steamCost) ? reportRow.steamCost : 0;
      const rowEnergyConsumptionSum =
        !isNaN(reportRow.energyConsumption) && isFinite(reportRow.energyConsumption)
          ? reportRow.energyConsumption
          : 0;
      const rowDosingRateSum =
        !isNaN(reportRow.dosingRate) && isFinite(reportRow.dosingRate) ? reportRow.dosingRate : 0;
      const rowThickeningFactorSum =
        !isNaN(reportRow.thickeningFactor) && isFinite(reportRow.thickeningFactor)
          ? reportRow.thickeningFactor
          : 0;

      steamCostCompareSum += rowSteamCost;
      energyConsumptionSum += rowEnergyConsumptionSum;
      dosingRateSum += rowDosingRateSum;
      thickeningFactorSum += rowThickeningFactorSum;
    }

    const averageSums = {
      steamCostCompareSum: steamCostCompareSum / report.length,
      energyConsumptionSum: energyConsumptionSum / report.length,
      dosingRateSum: dosingRateSum / report.length,
      thickeningFactorSum: thickeningFactorSum / report.length
    };

    return averageSums;
  },

  generateBarChartConfigs(currentYearReport, lastYearReport, prevYearText, fullYearString) {
    // bootstrap all price sums
    let currentYearPriceSum = 0;
    let currentYearWaterCostSum = 0;
    let currentYearWasteWaterCostSum = 0;
    let currentYearDosingCostSum = 0;
    let currentYearBurnMaterialCostSum = 0;
    let currentYearSteamConsumptionSum = 0;

    let lastYearPriceSum = 0;
    let lastYearWaterCostSum = 0;
    let lastYearWasteWaterCostSum = 0;
    let lastYearDosingCostSum = 0;
    let lastYearBurnMaterialCostSum = 0;
    let lastYearSteamConsumptionSum = 0;

    const steamCostChartConfig = [];
    const steamConsumptionChartConfig = [];

    // calculate current year price sums and add values for months
    for (let j = 0; j < currentYearReport.length; j += 1) {
      const reportRow = currentYearReport[j];
      const {
        priceSum,
        steamConsumption,
        waterValue,
        wasteWaterValue,
        dosingValue,
        burnMaterialValue
      } = reportRow;
      currentYearPriceSum += priceSum;
      currentYearWaterCostSum += waterValue;
      currentYearWasteWaterCostSum += wasteWaterValue;
      currentYearDosingCostSum += dosingValue;
      currentYearBurnMaterialCostSum += burnMaterialValue;
      currentYearSteamConsumptionSum += steamConsumption;
    }

    // calculate last year price sums and add values for months
    for (let k = 0; k < lastYearReport.length; k += 1) {
      const reportRow = lastYearReport[k];
      const {
        priceSum,
        steamConsumption,
        waterValue,
        wasteWaterValue,
        dosingValue,
        burnMaterialValue
      } = reportRow;
      lastYearPriceSum += priceSum;
      lastYearWaterCostSum += waterValue;
      lastYearWasteWaterCostSum += wasteWaterValue;
      lastYearDosingCostSum += dosingValue;
      lastYearBurnMaterialCostSum += burnMaterialValue;
      lastYearSteamConsumptionSum += steamConsumption;
    }

    // add sum values to price sum bar chart config
    steamCostChartConfig[0] = {};
    steamCostChartConfig[0].name = '';
    steamCostChartConfig[0][prevYearText] = lastYearPriceSum / 1000;
    steamCostChartConfig[0][fullYearString] = currentYearPriceSum / 1000;
    steamCostChartConfig[0].priceParts = {};
    steamCostChartConfig[0].priceParts[prevYearText] = {
      water: lastYearPriceSum > 0 ? (lastYearWaterCostSum / lastYearPriceSum) * 100 : 0,
      wasteWater: lastYearPriceSum > 0 ? (lastYearWasteWaterCostSum / lastYearPriceSum) * 100 : 0,
      dosing: lastYearPriceSum > 0 ? (lastYearDosingCostSum / lastYearPriceSum) * 100 : 0,
      burnMaterial:
        lastYearPriceSum > 0 ? (lastYearBurnMaterialCostSum / lastYearPriceSum) * 100 : 0
    };
    steamCostChartConfig[0].priceParts[fullYearString] = {
      water: currentYearPriceSum > 0 ? (currentYearWaterCostSum / currentYearPriceSum) * 100 : 0,
      wasteWater:
        currentYearPriceSum > 0 ? (currentYearWasteWaterCostSum / currentYearPriceSum) * 100 : 0,
      dosing: currentYearPriceSum > 0 ? (currentYearDosingCostSum / currentYearPriceSum) * 100 : 0,
      burnMaterial:
        currentYearPriceSum > 0 ? (currentYearBurnMaterialCostSum / currentYearPriceSum) * 100 : 0
    };

    if (
      isNaN(lastYearSteamConsumptionSum) ||
      !isFinite(lastYearSteamConsumptionSum) ||
      lastYearSteamConsumptionSum < 0
    ) {
      lastYearSteamConsumptionSum = 0;
    }

    if (
      isNaN(currentYearSteamConsumptionSum) ||
      !isFinite(currentYearSteamConsumptionSum) ||
      currentYearSteamConsumptionSum < 0
    ) {
      currentYearSteamConsumptionSum = 0;
    }

    // add sum values to steam consumption bar chart config
    steamConsumptionChartConfig[0] = {};
    steamConsumptionChartConfig[0].name = '';
    steamConsumptionChartConfig[0][prevYearText] = lastYearSteamConsumptionSum;
    steamConsumptionChartConfig[0][fullYearString] = currentYearSteamConsumptionSum;

    return {
      steamConsumptionChartConfig,
      steamCostChartConfig
    };
  },

  generatePriceOverTimeConfig(currentYearReport, lastYearReport, prevYearText, fullYearString) {
    // boostrapping month values for bar chart over time
    const months = moment.monthsShort();

    // bootstrap full year month config with initial value
    const steamCostOverTimeConfig = months.map(month => {
      const monthConfig = {};
      monthConfig.name = month.substr(0, 1);
      monthConfig.monthName = month;
      monthConfig[prevYearText] = 0;
      monthConfig[fullYearString] = 0;
      monthConfig.priceParts = {};
      monthConfig.priceParts[prevYearText] = {
        priceSum: 0,
        water: 0,
        wasteWater: 0,
        dosing: 0,
        burnMaterial: 0
      };
      monthConfig.priceParts[fullYearString] = {
        priceSum: 0,
        water: 0,
        wasteWater: 0,
        dosing: 0,
        burnMaterial: 0
      };

      return monthConfig;
    });

    // calculate current year price sums and add values for months
    for (let j = 0; j < currentYearReport.length; j += 1) {
      const reportRow = currentYearReport[j];
      const { priceSum, waterValue, wasteWaterValue, dosingValue, burnMaterialValue } = reportRow;

      const reportMonth = moment(reportRow.date).format('MMM'); // short month format
      const configIndex = steamCostOverTimeConfig.findIndex(item => item.monthName === reportMonth);

      if (configIndex >= 0) {
        steamCostOverTimeConfig[configIndex][fullYearString] += priceSum / 1000;

        steamCostOverTimeConfig[configIndex].priceParts[fullYearString].priceSum += priceSum;
        steamCostOverTimeConfig[configIndex].priceParts[fullYearString].water += waterValue;
        steamCostOverTimeConfig[configIndex].priceParts[
          fullYearString
        ].wasteWater += wasteWaterValue;
        steamCostOverTimeConfig[configIndex].priceParts[fullYearString].dosing += dosingValue;
        steamCostOverTimeConfig[configIndex].priceParts[
          fullYearString
        ].burnMaterial += burnMaterialValue;
      }
    }

    // calculate last year price sums and add values for months
    for (let k = 0; k < lastYearReport.length; k += 1) {
      const reportRow = lastYearReport[k];
      const { priceSum, waterValue, wasteWaterValue, dosingValue, burnMaterialValue } = reportRow;

      const reportMonth = moment(reportRow.date).format('MMM'); // short month format => only first char
      const configIndex = steamCostOverTimeConfig.findIndex(item => item.monthName === reportMonth);

      if (reportMonth === 'Nov.') {
        // console.log('row => ', reportRow);
        // console.log('month => ', reportMonth);
        // console.log('dosing sums => ', reportRow.dosingSums);
        // console.log('dosing value => ', dosingValue);
      }

      if (configIndex >= 0) {
        steamCostOverTimeConfig[configIndex][prevYearText] += priceSum / 1000;

        steamCostOverTimeConfig[configIndex].priceParts[prevYearText].priceSum += priceSum;
        steamCostOverTimeConfig[configIndex].priceParts[prevYearText].water += waterValue;
        steamCostOverTimeConfig[configIndex].priceParts[prevYearText].wasteWater += wasteWaterValue;
        steamCostOverTimeConfig[configIndex].priceParts[prevYearText].dosing += dosingValue;
        steamCostOverTimeConfig[configIndex].priceParts[
          prevYearText
        ].burnMaterial += burnMaterialValue;
      }
    }

    return steamCostOverTimeConfig;
  },

  calculatePerformanceValues(currentYearReport, lastYearReport) {
    // bootstrap all sums with null to be able to tell if we could calculate a value
    let currentYearFeedWaterSum = null;
    let currentYearCondensateSum = null;
    let currentYearCondensateSavingSum = null;
    let currentYearFreshWaterCostSum = null;
    let currentYearFeedWaterCostSum = null;
    let currentYearCondensateReturnSum = null;
    let currentYearEmissionSumSum = null;

    let lastYearFeedWaterSum = null;
    let lastYearCondensateSum = null;
    let lastYearCondensateSavingSum = null;
    let lastYearFreshWaterCostSum = null;
    let lastYearFeedWaterCostSum = null;
    let lastYearCondensateReturnSum = null;
    let lastYearEmissionSumSum = null;

    if (currentYearReport.length > 0) {
      currentYearFeedWaterSum = 0;
      currentYearCondensateSum = 0;
      currentYearCondensateSavingSum = 0;
      currentYearFreshWaterCostSum = 0;
      currentYearFeedWaterCostSum = 0;
      currentYearCondensateReturnSum = 0;
      currentYearEmissionSumSum = 0;

      // calculate last year price sums and add values for months
      for (let k = 0; k < currentYearReport.length; k += 1) {
        const reportRow = currentYearReport[k];
        const {
          condensateSavings,
          freshWaterCosts,
          feedWaterCosts,
          feedWater,
          condensate,
          emissionValue
        } = reportRow;

        currentYearCondensateSavingSum += condensateSavings;
        currentYearFreshWaterCostSum += freshWaterCosts;
        currentYearFeedWaterCostSum += feedWaterCosts;
        currentYearFeedWaterSum += feedWater;
        currentYearCondensateSum += condensate;
        currentYearEmissionSumSum += emissionValue;
      }

      currentYearCondensateReturnSum += (currentYearCondensateSum / currentYearFeedWaterSum) * 100;
    }

    if (lastYearReport.length > 0) {
      lastYearFeedWaterSum = 0;
      lastYearCondensateSum = 0;
      lastYearCondensateSavingSum = 0;
      lastYearFreshWaterCostSum = 0;
      lastYearFeedWaterCostSum = 0;
      lastYearCondensateReturnSum = 0;
      lastYearEmissionSumSum = 0;

      // calculate last year price sums and add values for months
      for (let k = 0; k < lastYearReport.length; k += 1) {
        const reportRow = lastYearReport[k];
        const {
          condensateSavings,
          freshWaterCosts,
          feedWaterCosts,
          feedWater,
          condensate,
          emissionValue
        } = reportRow;

        lastYearCondensateSavingSum += condensateSavings;
        lastYearFreshWaterCostSum += freshWaterCosts;
        lastYearFeedWaterCostSum += feedWaterCosts;
        lastYearFeedWaterSum += feedWater;
        lastYearCondensateSum += condensate;
        lastYearEmissionSumSum += emissionValue;
      }

      lastYearCondensateReturnSum += (lastYearCondensateSum / lastYearFeedWaterSum) * 100;
    }

    return {
      currentYearCondensateSavingSum,
      lastYearCondensateSavingSum,

      currentYearFreshWaterCostSum,
      lastYearFreshWaterCostSum,

      currentYearFeedWaterCostSum,
      lastYearFeedWaterCostSum,

      currentYearCondensateReturnSum,
      lastYearCondensateReturnSum,

      currentYearEmissionSumSum,
      lastYearEmissionSumSum
    };
  },

  async spawnReportWorker(workerMeasurements, components) {
    const reportParams = ParameterService.getParameterForReport();

    const workerData = {
      meterValueConfig: reportParams.meterParameter,
      operatingValueConfig: reportParams.operationalParameter,
      waterValueConfig: reportParams.waterValues,
      componentTypes: ComponentTypes,
      components,
      measurements: workerMeasurements
    };

    return WorkerService.spawnReportWorker(workerData);
  },

  // bar chart measurements for last year only need to be
  // the ones comparing for today but -1 year
  filterMeasurementsForCharts(measurements) {
    const filtered = [];
    const compareDate = moment()
      .subtract(1, 'years')
      .add(1, 'days')
      .endOf('day');

    for (let i = 0; i < measurements.length; i += 1) {
      const measurement = measurements[i];
      const measureDate = moment(measurement.createdAt);
      const isBefore = measureDate.isBefore(compareDate, 'day');

      if (isBefore) {
        filtered.push(measurement);
      }
    }

    return filtered;
  },

  // performance measurements need to be the last 30 days
  // compared with the last 30 days of the last year
  filterMeasurementsForPerformance(measurements, lastYear) {
    const filtered = [];

    const compareDateStart = moment().endOf('day');
    const compareDateEnd = moment()
      .subtract(31, 'days')
      .startOf('day');

    if (lastYear) {
      compareDateStart.subtract(1, 'years');
      compareDateEnd.subtract(1, 'years');
    }

    for (let i = 0; i < measurements.length; i += 1) {
      const measurement = measurements[i];
      const measureDate = moment(measurement.createdAt);
      const isBetween = measureDate.isBetween(compareDateEnd, compareDateStart);

      if (isBetween) {
        filtered.push(measurement);
      }
    }

    return filtered;
  },

  getLast3Measurements(measurements, lastyear) {
    const current = [...measurements];
    const last = [...lastyear];
    const diffs = [];

    // pop once because we dont want the last one but the three prior to this
    current.pop();

    // >= 3 because we need 3 and popped the array once already
    if (current.length >= 3) {
      diffs.push(current.pop());
      diffs.push(current.pop());
      diffs.push(current.pop());
    } else {
      let filled = 0;
      for (let i = 0; i < current.length; i += 1) {
        if (filled < 3) {
          diffs.push(current.pop());
          filled += 1;
        }
      }

      if (filled < 3) {
        for (let i = 0; i < last.length; i += 1) {
          if (filled < 3) {
            diffs.push(last.pop());
            filled += 1;
          }
        }
      }
    }

    return diffs;
  }
};

export default DashboardService;
