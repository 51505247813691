/**
 * Sort components with sorting index property
 * @param {*} components
 */
export const sortParameter = parameters => {
  const compare = (aDoc, bDoc) => {
    let a = aDoc;
    let b = bDoc;

    if (aDoc.data) {
      a = aDoc.data;
    }

    if (bDoc.data) {
      b = bDoc.data;
    }

    const aSort = a.sorting ? parseInt(a.sorting, 10) : 100;
    const bSort = b.sorting ? parseInt(b.sorting, 10) : 100;

    if (aSort < bSort) {
      return -1;
    }
    if (aSort > bSort) {
      return 1;
    }
    return 0;
  };

  const sorted = parameters.sort(compare);

  return sorted;
};

export const sortParameterOfComponents = (componentParameter, allParameter) => {
  const sortedComponentParams = [];

  const compare = (aParam, bParam) => {
    const aEquiv = allParameter.filter(p => p.name === aParam.name)[0];
    const bEquiv = allParameter.filter(p => p.name === bParam.name)[0];

    let aSort = 100;
    let bSort = 100;

    if (aEquiv) {
      aSort = aEquiv.sorting ? parseInt(aEquiv.sorting, 10) : 100;
    }

    if (aEquiv) {
      bSort = bEquiv.sorting ? parseInt(bEquiv.sorting, 10) : 100;
    }

    if (aSort < bSort) {
      return -1;
    }
    if (aSort > bSort) {
      return 1;
    }
    return 0;
  };

  const keys = Object.keys(componentParameter);

  for (let i = 0; i < keys.length; i += 1) {
    const componentId = keys[i];
    const componentParamValues = componentParameter[componentId];
    const { meterValues, operatingValues, waterValues } = componentParamValues;

    let sortedMeter = [];
    let sortedWater = [];
    let sortedOp = [];

    if (meterValues) {
      sortedMeter = meterValues.sort(compare);
    }

    if (waterValues) {
      sortedWater = waterValues.sort(compare);
    }

    if (operatingValues) {
      sortedOp = operatingValues.sort(compare);
    }

    const sortedEntry = {
      meterValues: sortedMeter,
      waterValues: sortedWater,
      operatingValues: sortedOp
    };

    sortedComponentParams[componentId] = sortedEntry;
  }

  // const sorted = parameters.sort(compare);

  // return sorted;

  return sortedComponentParams;
};
