import Box from 'components/Box';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import Text from 'components/Text';
import useFlattenFirebaseDoc from 'hooks/useFlattenFirebaseDoc';
import { messagesAnalysis } from 'messages/messages';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import WorkerService from 'services/WorkerService';
import Chart from './Chart';
import ChartFilter from './ChartFilter';

function AnalysisWaterParameter() {
  const { flattenMeasurement } = useFlattenFirebaseDoc();

  // Loading States
  const [loadingComponents, setLoadingComponents] = useState(true);
  const [loadingMeasurements, setLoadingMeasurements] = useState(false);
  const [calculatingValues, setCalculatingValues] = useState(false);

  // Entities
  const [components, setComponents] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [measurementsLastYear, setMeasurementsLastYear] = useState([]);

  // Chart Filter Values
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [dateRangeOption, setDateRangeOption] = useState('');
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [showBoundary, setShowBoundary] = useState(false);
  const [showLastYear, setShowLastYear] = useState(false);

  // Chart config
  const [chartConfig, setChartConfig] = useState({
    chartData: [],
    boundaryConfig: [],
    lineConfig: []
  });

  /**
   * Callback function to load measurements when date range changes
   * @NOTE for better perfomance not all current year and last year should always be loaded
   */
  const loadMeasurements = useCallback(async () => {
    const updateStateMeasurementDocs = (snapshot, setState) => {
      const docs = [];
      snapshot.forEach(doc => {
        // create a flat item since we dont need all ref and doc data
        const item = flattenMeasurement({
          id: doc.id,
          ...doc.data()
        });

        docs.push(item);
      });
      setState(docs);
    };

    let queryDateFrom = null;
    let queryDateTo = null;
    let queryDateToLastYear = null;
    let queryDateFromLastYear = null;

    // bit hacky ... but since all options are this way it should be ok
    if (dateRangeOption) {
      const dateRangeDays = parseInt(dateRangeOption.replace('last', ''), 10);

      queryDateTo = moment();
      queryDateFrom = moment().subtract(dateRangeDays, 'days');

      queryDateToLastYear = queryDateTo.clone();
      queryDateFromLastYear = queryDateFrom.clone();

      queryDateToLastYear.subtract(1, 'years');
      queryDateFromLastYear.subtract(1, 'years');
    }

    if (queryDateFrom !== null && queryDateTo !== null) {
      setLoadingMeasurements(true);
      const querySnapshot = await FirebaseService.getMeasurementsOfLocationsRef(
        AppStateService.location.ref,
        {
          dateFrom: queryDateFrom.toDate(),
          dateTo: queryDateTo.toDate()
        },
        'asc'
      ).get();

      const querySnapshotLastYear = await FirebaseService.getMeasurementsOfLocationsRef(
        AppStateService.location.ref,
        {
          dateFrom: queryDateFromLastYear.toDate(),
          dateTo: queryDateToLastYear.toDate()
        },
        'asc'
      ).get();

      updateStateMeasurementDocs(querySnapshot, setMeasurements);
      updateStateMeasurementDocs(querySnapshotLastYear, setMeasurementsLastYear);

      setLoadingMeasurements(false);
    }
  }, [dateRangeOption]);

  // Effect to load measurements on load
  useEffect(() => {
    loadMeasurements();
  }, [loadMeasurements]);

  // Effect to load components on page load
  useEffect(() => {
    const load = async () => {
      setLoadingComponents(true);

      const querySnapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );

      const docs = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.waterValues && data.waterValues.length > 0) {
          // create a flat item since we dont need all ref and doc data
          const item = {
            id: doc.id,
            value: doc.id,
            label: doc.data().internalName,
            ...doc.data()
          };

          // get rid of references to be able to send the data to our webworker
          delete item.location;

          docs.push(item);
        }
      });

      setComponents(docs);
      setLoadingComponents(false);
    };

    load();
  }, []);

  // Effect to update the chart data and configs when measurement data or filters change
  // change date loads measurements => will probably result on two times the render
  useEffect(() => {
    const createChartConfig = async () => {
      setCalculatingValues(true);
      const workerData = {
        selectedComponents,
        selectedParameter,
        measurements,
        measurementsLastYear,
        showBoundary,
        showLastYear
      };

      const config = await WorkerService.spawnWaterWorker(workerData);
      setChartConfig(config);
      setCalculatingValues(false);
    };

    createChartConfig();
  }, [
    selectedParameter,
    selectedComponents,
    showBoundary,
    showLastYear,
    measurements,
    measurementsLastYear
  ]);

  const updateSelectedComponents = (event, component) => {
    const { checked } = event.target;
    let updated = [...selectedComponents];
    if (checked) {
      updated.push(component);
    } else {
      updated = updated.filter(c => c.id !== component.id);
    }
    setSelectedComponents(updated);
  };

  const renderChart = () => {
    if (dateRangeOption === '' || selectedComponents.length === 0 || selectedParameter === null) {
      return renderInitialChartOverlay();
    }

    if (chartConfig.chartData.length === 0) {
      return renderNoDateChartOverlay();
    }

    return <Chart config={chartConfig} parameter={selectedParameter} />;
  };

  const renderInitialChartOverlay = () => {
    return (
      <Box display="flex" justify="center" alignItems="center" height="400px" paddingX={4}>
        <Text align="center">{intl.get(messagesAnalysis.chartNoticeDefault.id)}</Text>
      </Box>
    );
  };

  const renderNoDateChartOverlay = () => {
    return (
      <Box display="flex" justify="center" alignItems="center" height="400px" paddingX={4}>
        <Text align="center">{intl.get(messagesAnalysis.chartNoticeEmpty.id)}</Text>
      </Box>
    );
  };

  return (
    <LoadingBox loading={loadingComponents}>
      <Box marginX={7}>
        <Heading level="1" size="xl">
          {intl.get(messagesAnalysis.headlineWaterParameter.id)}
        </Heading>
      </Box>

      <Box display="flex" marginTop={5} paddingX={7} maxWidth="1600px">
        <ChartFilter
          dateRangeOption={dateRangeOption}
          dateFrom={dateFrom}
          dateTo={dateTo}
          showBoundary={showBoundary}
          showLastYear={showLastYear}
          selectedComponents={selectedComponents}
          components={components}
          selectedParameter={selectedParameter}
          onDateRangeChange={value => setDateRangeOption(value)}
          onDateFromChange={value => setDateFrom(value)}
          onDateToChange={value => setDateTo(value)}
          onShowBoundaryChange={value => setShowBoundary(value)}
          onShowLastYearChange={value => setShowLastYear(value)}
          onSelectedParameterChange={value => setSelectedParameter(value)}
          onSelectedComponentChange={updateSelectedComponents}
        />
        <Box
          background="white"
          width="100%"
          marginLeft={7}
          paddingTop={7}
          paddingBottom={7}
          alignSelf="stretch"
        >
          <LoadingBox loading={loadingMeasurements || calculatingValues}>
            {renderChart()}
          </LoadingBox>
        </Box>
      </Box>
    </LoadingBox>
  );
}

export default AnalysisWaterParameter;
