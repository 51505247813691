// PROD
export const FirebaseConfig = {
  apiKey: 'AIzaSyDBrkfCJoaJuWdGlqt-Kvj7ty986wl78V8',
  authDomain: 'diwaso-d9bf0.firebaseapp.com',
  databaseURL: 'https://diwaso-d9bf0.firebaseio.com',
  projectId: 'diwaso-d9bf0',
  storageBucket: 'diwaso-d9bf0.appspot.com',
  messagingSenderId: '706272250078',
  appId: '1:706272250078:web:ccdcf8e120b52b61d4da4f'
};

// DEV
/*
export const FirebaseConfig = {
  apiKey: 'AIzaSyABIJJw1mST3_xBAublxJqYlyL2LCDaF78',
  authDomain: 'diwaso-dev1.firebaseapp.com',
  databaseURL: 'https://diwaso-dev1.firebaseio.com',
  projectId: 'diwaso-dev1',
  storageBucket: 'diwaso-dev1.appspot.com',
  messagingSenderId: '251932266147',
  appId: '1:251932266147:web:44a957b32ed1dba882f916',
  measurementId: 'G-9FJCF5Z2RK'
};
*/

export const Collections = {
  user: 'user',
  customer: 'customer',
  location: 'location',
  costFactor: 'costFactor',
  servicePartner: 'servicePartner',
  component: 'component',
  measurement: 'measurement',
  measurementChanges: 'measurementChanges',
  externalChecks: 'externalChecks',
  notification: 'notification',
  parameter: 'parameter'
};
