export const INTERVALS = {
  Weekly: 'WEEKLY',
  TwoWeekly: 'TWO_WEEKLY',
  Monthly: 'MONTHLY',
  HalfYear: 'HALF_YEAR',
  Year: 'YEAR',
  All3Years: 'ALL_3_YEARS',
  All9Years: 'ALL_9_YEARS'
};

export const INTERVAL_CONFIG = {
  WEEKLY: {
    key: 'Weekly',
    inDays: 7
  },
  TWO_WEEKLY: {
    key: 'TwoWeekly',
    inDays: 14
  },
  MONTHLY: {
    key: 'Monthly',
    inDays: 30
  },
  HALF_YEAR: {
    key: 'HalfYear',
    inDays: 30 * 6
  },
  YEAR: {
    key: 'Year',
    inDays: 365
  },
  ALL_3_YEARS: {
    key: 'All3Years',
    inDays: 365 * 3
  },
  ALL_9_YEARS: {
    key: 'All9Years',
    inDays: 365 * 9
  }
};
