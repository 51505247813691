import Panel from 'components/Panel';
import { CostFactorTypes } from 'constants/CostFactor';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import CostFactorFormEdit from './CostFactorFormEdit';

function PanelCostFactor({ costFactor, onClose }) {
  const formValidator = useFormValidation();
  const boostrap = {
    location: AppStateService.location.ref,
    type: '',
    name: '',
    value: '',
    unit: ''
  };

  const [costFactorData, setCostFactorData] = useState(costFactor ? costFactor.data : boostrap);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isCreateMode] = useState(!costFactor);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const data = { ...costFactorData };
    // little hack to pass by name requirement
    if (
      costFactor &&
      costFactor.data.type &&
      costFactor.data.type !== CostFactorTypes.CUSTOM &&
      costFactor.data.type !== CostFactorTypes.DOSING
    ) {
      data.name = data.type;
    }

    const validData = formValidator.validateCostFactor(data);
    setValid(validData);
  }, [costFactorData, costFactor, formValidator]);

  const handleFormFieldChange = (key, value) => {
    const data = { ...costFactorData };
    data[key] = value;
    setCostFactorData(data);
  };

  const save = async () => {
    try {
      const id = costFactor ? costFactor.id : null;
      setSaveInProgress(true);
      await FirebaseService.updateCostFactor(id, costFactorData);
      setSaveInProgress(false);
      onClose();
    } catch (e) {
      console.log(e);
      console.log('error updating cost factor');
      setSaveInProgress(false);
    }
  };

  return (
    <Panel
      title={
        isCreateMode
          ? intl.get(messagesCrud.costFactorAdd.id)
          : intl.get(messagesCrud.costFactorEdit.id)
      }
      buttonPrimaryAction={save}
      buttonPrimaryDisabled={!valid}
      onClose={onClose}
      showSpinner={saveInProgress}
      isOpen
    >
      <CostFactorFormEdit
        costFactor={costFactorData}
        onChange={handleFormFieldChange}
        isCreateMode={costFactor === null}
      />
    </Panel>
  );
}

PanelCostFactor.propTypes = {
  costFactor: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

PanelCostFactor.defaultProps = {
  costFactor: null
};

export default PanelCostFactor;
