import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import { INTERVAL_CONFIG } from 'constants/Interval';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon, messagesNavigation } from 'messages/messages';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import NotificationService from 'services/NotificationService';
import CheckList from './CheckList';
import PanelCheck from './PanelCheck';

function ExternalChecks() {
  const [isLoading, setIsLoading] = useState(true);
  const [checks, setChecks] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelData, setPanelData] = useState(null);

  // Effect to load checks on load on keep them up to date
  useEffect(() => {
    const updateCollection = async querySnapshot => {
      setIsLoading(true);
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      // populate the component data inside the data of external check
      const populate = docs.map(async doc => {
        const docWithComponent = { ...doc };
        const component = await doc.data.component.get();
        docWithComponent.componentData = component.data();
        return docWithComponent;
      });

      const docsWithComponentData = await Promise.all(populate);

      // if the external check is active === false
      // update the next due date if its over due
      // this is needed since if its active the date gets renewed when archiving
      // the notificaton which allows to have an "over due" text
      for (let i = 0; i < docsWithComponentData.length; i += 1) {
        const checkDoc = docsWithComponentData[i];
        const checkData = checkDoc.data;
        const isDue = NotificationService.isExternalCheckDue(checkData);

        if (!checkData.active && isDue) {
          const intervalConfig = INTERVAL_CONFIG[checkData.interval];
          const newDueDate = NotificationService.getNextFutureDueDate(
            checkData.nextDueDate.toDate(),
            intervalConfig
          );

          const newDueDateTimestamp = FirebaseService.getTimestamp(newDueDate);

          // Update the next due date
          // Create a new notification based on new due date
          const checkUpdate = { ...checkData, nextDueDate: newDueDateTimestamp };

          // could be dangerous since this will trigger the firebase update collection
          FirebaseService.updateExternalCheck(checkDoc.id, checkUpdate);
        }
      }

      setChecks(docsWithComponentData);
      setIsLoading(false);
    };

    const unsub = FirebaseService.getExternalChecksForLocation(
      AppStateService.location.ref
    ).onSnapshot(updateCollection);

    return () => {
      unsub();
    };
  }, []);

  const openPanel = check => {
    setPanelData(check);
    setPanelOpen(true);
  };

  const closePanel = () => setPanelOpen(false);

  const deleteExternalCheck = async externalCheck => {
    try {
      await FirebaseService.deleteExternalCheck(externalCheck);
    } catch (e) {
      console.log('Check could not be deleted');
      console.log(e);
    }
  };

  return (
    <>
      <Box maxWidth="1600px">
        <Box marginX={7} display="flex">
          <Heading level="1" size="xl">
            {intl.get(messagesNavigation.externalChecks.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => openPanel(null)}
            />
          </Box>
        </Box>

        <LoadingBox loading={isLoading}>
          <Box background="white" marginX={7} marginTop={5} display="flex">
            <CheckList checks={checks} openPanel={openPanel} onCheckDelete={deleteExternalCheck} />
          </Box>
        </LoadingBox>
      </Box>

      {panelOpen && <PanelCheck check={panelData} onClose={closePanel} />}
    </>
  );
}

export default ExternalChecks;
