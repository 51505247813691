import { PackageTypes } from 'constants/Packages';

export const renderRouteByPackage = (location, routeName) => {
  const locationData = location && location.data ? location.data : location;
  const locationPackage = locationData.package ? locationData.package : PackageTypes.BASIC;

  let canRender = false;

  if (locationPackage === PackageTypes.BASIC) {
    switch (routeName) {
      case 'steam-plant':
        canRender = true;
        break;
      case 'change-location':
        canRender = true;
        break;
      case 'boiler-book':
        canRender = true;
        break;
      case 'reports':
        canRender = false;
        break;
      case 'notifications':
        canRender = false;
        break;
      case 'steam-costs':
        canRender = true;
        break;
      case 'company':
        canRender = true;
        break;
      case 'external-checks':
        canRender = false;
        break;
      case 'analysis-water-parameter':
        canRender = true;
        break;
      case 'analysis-operational-data':
        canRender = true;
        break;
      case 'dashboard':
        canRender = false;
        break;

      default:
        canRender = false;
        break;
    }
  } else if (locationPackage === PackageTypes.ESSANTIAL) {
    switch (routeName) {
      case 'steam-plant':
        canRender = true;
        break;
      case 'change-location':
        canRender = true;
        break;
      case 'boiler-book':
        canRender = true;
        break;
      case 'reports':
        canRender = true;
        break;
      case 'notifications':
        canRender = true;
        break;
      case 'steam-costs':
        canRender = true;
        break;
      case 'company':
        canRender = true;
        break;
      case 'external-checks':
        canRender = true;
        break;
      case 'analysis-water-parameter':
        canRender = true;
        break;
      case 'analysis-operational-data':
        canRender = true;
        break;

      default:
        canRender = true;
        break;
    }
  }

  return canRender;
};

export const renderMenuItemByPackage = (location, routeName) => {
  const locationData = location && location.data ? location.data : location;

  const locationPackage =
    locationData && locationData.package ? locationData.package : PackageTypes.BASIC;
  let canRender = false;

  if (locationPackage === PackageTypes.BASIC) {
    switch (routeName) {
      case 'steam-plant':
        canRender = true;
        break;
      case 'change-location':
        canRender = true;
        break;
      case 'boiler-book':
        canRender = true;
        break;
      case 'reports':
        canRender = false;
        break;
      case 'notifications':
        canRender = false;
        break;
      case 'steam-costs':
        canRender = true;
        break;
      case 'company':
        canRender = true;
        break;
      case 'external-checks':
        canRender = false;
        break;
      case 'analysis-water-parameter':
        canRender = true;
        break;
      case 'analysis-operational-data':
        canRender = true;
        break;
      case 'dashboard':
        canRender = false;
        break;

      default:
        canRender = false;
        break;
    }
  } else if (locationPackage === PackageTypes.ESSANTIAL) {
    switch (routeName) {
      case 'steam-plant':
        canRender = true;
        break;
      case 'change-location':
        canRender = true;
        break;
      case 'boiler-book':
        canRender = true;
        break;
      case 'reports':
        canRender = true;
        break;
      case 'notifications':
        canRender = true;
        break;
      case 'steam-costs':
        canRender = true;
        break;
      case 'company':
        canRender = true;
        break;
      case 'external-checks':
        canRender = true;
        break;
      case 'analysis-water-parameter':
        canRender = true;
        break;
      case 'analysis-operational-data':
        canRender = true;
        break;
      case 'dashboard':
        canRender = true;
        break;

      default:
        canRender = true;
        break;
    }
  }

  return canRender;
};
