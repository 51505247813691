import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function NumberSvg({ showZero, number, digits, suffix, round, fill, textAnchor, dy, y, x }) {
  const { currentLocale } = intl.options;

  let formatted = number;

  if (isNaN(number) || !isFinite(number) || number === null) {
    formatted = '-';
  } else {
    if (round === true) {
      formatted = number.toFixed(digits);
    }

    formatted = new Intl.NumberFormat(currentLocale, {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits
    }).format(number);
  }

  if (number === 0 && showZero === false) {
    return null;
  }

  return (
    <text fill={fill} textAnchor={textAnchor} x={x} y={y} dy={dy}>
      {formatted} {suffix}
    </text>
  );
}

NumberSvg.propTypes = {
  showZero: PropTypes.bool,
  number: PropTypes.number,
  digits: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  dy: PropTypes.number,
  round: PropTypes.bool,
  suffix: PropTypes.any,
  fill: PropTypes.string,
  textAnchor: PropTypes.string
};

NumberSvg.defaultProps = {
  showZero: false,
  number: 0,
  digits: 2,
  suffix: '',
  round: false,
  fill: '#000',
  textAnchor: 'middle',
  x: 0,
  y: 0,
  dy: 0
};

export default NumberSvg;
