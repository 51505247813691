import { ComponentTypes } from 'constants/SteamComponent';
import PropTypes from 'prop-types';
import React from 'react';
import PanelBurner from './PanelBurner';
import PanelCondensate from './PanelCondensate';
import PanelCustom from './PanelCustom';
import PanelDosing from './PanelDosing';
import PanelFeedWater from './PanelFeedWater';
import PanelSteamBoiler from './PanelSteamBoiler';
import PanelWaterTreatment from './PanelWaterTreatment';

function SteamPlantPanel({ open, type, component, allComponents, componentCounts, onClose }) {
  if (!open) {
    return null;
  }

  let componentToRender = null;
  switch (type) {
    case ComponentTypes.WaterTreatment:
      componentToRender = (
        <PanelWaterTreatment
          type={type}
          component={component}
          componentCounts={componentCounts}
          onClose={onClose}
        />
      );
      break;
    case ComponentTypes.FeedWater:
      componentToRender = (
        <PanelFeedWater
          type={type}
          component={component}
          componentCounts={componentCounts}
          onClose={onClose}
        />
      );
      break;
    case ComponentTypes.SteamBoiler:
      componentToRender = (
        <PanelSteamBoiler
          type={type}
          component={component}
          components={allComponents}
          componentCounts={componentCounts}
          onClose={onClose}
        />
      );
      break;
    case ComponentTypes.Dosing:
      componentToRender = (
        <PanelDosing
          type={type}
          component={component}
          componentCounts={componentCounts}
          onClose={onClose}
        />
      );
      break;
    case ComponentTypes.Burner:
      componentToRender = (
        <PanelBurner
          type={type}
          component={component}
          componentCounts={componentCounts}
          onClose={onClose}
        />
      );
      break;
    case ComponentTypes.Condensate:
      componentToRender = (
        <PanelCondensate
          type={type}
          component={component}
          componentCounts={componentCounts}
          onClose={onClose}
        />
      );
      break;
    case ComponentTypes.Custom:
      componentToRender = (
        <PanelCustom
          type={type}
          component={component}
          componentCounts={componentCounts}
          onClose={onClose}
        />
      );
      break;
    default:
      componentToRender = null;
      break;
  }

  return componentToRender;
}

SteamPlantPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  component: PropTypes.any,
  allComponents: PropTypes.any,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  componentCounts: PropTypes.array.isRequired
};

SteamPlantPanel.defaultProps = {
  open: false,
  type: '',
  allComponents: [],
  component: null
};

export default SteamPlantPanel;
