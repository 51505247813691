import Box from 'components/Box';
import Label from 'components/Label';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { REQUIRED_FIELDS } from 'constants/Form';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function LocationGeneralFormEdit({ location, onChange }) {
  const formOptions = useFormOptions();
  const formConfig = REQUIRED_FIELDS.location;
  const formValidator = useFormValidation();

  return (
    <>
      <Box marginY={3}>
        <Label
          htmlFor="panel-name"
          required={formValidator.isFieldRequired(formConfig, 'name')}
          valid={formValidator.isFieldValid(formConfig, 'name', location)}
        >
          {intl.get(messagesCommon.description.id)}
        </Label>
        <TextField
          id="panel-name"
          type="text"
          placeholder={location?.name || intl.get(messagesCommon.name.id)}
          value={location?.name}
          onChange={e => onChange('name', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-street"
          required={formValidator.isFieldRequired(formConfig, 'street')}
          valid={formValidator.isFieldValid(formConfig, 'street', location)}
        >
          {intl.get(messagesCommon.street.id)}
        </Label>
        <TextField
          id="panel-street"
          type="text"
          placeholder={location?.street || intl.get(messagesCommon.street.id)}
          value={location?.street}
          onChange={e => onChange('street', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-zip"
          required={formValidator.isFieldRequired(formConfig, 'zip')}
          valid={formValidator.isFieldValid(formConfig, 'zip', location)}
        >
          {intl.get(messagesCommon.zip.id)}
        </Label>
        <TextField
          id="panel-zip"
          type="text"
          placeholder={location?.zip || intl.get(messagesCommon.zip.id)}
          value={location?.zip}
          onChange={e => onChange('zip', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-city"
          required={formValidator.isFieldRequired(formConfig, 'city')}
          valid={formValidator.isFieldValid(formConfig, 'city', location)}
        >
          {intl.get(messagesCommon.city.id)}
        </Label>
        <TextField
          id="panel-city"
          type="text"
          placeholder={location?.city || intl.get(messagesCommon.city.id)}
          value={location?.city}
          onChange={e => onChange('city', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-country"
          required={formValidator.isFieldRequired(formConfig, 'country')}
          valid={formValidator.isFieldValid(formConfig, 'country', location)}
        >
          {intl.get(messagesCommon.country.id)}
        </Label>
        <Select
          id="panel-country"
          options={formOptions.country}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={location?.country}
          onChange={e => onChange('country', e.value)}
        />
      </Box>
    </>
  );
}

LocationGeneralFormEdit.propTypes = {
  location: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

LocationGeneralFormEdit.defaultProps = {
  location: null
};

export default LocationGeneralFormEdit;
