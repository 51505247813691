import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon, messagesNavigation } from 'messages/messages';
import PanelCustomerOrServicePartner from 'pages/Customer/PanelCustomerOrServicePartner';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';
import ServicePartnerList from './ServicePartnerList';

function ServicePartnerOverview() {
  const [servicePartners, setServicePartners] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelData, setPanelData] = useState(null);

  // Effect to load service partner on load on keep them up to date
  useEffect(() => {
    const updateCollection = querySnapshot => {
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      setServicePartners(docs);
    };

    const unsub = FirebaseService.getServicePartnerRef().onSnapshot(updateCollection);

    return () => {
      unsub();
    };
  }, []);

  const openEditPanel = servicePartner => {
    setPanelData(servicePartner);
    setPanelOpen(true);
  };

  const closePanel = () => setPanelOpen(false);

  const addServicePartner = () => {
    setPanelData(null);
    setPanelOpen(true);
  };

  return (
    <>
      <Box maxWidth="1600px">
        <Box marginX={7} display="flex">
          <Heading level="1" size="xl">
            {intl.get(messagesNavigation.servicePartners.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => addServicePartner()}
            />
          </Box>
        </Box>

        <Box background="white" marginX={7} marginTop={5} display="flex">
          <ServicePartnerList servicePartners={servicePartners} openPanel={openEditPanel} />
        </Box>
      </Box>

      {panelOpen && (
        <PanelCustomerOrServicePartner
          customer={panelData}
          isServicePartner={true}
          onClose={closePanel}
        />
      )}
    </>
  );
}

export default ServicePartnerOverview;
