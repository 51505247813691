import Badge from 'components/Badge';
import Box from 'components/Box';
import Text from 'components/Text';
import useNumberFormat from 'hooks/useNumberFormat';
import PropTypes from 'prop-types';
import React from 'react';
import Number from './Number';

function StatisticBox({
  title,
  value,
  diffValue,
  digits,
  unit,
  round,
  moodWhenHigher,
  moodWhenLower
}) {
  const calculatePercentageDiff = () => {
    let percent = null;

    if (value !== null && diffValue !== null) {
      try {
        percent = ((value - diffValue) / diffValue) * 100;
      } catch {
        percent = null;
      }
    }
    return percent;
  };

  let formattedDiffValue = useNumberFormat(diffValue !== null ? diffValue : 0, digits, round);

  if (!isFinite(diffValue) || isNaN(diffValue)) {
    formattedDiffValue = '-';
  }

  const percentageDiff = calculatePercentageDiff();
  const percentageDiffDisplay = percentageDiff !== null ? parseFloat(percentageDiff.toFixed(0)) : 0;
  let smallDiff = false;

  if (percentageDiff !== null) {
    if (Math.abs(percentageDiff) < 1) {
      smallDiff = true;
    }
  }

  return (
    <Box display="flex" alignSelf="stretch" padding={2} width="20%">
      <Box background="white" borderRadius="10" width="100%" paddingX={6} paddingY={7} height={220}>
        <Box height={60} alignItems="center" justify="center">
          <Text align="center" size="sm" bold>
            {title}
          </Text>
        </Box>
        <Box>
          <Number
            number={value}
            digits={digits}
            round={round}
            align="center"
            size="3xl"
            suffix={unit}
          />
        </Box>
        <Box
          marginTop={12}
          display="flex"
          justify="space-between"
          alignItems="center"
          alignSelf="stretch"
        >
          {!isNaN(diffValue) && isFinite(diffValue) && diffValue !== null && (
            <>
              {percentageDiff && percentageDiff !== 0 ? (
                <Badge
                  mood={percentageDiff > 0 ? moodWhenHigher : moodWhenLower}
                  text={smallDiff ? '< 1%' : `${percentageDiffDisplay}%`}
                  arrow={percentageDiff > 0 ? 'up' : 'down'}
                />
              ) : (
                <Box />
              )}

              <Badge mood="neutral" text={`${formattedDiffValue} ${unit}`} arrow={false} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

StatisticBox.propTypes = {
  title: PropTypes.string,
  unit: PropTypes.string,
  round: PropTypes.bool,
  digits: PropTypes.number,
  value: PropTypes.number,
  diffValue: PropTypes.number,
  moodWhenHigher: PropTypes.oneOf(['positive', 'negative']),
  moodWhenLower: PropTypes.oneOf(['positive', 'negative'])
};

StatisticBox.defaultProps = {
  title: '',
  value: null,
  unit: '',
  digits: 2,
  round: true,
  diffValue: null,
  moodWhenHigher: 'positive',
  moodWhenLower: 'negative'
};

export default StatisticBox;
