import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import Number from 'components/Number';
import { CostFactorNames, CostFactorTypes } from 'constants/CostFactor';
import { ReactComponent as Edit } from 'images/EditAlt.svg';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon, messagesCostFactor } from 'messages/messages';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import PanelCostFactor from './PanelCostFactor';

function CostFactor() {
  const [isLoading, setIsLoading] = useState(true);
  const [factors, setFactors] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelData, setPanelData] = useState(null);

  useEffect(() => {
    const updateCollection = querySnapshot => {
      setIsLoading(true);
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      setFactors(docs);
      setIsLoading(false);
    };

    const unsub = FirebaseService.getCostFactorOfLocationRef(
      AppStateService.location.ref
    ).onSnapshot(updateCollection);

    return () => {
      unsub();
    };
  }, []);

  const getNameForCostFactor = costFactorData => {
    const typeMsgName = CostFactorNames[costFactorData.type];
    return intl.get(messagesCostFactor[typeMsgName].id);
  };

  /**
   * Render cost factor table row
   * @param {*} costFactor
   */
  const renderRow = costFactor => {
    const { data } = costFactor;
    let name = data.name;

    if (data.type !== CostFactorTypes.CUSTOM && data.type !== CostFactorTypes.DOSING) {
      name = getNameForCostFactor(data);
    }

    return (
      <tr id={costFactor.id} key={costFactor.id}>
        <td>{name}</td>
        <td>
          <Number number={data.value} digits={-1} round={false} size="sm" suffix="€" />
        </td>
        <td>{data.unit}</td>
        <td>
          <Edit onClick={() => openPanel(costFactor)} />
        </td>
      </tr>
    );
  };

  const openPanel = costFactor => {
    setPanelData(costFactor);
    setPanelOpen(true);
  };

  const closePanel = () => setPanelOpen(false);

  return (
    <>
      <Box maxWidth="1600px">
        <Box marginX={7} display="flex">
          <Heading level="1" size="xl">
            {intl.get(messagesCommon.costFactors.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => openPanel(null)}
            />
          </Box>
        </Box>

        <LoadingBox loading={isLoading}>
          <Box background="white" marginX={7} marginTop={3} display="flex">
            <table>
              <thead>
                <tr>
                  <th>{intl.get(messagesCommon.name.id)}</th>
                  <th>{intl.get(messagesCommon.costs.id)}</th>
                  <th>{intl.get(messagesCommon.unit.id)}</th>
                  <th width="100" />
                </tr>
              </thead>
              <tbody>{factors.map(f => renderRow(f))}</tbody>
            </table>
          </Box>
        </LoadingBox>
      </Box>
      {panelOpen && <PanelCostFactor costFactor={panelData} onClose={closePanel} />}
    </>
  );
}

export default CostFactor;
