import Box from 'components/Box';
import Panel from 'components/Panel';
import Text from 'components/Text';
import { ComponentTypeConfig } from 'constants/SteamComponent';
import { messagesComponents, messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import styled from 'styled-components';

const StyledTypeBox = styled.div`
  padding: 20px;
  border-radius: 4px;

  &.active {
    cursor: pointer;
  }

  &.inactive {
    cursor: not-allowed;

    span {
      color: ${props => props.theme.colors.grey};
    }
  }

  &.active:hover {
    background-color: ${props => props.theme.colors.blue};

    span {
      color: #fff;
    }
  }
`;

function PanelNewComponent({ componentCounts, onClose, onTypeSelected }) {
  const renderComponentTypeSelect = (type, config) => {
    const componentName = intl.get(messagesComponents[`component${config.name}`].id);
    let canHaveMore = false;
    const configMax = config.canHave;
    const currentCount = componentCounts[type];

    if (currentCount < configMax) {
      canHaveMore = true;
    }

    return (
      <Box
        key={type}
        onClick={() => (canHaveMore ? onTypeSelected(type) : null)}
        border
        marginY={3}
      >
        <StyledTypeBox className={canHaveMore ? 'active' : 'inactive'}>
          <Text align="center">{componentName}</Text>
        </StyledTypeBox>
      </Box>
    );
  };

  return (
    <Panel
      title={intl.get(messagesCrud.newComponentAdd.id)}
      buttonPrimaryAction={null}
      onClose={onClose}
      showSpinner={false}
      isOpen
      width={720}
    >
      <Box marginTop={5}>
        {Object.keys(ComponentTypeConfig).map(type =>
          renderComponentTypeSelect(type, ComponentTypeConfig[type])
        )}
      </Box>
    </Panel>
  );
}

PanelNewComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onTypeSelected: PropTypes.func.isRequired,
  componentCounts: PropTypes.array.isRequired
};

export default PanelNewComponent;
