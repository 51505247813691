export const ComponentTypes = {
  WaterTreatment: 'WATER_TREATMENT',
  FeedWater: 'FEED_WATER',
  SteamBoiler: 'STEAM_BOILER',
  Burner: 'BURNER',
  Dosing: 'DOSING',
  Condensate: 'CONDENSATE',
  Custom: 'CUSTOM'
};

export const ComponentTypeConfig = {
  WATER_TREATMENT: {
    name: 'WaterTreatment',
    requiresOne: true,
    canHave: 2
  },
  FEED_WATER: {
    name: 'FeedWater',
    requiresOne: true,
    canHave: 1
  },
  STEAM_BOILER: {
    name: 'SteamBoiler',
    requiresOne: true,
    canHave: 2
  },
  BURNER: {
    name: 'Burner',
    requiresOne: true,
    canHave: 2
  },
  DOSING: {
    name: 'Dosing',
    requiresOne: true,
    canHave: 3
  },
  CONDENSATE: {
    name: 'Condensate',
    requiresOne: false,
    canHave: 1
  },
  CUSTOM: {
    name: 'Custom',
    requiresOne: false,
    canHave: 50
  }
};
