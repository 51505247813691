import Box from 'components/Box';
import Heading from 'components/Heading';
import Text from 'components/Text';
import { messagesNoMatch } from 'messages/messages';
import React from 'react';
import intl from 'react-intl-universal';

function NoMatch() {
  return (
    <Box paddingX={5}>
      <Heading size="xl">{intl.get(messagesNoMatch.headline.id)}</Heading>
      <Box marginTop={1}>
        <Text size="sm">{intl.get(messagesNoMatch.text.id)}</Text>
      </Box>
    </Box>
  );
}

export default NoMatch;
