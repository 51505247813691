export const paddingOrMarginToRem = (value = 0) => {
  let converted = 0;

  switch (value) {
    case 0:
      converted = '0rem';
      break;
    case 0.5:
      converted = '.063rem';
      break;
    case 1:
      converted = '.25rem';
      break;
    case 2:
      converted = '.5rem';
      break;
    case 3:
      converted = '.75rem';
      break;
    case 4:
      converted = '1rem';
      break;
    case 5:
      converted = '1.25rem';
      break;
    case 6:
      converted = '1.5rem';
      break;
    case 7:
      converted = '1.875rem';
      break;
    case 8:
      converted = '2rem';
      break;
    case 10:
      converted = '2.5rem';
      break;
    case 12:
      converted = '3rem';
      break;
    case 16:
      converted = '4rem';
      break;
    case 20:
      converted = '5rem';
      break;
    case 21:
      converted = '5.25rem';
      break;
    case 22:
      converted = '5.5rem';
      break;
    case 24:
      converted = '6rem';
      break;
    case 32:
      converted = '8rem';
      break;
    case 40:
      converted = '10rem';
      break;
    case 48:
      converted = '12rem';
      break;
    case 56:
      converted = '14rem';
      break;
    case 64:
      converted = '16rem';
      break;
    default:
      converted = '0rem';
      break;
  }

  return converted;
};

export const textSizes = (value = 'base') => {
  let converted = 0;

  switch (value) {
    case 'xs':
      converted = '.75rem';
      break;
    case 'sm':
      converted = '.875rem';
      break;
    case 'base':
      converted = '1rem';
      break;
    case 'md':
      converted = '1.05rem';
      break;
    case 'lg':
      converted = '1.125rem';
      break;
    case 'xl':
      converted = '1.25rem';
      break;
    case '2xl':
      converted = '1.5rem';
      break;
    case '3xl':
      converted = '1.875rem';
      break;
    case '4xl':
      converted = '2.25rem';
      break;
    case '5xl':
      converted = '3rem';
      break;
    case '6xl':
      converted = '4rem';
      break;
    default:
      converted = '1rem';
      break;
  }

  return converted;
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const sortCustomerDocs = customers => {
  const sorted = customers.sort((a, b) => {
    const aKey = a.data.name.toLowerCase();
    const bKey = b.data.name.toLowerCase();
    if (aKey < bKey) {
      return -1;
    }
    if (aKey > bKey) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const sortCustomers = customers => {
  const sorted = customers.sort((a, b) => {
    const aKey = a.name.toLowerCase();
    const bKey = b.name.toLowerCase();
    if (aKey < bKey) {
      return -1;
    }
    if (aKey > bKey) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const sortLocationDocs = customers => {
  const sorted = customers.sort((a, b) => {
    const aKey = a.data.name.toLowerCase();
    const bKey = b.data.name.toLowerCase();
    if (aKey < bKey) {
      return -1;
    }
    if (aKey > bKey) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const sortLocations = customers => {
  const sorted = customers.sort((a, b) => {
    const aKey = a.name.toLowerCase();
    const bKey = b.name.toLowerCase();
    if (aKey < bKey) {
      return -1;
    }
    if (aKey > bKey) {
      return 1;
    }
    return 0;
  });

  return sorted;
};
