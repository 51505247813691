import Panel from 'components/Panel';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import CustomerFormEdit from './CustomerFormEdit';

function PanelCustomerOrServicePartner({ customer, isServicePartner, onClose }) {
  const formValidator = useFormValidation();
  const [customerData, setCustomerData] = useState(customer && customer.data);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [valid, setValid] = useState(false);
  const [isCreateMode] = useState(!customer);

  useEffect(() => {
    let validData;

    if (isServicePartner) {
      validData = formValidator.validateServicePartner(customerData);
    } else {
      validData = formValidator.validateCustomer(customerData);
    }

    setValid(validData);
  }, [customerData, formValidator, isServicePartner]);

  const handleFormFieldChange = (key, value) => {
    const data = { ...customerData };
    data[key] = value;

    setCustomerData(data);
  };

  const save = async () => {
    try {
      const data = customerData;
      const id = customer ? customer.id : undefined;
      const isCreate = id === undefined;
      let newCustomerId;

      setSaveInProgress(true);

      if (isCreate) {
        data.isDeleted = false;
      }

      if (isServicePartner) {
        await FirebaseService.updateServicePartner(id, data);
      } else {
        const result = await FirebaseService.updateCustomer(id, data);

        if (isCreate) {
          newCustomerId = result.id;
        }

        // if current customer was changed => update it in app state
        if (AppStateService.customer && AppStateService.customer.ref.id === id) {
          const appStateServiceCustomer = {
            ...AppStateService.customer,
            data: { ...AppStateService.customer.data, ...customerData }
          };

          AppStateService.setCustomer(appStateServiceCustomer);
        }
      }

      setSaveInProgress(false);

      // if this was create => open popup that can redirect to customer overview page
      // with params that trigger an info to create a location and user
      onClose(newCustomerId);
    } catch (e) {
      console.log('Customer or service partner could not be updated');
      console.log(e);
    }
  };

  let title = isCreateMode
    ? intl.get(messagesCrud.customerAdd.id)
    : intl.get(messagesCrud.customerEdit.id);

  if (isServicePartner) {
    title = isCreateMode
      ? intl.get(messagesCrud.servicePartnerAdd.id)
      : intl.get(messagesCrud.servicePartnerEdit.id);
  }

  return (
    <Panel
      title={title}
      buttonPrimaryAction={save}
      buttonPrimaryDisabled={!valid}
      onClose={() => onClose()}
      showSpinner={saveInProgress}
      isOpen
    >
      <CustomerFormEdit
        customer={customerData}
        isServicePartner={isServicePartner}
        onChange={handleFormFieldChange}
      />
    </Panel>
  );
}

PanelCustomerOrServicePartner.propTypes = {
  customer: PropTypes.any,
  isServicePartner: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

PanelCustomerOrServicePartner.defaultProps = {
  customer: null,
  isServicePartner: false
};

export default PanelCustomerOrServicePartner;
