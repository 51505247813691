import Box from 'components/Box';
import Button from 'components/Button';
import DayPicker from 'components/DayPicker';
import Label from 'components/Label';
import LoadingBox from 'components/LoadingBox';
import Select from 'components/Select';
import Toggle from 'components/Toggle';
import useFormOptions from 'hooks/useFormOptions';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';
import PdfExportService from 'services/PdfExportService';
import styled from 'styled-components';

const StyledBackdrop = styled(Box)`
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 990;
  opacity: 0.4;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 80px;
`;

const StyledOverlay = styled(Box)`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 2px 20px 0 rgba(5, 16, 20, 0.1);
  transition: all 0.5s;
  height: 400px;
  width: 900px;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
`;

function ExportOverlay({
  onClose,
  measurements,
  components,
  costFactors,
  activeComponentValues,
  getUnitFunc
}) {
  const formOptions = useFormOptions();

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (filter.type) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [filter]);

  const handleFormFieldChange = (key, value) => {
    const data = { ...filter };
    data[key] = value;
    setFilter(data);
  };

  const handleDateChange = (key, date) => {
    const updated = FirebaseService.getTimestamp(date);
    handleFormFieldChange(key, updated);
  };

  const createExport = async () => {
    setLoading(true);
    await PdfExportService.createExport({
      measurements,
      components,
      costFactors,
      filter,
      activeComponentValues,
      getUnitFunc
    });
    setLoading(false);
  };

  return (
    <>
      <StyledBackdrop onClick={onClose} />
      <StyledOverlay paddingX={8} paddingY={8}>
        <LoadingBox loading={loading} renderChildren={!loading} height="100%">
          <Box marginY={3}>
            <Label htmlFor="filter-type">Welche Daten sollen exportiert werden</Label>
            <Select
              id="filter-type"
              options={formOptions.exportType}
              placeholder={intl.get(messagesCommon.choose.id)}
              value={filter.type}
              onChange={e => handleFormFieldChange('type', e.value)}
            />
          </Box>
          <Box marginY={3}>
            <Label htmlFor="filter-from">Daten von</Label>
            <DayPicker
              id="filter-from"
              placeholder={intl.get(messagesCommon.choose.id)}
              onChange={date => handleDateChange('from', date)}
              value={filter.from ? filter.from.toDate() : undefined}
            />
          </Box>
          <Box marginY={3}>
            <Label htmlFor="filter-to">Daten bis</Label>
            <DayPicker
              id="filter-to"
              disabled={!filter.from}
              placeholder={intl.get(messagesCommon.choose.id)}
              onChange={date => handleDateChange('to', date)}
              value={filter.to ? filter.to.toDate() : undefined}
            />
          </Box>
          <Box marginY={3}>
            <Label htmlFor="filter-to">Kommentare anzeigen</Label>
            <Toggle
              enabled={filter.showComments === true}
              onChange={active => handleFormFieldChange('showComments', active)}
            />
          </Box>

          <Box marginY={7}>
            <Button
              text="Exportieren"
              type="button"
              onClick={() => createExport()}
              disabled={!valid}
            />
          </Box>
        </LoadingBox>
      </StyledOverlay>
    </>
  );
}

ExportOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  measurements: PropTypes.any.isRequired,
  components: PropTypes.any.isRequired,
  costFactors: PropTypes.any.isRequired,
  activeComponentValues: PropTypes.any.isRequired,
  getUnitFunc: PropTypes.func.isRequired
};

export default ExportOverlay;
