import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import Popup from 'components/Popup';
import Status from 'components/Status';
import TabList from 'components/TabList';
import Text from 'components/Text';
import { INTERVAL_CONFIG } from 'constants/Interval';
import {
  NOTIFICATIONS,
  NOTIFICATION_ACTION_MESSAGE,
  NOTIFICATION_TYPES
} from 'constants/Notification';
import { ReactComponent as IconArchive } from 'images/Archive.svg';
import { ReactComponent as IconEdit } from 'images/EditAlt.svg';
import { ReactComponent as IconConfig } from 'images/Gear.svg';
import { messagesCommon, messagesNotification } from 'messages/messages';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import NotificationService from 'services/NotificationService';

function Notification() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingActive, setLoadingActive] = useState(true);
  const [loadingArchive, setLoadingArchive] = useState(true);
  const [tabListIndex, setTabListIndex] = useState(0);
  const [activeCurrentNotifications, setActiveCurrentNotifications] = useState([]);
  const [archivedNotifications, setArchivedNotifications] = useState([]);
  const [showArchiveAllInfo, setShowArchiveAllInfo] = useState(false);

  const handleTabListItemChange = ({ activeIndex }) => {
    setTabListIndex(activeIndex);
  };

  // Effect to notifications and keep them up to date
  useEffect(() => {
    const updateCollection = async (querySnapshot, stateSaveFunc, loadType) => {
      if (loadType === 'active') {
        setLoadingActive(true);
      }

      if (loadType === 'archive') {
        setLoadingArchive(true);
      }

      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc.ref,
          data: doc.data()
        });
      });

      // populate the component data inside the data of external check
      const populate = docs.map(async doc => {
        const docWithRelations = { ...doc };
        const component = await doc.data.component.get();

        docWithRelations.componentData = component.data();

        if (doc.data.externalCheck) {
          const externalCheck = await doc.data.externalCheck.get();

          if (externalCheck && externalCheck.exists) {
            docWithRelations.externalCheckData = externalCheck.data();
          } else {
            console.log('EXTERNAL CHECK DOES NOT EXIST ANY MORE !');
          }
        }

        if (doc.data.measurement) {
          const measurement = await doc.data.measurement.get();
          if (measurement && measurement.exists) {
            docWithRelations.measurementData = measurement.data();
          }
        }

        return docWithRelations;
      });

      const docsWithRelationData = await Promise.all(populate);
      stateSaveFunc(docsWithRelationData);
      if (loadType === 'active') {
        setLoadingActive(false);
      }

      if (loadType === 'archive') {
        setLoadingArchive(false);
      }
    };

    let activeUnsub = null;
    const activeQuery = FirebaseService.getActiveNotificationRef(AppStateService.location.ref);

    if (activeQuery) {
      activeUnsub = activeQuery.onSnapshot(snapshot =>
        updateCollection(snapshot, setActiveCurrentNotifications, 'active')
      );
    }

    let archivedUnsub = null;
    const archiveQuery = FirebaseService.getArchivedNotificationRef(AppStateService.location.ref);

    if (archiveQuery) {
      archivedUnsub = archiveQuery.onSnapshot(snapshot =>
        updateCollection(snapshot, setArchivedNotifications, 'archive')
      );
    }

    return () => {
      if (activeUnsub) {
        activeUnsub();
      }
      if (archivedUnsub) {
        archivedUnsub();
      }
    };
  }, []);

  const linkToMeasurement = notificationRef => {
    history.push(
      `/boiler-book/entry/${notificationRef.data.measurement.id}?notification=${notificationRef.id}&paramName=${notificationRef.data.param.name}&componentId=${notificationRef.data.component.id}`
    );
  };

  const openSteamPlantConfiguration = notificationRef => {
    history.push(`/steam-plant?componentId=${notificationRef.data.component.id}`);
  };

  const archiveNotification = async notificationRef => {
    return NotificationService.archiveNotification(notificationRef);
  };

  // Archive and renew notification => used for external check notifications
  const archiveAndRenewNotification = async notificationRef => {
    const externalCheck = notificationRef.externalCheckData;
    const notification = notificationRef.data;

    if (externalCheck && externalCheck.active) {
      const intervalConfig = INTERVAL_CONFIG[externalCheck.interval];
      const newDueDate = NotificationService.getNextFutureDueDate(
        externalCheck.nextDueDate.toDate(),
        intervalConfig
      );

      const newDueDateTimestamp = FirebaseService.getTimestamp(newDueDate);

      // Update the next due date
      // Create a new notification based on new due date
      const externalCheckUpdate = { ...externalCheck, nextDueDate: newDueDateTimestamp };

      const result = await FirebaseService.updateExternalCheck(
        notification.externalCheck.id,
        externalCheckUpdate
      );
      await NotificationService.archiveNotification(notificationRef);
      await NotificationService.addExternalCheckNotification(externalCheckUpdate, result);
    } else {
      // Archive current notification
      await NotificationService.archiveNotification(notificationRef);
    }

    return true;
  };

  // @NOTE notifications did not had the type inside it from the start
  //       so there is backup try and catch method to provide the correct name
  const getParamName = (type, paramName) => {
    // fix bad naming
    let name = null;
    let transType = type;
    if (type === 'operatingValues') {
      transType = 'operationalValues';
    }

    const valueTypes = ['waterValues', 'operationalValues', 'meterValues'];
    if (transType !== null && transType !== undefined) {
      const transKey = `component.${transType}.${paramName}`;
      name = intl.get(transKey);
    } else {
      valueTypes.map(type => {
        if (!name) {
          const transKey = `component.${type}.${paramName}`;
          name = intl.get(transKey);
        }
        return null;
      });
    }

    if (!name) {
      name = paramName;
    }

    return name;
  };

  const createNotificationText = notificationRef => {
    const notification = notificationRef.data;
    const component = notificationRef.componentData;

    let text = '';

    switch (notification.type) {
      case NOTIFICATION_TYPES.ValueOutOfBoundsMax:
      case NOTIFICATION_TYPES.ValueOutOfBoundsMin: {
        const paramName = getParamName(notification.paramType, notification.param.name);
        const measured = notification.value.value;
        const unit = notification.value.unit ? notification.value.unit : '';
        text = intl.get(messagesNotification.outOfBounds.id, {
          param: paramName,
          component: component.internalName,
          value: measured,
          unit
        });
        break;
      }
      case NOTIFICATION_TYPES.IntervalCheck: {
        const externalCheck = notificationRef.externalCheckData;
        text = intl.get(messagesNotification.interval.id, {
          check: externalCheck.name,
          component: component.internalName
        });
        break;
      }
      default:
        break;
    }

    return text;
  };

  const archiveAll = async () => {
    const active = [...activeCurrentNotifications];
    const ops = [];
    setShowArchiveAllInfo(false);
    setLoading(true);

    for (let i = 0; i < active.length; i += 1) {
      const notificationRef = active[i];
      let archivePromise = null;

      if (notificationRef.data.type === NOTIFICATION_TYPES.IntervalCheck) {
        archivePromise = archiveAndRenewNotification(notificationRef);
      } else {
        archivePromise = archiveNotification(notificationRef);
      }
      ops.push(archivePromise);
    }
    await Promise.all(ops);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const renderOutOfBoundsRow = (active, notificationRef) => {
    const componentData = notificationRef.componentData;
    const notification = notificationRef.data;
    const notificationConfig = NOTIFICATIONS[notification.type];
    let dateString = moment(notification.activeAt.toDate()).format('DD.MM.YYYY');
    if (notificationRef.measurementData) {
      dateString = moment(notificationRef.measurementData.createdAt.toDate()).format('DD.MM.YYYY');
    }

    const actionMessage = NOTIFICATION_ACTION_MESSAGE.filter(
      message =>
        message.componentType === componentData.type &&
        message.parameterName === notification.param.name
    )[0];

    return (
      <tr key={notificationRef.id}>
        <td>
          <Text size="sm" marginTop={2}>
            <Text inline size="sm" opacity=".65" marginRight={4}>
              {dateString}
            </Text>
            {createNotificationText(notificationRef)}
          </Text>
          {active && actionMessage && (
            <Text size="sm" opacity=".65" bold marginTop={4} marginBottom={2} marginLeft={21}>
              {intl.get(actionMessage.actionText)}
            </Text>
          )}
        </td>
        <td width="210">
          {active ? (
            <Status text={intl.get(messagesNotification.outOfBoundsError.id)} />
          ) : (
            <Text size="xs">
              {intl.get(messagesNotification.archivedAt.id)}{' '}
              {moment(notification.archivedAt.toDate()).format('DD.MM.YYYY HH:mm')}
            </Text>
          )}
        </td>
        {active && (
          <td width="150" style={{ textAlign: 'right' }}>
            <IconConfig
              title={intl.get(messagesNotification.openConfig.id)}
              onClick={() => openSteamPlantConfiguration(notificationRef)}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginRight: '15px',
                width: '21px',
                height: '21px'
              }}
            />
            {notificationConfig.linkToMeasurement && (
              <IconEdit
                title={intl.get(messagesNotification.openMeasurement.id)}
                style={{ cursor: 'pointer', display: 'inline-block', marginRight: '15px' }}
                onClick={() => linkToMeasurement(notificationRef)}
              />
            )}
            <IconArchive
              title={intl.get(messagesNotification.archive.id)}
              onClick={() => archiveNotification(notificationRef)}
              style={{ cursor: 'pointer' }}
            />
          </td>
        )}
      </tr>
    );
  };

  const renderExternalCheckRow = (active, notificationRef) => {
    const externalCheck = notificationRef.externalCheckData;

    if (!externalCheck) {
      return null;
    }

    const notification = notificationRef.data;
    const dateString = moment(notification.activeAt.toDate()).format('DD.MM.YYYY');

    const now = moment();
    const nextDue = moment(externalCheck.startDate.toDate());
    const daysToNextDueDate = now.diff(nextDue, 'days');
    const isDue = daysToNextDueDate > 0;

    return (
      <tr key={notificationRef.id}>
        <td>
          <Text size="sm" marginTop={2}>
            <Text inline size="sm" opacity=".65" marginRight={4}>
              {dateString}
            </Text>
            {createNotificationText(notificationRef)}
          </Text>
        </td>
        <td width="210">
          {active ? (
            <Status
              color={isDue ? 'red' : 'orange'}
              text={
                isDue
                  ? intl.get(messagesNotification.intervalError.id)
                  : intl.get(messagesNotification.intervalNotice.id)
              }
            />
          ) : (
            <Text size="xs">
              {intl.get(messagesNotification.archivedAt.id)}{' '}
              {moment(notification.archivedAt.toDate()).format('DD.MM.YYYY HH:mm')}
            </Text>
          )}
        </td>
        {active && (
          <td width="100" style={{ textAlign: 'right' }}>
            <IconArchive
              title={intl.get(messagesNotification.archive.id)}
              onClick={() => archiveAndRenewNotification(notificationRef)}
              style={{ cursor: 'pointer' }}
            />
          </td>
        )}
      </tr>
    );
  };

  const renderNotificationRows = () => {
    const renderActive = tabListIndex === 0;
    let notifications = [];

    if (renderActive) {
      notifications = activeCurrentNotifications;
    } else {
      notifications = archivedNotifications;
    }

    return notifications.map(notificationRef => {
      if (notificationRef.data.type === NOTIFICATION_TYPES.IntervalCheck) {
        return renderExternalCheckRow(renderActive, notificationRef);
      }

      return renderOutOfBoundsRow(renderActive, notificationRef);
    });
  };

  return (
    <>
      <LoadingBox loading={loading || loadingActive || loadingArchive} maxWidth="1600px">
        <Box marginX={7}>
          <Heading level="1" size="xl">
            {intl.get(messagesCommon.notifications.id)}
          </Heading>
        </Box>

        <Box marginX={7} marginTop={7} display="flex" direction="row" justify="space-between">
          <TabList
            width={500}
            items={[
              {
                index: 0,
                title: `${intl.get(messagesCommon.current.id)} (${
                  activeCurrentNotifications.length
                })`
              },
              {
                index: 1,
                title: `${intl.get(messagesCommon.archive.id)} (${archivedNotifications.length})`
              }
            ]}
            selectedItemIndex={tabListIndex}
            onChange={handleTabListItemChange}
          />
          {activeCurrentNotifications.length > 0 ? (
            <Box>
              <Button
                text={intl.get(messagesCommon.archiveAllTitle.id)}
                onClick={() => setShowArchiveAllInfo(true)}
              />
            </Box>
          ) : null}
        </Box>

        <Box background="white" marginX={7} marginTop={4} display="flex">
          <table>
            <tbody>{renderNotificationRows()}</tbody>
          </table>
        </Box>
      </LoadingBox>
      {showArchiveAllInfo ? (
        <Popup
          show
          size={400}
          title={intl.get(messagesCommon.archiveAllTitle.id)}
          message={intl.get(messagesCommon.archiveAllText.id)}
          confirmColor="red"
          confirmText={intl.get(messagesCommon.yes.id)}
          confirmAction={archiveAll}
          abortAction={() => setShowArchiveAllInfo(false)}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      ) : null}
    </>
  );
}

export default Notification;
