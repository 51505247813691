import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import ParameterService from './ParameterService';

const CsvExportService = {
  getParameterName: paramName => {
    const param = ParameterService.getParameterByName(paramName);
    const trans = localStorage.getItem('locale') === 'de-DE' ? param.labelDE : param.labelEN;
    return trans;
  },

  exportMeasurementData: ({
    customer,
    location,
    measurements,
    components,
    costFactors,
    activeComponentParameter,
    getUnitFunc
  }) => {
    const types = ['waterValues', 'operatingValues', 'meterValues'];

    const headers = [];
    const rows = [];

    // empty row for date row
    headers.push(' ');

    components.map(componentRef => {
      types.map(type => {
        const activeValues = activeComponentParameter[componentRef.id];
        const valuesOfType = activeValues[type];

        valuesOfType.map(value => {
          const paramName = CsvExportService.getParameterName(value.name);
          const paramUnit = getUnitFunc(value.name, {
            component: componentRef.data,
            costFactors
          });
          const headerName = `${componentRef.data.internalName} - ${paramName} ${
            paramUnit !== null && paramUnit !== undefined ? `[${paramUnit}]` : ''
          }`;

          headers.push(`"${headerName}"`);
          return null;
        });
        return null;
      });
      return null;
    });

    measurements.map(measurementRef => {
      const row = {};
      const measurement = measurementRef.data;

      // date
      row.date = moment(measurement.createdAt.toDate()).format('DD.MM.YY');

      components.map(componentRef => {
        const activeValues = activeComponentParameter[componentRef.id];

        types.map(type => {
          const valuesOfType = activeValues[type];

          const componentMeasureData = measurement.measuredValues.filter(
            measured => measured.componentId === componentRef.id
          )[0];

          valuesOfType.map(value => {
            let measuredValue = null;

            if (componentMeasureData && componentMeasureData[type]) {
              [measuredValue] = componentMeasureData[type].filter(
                mValue => mValue.name === value.name
              );
            }

            // @TODO Number format ?
            let valueText = '';

            if (measuredValue) {
              valueText = `${measuredValue.value}`;
            } else {
              valueText = '-';
            }

            row[`${componentRef.id}-${value.name}`] = valueText;
          });
          return null;
        });

        return null;
      });

      rows.push(row);

      return null;
    });
    const dateStr = moment().format('DD_MM_YYYY');
    const customerLocationStr = `${customer.name}_${location.name}`;
    const dataToConvert = {
      data: rows,
      filename: `diwaso_csv_export__${customerLocationStr}__${dateStr}`,
      delimiter: ',',
      headers
    };

    csvDownload(dataToConvert);

    return true;
  }
};

export default CsvExportService;
