import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Popup from 'components/Popup';
import Spinner from 'components/Spinner';
import { messagesCommon, messagesComponentArchive } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import styled from 'styled-components/macro';

const StyledPanelWrapper = styled.div`
  display: block;
  position: fixed;
  top: 80px;
  z-index: 1000;
`;

const StyledPanel = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 2px 20px 0 rgba(5, 16, 20, 0.1);
  transition: all 0.5s;
  height: 100vh;
  width: ${props => `${props.width}px`};
  transform: ${props => (props.isOpen ? ' translateX(0%)' : 'translateX(+100%)')};
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 1000;
`;

const StyledBackDrop = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: ${props => (props.isOpen ? 0.1 : 0)};
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 80px;
`;

function Panel({
  children,
  title,
  buttonPrimaryText,
  buttonPrimaryDisabled,
  buttonSecondaryText,
  buttonPrimaryAction,
  buttonSecondaryAction,
  isOpen,
  onClose,
  onArchive,
  closeOnBackDropClick,
  width,
  showSpinner
}) {
  const [showArchivePopup, setShowArchivePopup] = useState(false);
  const closeOnEscKey = useCallback(
    event => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', closeOnEscKey, false);
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', closeOnEscKey, false);
    };
  }, [isOpen, closeOnEscKey]);

  return (
    <>
      <StyledPanelWrapper>
        <StyledPanel width={width} isOpen={isOpen}>
          <Box height="calc(100% - 80px)" paddingX={5} paddingY={6}>
            <Box display="flex" justify="space-between" alignItems="center">
              <Heading size="xl">{title}</Heading>
              {onArchive && (
                <Button
                  background="transparent"
                  ghost="red"
                  color="red"
                  underline
                  noShadow
                  text={intl.get(messagesCommon.archiveAction.id)}
                  inline
                  textSize="xs"
                  type="button"
                  size="sm"
                  onClick={() => setShowArchivePopup(true)}
                />
              )}
            </Box>
            <Box position="relative" height="calc(100% - 26px)" marginTop={6}>
              {children}

              {buttonPrimaryAction && (
                <Box width="100%" position="absolute" bottom={5} display="flex" justify="center">
                  <Box>
                    <Button
                      text={
                        buttonPrimaryText !== null
                          ? buttonPrimaryText
                          : intl.get(messagesCommon.save.id)
                      }
                      onClick={buttonPrimaryAction}
                      disabled={buttonPrimaryDisabled}
                      minWidth={158}
                      background="blue"
                    />
                  </Box>
                  <Box marginLeft={3}>
                    <Button
                      text={
                        buttonSecondaryText !== null
                          ? buttonSecondaryText
                          : intl.get(messagesCommon.cancel.id)
                      }
                      onClick={buttonSecondaryAction !== null ? buttonSecondaryAction : onClose}
                      minWidth={158}
                      background="blueLight"
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {showSpinner && (
              <Box
                background="white"
                position="absolute"
                opacity="0.65"
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justify="center"
                left="0"
                top="0"
                zIndex={150}
              >
                <Spinner />
              </Box>
            )}
          </Box>
        </StyledPanel>
        <StyledBackDrop isOpen={isOpen} onClick={closeOnBackDropClick && onClose} />
      </StyledPanelWrapper>

      {showArchivePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesComponentArchive.title.id)}
          message={intl.get(messagesComponentArchive.message.id)}
          confirmColor="red"
          confirmText={intl.get(messagesComponentArchive.action.id)}
          confirmAction={onArchive}
          abortAction={() => setShowArchivePopup(false)}
        />
      )}
    </>
  );
}

Panel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryAction: PropTypes.func,
  buttonSecondaryAction: PropTypes.func,
  isOpen: PropTypes.bool,
  onArchive: PropTypes.func,
  closeOnBackDropClick: PropTypes.bool,
  width: PropTypes.number,
  showSpinner: PropTypes.bool,
  buttonPrimaryDisabled: PropTypes.bool
};

Panel.defaultProps = {
  title: '',
  buttonPrimaryText: null,
  buttonSecondaryText: null,
  buttonPrimaryAction: null,
  buttonSecondaryAction: null,
  isOpen: false,
  onArchive: null,
  width: 500,
  closeOnBackDropClick: true,
  showSpinner: false,
  buttonPrimaryDisabled: false
};

export default Panel;
