import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import Heading from 'components/Heading';
import Label from 'components/Label';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { REQUIRED_FIELDS } from 'constants/Form';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function UserFormEdit({ isCreateMode, user, onChange }) {
  const formOptions = useFormOptions();
  const formConfig = REQUIRED_FIELDS.user;
  const formValidator = useFormValidation();

  const [loadingLocations, setLoadingLocations] = useState(true);
  const [locations, setLocations] = useState([]);
  const [mailError, setMailError] = useState(null);

  // Effect to load locations of customer
  useEffect(() => {
    const loadLocations = async () => {
      setLoadingLocations(true);
      const docs = [];
      const querySnapshot = await FirebaseService.queryLocationsOfCustomer(
        AppStateService.customer.ref
      );

      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc.ref,
          data: doc.data()
        });
      });

      setLocations(docs);
      setLoadingLocations(false);
    };

    loadLocations();
  }, []);

  useEffect(() => {
    const createInitial = (first, last) => {
      return `${first.substr(0, 2).toUpperCase()}${last.substr(0, 2).toUpperCase()}`;
    };

    if (user && user.firstname && user.lastname && !user.tag) {
      if (user.firstname.length > 2 && user.lastname.length > 2) {
        user.tag = createInitial(user.firstname, user.lastname);
      }
    }
  }, [user]);

  const updateLocationList = (checked, location) => {
    /*
    if (user && user.defaultLocation && location.id === user.defaultLocation.id) {
      return false;
    }
    */

    const selected = user && user.locations ? user.locations : [];
    let updated = [...selected];
    if (checked) {
      updated.push(location.ref);
    } else {
      updated = updated.filter(l => l.id !== location.id);
    }
    onChange('locations', updated);
  };

  const updateDefaultLocation = id => {
    const location = locations.filter(location => location.id === id)[0];
    onChange('defaultLocation', location.ref);

    const selected = user && user.locations ? user.locations : [];
    const hasSelectedLocation = selected.filter(location => location.id === id)[0];

    if (!hasSelectedLocation) {
      // onChange('locations', selected);
    }
  };

  const renderLocationCheckbox = location => {
    const selected = user && user.locations ? user.locations : [];
    const isSelected = selected.filter(l => l.id === location.id).length > 0;
    const locationData = location.data;

    return (
      <Box key={`panel-location-${location.id}`} alignItems="center" direction="row" display="flex">
        <Label htmlFor={`panel-location-${location.id}`}>
          <Checkbox
            id={`panel-location-${location.id}`}
            checked={isSelected}
            onChange={e => updateLocationList(e.target.checked, location)}
          />
          <span style={{ marginLeft: 8 }}>{locationData.name}</span>
        </Label>
      </Box>
    );
  };

  const updateEmail = async e => {
    // eslint-disable-next-line
    const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValue = e.currentTarget.value;

    onChange('email', emailValue);
    setMailError(null);

    if (mailRegex.test(emailValue) === true) {
      const accounts = await FirebaseService.firebaseRef
        .auth()
        .fetchSignInMethodsForEmail(emailValue);
      if (accounts.length === 0) {
        // onChange('email', emailValue);
      } else {
        setMailError('duplicate');
      }
    } else {
      setMailError('invalid');
    }
  };

  return (
    <form noValidate autoComplete="off">
      {isCreateMode && (
        <>
          <Box marginY={3}>
            <Label
              htmlFor="panel-email"
              required={formValidator.isFieldRequired(formConfig, 'email')}
              valid={formValidator.isFieldValid(formConfig, 'email', user)}
            >
              {intl.get(messagesCommon.emailAddress.id)}
            </Label>
            <TextField
              id="panel-email"
              type="text"
              placeholder={intl.get(messagesCommon.emailAddress.id)}
              value={user?.email}
              onChange={updateEmail}
            />

            {mailError === 'invalid' && (
              <Text color="red" size="sm">
                {intl.get(messagesCommon.invalidMail.id)}
              </Text>
            )}

            {mailError === 'duplicate' && (
              <Text color="red" size="sm">
                {intl.get(messagesCommon.mailAlreadyInUse.id)}
              </Text>
            )}
          </Box>
        </>
      )}
      <Box marginY={3}>
        <Label
          htmlFor="panel-firstname"
          required={formValidator.isFieldRequired(formConfig, 'firstname')}
          valid={formValidator.isFieldValid(formConfig, 'firstname', user)}
        >
          {intl.get(messagesCommon.firstname.id)}
        </Label>
        <TextField
          id="panel-firstname"
          type="text"
          placeholder={user?.firstname || intl.get(messagesCommon.firstname.id)}
          value={user?.firstname}
          onChange={e => onChange('firstname', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-lastname"
          required={formValidator.isFieldRequired(formConfig, 'lastname')}
          valid={formValidator.isFieldValid(formConfig, 'lastname', user)}
        >
          {intl.get(messagesCommon.lastname.id)}
        </Label>
        <TextField
          id="panel-lastname"
          type="text"
          placeholder={user?.lastname || intl.get(messagesCommon.lastname.id)}
          value={user?.lastname}
          onChange={e => onChange('lastname', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-tag"
          required={formValidator.isFieldRequired(formConfig, 'tag')}
          valid={formValidator.isFieldValid(formConfig, 'tag', user)}
        >
          {intl.get(messagesCommon.abbreviation.id)}
        </Label>
        <TextField
          id="panel-tag"
          type="text"
          placeholder={user?.tag || intl.get(messagesCommon.abbreviation.id)}
          value={user?.tag}
          onChange={e => onChange('tag', e.currentTarget.value)}
          maxLength={4}
        />
      </Box>
      <Box marginY={3}>
        <Label htmlFor="panel-phone">{intl.get(messagesCommon.phone.id)}</Label>
        <TextField
          id="panel-phone"
          type="text"
          placeholder={user?.phone || intl.get(messagesCommon.phone.id)}
          value={user?.phone}
          onChange={e => onChange('phone', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-role"
          required={formValidator.isFieldRequired(formConfig, 'role')}
          valid={formValidator.isFieldValid(formConfig, 'role', user)}
        >
          {intl.get(messagesCommon.role.id)}
        </Label>
        <Select
          id="panel-role"
          options={formOptions.role}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={user?.role}
          onChange={e => onChange('role', e.value)}
        />
      </Box>

      <Box marginTop={7}>
        <Heading size="md">{intl.get(messagesCommon.locations.id)}</Heading>
        {loadingLocations ? (
          <Box display="flex" justify="center">
            <Spinner noMargin />
          </Box>
        ) : (
          <>
            <Box marginY={3}>
              <Label htmlFor="panel-default-location">
                {intl.get(messagesCommon.defaultLocation.id)}
              </Label>
              <Select
                id="panel-default-location"
                options={locations.map(location => {
                  return { label: location.data.name, value: location.id };
                })}
                placeholder={intl.get(messagesCommon.choose.id)}
                value={user && user.defaultLocation ? user.defaultLocation.id : ''}
                onChange={e => updateDefaultLocation(e.value)}
              />
            </Box>

            <Box marginY={3}>
              <Label
                htmlFor="panel-default-location"
                required={formValidator.isFieldRequired(formConfig, 'locations')}
                valid={formValidator.isFieldValid(formConfig, 'locations', user)}
              >
                {intl.get(messagesCommon.manageableLocations.id)}
              </Label>
              {locations.map(location => renderLocationCheckbox(location))}
            </Box>
          </>
        )}
      </Box>
    </form>
  );
}

UserFormEdit.propTypes = {
  isCreateMode: PropTypes.bool.isRequired,
  user: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

UserFormEdit.defaultProps = {
  user: null
};

export default UserFormEdit;
