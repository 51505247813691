import Box from 'components/Box';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';

function CountWithText({ text, count }) {
  return (
    <Box paddingTop={2} display="flex" wrap="no-wrap" direction="row" width="100%">
      <Box background="black" paddingX={2} noElavation>
        <Text color="white" size="xs" bold>
          {count}
        </Text>
      </Box>
      <Text color="black" size="sm" marginLeft={2}>
        {text}
      </Text>
    </Box>
  );
}

CountWithText.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
};

export default CountWithText;
