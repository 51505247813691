import { messagesCommon } from 'messages/messages';
import intl from 'react-intl-universal';

const WorkerService = {
  types: {
    report: {
      workerFile: 'worker/ReportWorker.worker.js'
    },
    operationalData: {
      workerFile: 'worker/OperationalAnalysisWorker.worker.js'
    },
    waterData: {
      workerFile: 'worker/WaterAnalysisWorker.worker.js'
    }
  },

  async spawnWorker(workerFile, data) {
    return new Promise(resolve => {
      const worker = new Worker(workerFile);
      // Respond to the worker message => when its finished
      worker.addEventListener(
        'message',
        event => {
          resolve(event.data);
        },
        false
      );

      // Give the worker the data needed to process the data
      worker.postMessage(data);
    });
  },

  spawnReportWorker(data) {
    return WorkerService.spawnWorker(WorkerService.types.report.workerFile, data);
  },

  spawnOperationalWorker(data) {
    const prevYearText = intl.get(messagesCommon.previousYearShort.id);

    const workerData = {
      ...data,
      i18nPreviousYearText: prevYearText
    };

    return WorkerService.spawnWorker(WorkerService.types.operationalData.workerFile, workerData);
  },

  spawnWaterWorker(data) {
    const boundaryText = intl.get(messagesCommon.boundary.id);
    const prevYearText = intl.get(messagesCommon.previousYearShort.id);

    const workerData = {
      ...data,
      i18nBoundaryText: boundaryText,
      i18nPreviousYearText: prevYearText
    };

    return WorkerService.spawnWorker(WorkerService.types.waterData.workerFile, workerData);
  }
};

export default WorkerService;
