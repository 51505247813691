import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Popup from 'components/Popup';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon, messagesNavigation } from 'messages/messages';
import PanelCustomerOrServicePartner from 'pages/Customer/PanelCustomerOrServicePartner';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import { sortCustomerDocs } from 'utils/Utils';
import CustomerList from './CustomerList';

function CustomerOverview() {
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelData, setPanelData] = useState(null);
  const [showAfterCreatePopup, setShowAfterCreatePopup] = useState(false);
  const [newCustomerId, setNewCustomerId] = useState(null);

  // Effect to load customers on load on keep them up to date
  useEffect(() => {
    const updateCollection = querySnapshot => {
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      const sorted = sortCustomerDocs(docs);

      setCustomers(sorted);
    };

    const unsub = FirebaseService.getCustomersRef().onSnapshot(updateCollection);

    return () => {
      unsub();
    };
  }, []);

  const openEditPanel = customer => {
    setPanelData(customer);
    setPanelOpen(true);
  };

  const closePanel = newId => {
    setPanelOpen(false);

    if (newId) {
      setNewCustomerId(newId);
      setShowAfterCreatePopup(true);
    }
  };

  const redirectToCustomerPage = async () => {
    const snapshot = await FirebaseService.loadCustomerById(newCustomerId);
    const customer = {
      id: snapshot.id,
      ref: snapshot.ref,
      data: snapshot.data()
    };

    AppStateService.setLocation(null);
    AppStateService.setCustomer(customer);
    history.push('/company?create-location=true');
  };

  const addCustomer = () => {
    setPanelData(null);
    setPanelOpen(true);
  };

  return (
    <>
      <Box maxWidth="1600px">
        <Box marginX={7} display="flex">
          <Heading level="1" size="xl">
            {intl.get(messagesNavigation.customers.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => addCustomer()}
            />
          </Box>
        </Box>

        <Box background="white" marginX={7} marginTop={5} display="flex">
          <CustomerList customers={customers} openPanel={openEditPanel} />
        </Box>
      </Box>

      {showAfterCreatePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesCommon.createLocationPopupTitle.id)}
          message={intl.get(messagesCommon.createLocationPopupText.id)}
          confirmColor="blue"
          confirmText={intl.get(messagesCommon.yes.id)}
          confirmAction={() => redirectToCustomerPage()}
          abortAction={() => setShowAfterCreatePopup(false)}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      )}

      {panelOpen && (
        <PanelCustomerOrServicePartner
          customer={panelData}
          onClose={newCustomerId => closePanel(newCustomerId)}
        />
      )}
    </>
  );
}

export default CustomerOverview;
