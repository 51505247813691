import Box from 'components/Box';
import Number from 'components/Number';
import Text from 'components/Text';
import { messagesCostFactor } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function SteamCostTooltip({ payload }) {
  if (payload.length !== 2) {
    return null;
  }

  const priceParts = payload[0].payload.priceParts;

  return (
    <Box background="white" padding={4}>
      <Box
        display="flex"
        justify="space-between"
        alignItems="center"
        marginBottom={1}
        noElevation
        minWidth="320px"
        zIndex={999}
      >
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>
                <Text size="sm" opacity=".65">
                  {payload[0].dataKey}
                </Text>
              </th>
              <th>
                <Text size="sm">{payload[1].dataKey}</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorWater.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].water}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].water}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  bold
                />
              </td>
            </tr>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorWaterDirty.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].wasteWater}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].wasteWater}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  bold
                />
              </td>
            </tr>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorDosing.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].dosing}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].dosing}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  bold
                />
              </td>
            </tr>
            <tr>
              <td>{intl.get(messagesCostFactor.costFactorBurnMaterial.id)}</td>
              <td>
                <Number
                  number={priceParts[payload[0].dataKey].burnMaterial}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  opacity=".65"
                />
              </td>
              <td>
                <Number
                  number={priceParts[payload[1].dataKey].burnMaterial}
                  digits={1}
                  round={true}
                  suffix="%"
                  size="sm"
                  bold
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

SteamCostTooltip.propTypes = {
  payload: PropTypes.array
};

SteamCostTooltip.defaultProps = {
  payload: []
};

export default SteamCostTooltip;
