import Box from 'components/Box';
import Button from 'components/Button';
import Label from 'components/Label';
import Link from 'components/Link';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextField from 'components/TextField';
import Logo from 'images/logo.svg';
import { messagesCommon, messagesLogin, messagesPlaceholder } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function ResetPassword() {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [sendReset, setSendReset] = useState(false);
  const [email, setEmail] = useState('');
  const [resetInProgress, setResetInProgress] = useState(false);

  useEffect(() => {
    if (AppStateService.hasUser()) {
      history.push('/');
    }
  }, [history]);

  const onChangeEmail = event => setEmail(event.currentTarget.value);

  const onSubmit = async event => {
    setError(false);
    event.preventDefault();
    try {
      setResetInProgress(true);
      await FirebaseService.resetPassword(email);
      setEmail('');
      setResetInProgress(false);
      setSendReset(true);
    } catch (e) {
      setResetInProgress(false);
      setError(true);
    }
  };

  return (
    <Box width="100%" height="100%" display="flex" alignItems justify="center">
      <Box background="white" width={508} height={565} padding={7} noElevation border>
        <Box display="flex" justify="center" marginTop={3} marginBottom={12}>
          <img src={Logo} alt="diwaso" width="120px" />
        </Box>

        <Box>
          {resetInProgress ? (
            <Spinner />
          ) : (
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
              <Box>
                <Label htmlFor="email">{intl.get(messagesCommon.emailAddress.id)}</Label>
                <TextField
                  id="email"
                  type="email"
                  placeholder={intl.get(messagesPlaceholder.emailAddress.id)}
                  value={email}
                  onChange={onChangeEmail}
                />
              </Box>

              {error && (
                <Box display="flex" justify="center" marginTop={7}>
                  <Text color="red">{intl.get(messagesLogin.resetPasswordError.id)}</Text>
                </Box>
              )}

              {sendReset && (
                <Box display="flex" justify="center" marginTop={7}>
                  <Text color="green">{intl.get(messagesLogin.resetPasswordSuccess.id)}</Text>
                </Box>
              )}

              <Box display="flex" justify="center" marginTop={7}>
                <Button
                  disabled={!email}
                  width={230}
                  text={intl.get(messagesLogin.resetPassword.id)}
                  inline
                  textSize="sm"
                  type="submit"
                />
              </Box>

              <Box display="flex" justify="center" marginTop={3}>
                <Link href="/login" routerLink>
                  <Text size="sm">{intl.get(messagesLogin.button.id)}</Text>
                </Link>
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </Box>
  );
}

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({ pathname: PropTypes.string })
  }).isRequired
};

export default ResetPassword;
