import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';

const StyledToggle = styled.div`
  background: ${props => props.theme.colors.sand};
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
  border: 1px solid #e3e3e5;
  border-radius: 15px;
  cursor: ${props => (props.interactable ? 'pointer' : 'not-allowed')};
  transition: background 0.4s 0.3s ease-out;

  &:before {
    background: #ffffff;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    display: block;
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    top: 2px;
    transition: all 0.4s;
    transition-delay: 0.01s;
  }

  ${props =>
    props.isActive &&
    css`
      background: ${props => props.theme.colors.blue};
      transition: background 0.4s 0.3s ease-out;

      &:before {
        transform: translateX(17px);
      }
    `};
`;

function Toggle({ enabled, interactable, onChange }) {
  return (
    <StyledToggle
      isActive={enabled}
      onClick={() => (interactable ? onChange(!enabled) : null)}
      interactable={interactable}
    />
  );
}

Toggle.propTypes = {
  enabled: PropTypes.bool,
  interactable: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Toggle.defaultProps = {
  enabled: false,
  interactable: true
};

export default Toggle;
