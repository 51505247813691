import Popup from 'components/Popup';
import { ReactComponent as Remove } from 'images/Remove.svg';
import { messagesCommon, messagesLocationDelete } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';

function LocationList({ locations, onRemoveLocation }) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [location, setLocation] = useState();

  const onShowDeletePopup = location => {
    setShowDeletePopup(true);
    setLocation(location);
  };

  const onHideDeletePopup = () => setShowDeletePopup(false);

  const renderRow = location => {
    const locationData = location.data;
    const locationString = `${locationData.street}, ${locationData.zip} ${locationData.city}, ${locationData.country}`;

    return (
      <tr key={location.id}>
        <td>{locationData.name}</td>
        <td>{locationString}</td>
        <td>
          <Remove onClick={() => onShowDeletePopup(location)} style={{ cursor: 'pointer' }} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{intl.get(messagesCommon.description.id)}</th>
            <th>{intl.get(messagesCommon.address.id)}</th>
            <th width="100" />
          </tr>
        </thead>
        <tbody>{locations.map(l => renderRow(l))}</tbody>
      </table>

      {showDeletePopup && (
        <Popup
          show
          size={400}
          title={intl.get(messagesLocationDelete.headline.id)}
          message={intl.get(messagesLocationDelete.text.id)}
          confirmColor="red"
          confirmText={intl.get(messagesLocationDelete.action.id)}
          confirmAction={() => onRemoveLocation(location)}
          abortAction={onHideDeletePopup}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      )}
    </>
  );
}

LocationList.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemoveLocation: PropTypes.func
};

LocationList.defaultProps = {
  onRemoveLocation: () => {}
};

export default LocationList;
