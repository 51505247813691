import Popup from 'components/Popup';
import { PackageTypes } from 'constants/Packages';
import { ReactComponent as Edit } from 'images/EditAlt.svg';
import { ReactComponent as Remove } from 'images/Remove.svg';
import { messagesCommon, messagesLocationDelete } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';

function LocationList({ locations, openPanel }) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [location, setLocation] = useState();

  const onShowDeletePopup = location => {
    setShowDeletePopup(true);
    setLocation(location);
  };

  const onHideDeletePopup = () => setShowDeletePopup(false);

  const onDeleteLocation = async () => {
    const locationData = { ...location.data, isDeleted: true };

    try {
      await FirebaseService.updateLocation(location.id, locationData);
      setShowDeletePopup(false);
    } catch (e) {
      console.log('Location could not be deleted');
      console.log(e);
      setShowDeletePopup(false);
    }
  };

  const renderRow = location => {
    const locationData = location.data;
    const locationString = `${locationData.street}, ${locationData.zip} ${locationData.city}, ${locationData.country}`;
    let packageString = '-';

    if (locationData.package) {
      packageString =
        locationData.package === PackageTypes.BASIC
          ? intl.get(messagesCommon.packageBasic.id)
          : intl.get(messagesCommon.packageEssential.id);
    }

    return (
      <tr key={location.id}>
        <td>{locationData.name}</td>
        <td>{locationString}</td>
        <td>{packageString}</td>
        <td>
          <Edit
            onClick={() => openPanel('location', location)}
            style={{ cursor: 'pointer', display: 'inline-block', marginRight: '15px' }}
          />
          <Remove onClick={() => onShowDeletePopup(location)} style={{ cursor: 'pointer' }} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{intl.get(messagesCommon.description.id)}</th>
            <th>{intl.get(messagesCommon.address.id)}</th>
            <th>{intl.get(messagesCommon.package.id)}</th>
            <th width="100" />
          </tr>
        </thead>
        <tbody>{locations.map(l => renderRow(l))}</tbody>
      </table>

      {showDeletePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesLocationDelete.headline.id)}
          message={intl.get(messagesLocationDelete.text.id)}
          confirmColor="red"
          confirmText={intl.get(messagesLocationDelete.action.id)}
          confirmAction={onDeleteLocation}
          abortAction={onHideDeletePopup}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      )}
    </>
  );
}

LocationList.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  openPanel: PropTypes.func
};

LocationList.defaultProps = {
  openPanel: null
};

export default LocationList;
