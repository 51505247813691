import Box from 'components/Box';
import Text from 'components/Text';
import { messagesCommon } from 'messages/messages';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { IconComponent } from './Icons';
import Number from './Number';

function TooltipChart({ unit, chartConfig, payload, label }) {
  const renderPayloadValue = (value, color) => {
    const multiValue = value.length > 0;

    if (!multiValue) {
      return (
        <Number
          size="xs"
          digits={2}
          color={color !== undefined ? color : 'black'}
          round={true}
          number={value}
        />
      );
    }

    // this is most likely the boundary value
    // which can be a min or a max value or both
    let startVal = value[0];
    if (value[1]) {
      let endVal = value[1];
      if (parseInt(startVal, 10) > parseInt(endVal, 10)) {
        [endVal, startVal] = value;
      }

      return `${startVal} - ${endVal}`;
    }

    return startVal;
  };

  const getPayload = config => {
    return payload.filter(seriesPoint => seriesPoint.dataKey === config.key)[0];
  };

  return (
    <Box background="white" padding={4}>
      {label && (
        <Text size="sm" bold marginBottom={2}>
          {moment.unix(label).format('DD.MM.YYYY')}
        </Text>
      )}
      {chartConfig.map(config => {
        const payload = getPayload(config);
        const color = config.color;
        let value = null;

        if (payload) {
          value = renderPayloadValue(payload.value, color);
        }

        return (
          <Box
            display="flex"
            justify="space-between"
            alignItems="center"
            key={config.key}
            marginBottom={1}
            noElevation
          >
            <Box display="flex" paddingRight={7}>
              <IconComponent width="14" height="14" stroke={color} />
              <Text size="xs" inline color="black" marginLeft={2}>
                {config.key}{' '}
              </Text>
            </Box>
            <Box>
              <Text size="xs" color={color} bold inline>
                {value !== null ? value : intl.get(messagesCommon.noValue.id)}
              </Text>
              <Text size="xs" color={color} bold inline marginLeft={1}>
                {unit !== null && value !== null ? unit : ''}
              </Text>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

TooltipChart.propTypes = {
  unit: PropTypes.any,
  payload: PropTypes.array,
  label: PropTypes.any,
  chartConfig: PropTypes.array
};

TooltipChart.defaultProps = {
  unit: null,
  payload: [],
  chartConfig: [],
  label: null
};

export default TooltipChart;
