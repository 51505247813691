import Popup from 'components/Popup';
import { ReactComponent as Edit } from 'images/EditAlt.svg';
import { ReactComponent as Remove } from 'images/Remove.svg';
import { messagesCommon, messagesParamDelete } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';

function ParameterList({ parameters, onEdit }) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [paramToDelete, setParamToDelete] = useState();

  const onShowDeletePopup = param => {
    setShowDeletePopup(true);
    setParamToDelete(param);
  };

  const onHideDeletePopup = () => setShowDeletePopup(false);

  const onDelete = async () => {
    const update = { ...paramToDelete.data, isDeleted: true };

    try {
      await FirebaseService.updateParameter(paramToDelete.id, update);
      setShowDeletePopup(false);
      setParamToDelete(null);
    } catch (e) {
      console.log('Param could not be deleted');
      console.log(e);
      setShowDeletePopup(false);
      setParamToDelete(null);
    }
  };

  const renderRow = param => {
    const data = param.data;

    return (
      <tr key={param.id}>
        <td>{data.name}</td>
        <td>{localStorage.getItem('locale') === 'de-DE' ? data.labelDE : data.labelEN}</td>
        <td>{data.unit}</td>
        <td>{intl.get(`common.${data.category}`)}</td>
        <td>
          <Edit
            onClick={() => onEdit(param)}
            style={{ cursor: 'pointer', display: 'inline-block', marginRight: '15px' }}
          />
          <Remove onClick={() => onShowDeletePopup(param)} style={{ cursor: 'pointer' }} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{intl.get(messagesCommon.paramInternal.id)}</th>
            <th>{intl.get(messagesCommon.paramName.id)}</th>
            <th>{intl.get(messagesCommon.paramUnit.id)}</th>
            <th>{intl.get(messagesCommon.paramCategory.id)}</th>
            <th width="100" />
          </tr>
        </thead>
        <tbody>{parameters.map(p => renderRow(p))}</tbody>
      </table>

      {showDeletePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesParamDelete.headline.id)}
          message={intl.get(messagesParamDelete.text.id)}
          confirmColor="red"
          confirmText={intl.get(messagesParamDelete.action.id)}
          confirmAction={onDelete}
          abortAction={onHideDeletePopup}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      )}
    </>
  );
}

ParameterList.propTypes = {
  parameters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEdit: PropTypes.func
};

ParameterList.defaultProps = {
  onEdit: () => {}
};

export default ParameterList;
