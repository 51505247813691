import TooltipChart from 'components/TooltipChart';
import { messagesCommon } from 'messages/messages';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

function Chart({ config }) {
  const formatXAxis = tickItem => {
    return moment.unix(tickItem).format('DD.MM.YYYY');
  };

  const { chartData } = config;
  const { lineConfig } = config;
  let unit = null;

  // @TODO most of the values are non operational values
  // but static values => get those units somewhere, somehow
  if (lineConfig[0]) {
    unit = lineConfig[0].unit ? lineConfig[0].unit : null;
  }

  const prevYearText = intl.get(messagesCommon.previousYearShort.id);

  return (
    <ResponsiveContainer width="100%" minHeight={400}>
      <ComposedChart data={chartData} margin={{ top: 5, right: 50, left: 10, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timestamp" fontSize="12" stroke="#051014" tickFormatter={formatXAxis} />
        <YAxis fontSize="12" stroke="#051014">
          <Label angle="-90" fontSize="14px" offset={-10} position="left">
            {unit !== null ? unit : ''}
          </Label>
        </YAxis>
        <Tooltip content={<TooltipChart unit={unit} chartConfig={lineConfig} />} />
        <Legend />

        {lineConfig.map(config => (
          <Line
            key={`line-chart-${config.key}`}
            type={config.type ? config.type : 'monotone'}
            dataKey={config.key}
            stroke={config.color}
            unit={config.unit}
            connectNulls={true}
            strokeDasharray={config.key.includes(prevYearText) ? '5 5' : null}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

Chart.propTypes = {
  config: PropTypes.any
};

Chart.defaultProps = {
  config: {
    chartData: [],
    lineConfig: []
  }
};

export default Chart;
