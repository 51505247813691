import Box from 'components/Box';
import LoadingBox from 'components/LoadingBox';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';
import styled from 'styled-components/macro';

const StyledLocationItem = styled(Box)`
  &:hover {
    background-color: ${props => props.theme.colors.grey};
  }
`;

function LocationAddForm({ selectedLocations, onSelect }) {
  const [loadingLocations, setLoadingLocations] = useState(false);

  const [query, setQuery] = useState('');
  const [locations, setLocations] = useState([]);

  const searchLocation = async e => {
    const queryUpdate = e.currentTarget.value;
    setQuery(queryUpdate);

    if (queryUpdate && queryUpdate.length >= 2) {
      setLoadingLocations(true);

      let resultDocs = await FirebaseService.queryLocations(queryUpdate);

      // filter result to not be deleted
      resultDocs = resultDocs.filter(doc => {
        if (doc.data.isDeleted === undefined || doc.data.isDeleted === false) {
          return doc;
        }

        return null;
      });

      // filter result docs by already selected locations
      resultDocs = resultDocs.filter(doc => {
        const match = selectedLocations.find(selected => selected.id === doc.id);

        if (!match) {
          return doc;
        }
      });

      setLocations(resultDocs);
      setLoadingLocations(false);
    }
  };

  return (
    <>
      <Box marginY={3}>
        <TextField
          id="search"
          type="text"
          placeholder={intl.get(messagesCommon.locationSearchPlaceholder.id)}
          value={query}
          onChange={searchLocation}
        />
      </Box>
      <LoadingBox loading={loadingLocations} renderChildren={!loadingLocations}>
        <Box
          style={{
            height: '85%',
            overflowY: 'auto'
          }}
        >
          {locations.map(locationDoc => {
            const location = locationDoc.data;
            const locationString = `${location.street}, ${location.zip} ${location.city}, ${location.country}`;
            return (
              <StyledLocationItem
                key={locationDoc.id}
                onClick={() => onSelect(locationDoc)}
                background="white"
                marginY={5}
                marginX={3}
                paddingY={3}
                paddingX={5}
                border
                noElevation
                style={{ cursor: 'pointer' }}
              >
                <Text size="lg" bold>
                  {location.name}
                </Text>
                <Text size="sm">{locationString}</Text>
              </StyledLocationItem>
            );
          })}
        </Box>
        <Box marginY={3}>
          {query.length > 2 && (
            <Text size="sm" align="right">
              {locations.length > 0
                ? `${locations.length} ${intl.get(messagesCommon.results.id)}`
                : ''}
            </Text>
          )}
        </Box>
      </LoadingBox>
    </>
  );
}

LocationAddForm.propTypes = {
  selectedLocations: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default LocationAddForm;
