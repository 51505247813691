import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Text from './Text';

const StyledButton = styled.button`
  background-color: ${props => props.theme.colors[props.background]};
  color: ${props => props.theme.colors[props.color]};
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: 3px 3px 17px 0 rgba(0, 0, 0, 0.05);
  border-radius: ${props => `${props.borderRadius}px`};
  border: none;
  outline: 0;
  padding: ${props => (props.size === 'sm' ? '4px 15px' : '10px 15px')};
  position: relative;

  ${props => props.ghost && `border: 1px solid ${props.theme.colors[props.ghost]}`};
  ${props => (!props.width && props.inline ? 'width: auto' : 'width: 100%')};
  ${props => props.disabled && 'opacity: 0.4'};
  ${props => props.width && `width: ${props.width}px`};
  ${props =>
    props.minWidth === 'auto' ? `min-width: ${props.minWidth}` : `min-width: ${props.minWidth}px`};
  ${props => props.noShadow && `box-shadow: none`};

  &:hover:not([disabled]) {
    background-color: ${props =>
      props.background === 'transparent' ? 'inherit' : props.theme.buttonHover.background};

    color: ${props =>
      props.background === 'transparent'
        ? props.theme.colors[props.color]
        : props.theme.buttonHover.color};

    ${props =>
      props.ghost &&
      css`
        background-color: ${props => props.theme.colors[props.ghost]};
        color: ${props => props.theme.colors.white};
      `};
  }

  ${props =>
    props.hasIconAndText &&
    css`
      display: flex;
      align-items: center;

      span {
        margin-left: 7px;
      }
    `};
`;

function Button({
  disabled,
  inline,
  name,
  onClick,
  text,
  textSize,
  width,
  minWidth,
  type,
  size,
  background,
  color,
  Icon,
  ghost,
  borderRadius,
  noShadow
}) {
  /* eslint-disable react/button-has-type */
  return (
    <StyledButton
      disabled={disabled}
      inline={inline}
      name={name}
      onClick={event => onClick && onClick({ event })}
      type={type}
      width={width}
      minWidth={minWidth}
      size={size}
      background={background}
      ghost={ghost}
      color={color}
      hasIconAndText={Icon && text}
      borderRadius={borderRadius}
      noShadow={noShadow}
    >
      {Icon && <Icon />}

      {text !== '' && (
        <Text color="fff" bold align="center" overflow="normal" size={textSize}>
          {text}
        </Text>
      )}
    </StyledButton>
  );
  /* eslint-enable react/button-has-type */
}

Button.propTypes = {
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  textSize: PropTypes.string,
  width: PropTypes.number,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  ghost: PropTypes.string,
  Icon: PropTypes.any,
  borderRadius: PropTypes.number,
  noShadow: PropTypes.bool
};

Button.defaultProps = {
  textSize: 'sm',
  width: null,
  minWidth: 'auto',
  disabled: false,
  inline: false,
  type: 'button',
  size: 'base',
  name: '',
  background: 'blue',
  color: 'white',
  ghost: null,
  text: '',
  onClick: () => {},
  Icon: null,
  borderRadius: 20,
  noShadow: false
};

export default Button;
