import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Label from 'components/Label';
import LoadingBox from 'components/LoadingBox';
import Text from 'components/Text';
import useFormOptions from 'hooks/useFormOptions';
import { ReactComponent as IconEditSolid } from 'images/EditSolid.svg';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon, messagesNavigation } from 'messages/messages';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import LocationList from './LocationList';
import PanelCustomerOrServicePartner from './PanelCustomerOrServicePartner';
import PanelLocation from './PanelLocation';
import PanelUser from './PanelUser';
import UserList from './UserList';

function Customer() {
  const location = useLocation();
  const formOptions = useFormOptions();
  const [loadingLocations, setloadingLocations] = useState(true);
  const [loadingUsers, setloadingUsers] = useState(true);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [panelOpen, setPanelOpen] = useState(null);
  const [panelData, setPanelData] = useState(null);

  // Effect to to check if the customer url was calles with param to create a location
  useEffect(() => {
    const searchString = location.search ? location.search.replace('?', '') : null;

    if (searchString) {
      const searchParams = new URLSearchParams(searchString);
      if (searchParams.has('create-location')) {
        openPanel('location', null);
      }
    }
  }, [location]);

  // Effect to load users and keep them up to date
  useEffect(() => {
    const updateCollection = querySnapshot => {
      setloadingUsers(true);
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      setUsers(docs);
      setloadingUsers(false);
    };

    const unsub = FirebaseService.getUsersOfCustomerRef(AppStateService.customer.ref).onSnapshot(
      updateCollection
    );

    return () => {
      unsub();
    };
  }, []);

  // Effect to load locations and keep them up to date
  useEffect(() => {
    const updateCollection = querySnapshot => {
      setloadingLocations(true);
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc.ref,
          data: doc.data()
        });
      });

      // if no location is currently set use the first one returned here
      // should only be relevant for admin when creating new customers
      if (AppStateService.location === null && docs.length > 0) {
        AppStateService.setLocation({
          id: docs[0].id,
          ref: docs[0].ref,
          data: docs[0].data
        });
      }

      setLocations(docs);
      setloadingLocations(false);
    };

    const unsub = FirebaseService.getLocationsOfCustomerRef(
      AppStateService.customer.ref
    ).onSnapshot(updateCollection);

    return () => {
      unsub();
    };
  }, []);

  const openPanel = (type, data) => {
    setPanelData(data);
    setPanelOpen(type);
  };

  const closePanel = () => {
    setPanelOpen(false);
    setPanelData(null);
  };

  return (
    <LoadingBox loading={loadingLocations || loadingUsers}>
      <Box maxWidth="1600px">
        <Box marginX={7}>
          <Heading level="1" size="xl">
            {intl.get(messagesNavigation.company.id)}
          </Heading>
          <Alert
            title={intl.get(messagesCommon.noLocationNotice.id)}
            type="error"
            isVisible={!loadingLocations && locations.length === 0}
          />
        </Box>

        <Box marginTop={8} marginX={7} display="flex">
          <Heading level="1" size="md">
            {intl.get(messagesCommon.overview.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.edit.id)}
              Icon={IconEditSolid}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => openPanel('customer', AppStateService.customer)}
            />
          </Box>
        </Box>

        <Box background="white" marginX={7} marginTop={5} paddingY={2}>
          <Box display="flex" wrap="wrap">
            <Box marginX={5} display="flex" wrap="wrap" width="100%">
              <Box marginY={3} width="33%">
                <Label htmlFor="company-name">{intl.get(messagesCommon.companyName.id)}</Label>
                <Text size="sm">{AppStateService.customer.data.name}</Text>
              </Box>
              <Box marginY={3} width="33%">
                <Label htmlFor="city">{intl.get(messagesCommon.street.id)}</Label>
                <Text size="sm">{AppStateService.customer.data.street}</Text>
              </Box>
              <Box marginY={3} width="33%">
                <Label htmlFor="branch">{intl.get(messagesCommon.country.id)}</Label>
                <Text size="sm">{AppStateService.customer.data.country}</Text>
              </Box>

              <Box marginY={3} width="33%">
                <Label htmlFor="zipcode">{intl.get(messagesCommon.zip.id)}</Label>
                <Text size="sm">{AppStateService.customer.data.zip}</Text>
              </Box>
              <Box marginY={3} width="33%">
                <Label htmlFor="country">{intl.get(messagesCommon.city.id)}</Label>
                <Text size="sm">{AppStateService.customer.data.city}</Text>
              </Box>
              <Box marginY={3} width="33%">
                <Label htmlFor="employee-count">{intl.get(messagesCommon.business.id)}</Label>
                <Text size="sm">
                  {
                    formOptions.business.filter(
                      option => option.value === AppStateService.customer.data.business
                    )[0].label
                  }
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box marginTop={8} marginX={7} display="flex">
          <Heading level="1" size="md">
            {intl.get(messagesCommon.locations.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => openPanel('location', null)}
            />
          </Box>
        </Box>
        <Box background="white" marginX={7} marginTop={3} display="flex">
          <LocationList locations={locations} openPanel={openPanel} />
        </Box>

        <Box marginTop={8} marginX={7} display="flex">
          <Heading level="1" size="md">
            {intl.get(messagesCommon.users.id)}
          </Heading>
          {locations.length > 0 && (
            <Box marginLeft={3}>
              <Button
                text={intl.get(messagesCommon.add.id)}
                Icon={IconPlus}
                inline
                textSize="sm"
                type="button"
                size="sm"
                onClick={() => openPanel('user', null)}
              />
            </Box>
          )}
        </Box>
        <Box background="white" marginX={7} marginTop={3} display="flex">
          <UserList users={users} openPanel={openPanel} />
        </Box>
      </Box>

      {panelOpen && panelOpen === 'location' && (
        <PanelLocation location={panelData} onClose={closePanel} />
      )}
      {panelOpen && panelOpen === 'user' && <PanelUser user={panelData} onClose={closePanel} />}
      {panelOpen && panelOpen === 'customer' && (
        <PanelCustomerOrServicePartner customer={panelData} onClose={closePanel} />
      )}
    </LoadingBox>
  );
}

export default Customer;
