export const SteamProductionInterferenceTypes = {
  COMPLETE: 'COMPLETE',
  MAJORITY: 'MAJORITY',
  MINORITY: 'MINORITY',
  NONE: 'NONE'
};

export const SteamProductionInterferenceConfig = {
  COMPLETE: {
    name: 'steamProductionInterferenceComplete',
    waterLossToWasteWaterFactor: 0,
    steamConsumptionWasteWaterFactor: 0.85
  },
  MAJORITY: {
    name: 'steamProductionInterferenceMajority',
    waterLossToWasteWaterFactor: 0.33,
    steamConsumptionWasteWaterFactor: 0.6
  },
  MINORITY: {
    name: 'steamProductionInterferenceMinority',
    waterLossToWasteWaterFactor: 0.66,
    steamConsumptionWasteWaterFactor: 0.3
  },
  NONE: {
    name: 'steamProductionInterferenceNone',
    waterLossToWasteWaterFactor: 1,
    steamConsumptionWasteWaterFactor: 0.05
  }
};
