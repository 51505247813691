import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import DayPicker from 'components/DayPicker';
import Label from 'components/Label';
import Select from 'components/Select';
import Text from 'components/Text';
import useFormOptions from 'hooks/useFormOptions';
import { messagesCommon, messagesPlaceholder } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

function ChartFilter({
  dateRangeOption,
  dateFrom,
  dateTo,
  showBoundary,
  showLastYear,
  selectedComponents,
  selectedParameter,
  components,
  onDateRangeChange,
  onDateFromChange,
  onDateToChange,
  onShowBoundaryChange,
  onShowLastYearChange,
  onSelectedComponentChange,
  onSelectedParameterChange
}) {
  const formOptions = useFormOptions();

  const [parameter, setParameter] = useState([]);
  const [parameterOptions, setParameterOptions] = useState([]);

  useEffect(() => {
    const params = [];
    const paramOptions = [];

    components.map(component => {
      const { waterValues } = component;

      waterValues.map(waterValueParam => {
        const hasParam = paramOptions.filter(parameter => parameter.value === waterValueParam.name)
          .length;
        if (!hasParam) {
          params.push(waterValueParam);

          const transKey = `component.waterValues.${waterValueParam.name}`;
          const name = intl.get(transKey);

          paramOptions.push({
            label: name,
            value: waterValueParam.name
          });
        }
        return true;
      });
      return true;
    });

    // sort param options by translated label
    paramOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

    setParameter(params);
    setParameterOptions(paramOptions);
  }, [components]);

  const updateSelectedParam = update => {
    const paramName = update.value;
    const param = parameter.filter(param => param.name === paramName)[0];
    onSelectedParameterChange(param);
  };

  const renderComponentCheckbox = component => {
    const isSelected = selectedComponents.filter(c => c.id === component.id).length > 0;

    return (
      <Box
        key={`water-analysis-filter-component-${component.id}`}
        alignItems="center"
        direction="row"
        display="flex"
        marginRight={5}
      >
        <Label htmlFor={`water-analysis-filter-component-checkbox-${component.id}`}>
          <Checkbox
            id={`water-analysis-filter-component-checkbox-${component.id}`}
            checked={isSelected}
            onChange={e => onSelectedComponentChange(e, component)}
          />
          <span style={{ marginLeft: 8 }}>{component.internalName}</span>
        </Label>
      </Box>
    );
  };

  return (
    <Box background="white" padding={4} width={300} minWidth={220} alignSelf="stretch">
      <Label htmlFor="analysis-period">{intl.get(messagesCommon.dateRange.id)}</Label>
      <Select
        id="analysis-period"
        color="black"
        size="xs"
        options={formOptions.dateRange}
        placeholder={intl.get(messagesCommon.choose.id)}
        value={dateRangeOption}
        onChange={update => onDateRangeChange(update.value)}
      />

      {dateRangeOption === 'customPeriod' && (
        <>
          <Text size="sm" bold marginTop={3}>
            {intl.get(messagesCommon.dateSelect.id)}
          </Text>
          <Box marginTop={2}>
            <DayPicker
              value={dateFrom}
              placeholder={intl.get(messagesPlaceholder.dateInput.id)}
              onChange={onDateFromChange}
            />
          </Box>
          <Box marginTop={1}>
            <DayPicker
              value={dateTo}
              placeholder={intl.get(messagesPlaceholder.dateInput.id)}
              onChange={onDateToChange}
            />
          </Box>
        </>
      )}

      <Box marginTop={3}>
        <Label htmlFor="analysis-values">{intl.get(messagesCommon.waterValues.id)}</Label>
        <Select
          id="analysis-values"
          color="black"
          size="xs"
          options={parameterOptions}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={selectedParameter !== null ? selectedParameter.name : ''}
          onChange={updateSelectedParam}
        />
      </Box>

      <Text size="sm" bold marginTop={3}>
        {intl.get(messagesCommon.options.id)}
      </Text>
      <Box marginTop={2}>
        <Label regular noMarginBottom htmlFor="analysis-tolerance-values">
          <Checkbox
            id="analysis-tolerance-values"
            variant="small"
            checked={showBoundary}
            onChange={e => onShowBoundaryChange(e.target.checked)}
          />
          <Text size="xs" inline style={{ marginLeft: 8 }}>
            {intl.get(messagesCommon.boundary.id)}
          </Text>
        </Label>
      </Box>
      <Box marginTop={1}>
        <Label regular noMarginBottom htmlFor="analysis-compare-last-year">
          <Checkbox
            id="analysis-compare-last-year"
            variant="small"
            checked={showLastYear}
            onChange={e => onShowLastYearChange(e.target.checked)}
          />
          <Text size="xs" inline style={{ marginLeft: 8 }}>
            {intl.get(messagesCommon.checkLastYear.id)}
          </Text>
        </Label>
      </Box>

      <Text size="sm" bold marginTop={3}>
        {intl.get(messagesCommon.components.id)}
      </Text>
      <Box marginTop={3}>{components.map(component => renderComponentCheckbox(component))}</Box>
    </Box>
  );
}

ChartFilter.propTypes = {
  dateRangeOption: PropTypes.string,
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  showBoundary: PropTypes.bool,
  showLastYear: PropTypes.bool,
  selectedParameter: PropTypes.any,
  selectedComponents: PropTypes.array,
  components: PropTypes.array,
  onDateRangeChange: PropTypes.func.isRequired,
  onDateFromChange: PropTypes.func.isRequired,
  onDateToChange: PropTypes.func.isRequired,
  onShowBoundaryChange: PropTypes.func.isRequired,
  onShowLastYearChange: PropTypes.func.isRequired,
  onSelectedComponentChange: PropTypes.func.isRequired,
  onSelectedParameterChange: PropTypes.func.isRequired
};

ChartFilter.defaultProps = {
  dateRangeOption: '',
  dateFrom: null,
  dateTo: null,
  selectedParameter: null,
  showBoundary: false,
  showLastYear: false,
  selectedComponents: [],
  components: []
};

export default ChartFilter;
