import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function Number({ number, digits, suffix, round, ...rest }) {
  const { currentLocale } = intl.options;

  let formatted = number;

  if (isNaN(number) || !isFinite(number) || number === null) {
    formatted = '-';
  } else {
    if (typeof number === 'string') {
      formatted = parseFloat(number);
    }

    if (round === true) {
      formatted = formatted.toFixed(digits);
    }

    // formatted = new Intl.NumberFormat(currentLocale, {}).format(number);

    if (digits >= 0) {
      formatted = new Intl.NumberFormat(currentLocale, {
        maximumFractionDigits: digits
      }).format(number);
    } else {
      formatted = new Intl.NumberFormat(currentLocale, {
        maximumFractionDigits: 5,
        minimumFractionDigits: 0
      }).format(number);
    }
  }

  return (
    <Text {...rest}>
      {formatted} {suffix}
    </Text>
  );
}

Number.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  digits: PropTypes.number,
  round: PropTypes.bool,
  suffix: PropTypes.any
};

Number.defaultProps = {
  number: null,
  digits: 2,
  suffix: '',
  round: false
};

export default Number;
