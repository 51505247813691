import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import Label from 'components/Label';
import Select from 'components/Select';
import Text from 'components/Text';
import useFormOptions from 'hooks/useFormOptions';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

function ChartFilter({
  dateRangeOption,
  showLastYear,
  selectedParameter,
  report,
  onDateRangeChange,
  onShowLastYearChange,
  onSelectedParameterChange
}) {
  const formOptions = useFormOptions();

  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterPlaceholder, setParameterPlaceholder] = useState(
    intl.get(messagesCommon.choosePeriod.id)
  );

  // Effect to enhance the options with
  // => dosing sums
  // => burner sums
  // => boiler loss sums
  useEffect(() => {
    let paramPlaceholder = intl.get(messagesCommon.choosePeriod.id);

    if (report && report.current && report.current.length > 0) {
      const reportData = report.current;
      const dynamicOptions = [];

      // iterate over complete report to get unique entries to generate options from
      const uniqueDosing = [];
      const uniqueBurner = [];
      const uniqueBoiler = [];

      for (let i = 0; i < reportData.length; i += 1) {
        const reportRow = reportData[i];
        const { steamBoilerLossAndMeter, dosingSums, burnerSums } = reportRow;

        for (let j = 0; j < steamBoilerLossAndMeter.length; j += 1) {
          const steamBoiler = steamBoilerLossAndMeter[j];
          const key = `${steamBoiler.steamBoilerId}`;
          const option = {
            steamBoilerId: steamBoiler.steamBoilerId,
            steamBoiler: steamBoiler.steamBoiler
          };

          uniqueBoiler[key] = option;
        }

        for (let j = 0; j < dosingSums.length; j += 1) {
          const dosingSum = dosingSums[j];
          const key = `${dosingSum.chemicalId}`;
          const option = {
            chemicalId: dosingSum.chemicalId,
            chemical: dosingSum.chemical
          };

          uniqueDosing[key] = option;
        }

        for (let j = 0; j < burnerSums.length; j += 1) {
          const burnerSum = burnerSums[j];
          const keyNum1 = `${burnerSum.burnerComponentId}-${burnerSum.steamBoilerId}-${burnerSum.burnMaterial1}-1`;
          const optionNum1 = {
            burnerComponentId: burnerSum.burnerComponentId,
            burnMaterialCostFactor: burnerSum.burnMaterial1CostFactor,
            burnMaterial: burnerSum.burnMaterial1,
            num: 1,
            steamBoiler: burnerSum.steamBoiler,
            steamBoilerId: burnerSum.steamBoilerId
          };
          uniqueBurner[keyNum1] = optionNum1;

          if (burnerSum.burnMaterial2 && burnerSum.burnMeter2Value) {
            const keyNum2 = `${burnerSum.burnerComponentId}-${burnerSum.steamBoilerId}-${burnerSum.burnMaterial2}-2`;
            const optionNum2 = {
              burnerComponentId: burnerSum.burnerComponentId,
              burnMaterialCostFactor: burnerSum.burnMaterial2CostFactor,
              burnMaterial: burnerSum.burnMaterial2,
              num: 2,
              steamBoiler: burnerSum.steamBoiler,
              steamBoilerId: burnerSum.steamBoilerId
            };
            uniqueBurner[keyNum2] = optionNum2;
          }
        }
      }

      Object.keys(uniqueDosing).map(key => {
        const dosingSum = uniqueDosing[key];
        const option = {
          value: `dosing-${dosingSum.chemicalId}`,
          label: `${intl.get(messagesCommon.tradename.id)} ${dosingSum.chemical}`,
          costFactorId: dosingSum.chemicalId
        };
        dynamicOptions.push(option);
        return true;
      });

      Object.keys(uniqueBurner).map(key => {
        const burnerSum = uniqueBurner[key];
        const burnMaterialOption = formOptions.fuel.filter(
          options => options.value === burnerSum.burnMaterial
        )[0];

        const option = {
          value: `burner-${burnerSum.burnerComponentId}-${burnerSum.steamBoilerId}-${burnerSum.num}`,
          label: `${burnMaterialOption.label} ${burnerSum.steamBoiler}`,
          unit: burnerSum.burnMaterialCostFactor ? burnerSum.burnMaterialCostFactor.unit : null,
          burnMaterial: burnerSum.burnMaterial
        };
        dynamicOptions.push(option);
        return true;
      });

      Object.keys(uniqueBoiler).map(key => {
        const boilerSum = uniqueBoiler[key];
        const option = {
          value: `boiler-${boilerSum.steamBoilerId}`,
          label: `${intl.get(messagesCommon.blowdown.id)} ${boilerSum.steamBoiler}`,
          unit: 't'
        };
        dynamicOptions.push(option);
        return true;
      });

      const fixedParameterOptions = formOptions.fixedAnalysisParams;
      const updatedOptions = [...fixedParameterOptions, ...dynamicOptions];

      setParameterOptions(updatedOptions);

      if (updatedOptions.length > 0) {
        paramPlaceholder = intl.get(messagesCommon.choose.id);
      }
    } else {
      if (dateRangeOption !== '') {
        paramPlaceholder = intl.get(messagesCommon.noData.id);
      }
      setParameterOptions([]);
    }

    setParameterPlaceholder(paramPlaceholder);
  }, [report, dateRangeOption]);

  const updateSelectedParameter = value => {
    let parameter = parameterOptions.filter(parameter => parameter.value === value)[0];

    if (parameter === undefined) {
      parameter = null;
    }

    onSelectedParameterChange(parameter);
  };

  return (
    <Box background="white" padding={4} width={300} minWidht={220} alignSelf="stretch">
      <Label htmlFor="analysis-period">{intl.get(messagesCommon.dateRange.id)}</Label>
      <Select
        id="analysis-period"
        color="black"
        size="xs"
        options={formOptions.dateRange}
        placeholder={intl.get(messagesCommon.choose.id)}
        value={dateRangeOption}
        onChange={update => onDateRangeChange(update.value)}
      />

      <Box marginTop={3}>
        <Label htmlFor="data-sets">{intl.get(messagesCommon.dataSets.id)}</Label>
        <Select
          id="data-sets"
          color="black"
          size="xs"
          options={parameterOptions.map(option => {
            // skip out the extra data for the select component
            return { label: option.label, value: option.value };
          })}
          placeholder={parameterPlaceholder}
          value={selectedParameter !== null ? selectedParameter.value : ''}
          onChange={update => updateSelectedParameter(update.value)}
        />
      </Box>

      <Text size="sm" bold marginTop={3}>
        {intl.get(messagesCommon.options.id)}
      </Text>
      <Box marginTop={1}>
        <Label regular noMarginBottom htmlFor="analysis-compare-last-year">
          <Checkbox
            id="analysis-compare-last-year"
            variant="small"
            checked={showLastYear}
            onChange={e => onShowLastYearChange(e.target.checked)}
          />
          <Text size="xs" inline style={{ marginLeft: 8 }}>
            {intl.get(messagesCommon.checkLastYear.id)}
          </Text>
        </Label>
      </Box>
    </Box>
  );
}

ChartFilter.propTypes = {
  dateRangeOption: PropTypes.string,
  showLastYear: PropTypes.bool,
  selectedParameter: PropTypes.any,
  onDateRangeChange: PropTypes.func.isRequired,
  onShowLastYearChange: PropTypes.func.isRequired,
  onSelectedParameterChange: PropTypes.func.isRequired,
  report: PropTypes.shape({
    current: PropTypes.array.isRequired,
    last: PropTypes.array.isRequired
  })
};

ChartFilter.defaultProps = {
  dateRangeOption: '',
  selectedParameter: null,
  showLastYear: false,
  report: {
    current: [],
    last: []
  }
};

export default ChartFilter;
