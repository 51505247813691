import { ComponentTypes } from 'constants/SteamComponent';
import useFormOptions from 'hooks/useFormOptions';
import AppStateService from 'services/AppStateService';
import ParameterService from 'services/ParameterService';

function useGetParameterUnit() {
  const formOptions = useFormOptions();
  const getUnitForParameter = (name, extra) => {
    const param = ParameterService.getParameterByName(name);
    let unit = null;

    if (param && param.name) {
      unit = param.unit && param.unit.length > 0 ? param.unit : null;

      // Use default or selected location unit for hardness and comparable parameter
      if (param.useHardnessUnitIfSet === true) {
        const locationHardnessConfig = AppStateService.location.data.hardnessUnit;
        const options = formOptions.hardness;
        const hardnessOption = options.filter(option => option.value === locationHardnessConfig)[0];

        if (hardnessOption) {
          unit = hardnessOption.labelShort;
        }
      }

      // get unit from cost factor of burner
      if (param.name.indexOf('burnMeter') >= 0) {
        const { costFactors } = extra;
        const { component } = extra;

        // component needs to be a burner
        if (component.type === ComponentTypes.Burner) {
          const burnMaterialKey = param.name.replace('Meter', 'Material');
          const burnMaterialName = component[burnMaterialKey];

          if (burnMaterialName) {
            const costFactor = costFactors.filter(
              costFactor => costFactor.type === burnMaterialName
            )[0];

            if (costFactor) {
              unit = costFactor.unit;
            }
          }
        }
      }
    }

    return unit;
  };

  return {
    getUnitForParameter
  };
}

export default useGetParameterUnit;
