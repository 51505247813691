function useComponentParameterChange() {
  const updateParameter = ({ paramSource, param, key, value }, componentData) => {
    const data = { ...componentData };

    // make sure param container is there
    if (!data[paramSource]) {
      data[paramSource] = [];
    }

    // find param value in param container
    let paramValue = data[paramSource].filter(value => value.name === param.name)[0];

    // check if param is present in component
    // if it is, update array entry with key, value
    // if not, create entry with param name + param unit
    if (paramValue) {
      const index = data[paramSource].findIndex(value => value.name === param.name);
      paramValue[key] = value;

      if (
        (key === 'minValue' || key === 'maxValue') &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        paramValue.active = true;
      }

      data[paramSource][index] = paramValue;
    } else {
      paramValue = {
        name: param.name,
        unit: param.unit ? param.unit : null,
        active: false
      };
      data[paramSource].push(paramValue);
    }

    return data;
  };

  return {
    update: updateParameter
  };
}

export default useComponentParameterChange;
