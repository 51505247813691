import Box from 'components/Box';
import Spinner from 'components/Spinner';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import ParameterService from 'services/ParameterService';
import { ThemeProvider } from 'styled-components';
import Routes from './Routes';
import GlobalStyle from './StyledApp';
import BaseTheme from './Theme';
// eslint-disable-next-line camelcase
import locale_de from './translations/de-DE.json';
// eslint-disable-next-line camelcase
import locale_en from './translations/en-US.json';

const LOCALES = {
  'de-DE': locale_de,
  'en-US': locale_en
};

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [localeToRender, setLocaleToRender] = useState(localStorage.getItem('locale') || 'de-DE');
  const [localesLoaded, setLocalesLoaded] = useState(false);

  useEffect(() => {
    const loadLocales = () => {
      setLocalesLoaded(false);

      let currentLocale = localeToRender;

      if (!localeToRender) {
        currentLocale = intl.determineLocale({
          urlLocaleKey: 'lang',
          cookieLocaleKey: 'lang'
        });
      }

      intl
        .init({
          currentLocale,
          locales: {
            [currentLocale]: LOCALES[currentLocale]
          }
        })
        .then(() => {
          setLocalesLoaded(true);
        });
    };

    loadLocales();
  }, [localeToRender]);

  useEffect(() => {
    const init = async () => {
      FirebaseService.init();
      await ParameterService.init();

      FirebaseService.firebaseRef.auth().onAuthStateChanged(async fbSessionUser => {
        if (fbSessionUser) {
          const userRef = await FirebaseService.loadUserData(fbSessionUser.email);
          const userData = userRef.data;
          AppStateService.setUser(userRef);

          if (userData.isDeleted) {
            AppStateService.removeUser();
            FirebaseService.signout();
            setIsLoading(false);
          } else {
            // check for saved location and customer
            const savedCustomer = localStorage.getItem('customer');
            const savedLocation = localStorage.getItem('location');

            let customer;
            let location;

            // service partner and super admin can have different customers/locations loaded
            if (
              savedCustomer !== null &&
              savedCustomer !== undefined &&
              (AppStateService.isSuperAdmin() || AppStateService.isServicePartner())
            ) {
              const customerSnapshot = await FirebaseService.loadCustomerById(savedCustomer);
              customer = {
                id: customerSnapshot.id,
                ref: customerSnapshot.ref,
                data: customerSnapshot.data()
              };
            } else if (!AppStateService.isServicePartner()) {
              customer = await FirebaseService.loadCustomerOfUser(userData);
            }

            // when customer user load default location or saved location if in configuration of user
            // when admin, load saved location if exists or none
            if (savedLocation) {
              let hasLocationConfigured = false;

              if (AppStateService.isSuperAdmin()) {
                hasLocationConfigured = true;
              } else {
                hasLocationConfigured =
                  userData.locations.filter(userLocation => userLocation.id === savedLocation)
                    .length > 0;
              }

              if (hasLocationConfigured) {
                const locationSnapshot = await FirebaseService.loadLocationById(savedLocation);
                location = {
                  id: locationSnapshot.id,
                  ref: locationSnapshot.ref,
                  data: locationSnapshot.data()
                };
              }
            }

            // No saved location could be loaded => load user default
            if (
              !location &&
              !AppStateService.isSuperAdmin() &&
              !AppStateService.isServicePartner()
            ) {
              location = await FirebaseService.loadDefaultLocationOfUser(userData);
            }

            if (customer) {
              AppStateService.setCustomer(customer);
            }

            if (location) {
              AppStateService.setLocation(location);
            }

            setIsLoading(false);
          }
        } else {
          if (AppStateService.hasUser()) {
            AppStateService.removeUser();
          }
          setIsLoading(false);
        }
      });
    };

    init();
  }, []);

  const setLocale = locale => setLocaleToRender(locale);

  return isLoading ? (
    <ThemeProvider theme={BaseTheme}>
      <GlobalStyle />
      <Box
        display="flex"
        width="100%"
        height="100%"
        alignItems="center"
        justify="center"
        position="fixed"
        left="0"
        top="0"
      >
        <Spinner noMargin />
      </Box>
    </ThemeProvider>
  ) : (
    localesLoaded && (
      <ThemeProvider theme={BaseTheme}>
        <GlobalStyle />
        <Routes setLocale={setLocale} />
      </ThemeProvider>
    )
  );
}

export default App;
