import Box from 'components/Box';
import { IconComponent } from 'components/Icons';
import Text from 'components/Text';
import TooltipChart from 'components/TooltipChart';
import useGetParameterUnit from 'hooks/useGetParameterUnit';
import { messagesCommon } from 'messages/messages';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import ParameterService from 'services/ParameterService';

function Chart({ config, parameter }) {
  const { getUnitForParameter } = useGetParameterUnit();

  const formatXAxis = tickItem => {
    return moment.unix(tickItem).format('DD.MM.YYYY');
  };

  const renderLegend = payload => {
    const boundaryText = intl.get(messagesCommon.boundary.id);
    const renderPayload = payload.filter(load => load.dataKey.indexOf(boundaryText) < 0);

    return (
      <Box
        display="flex"
        alignItems="center"
        justify="center"
        paddingY={1}
        paddingX={16}
        direction="row"
        wrap="wrap"
      >
        {renderPayload.map(entry => {
          const config = lineConfig.filter(config => config.key === entry.dataKey)[0];
          const color = config.color;
          return (
            <Box paddingY={2} paddingX={2} display="flex" key={`item-${entry.dataKey}`}>
              <IconComponent width="14" height="14" stroke={color} />
              <Text size="xs" inline color="black" marginLeft={2}>
                {entry.value}
              </Text>
            </Box>
          );
        })}
      </Box>
    );
  };

  const { chartData } = config;
  const { boundaryConfig } = config;
  const { lineConfig } = config;
  let scalingConfig = {};
  let unit = null;

  if (lineConfig[0]) {
    unit = getUnitForParameter(lineConfig[0].paramName);
  }

  if (parameter !== null) {
    const paramConfig = ParameterService.getParameterByName(parameter.name);
    if (paramConfig) {
      scalingConfig = paramConfig.chartScaling ? paramConfig.chartScaling : {};
    }
  }

  const prevYearText = intl.get(messagesCommon.previousYearShort.id);

  return (
    <ResponsiveContainer width="100%" minHeight={400}>
      <ComposedChart data={chartData} margin={{ top: 5, right: 50, left: 10, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timestamp" fontSize="12" stroke="#051014" tickFormatter={formatXAxis} />
        <YAxis fontSize="12" stroke="#051014" {...scalingConfig}>
          <Label angle="-90" fontSize="14px" offset={-10} position="left">
            {unit !== null ? unit : ''}
          </Label>
        </YAxis>
        <Tooltip
          content={<TooltipChart unit={unit} chartConfig={[...lineConfig, ...boundaryConfig]} />}
        />

        <Legend content={({ payload }) => renderLegend(payload)} />

        {lineConfig.map(config => (
          <Line
            key={`line-chart-${config.key}`}
            type={config.type ? config.type : 'monotone'}
            dataKey={config.key}
            stroke={config.color}
            unit={unit}
            connectNulls={true}
            strokeDasharray={config.key.includes(prevYearText) ? '5 5' : null}
          />
        ))}

        {boundaryConfig.map(config => (
          <Area
            key={`line-chart-${config.key}`}
            dataKey={config.key}
            stroke="none"
            unit={config.unit}
            fill={unit}
            fillOpacity={0.08}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

Chart.propTypes = {
  config: PropTypes.any,
  parameter: PropTypes.any
};

Chart.defaultProps = {
  config: {
    chartData: [],
    boundaryConfig: [],
    lineConfig: []
  },
  parameter: null
};

export default Chart;
