import 'firebase/auth';
import 'firebase/firestore';

import firebase from 'firebase/app';
import { Collections, FirebaseConfig } from '../constants/Firebase';

const FirebaseService = {
  firebaseRef: null,

  /**
   * Create a firebase timestamp
   */
  getTimestamp(srcDate) {
    const fromDate = srcDate !== undefined ? srcDate : new Date();
    return firebase.firestore.Timestamp.fromDate(fromDate);
  },

  /**
   * Init Firebase
   */
  init() {
    if (this.firebaseRef === null) {
      this.firebaseRef = firebase.initializeApp(FirebaseConfig);
    }
  },

  /**
   * Firebase Login
   */
  async authenticate(email, password) {
    return new Promise((resolve, reject) => {
      return this.firebaseRef
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => resolve(user))
        .catch(err => reject(err));
    });
  },

  /**
   * Firebase Logout
   */
  signout() {
    this.firebaseRef.auth().signOut();
  },

  /**
   * Reauthenticate the current session user in background
   */
  async reAuth(password) {
    return new Promise((resolve, reject) => {
      const authUser = this.firebaseRef.auth().currentUser;
      const userCredential = firebase.auth.EmailAuthProvider.credential(authUser.email, password);

      authUser
        .reauthenticateWithCredential(userCredential)
        .then(() => resolve())
        .catch(e => reject(e));
    });
  },

  /**
   * Change password of current auth session user
   * @param {*} currentPassword
   * @param {*} newPassword
   */
  async changePasswordOfUser(newPassword) {
    return new Promise((resolve, reject) => {
      const authUser = this.firebaseRef.auth().currentUser;
      authUser
        .updatePassword(newPassword)
        .then(() => resolve(true))
        .catch(e => reject(e));
    });
  },

  /**
   * Handle firebase user response and load user from collection
   */
  async loadUserData(email) {
    let user = null;
    try {
      const ref = this.firebaseRef
        .firestore()
        .collection(Collections.user)
        .doc(email);
      const doc = await ref.get();
      if (doc.exists) {
        user = {
          id: doc.id,
          ref: doc.ref,
          data: doc.data()
        };
      } else {
        user = null;
      }
    } catch (e) {
      user = null;
      console.log(e);
      console.log('Failed to handle user response');
    }

    return user;
  },

  /**
   * Load the customer based on the customer ref saved inside the user
   */
  async loadCustomerOfUser(user) {
    try {
      const snapshot = await user.customer.get();
      return { id: snapshot.id, ref: snapshot.ref, data: snapshot.data() };
    } catch {
      return null;
    }
  },

  /**
   * Get customer document
   */
  async loadCustomerById(id) {
    try {
      return await this.firebaseRef
        .firestore()
        .collection(Collections.customer)
        .doc(id)
        .get();
    } catch (error) {
      console.log('Error getting customer data');
      console.log(error);
      return false;
    }
  },

  /**
   * Update customer document
   */
  async updateCustomer(id, data) {
    try {
      let result = true;
      if (id) {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.customer)
          .doc(id)
          .set(data);
      } else {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.customer)
          .add(data);
      }
      return result;
    } catch (error) {
      console.log('Error writing customer data');
      console.log(error);
      return false;
    }
  },

  /**
   * Update notification document
   */
  async updateNotification(id, data) {
    try {
      let result = true;
      if (id) {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.notification)
          .doc(id)
          .set(data);
      } else {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.notification)
          .add(data);
      }
      return result;
    } catch (error) {
      console.log('Error writing notification data');
      console.log(error);
      return false;
    }
  },

  /**
   * Get notification document
   */
  async loadNotificationById(id) {
    try {
      return await this.firebaseRef
        .firestore()
        .collection(Collections.notification)
        .doc(id)
        .get();
    } catch (error) {
      console.log('Error getting location data');
      console.log(error);
      return false;
    }
  },

  /**
   * Get active notifications
   * @param {*} locationRef
   */
  getActiveNotificationRef(locationRef) {
    if (!locationRef) {
      return null;
    }

    const queryRef = this.firebaseRef
      .firestore()
      .collection(Collections.notification)
      .where('location', '==', locationRef)
      .where('archived', '==', false)
      .orderBy('activeAt', 'desc');

    /*
    const startDate = new Date();
    startDate.setHours(23);
    startDate.setMinutes(59);
    startDate.setSeconds(59);
    */

    // const startDateTimestamp = FirebaseService.getTimestamp(startDate);

    // queryRef = queryRef.startAt(startDateTimestamp);

    /*
    if (pastAndCurrent) {
      queryRef = queryRef.endAt(startDateTimestamp);
    } else {
      queryRef = queryRef.startAt(startDateTimestamp);
    }
    */

    return queryRef;
  },

  /**
   * Get archived notifications
   * @param {*} locationRef
   */
  getArchivedNotificationRef(locationRef) {
    if (!locationRef) {
      return null;
    }

    return this.firebaseRef
      .firestore()
      .collection(Collections.notification)
      .where('location', '==', locationRef)
      .where('archived', '==', true)
      .orderBy('activeAt', 'desc');
  },

  /**
   * Update location document
   */
  async updateLocation(id, data) {
    try {
      let result = true;
      if (id) {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.location)
          .doc(id)
          .set(data);
      } else {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.location)
          .add(data);
      }
      return result;
    } catch (error) {
      console.log('Error writing location data');
      console.log(error);
      return false;
    }
  },

  /**
   * Get customer document
   */
  async loadLocationById(id) {
    try {
      return await this.firebaseRef
        .firestore()
        .collection(Collections.location)
        .doc(id)
        .get();
    } catch (error) {
      console.log('Error getting location data');
      console.log(error);
      return false;
    }
  },

  /**
   * Create / Update user document
   * => should be done all with set since we set the id == email
   * => which does an insert or update on its own
   */
  async updateUser(id, data) {
    try {
      if (id) {
        await this.firebaseRef
          .firestore()
          .collection(Collections.user)
          .doc(id)
          .set(data);
      } else {
        await this.firebaseRef
          .firestore()
          .collection(Collections.user)
          .add(data);
      }
      return true;
    } catch (error) {
      console.log('Error writing user data');
      console.log(error);
      return false;
    }
  },

  /**
   * Create / Update component document
   */
  async updateComponent(id, data) {
    try {
      let docRef;

      if (id) {
        docRef = this.firebaseRef
          .firestore()
          .collection(Collections.component)
          .doc(id);
      } else {
        docRef = this.firebaseRef
          .firestore()
          .collection(Collections.component)
          .doc();
      }

      await docRef.set(data);
      return true;
    } catch (error) {
      console.log('Error writing component data');
      console.log(error);
      return false;
    }
  },

  /*
   * Get component by id
   */
  async getComponentById(id) {
    try {
      return await this.firebaseRef
        .firestore()
        .collection(Collections.component)
        .doc(id)
        .get();
    } catch (error) {
      console.log('Error getting component data');
      console.log(error);
      return false;
    }
  },

  /*
   * Update service partner document
   */
  async updateServicePartner(id, data) {
    try {
      if (id) {
        await this.firebaseRef
          .firestore()
          .collection(Collections.servicePartner)
          .doc(id)
          .set(data);
      } else {
        await this.firebaseRef
          .firestore()
          .collection(Collections.servicePartner)
          .add(data);
      }
      return true;
    } catch (error) {
      console.log('Error writing service partner data');
      console.log(error);
      return false;
    }
  },

  /**
   * Create / Update costFactor document
   */
  async updateCostFactor(id, data) {
    try {
      if (id) {
        await this.firebaseRef
          .firestore()
          .collection(Collections.costFactor)
          .doc(id)
          .set(data);
      } else {
        await this.firebaseRef
          .firestore()
          .collection(Collections.costFactor)
          .add(data);
      }
      return true;
    } catch (error) {
      console.log('Error writing cost factor data');
      console.log(error);
      return false;
    }
  },

  /**
   * Update external check document
   */
  async updateExternalCheck(id, data) {
    try {
      let result = true;
      if (id) {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.externalChecks)
          .doc(id)
          .set(data);
      } else {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.externalChecks)
          .add(data);
      }
      return result;
    } catch (error) {
      console.log('Error writing external check data');
      console.log(error);
      return false;
    }
  },

  /**
   * Remove external check document
   */
  async deleteExternalCheck(doc) {
    try {
      const id = doc.id;
      const ref = doc.ref.ref;

      await this.firebaseRef
        .firestore()
        .collection(Collections.externalChecks)
        .doc(id)
        .delete();

      const notifcationSnapshot = await this.firebaseRef
        .firestore()
        .collection(Collections.notification)
        .where('externalCheck', '==', ref)
        .get();

      notifcationSnapshot.forEach(doc => {
        doc.ref.delete();
      });

      return true;
    } catch (error) {
      console.log('External check could not be deleted');
      console.log(error);
      return false;
    }
  },

  /**
   * Load the default location of the user based on the location ref saved inside the user
   */
  async loadDefaultLocationOfUser(user) {
    try {
      const snapshot = await user.defaultLocation.get();
      const location = { id: snapshot.id, ref: snapshot.ref, data: snapshot.data() };
      return location;
    } catch {
      return null;
    }
  },

  /**
   * Init the reset password flow for the user
   * @param {*} email
   */
  async resetPassword(email) {
    return new Promise((resolve, reject) => {
      return this.firebaseRef
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => resolve(true))
        .catch(err => reject(err));
    });
  },

  /**
   * Create query to get locations by customer ref
   */
  async queryLocationsOfCustomer(customerRef) {
    return this.getLocationsOfCustomerRef(customerRef).get();
  },

  /**
   * Create query to get locations by customer ref
   */
  getLocationsOfCustomerRef(customerRef) {
    return this.firebaseRef
      .firestore()
      .collection(Collections.location)
      .where('customer', '==', customerRef)
      .where('isDeleted', '==', false);
  },

  async queryLocations(query) {
    try {
      const matchDocs = [];
      const locationSnapshot = await this.firebaseRef
        .firestore()
        .collection(Collections.location)
        .orderBy('name', 'asc')
        .get();

      const searchString = query.toLowerCase();

      locationSnapshot.forEach(doc => {
        let hasMatch = false;
        const data = doc.data();
        if (
          data.name.toLowerCase().includes(searchString) ||
          data.zip.toLowerCase().includes(searchString) ||
          data.city.toLowerCase().includes(searchString)
        ) {
          hasMatch = true;
        }

        if (hasMatch) {
          matchDocs.push({
            id: doc.id,
            ref: doc.ref,
            data
          });
        }
      });

      return matchDocs;
    } catch (e) {
      console.log('Error while searching for locations => ', e);
      return [];
    }
  },

  /**
   * Create query to get users by customer ref
   */
  async queryUsersOfCustomer(customerRef) {
    return this.getUsersOfCustomerRef(customerRef).get();
  },

  /**
   * Get users of customer
   */
  getUsersOfCustomerRef(customerRef) {
    return this.firebaseRef
      .firestore()
      .collection(Collections.user)
      .where('customer', '==', customerRef)
      .where('isDeleted', '==', false);
  },

  /**
   * Create query to get users by location ref
   * @param {*} locationRef
   */
  async queryCostFactorOfLocationRef(locationRef) {
    if (!locationRef) {
      return [];
    }

    return this.getCostFactorOfLocationRef(locationRef).get();
  },

  /**
   * Get a store reference for cost factors of customer
   * @param {*} locationRef
   */
  getCostFactorOfLocationRef(locationRef) {
    return this.firebaseRef
      .firestore()
      .collection(Collections.costFactor)
      .where('location', '==', locationRef)
      .orderBy('type', 'asc');
  },

  /**
   * Create query to get components of location
   * @param {*} customerRef
   */
  async queryComponentsOfLocation(locationRef) {
    if (!locationRef) {
      return [];
    }
    return this.getComponentsOfLocationRef(locationRef).get();
  },

  /**
   * Get steam components of location reference
   * @param {*} customerRef
   */
  getComponentsOfLocationRef(locationRef) {
    return this.firebaseRef
      .firestore()
      .collection(Collections.component)
      .where('location', '==', locationRef ? locationRef : null)
      .where('isDeleted', '==', false)
      .orderBy('componentNumber', 'asc');
  },

  /**
   * Create query to get all customers
   * @param {*} customerRef
   */
  async queryCustomers() {
    return this.getCustomersRef().get();
  },

  /**
   * Get query ref to all customers
   * @TODO check security roles to forbid access to all users
   * @param {*} customerRef
   */
  getCustomersRef() {
    return this.firebaseRef
      .firestore()
      .collection(Collections.customer)
      .where('isDeleted', '==', false);
  },

  /**
   * Create query to get all customers
   * @param {*} customerRef
   */
  async queryServicePartners() {
    return this.getServicePartnerRef().get();
  },

  /**
   * Create query to get all customers
   * @param {*} customerRef
   */
  async queryParameters() {
    return this.getParametersRef().get();
  },

  /**
   * Get query ref to all customers
   * @TODO check security roles to forbid access to all users
   * @param {*} customerRef
   */
  getParametersRef() {
    return this.firebaseRef.firestore().collection(Collections.parameter);
    // since we might need deleted parameters to fullfill historic data, skip the filter
    // .where('isDeleted', '==', false);
  },

  /**
   * Update external check document
   */
  async updateParameter(id, data) {
    try {
      let result = true;
      if (id) {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.parameter)
          .doc(id)
          .set(data);
      } else {
        result = await this.firebaseRef
          .firestore()
          .collection(Collections.parameter)
          .add(data);
      }
      return result;
    } catch (error) {
      console.log('Error writing external check data');
      console.log(error);
      return false;
    }
  },

  /**
   * Get service partner by id
   * @param {*} customerRef
   */
  async getParameter(id) {
    try {
      const doc = await this.firebaseRef
        .firestore()
        .collection(Collections.parameter)
        .doc(id)
        .get();

      if (doc && doc.exists) {
        return doc;
      }

      return false;
    } catch (e) {
      console.log('Error loading parameter');
      return false;
    }
  },

  /**
   * Get query ref to all customers
   * @TODO check security roles to forbid access to all users
   * @param {*} customerRef
   */
  getServicePartnerRef() {
    return this.firebaseRef
      .firestore()
      .collection(Collections.servicePartner)
      .where('isDeleted', '==', false);
  },

  /**
   * Get service partner by id
   * @param {*} customerRef
   */
  async getServicePartnerById(id) {
    try {
      const doc = await this.firebaseRef
        .firestore()
        .collection(Collections.servicePartner)
        .doc(id)
        .get();

      if (doc && doc.exists) {
        return doc;
      }

      return false;
    } catch (e) {
      console.log('Error loading service partner');
      return false;
    }
  },

  /**
   * Get external checks of location
   */
  getExternalChecksForLocation(locationRef) {
    return this.firebaseRef
      .firestore()
      .collection(Collections.externalChecks)
      .where('location', '==', locationRef ? locationRef : null)
      .orderBy('nextDueDate', 'asc');
  },

  /**
   * Create query to retrieve Measurements for location
   * @param {*} customerRef
   */
  async queryMeasurementsOfLocationsRef(locationRef) {
    return this.getComponentsOfLocationRef(locationRef).get();
  },

  /**
   * Get Measurement reference for location
   * @param {*} customerRef
   */
  getMeasurementsOfLocationsRef(locationRef, { dateFrom, dateTo }, sorting) {
    const sortOrder = sorting !== undefined ? sorting : 'desc';
    let startDateTimestamp = null;
    let endDateTimestamp = null;
    let startDate;
    let endDate;

    // Create a firebase timestamp that is <dateFrom 00:00:00>
    if (dateFrom && dateFrom !== '') {
      startDate = dateFrom;
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);

      startDateTimestamp = FirebaseService.getTimestamp(startDate);
      // set enddate to the current date so we can make sure the
      // startAt endAt query works even if only one date is set
      endDateTimestamp = FirebaseService.getTimestamp();
    }

    // Create a firebase timestamp that is <dateTo 23:59:59>
    if (dateTo && dateTo !== '') {
      endDate = dateTo;
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);

      endDateTimestamp = FirebaseService.getTimestamp(endDate);
    }

    let queryRef = this.firebaseRef
      .firestore()
      .collection(Collections.measurement)
      .where('location', '==', locationRef ? locationRef : null)
      .orderBy('createdAt', sortOrder);

    if (startDateTimestamp !== null) {
      if (sorting === 'desc') {
        queryRef = queryRef.endAt(startDateTimestamp);
      } else {
        queryRef = queryRef.startAt(startDateTimestamp);
      }
    }

    // since we order DESC we set startDateTimestamp as our endAt Query Cursor
    if (endDateTimestamp !== null) {
      if (sorting === 'desc') {
        queryRef = queryRef.startAt(endDateTimestamp);
      } else {
        queryRef = queryRef.endAt(endDateTimestamp);
      }
    }

    return queryRef;
  },

  /**
   * Get Measurement reference by id
   * @param {*} customerRef
   */
  async queryMeasurementById(id) {
    return this.firebaseRef
      .firestore()
      .collection(Collections.measurement)
      .doc(id)
      .get();
  },

  /**
   * Get last measurement(s) limited by limit
   * @param {*} customerRef
   */
  async getLastMeasurement(locationRef, limit) {
    const queryLimit = limit !== undefined ? limit : 1;
    return this.firebaseRef
      .firestore()
      .collection(Collections.measurement)
      .where('location', '==', locationRef)
      .orderBy('createdAt', 'desc')
      .limit(queryLimit)
      .get();
  },

  /**
   * Update external check document
   */
  async updateMeasurement(id, data) {
    try {
      if (id) {
        await this.firebaseRef
          .firestore()
          .collection(Collections.measurement)
          .doc(id)
          .set(data);
      } else {
        await this.firebaseRef
          .firestore()
          .collection(Collections.measurement)
          .add(data);
      }
      return true;
    } catch (error) {
      console.log('Error writing external check data');
      console.log(error);
      return false;
    }
  },

  async getMeasurementChanges(measurement) {
    try {
      const docs = await this.firebaseRef
        .firestore()
        .collection(Collections.measurementChanges)
        .where('measurement', '==', measurement.ref)
        .orderBy('updatedAt', 'desc')
        .get();

      return docs;
    } catch (e) {
      console.log(e);
      return [];
    }
  },

  async updateComponentSortingIndex(id, index) {
    try {
      await this.firebaseRef
        .firestore()
        .collection(Collections.component)
        .doc(id)
        .update({
          sortingIndex: index
        });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
};

export default FirebaseService;
