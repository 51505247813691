import 'react-day-picker/lib/style.css';
import 'moment/locale/de';

import Box from 'components/Box';
import PropTypes from 'prop-types';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import styled from 'styled-components/macro';

const StyledDayPickerInput = styled.div`
  input {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.border};
    color: ${props => props.theme.colors.black};
    outline: 0;
    width: 100%;
    border-radius: 4px;
    padding: 10px 14px;
    font-size: 14px;
  }
`;

function OverlayComponent({ children, ...props }) {
  return (
    <Box background="white" zIndex="1" position="absolute" left="0" {...props}>
      {children}
    </Box>
  );
}

function DayPicker({ onChange, value, placeholder }) {
  return (
    <StyledDayPickerInput>
      <DayPickerInput
        placeholder={placeholder}
        value={value}
        formatDate={formatDate}
        parseDate={parseDate}
        onDayChange={onChange}
        overlayComponent={OverlayComponent}
        dayPickerProps={{
          locale: localStorage.getItem('locale') || 'de',
          localeUtils: MomentLocaleUtils
        }}
      />
    </StyledDayPickerInput>
  );
}

DayPicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func
};

DayPicker.defaultProps = {
  placeholder: 'dd.mm.yyyy',
  value: null,
  onChange: () => {}
};

OverlayComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

OverlayComponent.defaultProps = {
  children: null
};

export default DayPicker;
