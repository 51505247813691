import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';
import ParameterService from 'services/ParameterService';

function ComponentValueNameColumns({ components, activeComponentValues, valueFilter }) {
  const getName = paramName => {
    const param = ParameterService.getParameterByName(paramName);
    const trans = localStorage.getItem('locale') === 'de-DE' ? param.labelDE : param.labelEN;
    return trans;
  };

  if (components.length > 0) {
    const activeComponentValuesColumns = components.map(componentRef => {
      const activeValues = activeComponentValues[componentRef.id];
      const valueTypes = ['waterValues', 'operatingValues', 'meterValues'].filter(
        type => valueFilter[type]
      );

      const activeValueColumns = valueTypes.map(type => {
        const valuesOfType = activeValues[type];
        return valuesOfType.map(value => (
          <td key={`measured-value-name-${value.name}-${componentRef.id}`}>
            <div>
              <Text size="sm" inline>
                {getName(value.name)}
              </Text>
            </div>
          </td>
        ));
      });

      return activeValueColumns;
    });

    const flatColumns = [].concat(...activeComponentValuesColumns);

    return [...flatColumns];
  }

  return null;
}

ComponentValueNameColumns.propTypes = {
  valueFilter: PropTypes.any.isRequired,
  components: PropTypes.any,
  activeComponentValues: PropTypes.any
};

ComponentValueNameColumns.defaultProps = {
  components: [],
  activeComponentValues: []
};

export default ComponentValueNameColumns;
