import Box from 'components/Box';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import TabList from 'components/TabList';
import Text from 'components/Text';
import { ComponentTypes } from 'constants/SteamComponent';
import useFlattenFirebaseDoc from 'hooks/useFlattenFirebaseDoc';
import { messagesAnalysis, messagesCommon, messagesReport } from 'messages/messages';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import ParameterService from 'services/ParameterService';
import WorkerService from 'services/WorkerService';
import styled from 'styled-components';
import BoilerLossRows from './BoilerLossRows';
import BurnerRows from './BurnerRows';
import DosingRows from './DosingRows';
import ReportRow from './ReportRow';
import SteamCostRow from './SteamCostRow';

const StyledReportTable = styled.table`
  tr:nth-child(even) {
    background-color: #fff !important;
  }

  th.right,
  td.right {
    text-align: right;
  }

  .padded {
    padding-left: 40px;
  }

  .section {
    border-bottom: 1px solid ${props => props.theme.colors.black};
  }

  .current {
    border-bottom: 3px solid ${props => props.theme.colors.black};
  }

  .vj {
    border-bottom: 3px solid ${props => props.theme.colors.borderTable};
  }

  .vjDiff {
    border-bottom: 1px solid ${props => props.theme.colors.black};
  }

  .bold {
    font-weight: 500;

    td {
      font-weight: 500;
    }
  }

  .sum {
    border-top: 1px solid ${props => props.theme.colors.borderTable};
  }
`;

function Reports() {
  const { flattenMeasurement, flattenComponent } = useFlattenFirebaseDoc();

  const [tabListIndex, setTabListIndex] = useState(0);
  const [steamPlantHasSteamMeter, setSteamPlantHasSteamMeter] = useState(false);

  const [loadingMeasurements, setLoadingMeasurements] = useState(true);
  const [loadingComponents, setLoadingComponents] = useState(true);
  const [calculatingReport, setCalculatingReport] = useState(false);

  const [components, setComponents] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [measurementsLastYear, setMeasurementsLastYear] = useState([]);

  const [reportData, setReportData] = useState([]);
  const [reportDataLastYear, setReportDataLastYear] = useState([]);

  // Effect to load components
  useEffect(() => {
    setLoadingComponents(true);
    const loadComponents = async () => {
      const docs = [];
      const querySnapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );
      querySnapshot.forEach(doc => {
        const item = flattenComponent({
          id: doc.id,
          ...doc.data()
        });
        docs.push(item);
      });

      const steamComponent = docs.filter(
        component => component.type === ComponentTypes.SteamBoiler
      )[0];

      if (steamComponent && steamComponent.meterValues.length > 0) {
        const steamMeter = steamComponent.meterValues.filter(
          meter => meter.name === 'steamMeter'
        )[0];

        setSteamPlantHasSteamMeter(steamMeter.active);
      }

      setComponents(docs);
      setLoadingComponents(false);
    };

    loadComponents();
  }, []);

  /**
   * Callback function to load measurements when date range changes
   */
  const loadMeasurements = useCallback(async () => {
    setLoadingMeasurements(true);

    // populate the last measurement data inside the measurement
    // and remove all references to provide a flat data structure used in worker file
    const populateFlattenAndSetState = async (queryDocs, stateSet) => {
      const loadLastData = queryDocs.map(async queryDoc => {
        const doc = queryDoc.data();
        let lastMeasurement = null;

        if (doc.lastMeasurement) {
          const lastMeasurementRef = await doc.lastMeasurement.get();
          lastMeasurement = flattenMeasurement({
            id: lastMeasurementRef.id,
            ...lastMeasurementRef.data()
          });
        }
        const item = flattenMeasurement({ ...doc });
        item.lastMeasurement = lastMeasurement;

        return item;
      });

      let measurementsWithLastData = await Promise.all(loadLastData);
      // only use measurements that actually have last data
      measurementsWithLastData = measurementsWithLastData.filter(
        item => item.lastMeasurement !== null
      );
      stateSet(measurementsWithLastData);
    };

    let queryDateFrom = moment().startOf('year');
    const queryDateTo = moment();

    if (tabListIndex === 1) {
      queryDateFrom = moment().subtract(30, 'days');
    }

    const queryDateFromLastYear = queryDateFrom.clone();
    const queryDateToLastYear = queryDateTo.clone();

    queryDateFromLastYear.subtract(1, 'years');
    queryDateToLastYear.subtract(1, 'years');

    const querySnapshot = await FirebaseService.getMeasurementsOfLocationsRef(
      AppStateService.location.ref,
      {
        dateFrom: queryDateFrom.toDate(),
        dateTo: queryDateTo.toDate()
      },
      'asc'
    ).get();

    const querySnapshotLastYear = await FirebaseService.getMeasurementsOfLocationsRef(
      AppStateService.location.ref,
      {
        dateFrom: queryDateFromLastYear.toDate(),
        dateTo: queryDateToLastYear.toDate()
      },
      'asc'
    ).get();

    await populateFlattenAndSetState(querySnapshot.docs, setMeasurements);
    await populateFlattenAndSetState(querySnapshotLastYear.docs, setMeasurementsLastYear);

    setLoadingMeasurements(false);
  }, [tabListIndex]);

  // Effect to load measurements on load
  useEffect(() => {
    loadMeasurements();
  }, [loadMeasurements]);

  // Effect to calculate the report using a web worker
  useEffect(() => {
    const generateReport = async () => {
      setCalculatingReport(true);

      const currentYearReport = await spawnReportWorker(measurements);
      const lastYearReport = await spawnReportWorker(measurementsLastYear);

      setReportData(currentYearReport);
      setReportDataLastYear(lastYearReport);
      setCalculatingReport(false);
    };

    if (loadingMeasurements === false) {
      generateReport();
    }
  }, [loadingMeasurements, measurements, measurementsLastYear]);

  const spawnReportWorker = async workerMeasurements => {
    const reportParams = ParameterService.getParameterForReport();

    const workerData = {
      meterValueConfig: reportParams.meterParameter,
      operatingValueConfig: reportParams.operationalParameter,
      waterValueConfig: reportParams.waterValues,
      componentTypes: ComponentTypes,
      components,
      measurements: workerMeasurements
    };

    return WorkerService.spawnReportWorker(workerData);
  };

  const handleTabListItemChange = ({ activeIndex }) => {
    setTabListIndex(activeIndex);
  };

  return (
    <>
      <Box marginX={7}>
        <Heading level="1" size="xl">
          {intl.get(messagesAnalysis.headlineReports.id)}
        </Heading>
      </Box>

      <Box marginX={7} marginTop={7}>
        <TabList
          width={600}
          items={[
            { index: 0, title: intl.get(messagesReport.reportDateRangeFilter1.id) },
            { index: 1, title: intl.get(messagesReport.reportDateRangeFilter2.id) }
          ]}
          selectedItemIndex={tabListIndex}
          onChange={handleTabListItemChange}
        />
      </Box>

      <LoadingBox
        loading={loadingMeasurements || loadingComponents || calculatingReport}
        renderChildren={!loadingMeasurements && !loadingComponents && !calculatingReport}
      >
        <Box background="white" marginX={7} marginTop={4} maxWidth="1280px">
          <StyledReportTable>
            <tbody>
              <tr>
                <td />
                <td />
                <td />
                <td colSpan="3" className="section">
                  <Text size="sm" bold align="center">
                    {intl.get(messagesReport.reportCurrentHeadline.id)}
                  </Text>
                </td>
                <td />
              </tr>
              <tr className="section">
                <td colSpan="2" style={{ width: '420px' }} className="bold">
                  {intl.get(messagesReport.reportTableHeadlineSteamCosts.id)}
                </td>
                <td style={{ width: '150px' }} className="center bold vj">
                  {intl.get(messagesCommon.previousYearShort.id)}
                </td>
                <td style={{ width: '150px' }} className="center bold current">
                  {intl.get(messagesReport.reportTableHeadlineDateRange.id)}
                </td>
                <td style={{ width: '150px' }} className="center bold current">
                  &empty;{intl.get(messagesReport.reportTableHeadlineDailyAvg.id)}
                </td>
                <td style={{ width: '150px' }} className="center bold current">
                  &empty;{intl.get(messagesReport.reportTableHeadlineHourlyAvg.id)}
                </td>
                <td style={{ width: '150px' }} className="center bold vjDiff">
                  &Delta;{intl.get(messagesCommon.previousYearShort.id)} %
                </td>
              </tr>
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="waterValue"
                unit="€"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="wasteWaterValue"
                unit="€"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="dosingValue"
                unit="€"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="burnMaterialValue"
                unit="€"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="priceSumRounded"
                unit="€"
                round={true}
                digits={0}
                rowClass="sum bold"
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="steamConsumption"
                unit="t"
                round={true}
                digits={0}
                digitsDaily={1}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />

              <SteamCostRow
                label={true}
                reportField="steamCost"
                unit="€ / t"
                round={true}
                digits={2}
                rowClass="sum bold"
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr className="section">
                <td colSpan="2" className="bold">
                  {intl.get(messagesReport.reportTableHeadlineConsumptions.id)}
                </td>
                <td className="vj" />
                <td className="current" />
                <td className="current" />
                <td className="current" />
                <td className="vjDiff" />
              </tr>
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="freshWater"
                unit={
                  <>
                    m<sup>3</sup>
                  </>
                }
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="treatedWater"
                unit={
                  <>
                    m<sup>3</sup>
                  </>
                }
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="feedWater"
                unit={
                  <>
                    m<sup>3</sup>
                  </>
                }
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <DosingRows
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <BurnerRows
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />

              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="condensate"
                unit="t"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />

              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr className="section">
                <td colSpan="2" className="bold">
                  {intl.get(messagesReport.reportTableHeadlineWaterLoss.id)}
                </td>
                <td className="vj" />
                <td className="current" />
                <td className="current" />
                <td className="current" />
                <td className="vjDiff" />
              </tr>
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="waterLoss"
                unit={
                  <>
                    m<sup>3</sup>
                  </>
                }
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <BoilerLossRows
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="thermicGasLoss"
                unit="t"
                round={true}
                digits={1}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
                steamPlantHasSteamMeter={steamPlantHasSteamMeter}
                alternativeText="Kein Zähler"
              />
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="productionLoss"
                unit="t"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
                alternativeText="Nicht plausibel"
              />
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr className="section">
                <td colSpan="2" className="bold">
                  {intl.get(messagesReport.reportTableHeadlineEnergyLoss.id)}
                </td>
                <td className="vj" />
                <td className="current" />
                <td className="current" />
                <td className="current" />
                <td className="vjDiff" />
              </tr>
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="energyBoilerLoss"
                unit="kWh"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              {/*
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={false}
                reportField="energyBoilerLossValue"
                unit="€"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
              />
              */}
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="energyThermicLoss"
                unit="kWh"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
                steamPlantHasSteamMeter={steamPlantHasSteamMeter}
                alternativeText="Kein Zähler"
              />
              {/*
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={false}
                reportField="energyThermicLossValue"
                unit="€"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
                steamPlantHasSteamMeter={steamPlantHasSteamMeter}
                alternativeText="Kein Zähler"
              />
              */}
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={true}
                reportField="energyProductionLoss"
                unit="kWh"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
                alternativeText="Nicht plausibel"
              />
              {/*
              <ReportRow
                period={tabListIndex === 1 ? 'last30' : 'currentYear'}
                label={false}
                reportField="energyProductionLossValue"
                unit="€"
                round={true}
                digits={0}
                reportData={reportData}
                reportDataLastYear={reportDataLastYear}
                alternativeText="Nicht plausibel"
              />
              */}
              <tr>
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </StyledReportTable>
        </Box>
      </LoadingBox>
    </>
  );
}

export default Reports;
