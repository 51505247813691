import Header from 'components/Header';
import Navigation from 'components/Navigation';
import AnalysisOperationalData from 'pages/AnalysisOperationalData/AnalysisOperationalData';
import AnalysisWaterParameter from 'pages/AnalysisWaterParameter/AnalysisWaterParameter';
import BoilerBook from 'pages/BoilerBook/BoilerBook';
import ChangeLocation from 'pages/ChangeLocation/ChangeLocation';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import CostFactor from 'pages/CostFactor/CostFactor';
import Customer from 'pages/Customer/Customer';
import CustomerOverview from 'pages/CustomerOverview/CustomerOverview';
import Dashboard from 'pages/Dashboard/Dashboard';
import ExternalChecks from 'pages/ExternalChecks/ExternalChecks';
import Login from 'pages/Login/Login';
import Logout from 'pages/Logout/Logout';
import Measurement from 'pages/Measurement/Measurement';
import NoMatch from 'pages/NoMatch/NoMatch';
import Notification from 'pages/Notification/Notification';
import Parameter from 'pages/Parameter/Parameter';
import Reports from 'pages/Reports/Reports';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import ServicePartner from 'pages/ServicePartner/ServicePartner';
import ServicePartnerOverview from 'pages/ServicePartnerOverview/ServicePartnerOverview';
import SteamPlant from 'pages/SteamPlant/SteamPlant';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import styled from 'styled-components/macro';
import { renderRouteByPackage } from 'utils/RenderByPackage';

const StyledMain = styled.div`
  display: flex;
  min-height: calc(100vh - 80px);
`;

const StyledContent = styled.div`
  width: calc(100% - 200px);
  padding: 35px 0;
`;

const Routes = ({ setLocale }) => (
  <Router>
    <Switch>
      <Route path="/login" exact render={props => <LoginContainer {...props} />} />
      <Route path="/reset-password" exact render={props => <ResetPasswordContainer {...props} />} />
      <Route path="/logout" exact component={Logout} />
      <PrivateRoutes setLocale={setLocale} />
    </Switch>
  </Router>
);

const LoginContainer = () => <Route path="/login" component={Login} />;
const ResetPasswordContainer = () => <Route path="/reset-password" component={ResetPassword} />;

const DefaultContainer = ({ setLocale }) => (
  <>
    <Header setLocale={setLocale} location={AppStateService.location} />
    <StyledMain>
      <Navigation />
      <StyledContent>
        <Switch>
          {/* default route */}
          {renderRoutesByLocationPackage('boiler-book') && (
            <Route path="/" exact render={props => <BoilerBook {...props} />} />
          )}

          {renderRoutesByLocationPackage('dashboard') && (
            <Route path="/dashboard" exact render={props => <Dashboard {...props} />} />
          )}

          {renderRoutesByLocationPackage('steam-plant') && (
            <Route path="/steam-plant" exact render={props => <SteamPlant {...props} />} />
          )}

          <Route path="/change-location" exact render={props => <ChangeLocation {...props} />} />

          {renderRoutesByLocationPackage('boiler-book') && (
            <Route path="/boiler-book" exact render={props => <BoilerBook {...props} />} />
          )}

          {renderRoutesByLocationPackage('boiler-book') && (
            <Route
              path="/boiler-book/entry/:id"
              exact
              render={props => <Measurement {...props} />}
            />
          )}

          {renderRoutesByLocationPackage('external-checks') && (
            <Route path="/external-checks" exact render={props => <ExternalChecks {...props} />} />
          )}

          {renderRoutesByLocationPackage('company') && (
            <Route path="/company" exact render={props => <Customer {...props} />} />
          )}

          {renderRoutesByLocationPackage('notifications') && (
            <Route path="/notifications" exact render={props => <Notification {...props} />} />
          )}

          {renderRoutesByLocationPackage('steam-costs') && (
            <Route path="/steam-costs" exact render={props => <CostFactor {...props} />} />
          )}

          {renderRoutesByLocationPackage('reports') && (
            <Route path="/reports" exact render={props => <Reports {...props} />} />
          )}

          {renderRoutesByLocationPackage('analysis-water-parameter') && (
            <Route
              path="/analysis-water-parameter"
              exact
              render={props => <AnalysisWaterParameter {...props} />}
            />
          )}

          {renderRoutesByLocationPackage('analysis-operational-data') && (
            <Route
              path="/analysis-operational-data"
              exact
              render={props => <AnalysisOperationalData {...props} />}
            />
          )}

          {AppStateService.isSuperAdmin() && (
            <>
              <Route path="/parameters" exact render={props => <Parameter {...props} />} />
              <Route
                path="/customer-overview"
                exact
                render={props => <CustomerOverview {...props} />}
              />
              <Route
                path="/service-partner-overview"
                exact
                render={props => <ServicePartnerOverview {...props} />}
              />
              <Route
                path="/service-partner/:id"
                exact
                render={props => <ServicePartner {...props} />}
              />
            </>
          )}

          <Route path="/change-password" exact render={props => <ChangePassword {...props} />} />

          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </StyledContent>
    </StyledMain>
  </>
);

const renderRoutesByLocationPackage = name => {
  const isSuperAdmin = AppStateService.isSuperAdmin();
  const isServicePartner = AppStateService.isServicePartner();
  const locationData = AppStateService.location;

  // no location => do not render
  // except for company page where user can create a location when user is customer
  // except for change-location page where service partner can select a customer/location
  if (isServicePartner && !locationData && name !== 'change-location') {
    return false;
  }

  if (!isServicePartner && !locationData && name !== 'company') {
    return false;
  }

  const doRender = isSuperAdmin || renderRouteByPackage(locationData, name);
  return doRender;
};

const PrivateRoutes = props => {
  const policyRoutingRender = location => {
    if (!AppStateService.hasUser()) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      );
    }

    if (
      AppStateService.isSuperAdmin() &&
      AppStateService.customer === null &&
      location.pathname !== '/customer-overview'
    ) {
      return (
        <Redirect
          to={{
            pathname: '/customer-overview',
            state: { from: location }
          }}
        />
      );
    }

    if (
      AppStateService.isServicePartner() &&
      (AppStateService.customer === null || AppStateService.location === null) &&
      location.pathname !== '/change-location'
    ) {
      return (
        <Redirect
          to={{
            pathname: '/change-location',
            state: { from: location }
          }}
        />
      );
    }

    return <DefaultContainer {...props} />;
  };

  return <Route {...props} render={({ location }) => policyRoutingRender(location)} />;
};

Routes.propTypes = {
  setLocale: PropTypes.func.isRequired
};

DefaultContainer.propTypes = {
  setLocale: PropTypes.func.isRequired
};

export default Routes;
