import Box from 'components/Box';
import Panel from 'components/Panel';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import UserFormEdit from './UserFormEdit';

function PanelUser({ user, onClose }) {
  const formValidator = useFormValidation();
  const [userData, setUserData] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isCreateMode] = useState(!user);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const validData = formValidator.validateUser(userData);
    setValid(validData);
  }, [userData, formValidator]);

  useEffect(() => {
    setUserData(user && user.data);
  }, [user]);

  useEffect(() => {}, [userData]);

  const handleFormFieldChange = (key, value) => {
    const update = userData === null ? {} : { ...userData };
    update[key] = value;
    setUserData(update);
  };

  const save = async () => {
    try {
      setSaveInProgress(true);
      const userDataToSave = { ...userData };
      const id = isCreateMode ? userDataToSave.email : user.id;

      if (isCreateMode) {
        userDataToSave.isDeleted = false;
        userDataToSave.customer = AppStateService.customer.ref;
        if (!userDataToSave.defaultLocation) {
          userDataToSave.defaultLocation = userDataToSave.locations[0];
        }
      }

      await FirebaseService.updateUser(id, userDataToSave);

      onClose();
    } catch (e) {
      console.log('User could not be inserted or updated');
      console.log(e);
    }
  };

  return (
    <Panel
      title={isCreateMode ? intl.get(messagesCrud.userAdd.id) : intl.get(messagesCrud.userEdit.id)}
      buttonPrimaryAction={save}
      buttonPrimaryDisabled={!valid}
      onClose={onClose}
      showSpinner={saveInProgress}
      isOpen
    >
      <Box marginTop={5} style={{ overflowY: 'auto', height: 'calc(100% - 110px)' }}>
        <UserFormEdit
          user={userData}
          isCreateMode={isCreateMode}
          onChange={handleFormFieldChange}
        />
      </Box>
    </Panel>
  );
}

PanelUser.propTypes = {
  user: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

PanelUser.defaultProps = {
  user: null
};

export default PanelUser;
