import 'firebase/firestore';

import Box from 'components/Box';
import DayPicker from 'components/DayPicker';
import Label from 'components/Label';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';
import Toggle from 'components/Toggle';
import { REQUIRED_FIELDS } from 'constants/Form';
import { ComponentTypeConfig } from 'constants/SteamComponent';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon, messagesComponents } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function CheckFormEdit({ externalCheck, isCreateMode, onChange }) {
  const formOptions = useFormOptions();
  const formConfig = REQUIRED_FIELDS.externalCheck;
  const formValidator = useFormValidation();

  const [loadingComponents, setLoadingComponents] = useState(false);
  const [components, setComponents] = useState([]);
  const [componentOptions, setComponentOptions] = useState([]);

  // Effect to load components of location
  useEffect(() => {
    const loadComponents = async () => {
      setLoadingComponents(true);
      const docs = [];
      const options = [];

      const querySnapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );

      querySnapshot.forEach(doc => {
        const data = doc.data();
        docs.push({
          id: doc.id,
          ref: doc.ref,
          data
        });

        // @TODO make this a util
        const getI18nComponentName = type => {
          const messageName = ComponentTypeConfig[type].name;
          const message = messagesComponents[`component${messageName}`];
          return intl.get(message.id);
        };

        const internalName = data.internalName;
        const componentName = getI18nComponentName(data.type);
        options.push({
          label: `${componentName} - ${internalName}`,
          value: doc.id
        });

        // sort options by label
        options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)); // eslint-disable-line
      });

      setComponents(docs);
      setComponentOptions(options);
      setLoadingComponents(false);
    };

    loadComponents();
  }, []);

  const handleComponentChange = value => {
    const [update] = components.filter(c => c.id === value);
    onChange('component', update.ref);
  };

  const handleOnChange = (key, value) => {
    onChange(key, value);
  };

  const updateStartDate = date => {
    const updated = FirebaseService.getTimestamp(date);
    handleOnChange('startDate', updated);
  };

  return (
    <>
      {loadingComponents ? (
        <Box display="flex" justify="center">
          <Spinner noMargin />
        </Box>
      ) : (
        <Box marginY={3}>
          <Label
            htmlFor="component"
            required={formValidator.isFieldRequired(formConfig, 'component')}
            valid={formValidator.isFieldValid(formConfig, 'component', externalCheck)}
          >
            {intl.get(messagesCommon.component.id)}
          </Label>
          <Select
            id="component"
            options={componentOptions}
            placeholder={intl.get(messagesCommon.choose.id)}
            value={externalCheck && externalCheck.component ? externalCheck.component.id : ''}
            onChange={e => handleComponentChange(e.value)}
          />
        </Box>
      )}

      <Box marginY={3}>
        <Label
          htmlFor="name"
          required={formValidator.isFieldRequired(formConfig, 'name')}
          valid={formValidator.isFieldValid(formConfig, 'name', externalCheck)}
        >
          {intl.get(messagesCommon.externalCheckTypeLong.id)}
        </Label>
        <TextField
          id="name"
          type="text"
          placeholder={intl.get(messagesCommon.externalCheckTypeLong.id)}
          value={externalCheck?.name}
          onChange={e => handleOnChange('name', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="operated-by"
          required={formValidator.isFieldRequired(formConfig, 'operatedBy')}
          valid={formValidator.isFieldValid(formConfig, 'operatedBy', externalCheck)}
        >
          {intl.get(messagesCommon.operatedBy.id)}
        </Label>
        <TextField
          id="operated-by"
          type="text"
          placeholder={intl.get(messagesCommon.operatedBy.id)}
          value={externalCheck?.operatedBy}
          onChange={e => handleOnChange('operatedBy', e.currentTarget.value)}
        />
      </Box>
      {isCreateMode && (
        <Box marginY={3}>
          <Label
            htmlFor="startdate"
            required={formValidator.isFieldRequired(formConfig, 'startDate')}
            valid={formValidator.isFieldValid(formConfig, 'startDate', externalCheck)}
          >
            {intl.get(messagesCommon.externalCheckDueDate.id)}
          </Label>
          <DayPicker
            id="startdate"
            placeholder={intl.get(messagesCommon.externalCheckDueDate.id)}
            onChange={updateStartDate}
            value={
              externalCheck && externalCheck.startDate
                ? externalCheck.startDate.toDate()
                : undefined
            }
          />
        </Box>
      )}
      <Box marginY={3}>
        <Label
          htmlFor="interval"
          required={formValidator.isFieldRequired(formConfig, 'interval')}
          valid={formValidator.isFieldValid(formConfig, 'interval', externalCheck)}
        >
          {intl.get(messagesCommon.interval.id)}
        </Label>
        <Select
          id="interval"
          options={formOptions.interval}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={externalCheck?.interval}
          disabled={!isCreateMode}
          onChange={e => handleOnChange('interval', e.value)}
        />
      </Box>
      {!isCreateMode && (
        <Box marginY={3}>
          <Label htmlFor="status">{intl.get(messagesCommon.notifications.id)}</Label>
          <Toggle
            enabled={externalCheck?.active}
            onChange={enabled => onChange('active', enabled)}
          />
        </Box>
      )}
    </>
  );
}

CheckFormEdit.propTypes = {
  externalCheck: PropTypes.any,
  onChange: PropTypes.func,
  isCreateMode: PropTypes.bool
};

CheckFormEdit.defaultProps = {
  externalCheck: null,
  onChange: null,
  isCreateMode: false
};

export default CheckFormEdit;
