import Box from 'components/Box';
import NumberSvg from 'components/NumberSvg';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import SteamCostTooltip from './SteamCostTooltip';

function SteamCostBarChart({ height, data, dataKeys }) {
  const barLabel = labelData => {
    return (
      <NumberSvg
        x={labelData.x + labelData.width / 2}
        y={labelData.y}
        fill="#051014"
        textAnchor="middle"
        dy={-6}
        number={labelData.value}
        digits={0}
        round={true}
      />
    );
  };

  const prevYearText = intl.get(messagesCommon.previousYearShort.id);

  return (
    <Box style={{ width: '100%', height }}>
      <ResponsiveContainer width="100%">
        <BarChart
          data={data}
          margin={{ top: 40, right: 20, left: 20, bottom: 0 }}
          barCategoryGap="20%"
          barGap={15}
        >
          <XAxis dataKey="name" />
          <Tooltip cursor={false} content={<SteamCostTooltip />} />
          <Tooltip cursor={false} />
          <Legend />
          {dataKeys.map(dataKey => (
            <Bar
              label={barLabel}
              key={dataKey}
              dataKey={dataKey}
              fill={dataKey === prevYearText ? '#c7c7c7' : '#163253'}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

SteamCostBarChart.propTypes = {
  height: PropTypes.number,
  data: PropTypes.array.isRequired,
  dataKeys: PropTypes.array.isRequired
};

SteamCostBarChart.defaultProps = {
  height: 300
};

export default SteamCostBarChart;
