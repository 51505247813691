import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
      box-sizing: border-box;
  }

  html {
    line-height: inherit;
    -ms-text-size-adjust: inherit;
    -webkit-text-size-adjust: inherit;
  }

  body {
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: 'Roboto', sans-serif;
  }

  html,
  body {
    background: ${props => props.theme.colors.background};
    height: 100%;
  }

  :focus,
  :active {
    outline: 0 !important;
  }

  #diwaso {
    width: 100%;
    height: 100%;
  }

  ol, ul {
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table:not(.boiler-book) {
    width: 100%;
    border-collapse: collapse;

    tr:nth-child(even) {
      background-color: #f5f5f5;
    }

    th.center, td.center {
      text-align: center !important;
    }
  }

  table:not(.boiler-book) th {
    background-color: #fff;
    border-bottom: 1px solid ${props => props.theme.colors.borderTable};
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #051014;
  }

  table:not(.boiler-book) td {
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    color: #051014;
  }

  table:not(.boiler-book) tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }

  table:not(.boiler-book) tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  table:not(.boiler-book) tr:first-child th:first-child,
  table:not(.boiler-book) tr:first-child td:first-child {
    border-top-left-radius: 4px;
  }

  table:not(.boiler-book) tr:first-child th:last-child,
  table:not(.boiler-book) tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }

  .boiler-book {
    tr th:not(:first-child):not(:empty):not(:last-child),
    tr td:not(:first-child):not(:empty):not(:last-child) {
      border-left: 1px solid ${props => props.theme.colors.borderTable};
      border-right: 1px solid ${props => props.theme.colors.borderTable};
    }

    tr td {
      text-align: center;
    }

    tr th:not(:empty) {
      height: 70px;
      font-size: 15px;
      border-bottom: 1px solid ${props => props.theme.colors.borderTable};
      white-space: nowrap;
    }

    tr:nth-child(2n + 3) {
      background-color: #f5f5f5;
    }

    th {
      padding: 0 10px;
    }

    td {
      white-space: nowrap;
      font-size: 14px;
      padding: 7px 10px;
      color: #051014;
    }

    tr.rotate td > div {
      cursor: default;
      /* transform: rotate(270deg); */
      height: 180px;
      overflow: visible;
      display: flex;
      justify-content: center;
      padding: 15px 10px 5px 10px;
      span {
        writing-mode: tb-rl;
      }
    }

    .subhead td:not(.has-padding) {
      padding: 0;
    }

    .subhead span {
      padding: 7px 3px;
      display: block;
      text-align: center;

      &:first-child {
        border-bottom: 1px solid ${props => props.theme.colors.borderTable};
      }
    }
  }

  table.table-panel {
    td {
      padding: 10px 0;

      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }

  table.table-panel tr:nth-child(even) {
    background-color: #fff;
  }

  .DayPicker-Body {
    display: block;
  }

  .DayPicker-Week,
  .DayPicker-WeekdaysRow {
    display: flex;
  }

  .DayPicker-Weekday {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.colors.black};
  }

  .DayPicker-Day {
    display: block;
    width: 28px;
    height: 28px;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 0px;
  }

  .DayPicker-Day--today {
    color: ${props => props.theme.colors.red};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${props => props.theme.colors.blue};
  }

  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
  }

  .popup-content {
    box-shadow: 4px 4px 17px 0 rgba(0,0,0,0.18) !important;
    border: 0 !important;
    padding: 20px 24px !important;
    border-radius: 10px;
  }

  .DayPickerInput {
    width: 100%
  }

  .recharts-tooltip-wrapper {
    z-index: 999;
  }
`;

export default GlobalStyle;
