import Box from 'components/Box';
import Link from 'components/Link';
import RoleText from 'components/RoleText';
import Select from 'components/Select';
import { ReactComponent as ArrowDown } from 'images/ArrowDown.svg';
import { ReactComponent as Bell } from 'images/Bell.svg';
import Logo from 'images/diwaso_logo_white_border.svg';
import { messagesCommon, messagesNavigation } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FloatyBox from 'react-floatybox';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import styled, { css } from 'styled-components/macro';
import { renderMenuItemByPackage } from 'utils/RenderByPackage';
import Text from './Text';

const StyledLogo = styled(Box)`
  /* background: #ffffff; */
  /* border-radius: 100%; */
  width: 60px;
  height: 60px;
`;

const StyledItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
`;

const StyledLocation = styled.span`
  display: block;
  color: ${props => props.theme.colors.white};
  font-size: 17px;
  margin-left: 30px;

  &:hover span {
    color: white !important;
    text-decoration: underline;
  }

  a:hover,
  a:focus,
  a:active {
    text-decoration: none !important;
  }
`;

const StyledNotification = styled.div`
  position: relative;
  cursor: pointer;

  ${props =>
    props.hasNotification &&
    css`
      &::after {
      background: ${props.theme.colors.red};
      border: 1px ${props.theme.colors.white} solid;
      border-radius: 100%;
      position: absolute;
      top: 3px;
      right: -1px;
      content: '';
      width: 9px;
      height: 9px;
    `}
`;

const StyledLanguageSwitch = styled.div`
  margin-left: 30px;
`;

const StyledUser = styled.div`
  color: ${props => props.theme.colors.white};
  margin-left: 30px;
  cursor: pointer;
`;

const StyledLink = styled(Box)`
  width: 100%;
  padding: 0px 0px;
  cursor: pointer;
  & > * {
    border-radius: 4px;
    cursor: pointer;
    color: ${props => props.theme.colors.black};
    display: block;
    font-size: 14px;
    padding: 10px 15px;
    text-decoration: none;

    &:hover {
      background: ${props => !props.highlight && props.theme.colors.blue};
      color: ${props => !props.highlight && props.theme.colors.white};
    }
  }
`;

const StyledExtraInfo = styled.span`
  color: rgba(255, 255, 255, 0.7);
  display: block;
  font-size: 13px;
`;

const StyledArrowDown = styled(ArrowDown)`
  display: inline-block;
  margin-left: 12px;
`;

function Header({ setLocale, location }) {
  const history = useHistory();

  const [currentLocale, setCurrentLocale] = useState(localStorage.getItem('locale') || 'de');
  const [notifications, setNotifications] = useState([]);
  const [userFloatyVisible, setUserFloatyVisible] = useState(false);

  history.listen(() => {
    setUserFloatyVisible(false);
  });

  const navigateFromFloaty = target => {
    setUserFloatyVisible(false);
    history.push(target);
  };

  const onChangeLanguage = event => {
    const locale = event.value;
    localStorage.setItem('locale', locale);
    setCurrentLocale(locale);
    setLocale(locale);
  };

  // Effect to load notifications and keep them up to date
  useEffect(() => {
    const updateCollection = querySnapshot => {
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      setNotifications(docs);
    };

    let unsubscribe = null;

    if (location) {
      const notifiicationQuery = FirebaseService.getActiveNotificationRef(location.ref, true);
      if (notifiicationQuery) {
        unsubscribe = notifiicationQuery.onSnapshot(updateCollection);
      }
    } else {
      setNotifications([]);
    }

    return () => {
      if (unsubscribe !== null) {
        unsubscribe();
      }
    };
  }, [location]);

  const userRoutesBubble = () => {
    return (
      <Box background="white" width={200}>
        <Box direction="column" display="flex">
          <StyledLink onClick={() => navigateFromFloaty('/change-password')}>
            <Text size="sm" color="blue">
              {intl.get(messagesNavigation.changePassword.id)}
            </Text>
          </StyledLink>

          <StyledLink onClick={() => navigateFromFloaty('/logout')}>
            <Text size="sm" color="blue">
              {intl.get(messagesNavigation.logout.id)}
            </Text>
          </StyledLink>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      background="blue"
      display="flex"
      alignItems="center"
      justify="space-between"
      zIndex="100"
      height={80}
      noBorderRadius
      paddingX={5}
    >
      <StyledItems>
        <StyledLogo display="flex" alignItems="center" justify="center">
          <img src={Logo} alt="diwaso" width="48px" />
        </StyledLogo>

        {AppStateService.customer && (
          <>
            <StyledLocation>
              <Link href="/change-location" routerLink>
                {AppStateService.customer.data.name}
                {location && <StyledExtraInfo>{location.data.name}</StyledExtraInfo>}
              </Link>
            </StyledLocation>
            <StyledArrowDown />
          </>
        )}
      </StyledItems>
      <StyledItems>
        {(AppStateService.isSuperAdmin() ||
          renderMenuItemByPackage(AppStateService.location, 'notifications')) && (
          <StyledNotification
            hasNotification={notifications.length > 0}
            onClick={() => history.push('/notifications')}
          >
            <Bell />
          </StyledNotification>
        )}

        <StyledLanguageSwitch>
          <Select
            id="language-switch"
            background="blue"
            noBorder={true}
            color="white"
            options={[
              { value: 'de-DE', label: intl.get(messagesCommon.languageGerman.id) },
              { value: 'en-US', label: intl.get(messagesCommon.languageEnglish.id) }
            ]}
            placeholder="Bitte wählen"
            value={currentLocale}
            onChange={onChangeLanguage}
          />
        </StyledLanguageSwitch>
        <StyledUser>
          <FloatyBox
            isOpen={userFloatyVisible}
            onChange={visible => setUserFloatyVisible(visible)}
            bubble={userRoutesBubble}
            open="click"
            side="bottom"
            align="left"
            alignInner="right"
          >
            <>
              {AppStateService.user.data.firstname} {AppStateService.user.data.lastname}
              <StyledExtraInfo>
                <RoleText role={AppStateService.user.data.role} />
              </StyledExtraInfo>
            </>
          </FloatyBox>
        </StyledUser>
      </StyledItems>
    </Box>
  );
}

Header.propTypes = {
  setLocale: PropTypes.func,
  location: PropTypes.any
};

Header.defaultProps = {
  setLocale: () => {},
  location: null
};

export default Header;
