function useFlattenFirebaseDoc() {
  const flattenCostFactor = doc => {
    const item = { ...doc };

    delete item.location;

    return item;
  };

  const flattenComponent = doc => {
    const item = { ...doc };

    delete item.location;

    if (item.dilutedDosingTable) {
      item.dilutedDosingTable = item.dilutedDosingTable.map(dosing => {
        const dosingWithoutRefs = { ...dosing };
        const costFactorId = dosingWithoutRefs.costFactor.id;
        delete dosingWithoutRefs.costFactor;
        dosingWithoutRefs.costFactor = costFactorId;
        return dosingWithoutRefs;
      });
    }

    if (item.chemical) {
      const chemicalId = item.chemical.id;
      delete item.chemical;
      item.chemical = chemicalId;
    }

    if (item.steamBoiler) {
      const steamBoilerId = item.steamBoiler.id;
      delete item.steamBoiler;
      item.steamBoiler = steamBoilerId;
    }

    return item;
  };

  const flattenMeasurement = doc => {
    const item = { ...doc };

    // get rid of references
    delete item.createdBy;
    delete item.updatedBy;
    delete item.lastMeasurement;
    delete item.location;

    // transform date
    item.createdAt = item.createdAt.toDate();
    item.createdAtTime = item.createdAt.getTime() / 1000;

    item.measuredValues = item.measuredValues.map(measured => {
      const withoutRefs = { ...measured };
      delete withoutRefs.component;
      return withoutRefs;
    });

    /*
    item.costFactorAtTime = item.costFactorAtTime.map(costFactor => {
      const withoutRefs = { ...costFactor };
      delete withoutRefs.location;
      return withoutRefs;
    });
    */

    item.burnerConfigs = item.burnerConfigs.map(config => {
      const withoutRefs = { ...config };
      delete withoutRefs.component;
      delete withoutRefs.steamBoiler;
      return withoutRefs;
    });

    item.dosingConfigs = item.dosingConfigs.map(config => {
      const withoutRefs = { ...config };
      delete withoutRefs.component;
      const chemicalId = withoutRefs.chemical.id;
      delete withoutRefs.chemical;
      withoutRefs.chemical = chemicalId;

      withoutRefs.dilutedDosingTable = config.dilutedDosingTable.map(dosing => {
        const dosingWithoutRefs = { ...dosing };
        const costFactorId = dosingWithoutRefs.costFactor.id;
        delete dosingWithoutRefs.costFactor;
        dosingWithoutRefs.costFactor = costFactorId;
        return dosingWithoutRefs;
      });

      return withoutRefs;
    });

    return item;
  };

  return {
    flattenMeasurement,
    flattenComponent,
    flattenCostFactor
  };
}

export default useFlattenFirebaseDoc;
