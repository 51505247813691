import useFormOptions from 'hooks/useFormOptions';
import { messagesReport } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import ReportRow from './ReportRow';

function BurnerRows({ reportData, reportDataLastYear }) {
  const formOptions = useFormOptions();
  const burnMaterialSumsByBurnerSteamComponentAndMaterial = [];

  for (let i = 0; i < reportData.length; i += 1) {
    const reportItem = reportData[i];
    const { burnerSums } = reportItem;

    for (let j = 0; j < burnerSums.length; j += 1) {
      const burnerSum = burnerSums[j];
      const burnerType1Index = burnMaterialSumsByBurnerSteamComponentAndMaterial.findIndex(
        burnerItem =>
          burnerItem.burnerComponentId === burnerSum.burnerComponentId &&
          burnerItem.steamBoilerId === burnerSum.steamBoilerId &&
          burnerItem.material === burnerSum.burnMaterial1
      );

      const burnerType2Index = burnMaterialSumsByBurnerSteamComponentAndMaterial.findIndex(
        burnerItem =>
          burnerItem.burnerComponentId === burnerSum.burnerComponentId &&
          burnerItem.steamBoilerId === burnerSum.steamBoilerId &&
          burnerItem.material === burnerSum.burnMaterial2
      );

      if (burnerType1Index < 0) {
        // get the cost factor for burn material to get the unit
        const burnMaterial1CostFactor = burnerSum.burnMaterial1CostFactor;

        burnMaterialSumsByBurnerSteamComponentAndMaterial.push({
          burnerComponentId: burnerSum.burnerComponentId,
          steamBoiler: burnerSum.steamBoiler,
          steamBoilerId: burnerSum.steamBoilerId,
          currentValue: burnerSum.burnMeter1Value,
          lastValue: undefined,
          material: burnerSum.burnMaterial1,
          unit: burnMaterial1CostFactor ? burnMaterial1CostFactor.unit : '',
          num: 1
        });
      } else {
        burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType1Index].currentValue +=
          burnerSum.burnMeter1Value;
      }

      if (burnerSum.burnMaterial2) {
        if (burnerType2Index < 0) {
          // get the cost factor for burn material to get the unit
          const burnMaterial2CostFactor = burnerSum.burnMaterial2CostFactor;

          burnMaterialSumsByBurnerSteamComponentAndMaterial.push({
            burnerComponentId: burnerSum.burnerComponentId,
            steamBoiler: burnerSum.steamBoiler,
            steamBoilerId: burnerSum.steamBoilerId,
            currentValue: burnerSum.burnMeter2Value,
            lastValue: undefined,
            material: burnerSum.burnMaterial2,
            unit: burnMaterial2CostFactor ? burnMaterial2CostFactor.unit : '',
            num: 2
          });
        } else {
          burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType2Index].currentValue +=
            burnerSum.burnMeter2Value;
        }
      }
    }
  }

  for (let i = 0; i < reportDataLastYear.length; i += 1) {
    const reportItem = reportDataLastYear[i];
    const { burnerSums } = reportItem;

    for (let j = 0; j < burnerSums.length; j += 1) {
      const burnerSum = burnerSums[j];
      const burnerType1Index = burnMaterialSumsByBurnerSteamComponentAndMaterial.findIndex(
        burnerItem =>
          burnerItem.burnerComponentId === burnerSum.burnerComponentId &&
          burnerItem.steamBoilerId === burnerSum.steamBoilerId &&
          burnerItem.material === burnerSum.burnMaterial1
      );

      const burnerType2Index = burnMaterialSumsByBurnerSteamComponentAndMaterial.findIndex(
        burnerItem =>
          burnerItem.burnerComponentId === burnerSum.burnerComponentId &&
          burnerItem.steamBoilerId === burnerSum.steamBoilerId &&
          burnerItem.material === burnerSum.burnMaterial2
      );

      if (burnerType1Index < 0) {
        // get the cost factor for burn material to get the unit
        const burnMaterial1CostFactor = burnerSum.burnMaterial1CostFactor;

        burnMaterialSumsByBurnerSteamComponentAndMaterial.push({
          burnerComponentId: burnerSum.burnerComponentId,
          steamBoiler: burnerSum.steamBoiler,
          steamBoilerId: burnerSum.steamBoilerId,
          lastValue: burnerSum.burnMeter1Value,
          currentValue: undefined,
          material: burnerSum.burnMaterial1,
          unit: burnMaterial1CostFactor ? burnMaterial1CostFactor.unit : '',
          num: 1
        });
      } else {
        if (
          burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType1Index].lastValue ===
          undefined
        ) {
          burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType1Index].lastValue = 0;
        }
        burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType1Index].lastValue +=
          burnerSum.burnMeter1Value;
      }

      if (burnerSum.burnMaterial2) {
        if (burnerType2Index < 0) {
          // get the cost factor for burn material to get the unit
          const burnMaterial2CostFactor = burnerSum.burnMaterial2CostFactor;

          burnMaterialSumsByBurnerSteamComponentAndMaterial.push({
            burnerComponentId: burnerSum.burnerComponentId,
            steamBoiler: burnerSum.steamBoiler,
            steamBoilerId: burnerSum.steamBoilerId,
            currentValue: burnerSum.burnMeter2Value,
            lastValue: undefined,
            material: burnerSum.burnMaterial2,
            unit: burnMaterial2CostFactor ? burnMaterial2CostFactor.unit : '',
            num: 2
          });
        } else {
          if (
            burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType2Index].lastValue ===
            undefined
          ) {
            burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType2Index].lastValue = 0;
          }

          burnMaterialSumsByBurnerSteamComponentAndMaterial[burnerType2Index].lastValue +=
            burnerSum.burnMeter2Value;
        }
      }
    }
  }

  return burnMaterialSumsByBurnerSteamComponentAndMaterial.map(burnerItem => {
    const burnMaterialOption = formOptions.fuel.filter(
      options => options.value === burnerItem.material
    )[0];

    return (
      <ReportRow
        label={intl.get(messagesReport.reportBurnMaterialForBoilerWithName.id, {
          ...burnerItem,
          material: burnMaterialOption.label
        })}
        sum={burnerItem.currentValue}
        sumLast={burnerItem.lastValue}
        key={`report-row-burner-${burnerItem.burnerComponentId}-${burnerItem.steamBoilerId}-${burnerItem.num}`}
        unit={burnerItem.unit}
        round={true}
        digits={0}
        reportData={reportData}
        reportDataLastYear={reportDataLastYear}
      />
    );
  });
}

BurnerRows.propTypes = {
  reportData: PropTypes.any.isRequired,
  reportDataLastYear: PropTypes.any.isRequired
};

export default BurnerRows;
