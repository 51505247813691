import Number from 'components/Number';
import Text from 'components/Text';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function ReportRow({
  period,
  label,
  reportField,
  sum,
  sumLast,
  rowKey,
  unit,
  rowClass,
  round,
  digits,
  digitsDaily,
  digitsHourly,
  reportData,
  reportDataLastYear,
  steamPlantHasSteamMeter,
  alternativeText
}) {
  let showReportNumber = true;
  let showReportNumberLastYear = true;

  const reportRowKey = rowKey !== undefined ? rowKey : `report-row-${reportField}`;
  let labelText = '';
  let rowSum = 0;
  let rowSumLast;
  let sumDaily = 0;
  let sumHourly = 0;

  let firstDayTime = null;
  let firstDay = null;
  const today = moment();
  let dayCount = 30;

  if (period === 'currentYear') {
    for (let i = 0; i < reportData.length; i += 1) {
      const reportRow = reportData[i];
      if (firstDayTime === null) {
        firstDayTime = reportRow.date.getTime();
      } else if (reportRow.date.getTime() <= firstDayTime) {
        firstDayTime = reportRow.date.getTime();
      }
    }

    if (firstDayTime !== null) {
      firstDay = moment.unix(firstDayTime / 1000);
      dayCount = Math.abs(today.diff(firstDay, 'days')) + 1; // include first day
    } else {
      dayCount = 1;
    }
  }

  if (sum === undefined || sum === null) {
    for (let i = 0; i < reportData.length; i += 1) {
      const reportItem = reportData[i];
      rowSum += reportItem[reportField];
    }
  } else {
    rowSum = sum;
  }

  if (sumLast === undefined || sumLast === null) {
    for (let i = 0; i < reportDataLastYear.length; i += 1) {
      if (rowSumLast === undefined) {
        rowSumLast = 0;
      }
      const reportItemLast = reportDataLastYear[i];
      rowSumLast += reportItemLast[reportField];
    }
  } else {
    rowSumLast = sumLast;
  }

  // calculate daily basis, using either 30 as fixed number or the number of days from the first entry this year until today
  if (reportData.length > 0) {
    sumDaily = rowSum / dayCount;
    sumHourly = sumDaily / 24;
  }

  if (label === true) {
    labelText = intl.get(`report.row.${reportField}`);
  } else if (label.length > 0) {
    labelText = label;
  }

  const calculatePercentageDiff = (rowSum, rowSumLast) => {
    let percent = null;

    if (rowSum && rowSumLast) {
      try {
        percent = ((rowSum - rowSumLast) / rowSumLast) * 100;
      } catch {
        percent = null;
      }
    }
    return percent;
  };

  const percentageDiff = calculatePercentageDiff(rowSum, rowSumLast);

  if (
    (reportField === 'thermicGasLoss' ||
      reportField === 'energyThermicLoss' ||
      reportField === 'energyThermicLossValue') &&
    !steamPlantHasSteamMeter
  ) {
    showReportNumber = false;
    showReportNumberLastYear = false;
  }

  if (
    (reportField === 'productionLoss' ||
      reportField === 'energyProductionLoss' ||
      reportField === 'energyProductionLossValue') &&
    rowSum <= 0
  ) {
    showReportNumber = false;
  }

  if (
    (reportField === 'productionLoss' ||
      reportField === 'energyProductionLoss' ||
      reportField === 'energyProductionLossValue') &&
    rowSumLast <= 0
  ) {
    showReportNumberLastYear = false;
  }

  return (
    <tr key={reportRowKey} className={rowClass !== undefined ? rowClass : null}>
      <td className="padded">{labelText}</td>
      <td className="center">{unit}</td>
      <td className={showReportNumberLastYear ? '' : 'center'}>
        {showReportNumberLastYear ? (
          <Number number={rowSumLast} digits={digits} round={round} size="sm" align="center" />
        ) : (
          <Text size="sm" inline>
            {alternativeText}
          </Text>
        )}
      </td>
      <td className={showReportNumber ? '' : 'center'}>
        {showReportNumber ? (
          <Number number={rowSum} digits={digits} round={round} size="sm" align="center" />
        ) : (
          <Text size="sm" inline>
            {alternativeText}
          </Text>
        )}
      </td>
      <td className={showReportNumber ? '' : 'center'}>
        {showReportNumber ? (
          <Number number={sumDaily} digits={digitsDaily} round={round} size="sm" align="center" />
        ) : (
          <Text size="sm" inline>
            {alternativeText}
          </Text>
        )}
      </td>
      <td className={showReportNumber ? '' : 'center'}>
        {showReportNumber ? (
          <Number number={sumHourly} digits={digitsHourly} round={round} size="sm" align="center" />
        ) : (
          <Text size="sm" inline>
            {alternativeText}
          </Text>
        )}
      </td>
      <td className={showReportNumber ? '' : 'center'}>
        {showReportNumber && (
          <Text size="sm" align="center">
            <Text size="sm" inline>
              {percentageDiff !== null && percentageDiff > 0 ? '+' : null}
              {percentageDiff !== null && percentageDiff < 0 ? '-' : null}
            </Text>
            <Number
              number={Math.abs(percentageDiff)}
              digits={Math.abs(percentageDiff) < 10 ? 1 : 0}
              round={true}
              suffix="%"
              size="sm"
              inline
            />
          </Text>
        )}
      </td>
    </tr>
  );
}
ReportRow.propTypes = {
  period: PropTypes.oneOf(['last30', 'currentYear']),
  reportData: PropTypes.any,
  reportDataLastYear: PropTypes.any,
  reportField: PropTypes.any,
  label: PropTypes.any,
  rowKey: PropTypes.any,
  sum: PropTypes.any,
  sumLast: PropTypes.any,
  unit: PropTypes.any,
  rowClass: PropTypes.string,
  round: PropTypes.bool,
  digits: PropTypes.number,
  digitsDaily: PropTypes.number,
  digitsHourly: PropTypes.number,
  steamPlantHasSteamMeter: PropTypes.bool,
  alternativeText: PropTypes.string
};

ReportRow.defaultProps = {
  period: 'currentYear',
  reportData: [],
  reportDataLastYear: [],
  label: true,
  reportField: undefined,
  rowKey: undefined,
  sum: undefined,
  sumLast: undefined,
  rowClass: undefined,
  unit: '',
  round: false,
  digits: 2,
  digitsDaily: 0,
  digitsHourly: 1,
  steamPlantHasSteamMeter: false,
  alternativeText: ''
};

export default ReportRow;
