import ParameterService from 'services/ParameterService';

function useComponentAlwaysOnParams() {
  const isParamAlwaysOn = name => {
    const param = ParameterService.allParameter.filter(p => p.name === name)[0];

    if (param) {
      return param.alwaysOn;
    }

    return false;
  };

  const getAlwaysOnParams = type => {
    const componentParams = ParameterService.getParametersByComponent(type);

    const alwaysOnWaterParams = componentParams.filter(
      p => p.alwaysOn === true && p.category === 'waterValues'
    );
    const alwaysOnOperatingParams = componentParams.filter(
      p => p.alwaysOn === true && p.category === 'operatingValues'
    );
    const alwaysOnMeterParams = componentParams.filter(
      p => p.alwaysOn === true && p.category === 'meterValues'
    );

    // Perform some mapping since we dont need all data from the params
    const waterValues = alwaysOnWaterParams.map(param => {
      return {
        active: true,
        name: param.name,
        unit: param.unit
      };
    });

    const operatingValues = alwaysOnOperatingParams.map(param => {
      return {
        active: true,
        name: param.name,
        unit: param.unit
      };
    });

    const meterValues = alwaysOnMeterParams.map(param => {
      return {
        active: true,
        name: param.name,
        unit: param.unit
      };
    });

    return {
      waterValues,
      operatingValues,
      meterValues
    };
  };

  return {
    isParamAlwaysOn,
    getAlwaysOnParams
  };
}

export default useComponentAlwaysOnParams;
