import Box from 'components/Box';
import Button from 'components/Button';
import Popup from 'components/Popup';
import useFormOptions from 'hooks/useFormOptions';
import { ReactComponent as Edit } from 'images/EditAlt.svg';
import { ReactComponent as Remove } from 'images/Remove.svg';
import { messagesCommon, messagesServicePartnerDelete } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import FirebaseService from 'services/FirebaseService';

function ServicePartnerList({ servicePartners, openPanel }) {
  const history = useHistory();
  const formOptions = useFormOptions();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [servicePartner, setServicePartner] = useState();

  const onShowDeletePopup = servicePartner => {
    setShowDeletePopup(true);
    setServicePartner(servicePartner);
  };

  const onHideDeletePopup = () => setShowDeletePopup(false);

  const onDeleteServicePartner = async () => {
    const servicePartnerData = { ...servicePartner.data, isDeleted: true };

    try {
      await FirebaseService.updateServicePartner(servicePartner.id, servicePartnerData);
      setShowDeletePopup(false);
    } catch (e) {
      console.log('Service Partner could not be deleted');
      console.log(e);
      setShowDeletePopup(false);
    }
  };

  const openDetails = servicePartner => {
    history.push(`/service-partner/${servicePartner.id}`);
  };

  const renderRow = servicePartner => {
    const servicePartnerData = servicePartner.data;

    return (
      <tr key={servicePartner.id}>
        <td>{servicePartnerData.name}</td>
        <td>{servicePartnerData.city}</td>
        <td>{servicePartnerData.zip}</td>
        <td>{servicePartnerData.country}</td>
        <td>
          {
            formOptions.businessSP.filter(option => option.value === servicePartnerData.business)[0]
              .label
          }
        </td>
        <td style={{ textAlign: 'right' }}>
          <Box display="inline" position="relative" bottom={1}>
            <Button
              text={intl.get(messagesCommon.config.id)}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => openDetails(servicePartner)}
            />
          </Box>
          <Edit
            onClick={() => openPanel(servicePartner)}
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              marginRight: '15px',
              marginLeft: '15px'
            }}
          />
          <Remove onClick={() => onShowDeletePopup(servicePartner)} style={{ cursor: 'pointer' }} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{intl.get(messagesCommon.companyName.id)}</th>
            <th>{intl.get(messagesCommon.city.id)}</th>
            <th>{intl.get(messagesCommon.zip.id)}</th>
            <th>{intl.get(messagesCommon.country.id)}</th>
            <th>{intl.get(messagesCommon.business.id)}</th>
            <th width="250" />
          </tr>
        </thead>
        <tbody>{servicePartners.map(s => renderRow(s))}</tbody>
      </table>
      {showDeletePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesServicePartnerDelete.headline.id)}
          message={intl.get(messagesServicePartnerDelete.text.id)}
          confirmColor="red"
          confirmText={intl.get(messagesServicePartnerDelete.action.id)}
          confirmAction={onDeleteServicePartner}
          abortAction={onHideDeletePopup}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      )}
    </>
  );
}

ServicePartnerList.propTypes = {
  servicePartners: PropTypes.arrayOf(PropTypes.object).isRequired,
  openPanel: PropTypes.func
};

ServicePartnerList.defaultProps = {
  openPanel: null
};

export default ServicePartnerList;
