import Box from 'components/Box';
import Heading from 'components/Heading';
import Number from 'components/Number';
import Text from 'components/Text';
import { ReactComponent as IconComment } from 'images/Comment.svg';
import { messagesCommon } from 'messages/messages';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import FloatyBox from 'react-floatybox';
import intl from 'react-intl-universal';
import styled from 'styled-components';

const StyledRow = styled.tr`
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
    td {
      background-color: ${props => props.theme.colors.blue};
      color: ${props => props.theme.colors.white};
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }

    * {
      color: ${props => props.theme.colors.white};
    }

    svg {
      path {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`;

const StyledLink = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

function ValueRow({
  onRowClick,
  onRowViewChangesClick,
  measurementRef,
  components,
  activeComponentValues,
  valueFilter
}) {
  const noteBubble = measurement => {
    return (
      <Box background="white" width={360} paddingY={5} paddingX={7}>
        {measurement.updatedByTag !== undefined && (
          <>
            <Heading level="1" size="base">
              {intl.get(messagesCommon.updated.id)}
            </Heading>

            <Box marginTop={5} marginBottom={measurement.note ? 5 : 0}>
              <Text size="sm">
                {intl.get(messagesCommon.updatedAtBy.id, {
                  time: moment(measurement.updatedAt.toDate()).format('DD.MM.YYYY HH:mm'),
                  by: measurement.updatedByTag
                })}
              </Text>
              <Box marginTop={3} onClick={() => onRowViewChangesClick(measurementRef)}>
                <StyledLink size="sm" color="blue">
                  &rarr; {intl.get(messagesCommon.viewUpdates.id)}
                </StyledLink>
              </Box>
            </Box>
          </>
        )}

        {measurement.note && (
          <>
            <Heading level="1" size="base">
              {intl.get(messagesCommon.comment.id)}
            </Heading>

            <Box marginTop={5}>
              <Text whiteSpace="pre-line" size="sm">
                {measurement.note}
              </Text>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const measurement = measurementRef.data;
  const measurementLast = measurementRef.lastMeasurement;

  // comment filter
  if (valueFilter.withComments && !(measurement.note || measurement.updatedByTag)) {
    return null;
  }

  const activeMeasurementData = components.map(componentRef => {
    const activeValues = activeComponentValues[componentRef.id];
    const valueTypes = ['waterValues', 'operatingValues', 'meterValues'].filter(
      type => valueFilter[type]
    );

    const componentMeasureData = measurement.measuredValues.filter(
      measured => measured.componentId === componentRef.id
    )[0];

    let componentMeasureLastData = null;

    if (measurementLast) {
      componentMeasureLastData = measurementLast.measuredValues.filter(
        measured => measured.componentId === componentRef.id
      )[0];
    }

    const activeValueColumns = valueTypes.map(type => {
      const valuesOfType = activeValues[type];
      return valuesOfType.map(value => {
        let measuredValue = null;

        if (componentMeasureData && componentMeasureData[type]) {
          [measuredValue] = componentMeasureData[type].filter(mValue => mValue.name === value.name);
        }

        if (type === 'meterValues') {
          let lastMeasuredValue = null;
          if (measurementLast) {
            try {
              [lastMeasuredValue] = componentMeasureLastData[type].filter(
                mValue => mValue.name === value.name
              );
            } catch (e) {
              lastMeasuredValue = null;
            }
          }

          return (
            <td key={`measured-row-value-${value.name}-${measurementRef.id}`}>
              <Box onClick={onRowClick}>
                {measuredValue ? (
                  <>
                    <Number number={measuredValue.value} size="sm" inline marginRight={2} />
                    {lastMeasuredValue && (
                      <Text size="xs" inline>
                        (
                        <Number
                          number={measuredValue.value - lastMeasuredValue.value}
                          size="xs"
                          inline
                        />
                        )
                      </Text>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </Box>
            </td>
          );
        }

        return (
          <td key={`measured-row-value-${value.name}-${measurementRef.id}`}>
            <Box onClick={onRowClick}>
              {measuredValue ? <Number number={measuredValue.value} size="sm" inline /> : '-'}
            </Box>
          </td>
        );
      });
    });

    return activeValueColumns;
  });

  const flatColumns = [].concat(...activeMeasurementData);

  const commentOrUpdate = measurement.note || measurement.updatedByTag !== undefined;

  return (
    <StyledRow key={`measurement-value-row-${measurementRef.id}`}>
      <td key={`measurement-row-date-${measurementRef.id}`}>
        <Box onClick={onRowClick}>
          {moment(measurement.createdAt.toDate()).format('DD.MM.YY HH:mm')}
        </Box>
      </td>
      <td key={`measurement-row-tag-${measurementRef.id}`}>
        <Box onClick={onRowClick}>{measurement.createdByTag}</Box>
      </td>
      <td key={`measurement-row-comment-${measurementRef.id}`}>
        {commentOrUpdate && (
          <FloatyBox
            bubble={() => noteBubble(measurement)}
            open="click"
            side="left"
            align="center"
            alignInner="center"
          >
            <IconComment />
          </FloatyBox>
        )}
      </td>
      {flatColumns}
    </StyledRow>
  );
}

ValueRow.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  onRowViewChangesClick: PropTypes.func.isRequired,
  measurementRef: PropTypes.any.isRequired,
  valueFilter: PropTypes.any.isRequired,
  components: PropTypes.any,
  activeComponentValues: PropTypes.any
};

ValueRow.defaultProps = {
  components: [],
  activeComponentValues: []
};

export default ValueRow;
