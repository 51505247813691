import { sortParameter } from 'utils/SortParameters';
import FirebaseService from './FirebaseService';

const ParameterService = {
  allParameter: [],

  init: async () => {
    const paramSnapshot = await FirebaseService.queryParameters();
    const docs = [];

    paramSnapshot.forEach(doc => {
      // id and ref not needed for parameters since they will not be updated/linked when used outside the admin tool
      docs.push(doc.data());
    });

    ParameterService.allParameter = docs;
  },

  update: paramterUpdate => {
    const mapped = paramterUpdate.map(p => p.data);
    ParameterService.allParameter = mapped;
  },

  /**
   * Get parameter by name
   * @param {*} name parameter name
   * @returns object | undefined
   */
  getParameterByName: name => {
    return ParameterService.allParameter.filter(p => p.name === name)[0];
  },

  /**
   * Get Parameter By Type
   * @param {*} type - waterValues, operationalValues, meterValues
   * @param {*} componentType - CUSTOM, DOSING, BURNER, ...
   * @param {bool} onlyActive - if true only active parameters are returned (default true)
   */
  getParametersByTypeAndComponent: (type, componentType, onlyActive = true) => {
    const parameters = ParameterService.allParameter.filter(p => {
      if (p.category === type && p.components.includes(componentType)) {
        if (onlyActive === true) {
          if (p.isDeleted === undefined) {
            return p;
          }
        } else {
          return p;
        }
      }
    });

    return sortParameter(parameters);
  },

  /**
   * Get Parameter by component
   * @param {*} componentType - CUSTOM, DOSING, BURNER, ...
   * @param {bool} onlyActive - if true only active parameters are returned (default true)
   * @returns array
   */
  getParametersByComponent: (componentType, onlyActive = true) => {
    const parameters = ParameterService.allParameter.filter(p => {
      if (p.components.includes(componentType)) {
        if (onlyActive === true) {
          if (p.isDeleted === undefined) {
            return p;
          }
        } else {
          return p;
        }
      }
    });

    return sortParameter(parameters);
  },

  /**
   * Get Parameter By Type
   * @param {*} type - waterValues, operationalValues, meterValues
   * @param {bool} onlyActive - if true only active parameters are returned (default true)
   */
  getParametersByType: (type, onlyActive = true) => {
    const parameters = ParameterService.allParameter.filter(p => {
      if (p.category === type) {
        if (onlyActive === true) {
          if (p.isDeleted === undefined) {
            return p;
          }
        } else {
          return p;
        }
      }
    });

    return sortParameter(parameters);
  },

  /**
   * Get all water parameters
   * @param {*} onlyActive
   * @returns array
   */
  getWaterParameter: (onlyActive = true) => {
    return ParameterService.getParametersByType('waterValues', onlyActive);
  },

  /**
   * Get all water parameters
   * @param {*} onlyActive
   * @returns array
   */
  getOperationalParameter: (onlyActive = true) => {
    return ParameterService.getParametersByType('operatingValues', onlyActive);
  },

  /**
   * Get all water parameters
   * @param {*} onlyActive
   * @returns array
   */
  getMeterParameter: (onlyActive = true) => {
    return ParameterService.getParametersByType('meterValues', onlyActive);
  },

  /**
   * To match the previous config structure we map the parameter arrays to objects with name keys
   * @returns object
   */
  getParameterForReport: () => {
    const waterParams = ParameterService.getWaterParameter(false);
    const operationalParams = ParameterService.getOperationalParameter(false);
    const meterParams = ParameterService.getMeterParameter(false);

    // map arrays to match previous config object structure
    const waterReportParams = {};
    const opReportParams = {};
    const meterReportParams = {};

    for (let i = 0; i < operationalParams.length; i += 1) {
      const p = operationalParams[i];
      opReportParams[p.name] = p;
    }

    for (let i = 0; i < waterParams.length; i += 1) {
      const p = waterParams[i];
      waterReportParams[p.name] = p;
    }

    for (let i = 0; i < meterParams.length; i += 1) {
      const p = meterParams[i];
      meterReportParams[p.name] = p;
    }

    return {
      meterParameter: meterReportParams,
      operationalParameter: opReportParams,
      waterValues: waterReportParams
    };
  }
};

export default ParameterService;
