import Box from 'components/Box';
import NumberSvg from 'components/NumberSvg';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import styled from 'styled-components';
import SteamCostOverTimeTooltip from './SteamCostOverTimeTooltip';

const StyledChartHolder = styled(Box)`
  svg {
    overflow: visible;
  }
`;

function SteamCostOverTimeBarChart({ height, data, dataKeys }) {
  const barLabel = (dataKey, labelData) => {
    const prevYearText = intl.get(messagesCommon.previousYearShort.id);

    if (dataKey === prevYearText) {
      return null;
    }

    return (
      <NumberSvg
        x={labelData.x + labelData.width / 2}
        y={labelData.y - 10}
        fill="#051014"
        textAnchor="middle"
        dy={6}
        number={labelData.value}
        digits={0}
        round={true}
      />
    );
  };

  const prevYearText = intl.get(messagesCommon.previousYearShort.id);

  return (
    <StyledChartHolder style={{ width: '100%', height }}>
      <ResponsiveContainer width="100%">
        <BarChart data={data} maxBarSize={20} barGap="-45%">
          <XAxis dataKey="name" />
          <Tooltip cursor={false} content={<SteamCostOverTimeTooltip />} />
          <Legend />
          {dataKeys.map(dataKey => (
            <Bar
              label={props => barLabel(dataKey, props)}
              key={dataKey}
              dataKey={dataKey}
              fill={dataKey === prevYearText ? '#c7c7c7' : '#163253'}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </StyledChartHolder>
  );
}

// <CartesianGrid strokeDasharray="1" />

SteamCostOverTimeBarChart.propTypes = {
  height: PropTypes.number,
  data: PropTypes.array.isRequired,
  dataKeys: PropTypes.array.isRequired
};

SteamCostOverTimeBarChart.defaultProps = {
  height: 300
};

export default SteamCostOverTimeBarChart;
