import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import Label from 'components/Label';
import Select from 'components/Select';
import Text from 'components/Text';
import TextField from 'components/TextField';
import Toggle from 'components/Toggle';
import { ParamCategoryTypes, REQUIRED_FIELDS } from 'constants/Form';
import useFormOptions from 'hooks/useFormOptions';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function ParameterForm({ parameter, onChange, isCreateMode, invalidName }) {
  const formOptions = useFormOptions();
  const formConfig = REQUIRED_FIELDS.parameter;
  const formValidator = useFormValidation();

  const updateComponentList = (checked, component) => {
    const selected = parameter && parameter.components ? parameter.components : [];

    let updated = [...selected];

    if (checked) {
      updated.push(component);
    } else {
      updated = updated.filter(c => c !== component);
    }

    onChange('components', updated);
  };

  const renderComponentCheckbox = componentOption => {
    const selected = parameter && parameter.components ? parameter.components : [];
    const isSelected = selected.filter(c => c === componentOption.value).length > 0;

    return (
      <Box
        key={`panel-param-component-${componentOption.value}`}
        alignItems="center"
        direction="row"
        display="flex"
      >
        <Label htmlFor={`panel-param-component-${componentOption.value}`}>
          <Checkbox
            id={`panel-param-component-${componentOption.value}`}
            checked={isSelected}
            onChange={e => updateComponentList(e.target.checked, componentOption.value)}
          />
          <span style={{ marginLeft: 8 }}>{componentOption.label}</span>
        </Label>
      </Box>
    );
  };

  const handleOnChange = (key, value) => {
    onChange(key, value);
  };

  return (
    <>
      <Box marginY={3}>
        <Label
          htmlFor="name"
          required={formValidator.isFieldRequired(formConfig, 'name')}
          valid={formValidator.isFieldValid(formConfig, 'name', parameter)}
        >
          {intl.get(messagesCommon.paramInternal.id)}
        </Label>
        <TextField
          disabled={!isCreateMode}
          id="name"
          type="text"
          placeholder={intl.get(messagesCommon.paramInternal.id)}
          value={parameter?.name}
          onChange={e => handleOnChange('name', e.currentTarget.value)}
        />
        {invalidName ? (
          <Text color="red">{intl.get(messagesCommon.paramNameDuplicateError.id)}</Text>
        ) : null}
      </Box>

      <Box marginY={3}>
        <Label
          htmlFor="sorting"
          required={formValidator.isFieldRequired(formConfig, 'sorting')}
          valid={formValidator.isFieldValid(formConfig, 'sorting', parameter)}
        >
          {intl.get(messagesCommon.paramSorting.id)}
        </Label>
        <TextField
          id="sorting"
          type="number"
          placeholder={intl.get(messagesCommon.paramSorting.id)}
          value={parameter?.sorting}
          onChange={e => handleOnChange('sorting', e.currentTarget.value)}
        />
      </Box>

      <Box marginY={3}>
        <Label
          htmlFor="labelDE"
          required={formValidator.isFieldRequired(formConfig, 'labelDE')}
          valid={formValidator.isFieldValid(formConfig, 'labelDE', parameter)}
        >
          {`${intl.get(messagesCommon.paramName.id)} DE`}
        </Label>
        <TextField
          id="labelDE"
          type="text"
          placeholder={`${intl.get(messagesCommon.paramName.id)} DE`}
          value={parameter?.labelDE}
          onChange={e => handleOnChange('labelDE', e.currentTarget.value)}
        />
      </Box>

      <Box marginY={3}>
        <Label
          htmlFor="labelEN"
          required={formValidator.isFieldRequired(formConfig, 'labelEN')}
          valid={formValidator.isFieldValid(formConfig, 'labelEN', parameter)}
        >
          {`${intl.get(messagesCommon.paramName.id)} EN`}
        </Label>
        <TextField
          id="labelEN"
          type="text"
          placeholder={`${intl.get(messagesCommon.paramName.id)} EN`}
          value={parameter?.labelEN}
          onChange={e => handleOnChange('labelEN', e.currentTarget.value)}
        />
      </Box>

      <Box marginY={3}>
        <Label
          htmlFor="unit"
          required={formValidator.isFieldRequired(formConfig, 'unit')}
          valid={formValidator.isFieldValid(formConfig, 'unit', parameter)}
        >
          {intl.get(messagesCommon.paramUnit.id)}
        </Label>
        <TextField
          id="unit"
          type="text"
          placeholder={intl.get(messagesCommon.paramUnit.id)}
          value={parameter?.unit}
          onChange={e => handleOnChange('unit', e.currentTarget.value)}
        />
      </Box>

      <Box marginY={3}>
        <Label htmlFor="alwaysOn">{intl.get(messagesCommon.paramAlwaysOn.id)}</Label>
        <Toggle enabled={parameter?.alwaysOn} onChange={enabled => onChange('alwaysOn', enabled)} />
      </Box>

      <Box marginY={3}>
        <Label htmlFor="minValue">{intl.get(messagesCommon.paramMinVal.id)}</Label>
        <Toggle enabled={parameter?.minValue} onChange={enabled => onChange('minValue', enabled)} />
      </Box>

      <Box marginY={3}>
        <Label htmlFor="maxValue">{intl.get(messagesCommon.paramMaxVal.id)}</Label>
        <Toggle enabled={parameter?.maxValue} onChange={enabled => onChange('maxValue', enabled)} />
      </Box>

      <Box marginY={3}>
        <Label
          htmlFor="category"
          required={formValidator.isFieldRequired(formConfig, 'category')}
          valid={formValidator.isFieldValid(formConfig, 'category', parameter)}
        >
          {intl.get(messagesCommon.paramCategory.id)}
        </Label>
        <Select
          id="category"
          options={formOptions.paramCategory}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={parameter && parameter.category ? parameter.category : ''}
          onChange={e => onChange('category', e.value)}
        />
      </Box>

      <Box marginTop={7}>
        <Label
          htmlFor="panel-param-components"
          required={formValidator.isFieldRequired(formConfig, 'components')}
          valid={formValidator.isFieldValid(formConfig, 'components', parameter)}
        >
          {intl.get(messagesCommon.paramComponents.id)}
        </Label>
        {formOptions.paramComponents.map(option => renderComponentCheckbox(option))}
      </Box>

      <Box marginY={3}>
        <Label
          htmlFor="appFormElement"
          required={formValidator.isFieldRequired(formConfig, 'appFormElement')}
          valid={formValidator.isFieldValid(formConfig, 'appFormElement', parameter)}
        >
          {intl.get(messagesCommon.paramFormElement.id)}
        </Label>
        <Select
          id="appFormElement"
          options={formOptions.paramFormElement}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={parameter && parameter.appFormElement ? parameter.appFormElement : ''}
          onChange={e => onChange('appFormElement', e.value)}
        />
      </Box>

      {parameter && parameter.appFormElement === ParamCategoryTypes.PlusMinus ? (
        <Box marginY={3}>
          <Label
            htmlFor="appFormSteps"
            required={formValidator.isFieldRequired(formConfig, 'appFormSteps', parameter)}
            valid={formValidator.isFieldValid(formConfig, 'appFormSteps', parameter)}
          >
            {intl.get(messagesCommon.paramFormElementSteps.id)}
          </Label>
          <TextField
            id="appFormSteps"
            type="number"
            placeholder={intl.get(messagesCommon.paramFormElementSteps.id)}
            value={parameter?.appFormSteps}
            onChange={e => handleOnChange('appFormSteps', e.currentTarget.value)}
          />
        </Box>
      ) : null}

      {parameter && parameter.appFormElement === ParamCategoryTypes.Spinner ? (
        <Box marginY={3}>
          <Label
            htmlFor="appNumberRange"
            required={formValidator.isFieldRequired(formConfig, 'appNumberRange', parameter)}
            valid={formValidator.isFieldValid(formConfig, 'appNumberRange', parameter)}
          >
            {intl.get(messagesCommon.paramFormElementRange.id)}
          </Label>
          <TextField
            id="appNumberRange"
            type="number"
            placeholder={intl.get(messagesCommon.paramFormElementRange.id)}
            value={parameter?.appNumberRange}
            onChange={e => handleOnChange('appNumberRange', e.currentTarget.value)}
          />
        </Box>
      ) : null}
    </>
  );
}

ParameterForm.propTypes = {
  parameter: PropTypes.any,
  invalidName: PropTypes.bool,
  isCreateMode: PropTypes.bool,
  onChange: PropTypes.func
};

ParameterForm.defaultProps = {
  parameter: null,
  invalidName: false,
  isCreateMode: false,
  onChange: () => {}
};

export default ParameterForm;
