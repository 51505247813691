import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const StyledIcon = styled.svg`
  fill: none;
  stroke: ${props => props.theme.colors.white};
  stroke-width: 2px;
`;

const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  background: ${props => props.theme.colors.blue};
  border-radius: 3px;
  cursor: pointer;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};

  ${StyledIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

function Checkbox({ checked, width, height, ...props }) {
  return (
    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
      <StyledHiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox width={width} height={height} checked={checked}>
        <StyledIcon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </StyledIcon>
      </StyledCheckbox>
    </div>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number
};

Checkbox.defaultProps = {
  checked: false,
  width: 18,
  height: 18
};

export default Checkbox;
