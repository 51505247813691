import Box from 'components/Box';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import styled from 'styled-components';

// Style overwrite since this is not configureable
const StyledGaugeChartHolder = styled.div`
  svg.speedometer .current-value,
  svg.speedometer .segment-value {
    font-weight: 400 !important;
  }
`;

function GaugeChart({
  height,
  segments,
  segmentColors,
  value,
  maxValue,
  minValue,
  currentValueText
}) {
  return (
    <StyledGaugeChartHolder>
      <Box width="100%" height={height}>
        <ReactSpeedometer
          fluidWidth={true}
          value={value}
          maxValue={maxValue}
          minValue={minValue}
          customSegmentStops={segments}
          needleColor="black"
          textColor="black"
          ringWidth={60}
          valueTextFontSize="30px"
          needleHeightRatio={0.7}
          paddingVertical={25}
          segmentColors={segmentColors}
          currentValueText={currentValueText}
        />
      </Box>
    </StyledGaugeChartHolder>
  );
}

GaugeChart.propTypes = {
  height: PropTypes.number.isRequired,
  segments: PropTypes.array.isRequired,
  segmentColors: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  currentValueText: PropTypes.string.isRequired
};

export default GaugeChart;
