import Popup from 'components/Popup';
import RoleText from 'components/RoleText';
import { ReactComponent as Edit } from 'images/EditAlt.svg';
import { ReactComponent as Remove } from 'images/Remove.svg';
import { messagesCommon, messagesUserDelete } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function UserList({ users, openPanel }) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [user, setUser] = useState();

  const onShowDeletePopup = user => {
    setShowDeletePopup(true);
    setUser(user);
  };

  const onHideDeletePopup = () => setShowDeletePopup(false);

  const onDeleteUser = async () => {
    const userData = { ...user.data, isDeleted: true };

    try {
      await FirebaseService.updateUser(user.id, userData);
      setShowDeletePopup(false);
    } catch (e) {
      console.log('User could not be deleted');
      console.log(e);
      setShowDeletePopup(false);
    }
  };

  const renderRow = user => {
    const userData = user.data;
    const nameString = `${userData.firstname} ${userData.lastname}`;
    return (
      <tr key={user.id}>
        <td>{nameString}</td>
        <td>
          <RoleText role={userData.role} />
        </td>
        <td>{userData.email}</td>
        <td>{userData.tag}</td>
        <td>
          <Edit
            onClick={() => openPanel('user', user)}
            style={{ cursor: 'pointer', display: 'inline-block', marginRight: '15px' }}
          />
          {AppStateService.user.id !== user.id && (
            <Remove onClick={() => onShowDeletePopup(user)} style={{ cursor: 'pointer' }} />
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{intl.get(messagesCommon.name.id)}</th>
            <th>{intl.get(messagesCommon.emailAddress.id)}</th>
            <th>{intl.get(messagesCommon.role.id)}</th>
            <th>{intl.get(messagesCommon.abbreviation.id)}</th>
            <th width="100" />
          </tr>
        </thead>
        <tbody>{users.map(u => renderRow(u))}</tbody>
      </table>
      {showDeletePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesUserDelete.headline.id)}
          message={intl.get(messagesUserDelete.text.id)}
          confirmColor="red"
          confirmText={intl.get(messagesUserDelete.action.id)}
          confirmAction={onDeleteUser}
          abortAction={onHideDeletePopup}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      )}
    </>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  openPanel: PropTypes.func
};

UserList.defaultProps = {
  openPanel: null
};

export default UserList;
