import Box from 'components/Box';
import Button from 'components/Button';
import Label from 'components/Label';
import Link from 'components/Link';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextField from 'components/TextField';
import Logo from 'images/diwaso_logo_claim.svg';
import { messagesCommon, messagesLogin, messagesPlaceholder } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function Login() {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginInProgress, setLoginInProgress] = useState(false);

  useEffect(() => {
    if (AppStateService.hasUser()) {
      history.push('/');
    }
  }, [history]);

  const onChangeEmail = event => setEmail(event.currentTarget.value);
  const onChangePassword = event => setPassword(event.currentTarget.value);

  const onSubmit = async event => {
    setError(false);
    event.preventDefault();
    try {
      setLoginInProgress(true);
      const result = await FirebaseService.authenticate(email, password);

      if (result !== null) {
        const user = await FirebaseService.loadUserData(result.user.email);

        if (user.data.isDeleted) {
          console.log('user is deleted !');
          AppStateService.removeUser();
          FirebaseService.signout();
          setLoginInProgress(false);
          setError(true);
        }

        AppStateService.setUser(user);

        // for regular customer users => load customer and location data
        if (!AppStateService.isSuperAdmin() && !AppStateService.isServicePartner()) {
          const customer = await FirebaseService.loadCustomerOfUser(user.data);
          const location = await FirebaseService.loadDefaultLocationOfUser(user.data);

          AppStateService.setCustomer(customer);
          AppStateService.setLocation(location);
        }

        setLoginInProgress(false);

        if (AppStateService.isSuperAdmin()) {
          history.push('/customer-overview');
        } else if (AppStateService.isServicePartner()) {
          history.push('/change-location');
        } else {
          history.push('/');
        }
      } else {
        setLoginInProgress(false);
        setError(true);
      }
    } catch (e) {
      setLoginInProgress(false);
      setError(true);
    }
  };

  return (
    <Box width="100%" height="100%" display="flex" alignItems justify="center">
      <Box background="white" width={508} height={565} padding={7} noElevation border>
        <Box display="flex" justify="center" marginTop={3} marginBottom={12}>
          <img src={Logo} alt="diwaso" width="120px" />
        </Box>

        <Box>
          {loginInProgress ? (
            <Spinner />
          ) : (
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
              <Box>
                <Label htmlFor="email">{intl.get(messagesCommon.emailAddress.id)}</Label>
                <TextField
                  id="email"
                  type="email"
                  placeholder={intl.get(messagesPlaceholder.emailAddress.id)}
                  value={email}
                  onChange={onChangeEmail}
                />
              </Box>

              <Box marginTop={7}>
                <Label htmlFor="password">{intl.get(messagesCommon.password.id)}</Label>
                <TextField
                  id="password"
                  type="password"
                  placeholder={intl.get(messagesPlaceholder.password.id)}
                  value={password}
                  onChange={onChangePassword}
                />
              </Box>

              {error && (
                <Box display="flex" justify="center" marginTop={7}>
                  <Text color="red">{intl.get(messagesLogin.wrongData.id)}</Text>
                </Box>
              )}

              <Box display="flex" justify="center" marginTop={7}>
                <Button
                  disabled={!email || !password}
                  width={230}
                  text={intl.get(messagesLogin.button.id)}
                  inline
                  textSize="sm"
                  type="submit"
                />
              </Box>

              <Box display="flex" justify="center" marginTop={3}>
                <Link href="/reset-password" routerLink>
                  <Text size="sm">{intl.get(messagesLogin.forgotPassword.id)}</Text>
                </Link>
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </Box>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({ pathname: PropTypes.string })
  }).isRequired
};

export default Login;
