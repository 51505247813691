import Box from 'components/Box';
import Panel from 'components/Panel';
import TabList from 'components/TabList';
import { CostFactorTemplates } from 'constants/CostFactor';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon, messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import LocationConfigFormEdit from './LocationConfigFormEdit';
import LocationGeneralFormEdit from './LocationGeneralFormEdit';

function PanelLocation({ location, onClose }) {
  const formValidator = useFormValidation();
  const [tabListIndex, setTabListIndex] = useState(0);
  const [locationData, setLocationData] = useState(location && location.data);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isCreateMode] = useState(!location);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const validData = formValidator.validateLocation(locationData);
    setValid(validData);
  }, [locationData, formValidator]);

  const handleFormFieldChange = (key, value) => {
    const data = { ...locationData };
    data[key] = value;
    setLocationData(data);
  };

  const save = async () => {
    try {
      const data = { ...locationData };
      const id = location ? location.id : null;
      setSaveInProgress(true);

      if (!id) {
        data.customer = AppStateService.customer.ref;
        data.isDeleted = false;
        data.thermicGasLoss = data.thermicGasLoss ? data.thermicGasLoss : false;
      }

      const result = await FirebaseService.updateLocation(id, data);

      // create needed cost factors for new location
      if (!id) {
        // this does not respect async await
        // but its probably not needed to wait for that calls here anyway
        CostFactorTemplates.map(costFactorTemplate => {
          FirebaseService.updateCostFactor(null, { ...costFactorTemplate, location: result });
          return true;
        });
      }

      setSaveInProgress(false);
      onClose();
    } catch (e) {
      console.log('Location could not be inserted or updated');
      console.log(e);
      setSaveInProgress(false);
    }
  };

  const handleTabListItemChange = ({ activeIndex }) => {
    setTabListIndex(activeIndex);
  };

  const renderTabContentByIndex = () => {
    if (tabListIndex === 0) {
      return <LocationGeneralFormEdit location={locationData} onChange={handleFormFieldChange} />;
    }

    return <LocationConfigFormEdit location={locationData} onChange={handleFormFieldChange} />;
  };

  return (
    <Panel
      title={
        isCreateMode
          ? intl.get(messagesCrud.locationAdd.id)
          : intl.get(messagesCrud.locationEdit.id)
      }
      buttonPrimaryAction={save}
      buttonPrimaryDisabled={!valid}
      onClose={onClose}
      showSpinner={saveInProgress}
      isOpen
    >
      <Box marginTop={7}>
        <TabList
          width={500}
          variant="minimal"
          items={[
            { index: 0, title: intl.get(messagesCommon.general.id) },
            { index: 1, title: intl.get(messagesCommon.config.id) }
          ]}
          selectedItemIndex={tabListIndex}
          onChange={handleTabListItemChange}
        />
      </Box>

      <Box marginTop={5}>{renderTabContentByIndex()}</Box>
    </Panel>
  );
}

PanelLocation.propTypes = {
  onClose: PropTypes.func.isRequired,
  location: PropTypes.any
};

PanelLocation.defaultProps = {
  location: null
};

export default PanelLocation;
