import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function RoleText({ role }) {
  return <>{intl.get(`role.${role}`)}</>;
}

RoleText.propTypes = {
  role: PropTypes.string.isRequired
};

export default RoleText;
