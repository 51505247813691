import Box from 'components/Box';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import React from 'react';

function LoadingBox({ loading, children, renderChildren, ...rest }) {
  if (loading) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        alignItems="center"
        justify="center"
        position="fixed"
        left="0"
        top="0"
        {...rest}
      >
        <Spinner noMargin />
      </Box>
    );
  }

  if (renderChildren !== true) {
    return null;
  }

  return <Box {...rest}>{children}</Box>;
}

LoadingBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  loading: PropTypes.bool.isRequired,
  renderChildren: PropTypes.bool
};

LoadingBox.defaultProps = {
  renderChildren: true
};

export default LoadingBox;
