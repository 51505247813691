import { ComponentTypes } from 'constants/SteamComponent';
import FirebaseService from 'services/FirebaseService';

/**
 * Check if every components has a sort index or not
 * @param {*} components
 * @returns
 */
const hasSortIndex = components => {
  let indexExistsForAll = true;

  for (let i = 0; i < components.length; i += 1) {
    const componentDoc = components[i];
    const component = componentDoc.data;

    if (component.sortingIndex === undefined) {
      indexExistsForAll = false;
    }
  }

  return indexExistsForAll;
};

/**
 * Sort by types which is the default natural sorting order
 * used when not alle components have a sorting order
 * @param {*} components
 */
const sortNaturalByType = components => {
  const sorted = [];
  Object.keys(ComponentTypes).map(componentName => {
    const type = ComponentTypes[componentName];

    for (let i = 0; i < components.length; i += 1) {
      const componentDoc = components[i];
      const component = componentDoc.data;

      if (component.type === type) {
        sorted.push(componentDoc);
      }
    }
  });

  return sorted;
};

/**
 * Sort components with sorting index property
 * @param {*} components
 */
const sortWithSortIndex = components => {
  const compare = (aDoc, bDoc) => {
    const a = aDoc.data;
    const b = bDoc.data;

    if (a.sortingIndex < b.sortingIndex) {
      return -1;
    }
    if (a.sortingIndex > b.sortingIndex) {
      return 1;
    }
    return 0;
  };

  const sorted = components.sort(compare);

  return sorted;
};

/**
 * filter the highest sort index and add 1
 * @param {*} components
 * @returns
 */
export const getNextSortIndex = components => {
  let maxIndex = 0;

  for (let i = 0; i < components.length; i += 1) {
    const componentDoc = components[i];
    const component = componentDoc.data;

    if (component.sortingIndex === undefined && component.sortingIndex >= maxIndex) {
      maxIndex = component.sortingIndex;
    }
  }

  return maxIndex + 1;
};

/**
 * Sort components
 * @param {*} components
 * @returns
 */
export const sortComponents = components => {
  const useSortingIndex = hasSortIndex(components);

  let sorted = [...components];

  if (useSortingIndex) {
    sorted = sortWithSortIndex(components);
  } else {
    sorted = sortNaturalByType(components);
  }

  return sorted;
};

/**
 * Update sorting index by given order of component array
 * (not using await here since it is fire and forget)
 * @param {*} components
 */
export const updateSorting = components => {
  for (let i = 0; i < components.length; i += 1) {
    const componentDoc = components[i];
    FirebaseService.updateComponentSortingIndex(componentDoc.id, i);
  }
};
