import Box from 'components/Box';
import Button from 'components/Button';
import DayPicker from 'components/DayPicker';
import Heading from 'components/Heading';
import Label from 'components/Label';
import LoadingBox from 'components/LoadingBox';
import Popup from 'components/Popup';
import Text from 'components/Text';
import TextArea from 'components/TextArea';
import TextField from 'components/TextField';
import { ComponentTypeConfig, ComponentTypes } from 'constants/SteamComponent';
import { SteamProductionInterferenceConfig } from 'constants/SteamProductionInterference';
import useFormOptions from 'hooks/useFormOptions';
import useGetParameterUnit from 'hooks/useGetParameterUnit';
import { messagesCommon, messagesComponents, messagesMeasurement } from 'messages/messages';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import NotificationService from 'services/NotificationService';
import ParameterService from 'services/ParameterService';
import { sortComponents } from 'utils/SortComponents';
import { sortParameterOfComponents } from 'utils/SortParameters';

// @TODO capsulate block and row rendering to components
function Measurement() {
  const formOptions = useFormOptions();
  const { getUnitForParameter } = useGetParameterUnit();
  const notificationRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [measurementDoc, setMeasurementDoc] = useState(null);
  const [measurement, setMeasurement] = useState(null);
  const [lastMeasurement, setLastMeasurement] = useState(null);
  const [components, setComponents] = useState([]);
  const [costFactors, setCostFactors] = useState([]);
  const [activeComponentValues, setActiveComponentValues] = useState([]);

  const [notificationId, setNotificationId] = useState(null);
  const [componentHighlightId, setComponentHighlightId] = useState(null);
  const [paramHighlightName, setParamHighlightName] = useState(null);

  // Effect to determine if we come from a notification
  useEffect(() => {
    const searchString = location.search ? location.search.replace('?', '') : null;

    if (searchString) {
      const searchParams = new URLSearchParams(searchString);
      if (searchParams.has('notification')) {
        setNotificationId(searchParams.get('notification'));
      }
      if (searchParams.has('componentId')) {
        setComponentHighlightId(searchParams.get('componentId'));
      }
      if (searchParams.has('paramName')) {
        setParamHighlightName(searchParams.get('paramName'));
      }
    }
  }, [location]);

  // Effect to scroll to notificatin Ref when set after loading is done
  useEffect(() => {
    if (notificationRef.current !== null) {
      const yPos = notificationRef.current.getBoundingClientRect().top - 40;
      window.scrollTo({ top: yPos, behavior: 'smooth' });
    }
  }, [isLoading]);

  // Effect to load components of location
  // not using snapshot listener to avoid re-render on change
  // boostrap the measurement with initial values and structure if its a new one
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const componentDocs = [];
      const costFactorDocs = [];
      const activeValues = [];
      let lastMeasure = null;

      const costFactorSnapshot = await FirebaseService.queryCostFactorOfLocationRef(
        AppStateService.location.ref
      );

      costFactorSnapshot.forEach(doc => {
        const item = {
          id: doc.id,
          ...doc.data()
        };
        costFactorDocs.push(item);
      });

      // Query Components of location
      const componentQuerySnapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );

      // Deconstruct snapshot data and build a component based active value map
      componentQuerySnapshot.forEach(doc => {
        const data = doc.data();
        componentDocs.push({
          id: doc.id,
          ref: doc.ref,
          data
        });

        activeValues[doc.id] = {
          waterValues: data.waterValues ? data.waterValues.filter(value => value.active) : [],
          operatingValues: data.operatingValues
            ? data.operatingValues.filter(value => value.active)
            : [],
          meterValues: data.meterValues ? data.meterValues.filter(value => value.active) : []
        };
      });

      // Check if given url param is an ID or is "new" to load or bootstrap measurement object
      if (id && id !== 'new') {
        const measurementDocRef = await FirebaseService.queryMeasurementById(id);
        const measurementData = measurementDocRef.data();
        setMeasurementDoc({
          id: measurementDocRef.id,
          ref: measurementDocRef,
          data: measurement
        });

        setMeasurement(measurementData);

        if (measurementData.lastMeasurement) {
          lastMeasure = await measurementData.lastMeasurement.get();
        }
      } else {
        const costFactorQuerySnapshot = await FirebaseService.queryCostFactorOfLocationRef(
          AppStateService.location.ref
        );
        const costFactors = [];

        costFactorQuerySnapshot.forEach(costFactor => {
          const data = costFactor.data();
          // we dont need the location ref
          delete data.location;
          // push data and add id
          costFactors.push({ id: costFactor.id, ...data });
        });

        // get last measurement to add it to the current since a lot of calculations are based on the last measured value
        const lastMeasurementSnapshot = await FirebaseService.getLastMeasurement(
          AppStateService.location.ref
        );

        if (lastMeasurementSnapshot.docs.length > 0) {
          [lastMeasure] = lastMeasurementSnapshot.docs;
        }

        // Boostrap measured references
        const boostrapped = {
          location: AppStateService.location.ref,
          lastMeasurement: lastMeasure ? lastMeasure.ref : null,
          createdBy: AppStateService.user.ref,
          createdByTag: AppStateService.user.data.tag,
          createdAt: FirebaseService.getTimestamp(),
          costFactorAtTime: costFactors,
          measuredValues: [],
          steamBoilersHaveMeter: false,
          dosingConfigs: [],
          burnerConfigs: []
        };

        // Boostrap measured values
        boostrapped.measuredValues = componentDocs.map(component => {
          return {
            component: component.ref,
            componentId: component.id,
            componentType: component.data.type,
            waterValues: [],
            operatingValues: [],
            meterValues: []
          };
        });

        // Copy Dosing Component configs
        boostrapped.dosingConfigs = componentDocs
          .filter(component => component.data.type === ComponentTypes.Dosing)
          .map(component => {
            return {
              component: component.ref,
              componentId: component.id,
              chemical: component.data.chemical,
              chemicalId: component.data.chemical.id,
              containerSize: component.data.containerSize,
              density: component.data.density,
              isDilutedDosing: component.data.isDilutedDosing,
              dilutedDosingTable: component.data.dilutedDosingTable
            };
          });

        // Copy Burner Component configs
        boostrapped.burnerConfigs = componentDocs
          .filter(component => component.data.type === ComponentTypes.Burner)
          .map(component => {
            return {
              component: component.ref,
              componentId: component.id,
              steamBoiler: component.data.steamBoiler,
              steamBoilerId: component.data.steamBoiler.id,
              burnMaterial1: component.data.burnMaterial1,
              burnMaterial2: component.data.burnMaterial2 ? component.data.burnMaterial2 : null
            };
          });

        // Copy value from feedWater component "steamBoilersHaveMeter"
        const feedWaterComponent = componentDocs.filter(
          component => component.data.type === ComponentTypes.FeedWater
        )[0];
        boostrapped.steamBoilersHaveMeter = feedWaterComponent.data.steamBoilersHaveMeter;

        // Copy values from location needed for measurement calculations
        boostrapped.thermicGasLoss = AppStateService.location.data.thermicGasLoss;
        boostrapped.steamProductionInterference =
          SteamProductionInterferenceConfig[
            AppStateService.location.data.steamProductionInterference
          ];

        setMeasurement(boostrapped);
      }

      // Sort components & parameters
      const sorted = sortComponents(componentDocs);
      const allParams = ParameterService.allParameter;
      const sortedActiveParameterValues = sortParameterOfComponents(activeValues, allParams);

      setLastMeasurement(lastMeasure ? lastMeasure.data() : null);
      setActiveComponentValues(sortedActiveParameterValues);
      setComponents(sorted);
      setCostFactors(costFactorDocs);
      setIsLoading(false);

      return true;
    };

    loadData();
  }, [id]);

  // @NOTE can be used for updating configs on a measurement, dev/debug purpose only
  // eslint-disable-next-line no-unused-vars
  const updateConfigs = async () => {
    const updatedBurnerConfig = components
      .filter(component => component.data.type === ComponentTypes.Burner)
      .map(component => {
        return {
          component: component.ref,
          componentId: component.id,
          steamBoiler: component.data.steamBoiler,
          steamBoilerId: component.data.steamBoiler.id,
          burnMaterial1: component.data.burnMaterial1,
          burnMaterial2: component.data.burnMaterial2 ? component.data.burnMaterial2 : null
        };
      });

    // Copy Dosing Component configs
    const updatedDosingConfig = components
      .filter(component => component.data.type === ComponentTypes.Dosing)
      .map(component => {
        return {
          component: component.ref,
          componentId: component.id,
          chemical: component.data.chemical,
          chemicalId: component.data.chemical.id,
          containerSize: component.data.containerSize,
          density: component.data.density,
          isDilutedDosing: component.data.isDilutedDosing,
          dilutedDosingTable: component.data.dilutedDosingTable
        };
      });

    const updatedCostFactorsAtTime = [...costFactors].map(costFactor => {
      const copy = { ...costFactor };
      delete copy.location;
      return copy;
    });

    const data = { ...measurement };

    data.costFactorAtTime = updatedCostFactorsAtTime;
    data.burnerConfigs = updatedBurnerConfig;
    data.dosingConfigs = updatedDosingConfig;

    await FirebaseService.updateMeasurement(id, data);

    return true;
  };

  const updateValue = (componentRef, valueType, param, value) => {
    const update = { ...measurement };

    const unit = getUnitForParameter(param.name, {
      component: componentRef.data,
      costFactors
    });

    const valueInsert = {
      name: param.name,
      unit,
      value
    };

    // find component inside measured values
    const updateComponentIndex = update.measuredValues.findIndex(
      measuredValue => measuredValue.componentId === componentRef.id
    );

    // check if the component can be found inside the measurement to update the value
    // => can be the case if its a new component and an old measurement wants to be edited
    if (updateComponentIndex >= 0) {
      // find parameter inside components different types of param values
      const updateValueIndex = update.measuredValues[updateComponentIndex][valueType].findIndex(
        value => value.name === param.name
      );

      // if that value exists, update the value
      // else create an entry using the param
      if (updateValueIndex >= 0) {
        update.measuredValues[updateComponentIndex][valueType][updateValueIndex].value = value;
      } else {
        update.measuredValues[updateComponentIndex][valueType].push(valueInsert);
      }
    } else {
      const componentInsert = {
        component: componentRef.ref,
        componentId: componentRef.id,
        componentType: componentRef.data.type,
        waterValues: [],
        operatingValues: [],
        meterValues: []
      };

      update.measuredValues.push(componentInsert);
      update.measuredValues[update.measuredValues.length - 1][valueType].push(valueInsert);
    }

    setMeasurement(update);
    setHasChanges(true);
  };

  const getParamValue = (mData, componentId, paramType, paramName) => {
    let value = '';
    try {
      // find component inside measured values
      const updateComponentIndex = mData.measuredValues.findIndex(
        measuredValue => measuredValue.componentId === componentId
      );

      // find parameter inside components different types of param values
      const updateValueIndex = mData.measuredValues[updateComponentIndex][paramType].findIndex(
        value => value.name === paramName
      );
      const measuredValue = mData.measuredValues[updateComponentIndex][paramType][updateValueIndex];

      if (measuredValue) {
        value = measuredValue.value;
      }
    } catch (e) {
      // dont care, will fail if a component has been added or removed in between measurements
    }

    return value;
  };

  const getParamName = paramName => {
    const param = ParameterService.getParameterByName(paramName);
    const trans = localStorage.getItem('locale') === 'de-DE' ? param.labelDE : param.labelEN;
    return trans;
  };

  const updateNote = note => {
    const update = { ...measurement };
    update.note = note;
    setMeasurement(update);
  };

  const updateMeasurementDate = date => {
    const update = { ...measurement };
    update.createdAt = FirebaseService.getTimestamp(date);
    setMeasurement(update);
  };

  const save = async () => {
    setIsLoading(true);

    const data = { ...measurement };
    const id = measurementDoc !== null ? measurementDoc.id : null;

    // tag updated by
    if (id) {
      data.updatedAt = FirebaseService.getTimestamp();
      data.updatedBy = AppStateService.user.ref;
      data.updatedByTag = AppStateService.user.data.tag;
    }

    // check if the measurement has data for components which are not listed for the relating configs (dosing, burner)
    for (let i = 0; i < data.measuredValues.length; i += 1) {
      const measuredValue = data.measuredValues[i];
      const { componentId, componentType } = measuredValue;

      if (componentType === ComponentTypes.Dosing) {
        const dosingConfigIndex = data.dosingConfigs.findIndex(
          configPart => configPart.componentId === componentId
        );

        if (dosingConfigIndex < 0) {
          console.warn('Missing Dosing Config for already saved dosing data => ', componentId);
        }
      }

      if (componentType === ComponentTypes.Burner) {
        const burnerConfigIndex = data.burnerConfigs.findIndex(
          configPart => configPart.componentId === componentId
        );

        if (burnerConfigIndex < 0) {
          console.warn('Missing Burner Config for already saved burner data => ', componentId);
        }
      }
    }

    const result = await FirebaseService.updateMeasurement(id, data);

    if (result) {
      if (notificationId) {
        const notificationRef = await FirebaseService.loadNotificationById(notificationId);
        NotificationService.archiveNotification(notificationRef);
        history.push('/notifications');
      } else {
        history.push('/boiler-book');
      }
    } else {
      setIsLoading(false);
      // @TODO SHOW ERROR POPUP
    }
  };

  const cancel = () => {
    history.push('/boiler-book');
  };

  const renderForm = () => {
    if (measurement) {
      return (
        <Box>
          <Box marginTop={7} marginX={7} display="flex">
            <Heading level="2" size="md" color="blueLight">
              {intl.get(messagesCommon.currentValues.id)}
            </Heading>
          </Box>

          {components.map(component => renderBlockPartForComponent(component, true))}

          <Box>
            <Box marginTop={7} marginX={7} display="flex">
              <Heading level="1" size="md">
                {intl.get(messagesCommon.comment.id)}
              </Heading>
            </Box>

            <Box background="white" marginX={7} marginTop={5} paddingY={2}>
              <Box marginX={4} paddingY={2}>
                <TextArea
                  id="notes"
                  rows={6}
                  value={measurement.note}
                  onChange={e => updateNote(e.currentTarget.value)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
  };

  const renderLastValues = () => {
    if (lastMeasurement) {
      return (
        <Box>
          <Box marginTop={7} marginX={7} display="flex">
            <Heading level="2" size="md" color="blueLight">
              {intl.get(messagesCommon.lastValues.id)} -{' '}
              {moment(lastMeasurement.createdAt.toDate()).format('DD.MM.YYYY')}
            </Heading>
          </Box>
          {components.map(component => renderBlockPartForComponent(component, false))}
        </Box>
      );
    }

    return null;
  };

  const renderBlockPartForComponent = (componentRef, asForm) => {
    const component = componentRef.data;
    const typeName = ComponentTypeConfig[component.type].name;
    const message = messagesComponents[`component${typeName}`];
    const valueTypes = ['waterValues', 'operatingValues', 'meterValues'];
    const hasActiveValueParams =
      activeComponentValues[componentRef.id].waterValues.length +
        activeComponentValues[componentRef.id].operatingValues.length +
        activeComponentValues[componentRef.id].meterValues.length >
      0;

    if (!hasActiveValueParams) {
      return null;
    }

    return (
      <React.Fragment key={`measured-values-${componentRef.id}`}>
        {asForm ? (
          <Box marginTop={7} marginX={7} display="flex">
            <Heading level="1" size="md">
              {intl.get(message.id)} {component.componentNumber}{' '}
              <Text size="sm" inline>
                ({component.internalName})
              </Text>
            </Heading>
          </Box>
        ) : (
          <Box marginTop={7} marginX={7} display="flex" overflow="hidden">
            <Heading level="1" size="md" color="background" style={{ whiteSpace: 'nowrap' }}>
              {intl.get(message.id)} {component.componentNumber}{' '}
              <Text size="sm" inline color="background">
                ({component.internalName})
              </Text>
            </Heading>
          </Box>
        )}

        {asForm ? (
          <Box background="white" marginX={7} marginTop={5} paddingY={2}>
            {valueTypes.map(type => renderValueFieldsForComponent(componentRef, type))}
          </Box>
        ) : (
          <Box background="white" marginX={7} marginTop={5} paddingY={2}>
            {valueTypes.map(type => renderLastValuesForComponent(componentRef, type))}
          </Box>
        )}
      </React.Fragment>
    );
  };

  const renderValueFieldsForComponent = (componentRef, type) => {
    const activeValueParams = activeComponentValues[componentRef.id][type];

    if (activeValueParams && activeValueParams.length > 0) {
      return (
        <React.Fragment key={`measured-values-input-container-${componentRef.id}-${type}`}>
          <Box display="flex" wrap="wrap" paddingX={2}>
            {activeValueParams.map(param => {
              const unit = getUnitForParameter(param.name, {
                component: componentRef.data,
                costFactors
              });
              const value = getParamValue(measurement, componentRef.id, type, param.name);
              const lastValue = getParamValue(lastMeasurement, componentRef.id, type, param.name);
              let fuelOption = null;
              let meterWarning = false;

              const markForNotification =
                componentRef.id === componentHighlightId && param.name === paramHighlightName;

              // @NOTE to be fully correct we would need to get the values not from the current component
              // but from the measurement burnerConfig array
              if (componentRef.data.type === ComponentTypes.Burner && type === 'meterValues') {
                if (param.name === 'burnMeter1') {
                  [fuelOption] = formOptions.fuel.filter(
                    options => options.value === componentRef.data.burnMaterial1
                  );
                }
                if (param.name === 'burnMeter2') {
                  [fuelOption] = formOptions.fuel.filter(
                    options => options.value === componentRef.data.burnMaterial2
                  );
                }
              }

              // check type = meterValues if current > last
              if (type === 'meterValues') {
                if (value !== '' && lastValue !== '') {
                  if (parseFloat(value) <= parseFloat(lastValue)) {
                    meterWarning = true;
                  }
                }
              }

              return (
                <React.Fragment key={`measured-values-input-${componentRef.id}-${param.name}`}>
                  <Box
                    id={`measured-values-input-${componentRef.id}-${param.name}`}
                    ref={markForNotification ? notificationRef : null}
                    marginY={2}
                    width="100%"
                    paddingX={2}
                    display="flex"
                    direction="row"
                    height="58px"
                    style={markForNotification ? { backgroundColor: '#dc3961' } : null}
                  >
                    <Box display="flex" width="40%">
                      <Label htmlFor={`${componentRef.id}-${type}-${param.name}`}>
                        {getParamName(param.name)} {fuelOption ? `- ${fuelOption.label}` : null}
                      </Label>
                    </Box>

                    <Box display="flex" alignItems="center" width="60%">
                      <TextField
                        id={`${componentRef.id}-${type}-${param.name}`}
                        type="number"
                        placeholder={intl.get(messagesMeasurement.placeholder.id)}
                        value={value}
                        onChange={e => updateValue(componentRef, type, param, e.target.value)}
                        addOn={unit}
                      />
                    </Box>
                  </Box>

                  {meterWarning && (
                    <Box marginY={2} width="100%" paddingX={2} height="20px">
                      <Text size="sm" color="red">
                        {intl.get(messagesMeasurement.meterWarning.id)}
                      </Text>
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        </React.Fragment>
      );
    }

    return null;
  };

  const renderLastValuesForComponent = (componentRef, type) => {
    const activeValueParams = activeComponentValues[componentRef.id][type];

    if (activeValueParams && activeValueParams.length > 0) {
      return (
        <React.Fragment key={`measured-values-input-container-${componentRef.id}-${type}`}>
          <Box display="flex" wrap="wrap" paddingX={2}>
            {activeValueParams.map(param => {
              const unit = getUnitForParameter(param.name, {
                component: componentRef.data,
                costFactors
              });
              const lastValue = getParamValue(lastMeasurement, componentRef.id, type, param.name);
              const value = getParamValue(measurement, componentRef.id, type, param.name);
              let meterWarning = false;

              // check type = meterValues if current > last
              if (type === 'meterValues') {
                if (value !== '' && lastValue !== '') {
                  if (parseFloat(value) <= parseFloat(lastValue)) {
                    meterWarning = true;
                  }
                }
              }

              return (
                <React.Fragment
                  key={`measured-values-input-${componentRef.id}-${type}-${param.name}`}
                >
                  <Box display="flex" marginY={2} width="100%" paddingX={2} height="58px">
                    <Box display="flex" alignItems="center" style={{ padding: '9.5px 14px' }}>
                      <Text>{`${lastValue !== '' ? lastValue : '-'} ${
                        unit !== null ? unit : ''
                      }`}</Text>
                    </Box>
                  </Box>
                  {/* Box Padding if form field was warning text */}
                  {meterWarning && <Box marginY={2} width="100%" paddingX={2} height="20px" />}
                </React.Fragment>
              );
            })}
          </Box>
        </React.Fragment>
      );
    }

    return null;
  };

  const renderConfirmLeaveModal = (onConfirm, onCancel) => {
    return (
      <Popup
        show
        size={400}
        title={intl.get(messagesCommon.confirmLeaveTitle.id)}
        message={intl.get(messagesCommon.confirmLeaveMessage.id)}
        confirmColor="red"
        confirmText={intl.get(messagesCommon.yes.id)}
        confirmAction={onConfirm}
        abortAction={onCancel}
        abortText={intl.get(messagesCommon.no.id)}
      />
    );
  };

  return (
    <LoadingBox loading={isLoading} renderChildren={!isLoading}>
      <Box marginX={7} display="flex">
        <Heading level="1" size="xl">
          {id === 'new'
            ? intl.get(messagesMeasurement.create.id)
            : `${intl.get(messagesMeasurement.edit.id)} - ${moment(
                measurement?.createdAt.toDate()
              ).format('DD.MM.YYYY')}`}
        </Heading>
        <Box marginLeft={3}>
          <Button
            text={intl.get(messagesMeasurement.back.id)}
            onClick={cancel}
            width={200}
            size="sm"
            inline
          />
        </Box>
      </Box>

      {/* && id !== 'new' */}
      {AppStateService.isSuperAdmin() && id ? (
        <Box display="flex" alignItems="flex-end">
          <Box marginY={4} marginX={7} width="150px">
            <Label htmlFor="measurement-date">{intl.get(messagesCommon.date.id)}</Label>
            <DayPicker
              id="measurement-date"
              value={measurement?.createdAt?.toDate()}
              onChange={updateMeasurementDate}
            />
          </Box>
          {/*
          <Box marginY={5} marginX={2}>
            <Button
              text="Konfigurationen aktualisieren"
              onClick={updateConfigs}
              minWidth={250}
              background="blueLight"
            />
          </Box>
          */}
        </Box>
      ) : null}

      <Box display="flex" wrap="no-wrap" direction="row" alignItems="flex-start" maxWidth="1280px">
        <Box width="65%" paddingY={2}>
          {renderForm()}
        </Box>
        <Box width="35%" paddingY={2}>
          {renderLastValues()}
        </Box>
      </Box>

      <Box width="100%" marginX={7} marginTop={7} display="flex" alignItems="right">
        <Box>
          <Button text={intl.get(messagesCommon.save.id)} onClick={save} minWidth={158} />
        </Box>
        <Box marginLeft={3}>
          <Button
            text={intl.get(messagesCommon.cancel.id)}
            onClick={cancel}
            minWidth={158}
            background="blueLight"
          />
        </Box>
      </Box>

      <NavigationPrompt when={hasChanges}>
        {({ onConfirm, onCancel }) => renderConfirmLeaveModal(onConfirm, onCancel)}
      </NavigationPrompt>
    </LoadingBox>
  );
}

export default Measurement;
