import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import Heading from 'components/Heading';
import Label from 'components/Label';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { REQUIRED_FIELDS } from 'constants/Form';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';

function UserFormEdit({ isCreateMode, user, onChange, selectedLocations }) {
  const formConfig = REQUIRED_FIELDS.user;
  const formValidator = useFormValidation();

  const [mailError, setMailError] = useState(null);

  const updateLocationList = (checked, location) => {
    const selected = user && user.locations ? user.locations : [];
    let updated = [...selected];
    if (checked) {
      updated.push(location.ref);
    } else {
      updated = updated.filter(l => l.id !== location.id);
    }
    onChange('locations', updated);
  };

  const renderLocationCheckbox = location => {
    const selected = user && user.locations ? user.locations : [];
    const isSelected = selected.filter(l => l.id === location.id).length > 0;
    const locationData = location.data;

    return (
      <Box key={`panel-location-${location.id}`} alignItems="center" direction="row" display="flex">
        <Label htmlFor={`panel-location-${location.id}`}>
          <Checkbox
            id={`panel-location-${location.id}`}
            checked={isSelected}
            onChange={e => updateLocationList(e.target.checked, location)}
          />
          <span style={{ marginLeft: 8 }}>{locationData.name}</span>
        </Label>
      </Box>
    );
  };

  const updateEmail = async e => {
    // eslint-disable-next-line
    const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValue = e.currentTarget.value;

    onChange('email', emailValue);
    setMailError(null);

    if (mailRegex.test(emailValue) === true) {
      const accounts = await FirebaseService.firebaseRef
        .auth()
        .fetchSignInMethodsForEmail(emailValue);
      if (accounts.length === 0) {
        // onChange('email', emailValue);
      } else {
        setMailError('duplicate');
      }
    } else {
      setMailError('invalid');
    }
  };

  return (
    <form noValidate autoComplete="off">
      {isCreateMode && (
        <>
          <Box marginY={3}>
            <Label
              htmlFor="panel-email"
              required={formValidator.isFieldRequired(formConfig, 'email')}
              valid={formValidator.isFieldValid(formConfig, 'email', user)}
            >
              {intl.get(messagesCommon.emailAddress.id)}
            </Label>
            <TextField
              id="panel-email"
              type="text"
              placeholder={intl.get(messagesCommon.emailAddress.id)}
              value={user?.email}
              onChange={updateEmail}
            />

            {mailError === 'invalid' && (
              <Text color="red" size="sm">
                {intl.get(messagesCommon.invalidMail.id)}
              </Text>
            )}

            {mailError === 'duplicate' && (
              <Text color="red" size="sm">
                {intl.get(messagesCommon.mailAlreadyInUse.id)}
              </Text>
            )}
          </Box>
        </>
      )}
      <Box marginY={3}>
        <Label
          htmlFor="panel-firstname"
          required={formValidator.isFieldRequired(formConfig, 'firstname')}
          valid={formValidator.isFieldValid(formConfig, 'firstname', user)}
        >
          {intl.get(messagesCommon.firstname.id)}
        </Label>
        <TextField
          id="panel-firstname"
          type="text"
          placeholder={user?.firstname || intl.get(messagesCommon.firstname.id)}
          value={user?.firstname}
          onChange={e => onChange('firstname', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="panel-lastname"
          required={formValidator.isFieldRequired(formConfig, 'lastname')}
          valid={formValidator.isFieldValid(formConfig, 'lastname', user)}
        >
          {intl.get(messagesCommon.lastname.id)}
        </Label>
        <TextField
          id="panel-lastname"
          type="text"
          placeholder={user?.lastname || intl.get(messagesCommon.lastname.id)}
          value={user?.lastname}
          onChange={e => onChange('lastname', e.currentTarget.value)}
        />
      </Box>
      <Box marginY={3}>
        <Label htmlFor="panel-phone">{intl.get(messagesCommon.phone.id)}</Label>
        <TextField
          id="panel-phone"
          type="text"
          placeholder={user?.phone || intl.get(messagesCommon.phone.id)}
          value={user?.phone}
          onChange={e => onChange('phone', e.currentTarget.value)}
        />
      </Box>

      <Box marginTop={7}>
        <Heading size="md">{intl.get(messagesCommon.locations.id)}</Heading>
        <Box marginY={3}>
          <Label
            htmlFor="panel-locations"
            required={formValidator.isFieldRequired(formConfig, 'locations')}
            valid={formValidator.isFieldValid(formConfig, 'locations', user)}
          >
            {intl.get(messagesCommon.manageableLocations.id)}
          </Label>
          {selectedLocations.map(location => renderLocationCheckbox(location))}
        </Box>
      </Box>
    </form>
  );
}

UserFormEdit.propTypes = {
  selectedLocations: PropTypes.array.isRequired,
  isCreateMode: PropTypes.bool.isRequired,
  user: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

UserFormEdit.defaultProps = {
  user: null
};

export default UserFormEdit;
