export const CostFactorTypes = {
  // fuel types
  GAS: 'GAS',
  GASN: 'GASN',
  BIOGAS: 'BIOGAS',
  BIOGASN: 'BIOGASN',
  OIL: 'OIL',
  // water
  WATER: 'WATER',
  WATER_DIRTY: 'WATER_DIRTY',
  // dosing
  DOSING: 'DOSING',
  // custom
  CUSTOM: 'CUSTOM'
};

export const CostFactorNames = {
  GAS: 'costFactorGas',
  GASN: 'costFactorGasN',
  BIOGAS: 'costFactorBioGas',
  BIOGASN: 'costFactorBioGasN',
  OIL: 'costFactorOil',
  WATER: 'costFactorWater',
  WATER_DIRTY: 'costFactorWaterDirty',
  DOSING: 'costFactorDosing',
  CUSTOM: 'costFactorCustom'
};

export const CostFactorTemplates = [
  // Heizwert: 10,9 kWh/m3 // Startkosten: 0,0315 €/kWh // CO2 Ausstoß: 2,71 kg/m3
  {
    type: CostFactorTypes.GAS,
    value: 0.327,
    unit: 'm³',
    heatFactor: 10.9,
    heatFactorUnit: 'kWh/m³',
    emissionFactor: 2.71,
    emissionFactorUnit: 'kg/m³'
  },
  // Heizwert: 10,85 kWh/m3 // Startkosten: 0,0315 €/kWh // CO2 Ausstoß: 2,71 kg/m3
  {
    type: CostFactorTypes.GASN,
    value: 0.342,
    unit: 'm³n',
    heatFactor: 10.85,
    heatFactorUnit: 'kWh/m³',
    emissionFactor: 2.71,
    emissionFactorUnit: 'kg/m³'
  },
  // Heizwert: 5,9 kWh/m3 // Startkosten: 0,06 €/kWh // CO2 Ausstoß: 1,1 kg/m3
  {
    type: CostFactorTypes.BIOGAS,
    value: 0.354,
    unit: 'm³',
    heatFactor: 5.9,
    heatFactorUnit: 'kWh/m³',
    emissionFactor: 1.1,
    emissionFactorUnit: 'kg/m³'
  },
  // Heizwert: 5,85 kWh/m3 // Startkosten: 0,06 €/kWh // CO2 Ausstoß: 1,1 kg/m3
  {
    type: CostFactorTypes.BIOGASN,
    value: 0.351,
    unit: 'm³n',
    heatFactor: 5.85,
    heatFactorUnit: 'kWh/m³',
    emissionFactor: 1.1,
    emissionFactorUnit: 'kg/m³'
  },
  // Heizwert: 12 kWh/kg // Startkosten: 0,80 €/l // CO2 Ausstoß: 3,37 kg/l
  {
    type: CostFactorTypes.OIL,
    value: 0.8,
    unit: 'l',
    heatFactor: 12,
    heatFactorUnit: 'kWh/kg',
    emissionFactor: 3.37,
    emissionFactorUnit: 'kg/l'
  },
  {
    type: CostFactorTypes.WATER,
    value: 1.5,
    unit: 'm³'
  },
  {
    type: CostFactorTypes.WATER_DIRTY,
    value: 2,
    unit: 'm³'
  }
];
