const BaseTheme = {
  colors: {
    transparent: 'transparent',
    background: '#fafafa',
    sand: '#f4f4f4',
    white: '#fff',
    black: '#051014',
    blue: '#163253',
    blueLight: '#8793a1',
    red: '#dc3961',
    green: '#37d597',
    orange: '#fab398',
    border: '#e7e7e9',
    borderTable: '#dbdbdb',
    grey: '#dbdbdb'
  },
  button: {
    background: '#163253',
    color: '#ffffff'
  },
  buttonHover: {
    background: '#051014',
    color: '#ffffff'
  }
};

export default BaseTheme;
