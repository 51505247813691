import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { textSizes } from 'utils/Utils';

const StyledHeading = styled.h1`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
  font-size: ${props => (props.size ? textSizes(props.size) : textSizes('lg'))};
  font-weight: 500;
  margin: 0;
`;

function Heading({ children, level, size, color, ...rest }) {
  return (
    <StyledHeading as={`h${level}`} level={level} size={size} color={color} {...rest}>
      {children}
    </StyledHeading>
  );
}

Heading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  level: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string
};

Heading.defaultProps = {
  level: '1',
  size: 'lg',
  color: 'black'
};

export default Heading;
