import Box from 'components/Box';
import Label from 'components/Label';
import LoadingBox from 'components/LoadingBox';
import Select from 'components/Select';
import Text from 'components/Text';
import TextField from 'components/TextField';
import Toggle from 'components/Toggle';
import { CostFactorTypes } from 'constants/CostFactor';
import { REQUIRED_FIELDS } from 'constants/Form';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCommon, messagesCostFactor } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import styled from 'styled-components';

const StyledDosingTable = styled.table`
  .section {
    &--top {
      border-top: 1px solid ${props => props.theme.colors.borderTable};
    }

    &--bottom {
      border-bottom: 1px solid ${props => props.theme.colors.borderTable};
    }

    td {
      padding: 0;
    }
  }
`;

function DataDosing({ component, onChange }) {
  const formConfig = REQUIRED_FIELDS.component.DOSING;
  const formValidator = useFormValidation();
  const [costFactors, setCostFactors] = useState([]);
  const [chemicalOptions, setChemicalOptions] = useState([]);
  const [dilutedDosingTable, setDilutedDosingTable] = useState(
    component && component.dilutedDosingTable ? component.dilutedDosingTable : []
  );
  const [initDilutedDosingTable, setInitDilutedDosingTable] = useState(false);
  const [isDilutedDosingTableVisible, setIsDilutedDosingTableVisible] = useState(
    component && component.isDilutedDosing ? component.isDilutedDosing : false
  );
  const [loading, setLoading] = useState(true);

  // load chemicals and setup cost factors
  useEffect(() => {
    const loadChemicals = async () => {
      setLoading(true);
      const docs = [];
      const options = [];

      const snapshot = await FirebaseService.queryCostFactorOfLocationRef(
        AppStateService.location.ref
      );

      snapshot.forEach(doc => {
        const data = doc.data();
        docs.push({
          id: doc.id,
          ref: doc.ref,
          data
        });

        if (data.type === CostFactorTypes.DOSING) {
          options.push({
            label: data.name,
            value: doc.id
          });
        }
      });

      setCostFactors(docs);
      setChemicalOptions(options);
      setLoading(false);
    };

    loadChemicals();
  }, []);

  // bootstrap dosing table
  const bootstrapDosingTable = useCallback(() => {
    let boostrapedDosingTable = [];

    costFactors.map(factor => {
      if (factor.data.type === CostFactorTypes.DOSING) {
        const data = {
          name: factor.data.name,
          costFactor: factor.ref,
          amount: 0
        };
        boostrapedDosingTable = [...boostrapedDosingTable, data];
      }
      return true;
    });

    const waterFilterCriteria = factor => factor.data.type === CostFactorTypes.WATER;
    const costFactorForWater = costFactors.filter(waterFilterCriteria)[0].ref;
    const water = {
      name: intl.get(messagesCostFactor.costFactorWater.id),
      costFactor: costFactorForWater,
      amount: 0
    };
    boostrapedDosingTable.unshift(water);

    setDilutedDosingTable(boostrapedDosingTable);
  }, [costFactors]);

  // boostrap dosing table as soon as toggle becomes active
  useEffect(() => {
    if (dilutedDosingTable.length === 0 && isDilutedDosingTableVisible) {
      bootstrapDosingTable();
    }
  }, [isDilutedDosingTableVisible, bootstrapDosingTable, dilutedDosingTable]);

  // fill diluted dosing table with data if it is bootstrapped
  useEffect(() => {
    if (!initDilutedDosingTable && component && dilutedDosingTable.length > 0) {
      const updateDosingTable = dilutedDosingTable.map(dosing => {
        let updatedDosing = { ...dosing };
        const componentDosing = component.dilutedDosingTable.filter(
          componentDosing => componentDosing.costFactor.id === dosing.costFactor.id
        )[0];

        if (componentDosing) {
          updatedDosing = {
            ...updatedDosing,
            amount: componentDosing.amount
          };
        }

        return updatedDosing;
      });

      setInitDilutedDosingTable(true);
      setDilutedDosingTable(updateDosingTable);
    }
  }, [initDilutedDosingTable, component, dilutedDosingTable]);

  const updateDilutedDosingTable = useCallback(
    (value, dosingToUpdate) => {
      let updated = [...dilutedDosingTable];

      updated = updated.map(dosing => {
        const dosingUpdate = { ...dosing };
        if (dosing.costFactor.id === dosingToUpdate.costFactor.id) {
          dosingUpdate.amount = value;
        }

        return dosingUpdate;
      });

      setDilutedDosingTable(updated);
      onChange('dilutedDosingTable', updated);
    },
    [onChange, dilutedDosingTable]
  );

  const updateChemicalOption = update => {
    const chemicalCostFactor = costFactors.filter(costFactor => costFactor.id === update.value)[0];
    onChange('chemical', chemicalCostFactor.ref);
  };

  const calculateDosingSum = () => {
    const reducer = (ac, cv) => parseFloat(ac) + parseFloat(cv.amount);
    return dilutedDosingTable.reduce(reducer, 0);
  };

  const renderDosageOptions = () => {
    const dosingSum = calculateDosingSum();

    return (
      <>
        <Box background="white" paddingY={2} paddingX={5} border>
          <StyledDosingTable className="table-panel">
            <tbody>
              <tr>
                <td style={{ width: '300px' }}>
                  <Text size="sm" paddingLeft={2} bold>
                    {intl.get(messagesCommon.containerSize.id)}
                  </Text>
                </td>
                <td>
                  <TextField
                    id="containerSize"
                    type="number"
                    placeholder=""
                    value={component?.containerSize}
                    addOn="l"
                    onChange={e => onChange('containerSize', e.currentTarget.value)}
                  />
                </td>
              </tr>
              <tr className="section section--bottom">
                <td colSpan={2}>&nbsp;</td>
              </tr>
              {dilutedDosingTable.map(dosing => (
                <tr key={dosing.costFactor.id}>
                  <td>
                    <Text size="sm" paddingLeft={2}>
                      {dosing.name}
                    </Text>
                  </td>
                  <td>
                    <TextField
                      id={`dosing-table-option-${dosing.name}`}
                      type="number"
                      placeholder=""
                      value={dosing.amount}
                      addOn="l"
                      onChange={e => updateDilutedDosingTable(e.target.value, dosing)}
                    />
                  </td>
                </tr>
              ))}
              <tr className="section section--top">
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <Text size="sm" paddingLeft={2} bold>
                    {intl.get(messagesCommon.sum.id)}
                    Summe
                  </Text>
                </td>
                <td>
                  <Text
                    size="sm"
                    color={
                      component.containerSize && dosingSum <= component.containerSize
                        ? 'blue'
                        : 'red'
                    }
                    bold
                  >
                    {dosingSum} l
                  </Text>
                </td>
              </tr>
            </tbody>
          </StyledDosingTable>
        </Box>
      </>
    );
  };

  return (
    <LoadingBox loading={loading} renderChildren={!loading}>
      <Box marginY={3}>
        <Label
          htmlFor="chemical"
          required={formValidator.isFieldRequired(formConfig, 'chemical')}
          valid={formValidator.isFieldValid(formConfig, 'chemical', component)}
        >
          {intl.get(messagesCommon.tradename.id)}
        </Label>
        <Select
          id="chemical"
          options={chemicalOptions}
          placeholder={intl.get(messagesCommon.choose.id)}
          value={component && component.chemical ? component.chemical.id : ''}
          onChange={e => updateChemicalOption(e)}
        />
      </Box>
      <Box marginY={3}>
        <Label
          htmlFor="density"
          required={formValidator.isFieldRequired(formConfig, 'density')}
          valid={formValidator.isFieldValid(formConfig, 'density', component)}
        >
          {intl.get(messagesCommon.density.id)}
        </Label>
        <TextField
          id="density"
          type="number"
          placeholder=""
          value={component?.density}
          onChange={e => onChange('density', e.currentTarget.value)}
          addOn="g/cm³"
        />
      </Box>
      <Box marginY={3} display="flex" alignItems="center">
        <Toggle
          enabled={isDilutedDosingTableVisible}
          onChange={enabled => {
            setIsDilutedDosingTableVisible(enabled);
            onChange('isDilutedDosing', enabled);
          }}
        />
        <Text marginLeft={2} size="sm">
          {intl.get(messagesCommon.dilutedDosing.id)}
        </Text>
      </Box>
      {isDilutedDosingTableVisible && renderDosageOptions()}
    </LoadingBox>
  );
}

DataDosing.propTypes = {
  onChange: PropTypes.func.isRequired,
  component: PropTypes.any
};

DataDosing.defaultProps = {
  component: null
};

export default DataDosing;
