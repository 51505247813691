import Box from 'components/Box';
import Text from 'components/Text';
import { ReactComponent as IconEdit } from 'images/Edit.svg';
import PropTypes from 'prop-types';
import React from 'react';

function SteamPlantComponent({ children, title, margin, incomplete, onClickEdit, onClickCreate }) {
  return (
    <Box width={347} height={325} background="white" margin={margin}>
      {title && title !== '' && (
        <Box
          paddingX={5}
          paddingY={3}
          display="flex"
          justify="space-between"
          borderBottom
          noBorderRadius
        >
          <Text size="sm" bold>
            {title}
          </Text>
          {!incomplete && (
            <button
              type="button"
              onClick={() => (onClickEdit ? onClickEdit() : null)}
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              <IconEdit cursor="pointer" style={{ pointerEvents: 'none' }} />
            </button>
          )}
        </Box>
      )}
      <Box height="calc(100% - 45px)" paddingX={5} paddingY={3}>
        {incomplete ? (
          <Box
            cursor="pointer"
            display="flex"
            height="100%"
            alignItems="center"
            justify="center"
            onClick={() => (onClickCreate ? onClickCreate() : null)}
          >
            <Box>
              <Box display="flex" justify="center">
                <Text color="red">
                  <IconEdit cursor="pointer" />
                </Text>
              </Box>
              <Text color="red" size="sm" bold marginTop={2} hover>
                Einrichtung starten
              </Text>
            </Box>
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
}

SteamPlantComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  margin: PropTypes.number,
  incomplete: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickCreate: PropTypes.func
};

SteamPlantComponent.defaultProps = {
  children: null,
  title: null,
  margin: 0,
  incomplete: false,
  onClickEdit: null,
  onClickCreate: null
};

export default SteamPlantComponent;
