import {
  BUSINESS_OPTIONS,
  BUSINESS_OPTIONS_SP,
  CHECK_INTERVALS,
  COSTFACTOR_ADDABLE_OPTIONS,
  COSTFACTOR_OPTIONS,
  COUNTRY_OPTIONS,
  EXPORT_TYPE_OPTIONS,
  FILTER_DATE_RANGE,
  FIX_ANALYSIS_PARAMETER,
  FUEL_TYPES,
  HARDNESS_UNITS,
  PACKAGE_OPTIONS,
  PARAM_CATEGORY_OPTIONS,
  PARAM_COMPONENTS_OPTIONS,
  PARAM_FORM_ELEMENT_OPTIONS,
  ROLE_OPTIONS,
  STATUS_OPTIONS,
  STEAM_INTERFERENCE_TYPES
} from 'constants/Form';
import intl from 'react-intl-universal';

function useFormOptions() {
  const translatedOptions = [];

  // mapping name to the option constant
  const formOptionsMap = {
    country: {
      options: COUNTRY_OPTIONS,
      sort: true
    },
    role: {
      options: ROLE_OPTIONS,
      sort: true
    },
    hardness: {
      options: HARDNESS_UNITS,
      sort: false
    },
    fuel: {
      options: FUEL_TYPES,
      sort: true
    },
    interval: {
      options: CHECK_INTERVALS,
      sort: false
    },
    status: {
      options: STATUS_OPTIONS,
      sort: true
    },
    dateRange: {
      options: FILTER_DATE_RANGE,
      sort: false
    },
    costFactor: {
      options: COSTFACTOR_OPTIONS,
      sort: true
    },
    costFactorAddable: {
      options: COSTFACTOR_ADDABLE_OPTIONS,
      sort: true
    },
    business: {
      options: BUSINESS_OPTIONS,
      sort: false
    },
    businessSP: {
      options: BUSINESS_OPTIONS_SP,
      sort: false
    },
    interference: {
      options: STEAM_INTERFERENCE_TYPES,
      sort: false
    },
    fixedAnalysisParams: {
      options: FIX_ANALYSIS_PARAMETER,
      sort: false
    },
    exportType: {
      options: EXPORT_TYPE_OPTIONS,
      sort: false
    },
    package: {
      options: PACKAGE_OPTIONS,
      sort: false
    },
    paramCategory: {
      options: PARAM_CATEGORY_OPTIONS,
      sort: false
    },
    paramComponents: {
      options: PARAM_COMPONENTS_OPTIONS,
      sort: false
    },
    paramFormElement: {
      options: PARAM_FORM_ELEMENT_OPTIONS,
      sort: false
    }
  };

  const availableOptions = Object.keys(formOptionsMap);

  availableOptions.map(optionName => {
    const { options, sort } = formOptionsMap[optionName];
    const translatedOptionSet = options.map(option => {
      const i18nOption = {};

      i18nOption.value = option.value;
      i18nOption.label = intl.get(option.label.id);
      i18nOption.labelShort = option.labelShort ? intl.get(option.labelShort.id) : null;
      i18nOption.unit = option.unit ? option.unit : null;

      return i18nOption;
    });

    // Sort option set by translated label name
    if (sort) {
      translatedOptionSet.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    translatedOptions[optionName] = translatedOptionSet;

    return optionName;
  });

  return translatedOptions;
}

export default useFormOptions;
