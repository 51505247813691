import Box from 'components/Box';
import LoadingBox from 'components/LoadingBox';
import Text from 'components/Text';
import { messagesCommon } from 'messages/messages';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';
import ParameterService from 'services/ParameterService';
import styled from 'styled-components';

const StyledRow = styled(Box)`
  border-bottom: 1px solid #f5f5f5;
  padding: 15px;
`;

const StyledContent = styled(Box)`
  height: 350px;
  overflow-y: scroll;

  &::last-child {
    border: none;
  }
`;

const StyledBackdrop = styled(Box)`
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 990;
  opacity: 0.4;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 80px;
`;

const StyledOverlay = styled(Box)`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 2px 20px 0 rgba(5, 16, 20, 0.1);
  transition: all 0.5s;
  height: 400px;
  width: 900px;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
`;

function ChangeOverlay({ measurement, components, onClose }) {
  const [changeEntries, setChangeEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const docs = [];
      const snapshot = await FirebaseService.getMeasurementChanges(measurement.ref);

      snapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ...doc.data()
        });
      });

      setChangeEntries(docs);
      setLoading(false);
    };

    setChangeEntries([]);
    load();
  }, [measurement]);

  const getName = paramName => {
    const param = ParameterService.getParameterByName(paramName);
    const trans = localStorage.getItem('locale') === 'de-DE' ? param.labelDE : param.labelEN;
    return trans;
  };

  const renderChangeRows = () => {
    return changeEntries.map(entry => {
      return entry.changes.map(change => {
        const changedComponent = components.find(c => c.id === change.componentId);
        return (
          <StyledRow
            key={`${entry.id}-${change.componentId}-${change.name}`}
            display="flex"
            direction="row"
            justify="space-between"
          >
            <Box width="150px">
              <Text size="sm" color="gray">
                {moment(entry.updatedAt.toDate()).format('DD.MM.YYYY HH:mm')}
              </Text>
            </Box>
            <Box width="75px">
              <Text size="sm">{entry.updatedByTag}</Text>
            </Box>
            <Box width="250px">
              <Text size="sm">{changedComponent?.data.internalName}</Text>
            </Box>
            <Box width="250px">
              <Text size="sm">{getName(change.name)}</Text>
            </Box>
            <Box display="flex" direction="row" justify="center" width="180px">
              <Box width="80px">
                <Text size="sm" color="blue" bold>
                  {change.before}
                  {change.unit}
                </Text>
              </Box>
              <Box width="20px">
                <Text size="sm" color="blue" bold>
                  &rarr;
                </Text>
              </Box>
              <Box width="80px">
                <Text size="sm" color="blue" bold align="right">
                  {change.after}
                  {change.unit}
                </Text>
              </Box>
            </Box>
          </StyledRow>
        );
      });
    });
  };

  return (
    <>
      <StyledBackdrop onClick={onClose} />
      <StyledOverlay paddingX={8} paddingY={8}>
        <LoadingBox loading={loading} renderChildren={!loading} height="100%">
          {changeEntries.length > 0 ? (
            <StyledContent>{renderChangeRows()}</StyledContent>
          ) : (
            <Box display="flex" height="100%" alignItems="center" justify="center">
              <Text color="blue">{intl.get(messagesCommon.noDetailChanges.id)}</Text>
            </Box>
          )}
        </LoadingBox>
      </StyledOverlay>
    </>
  );
}

ChangeOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  components: PropTypes.array.isRequired,
  measurement: PropTypes.any.isRequired
};

export default ChangeOverlay;
