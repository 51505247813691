import { messagesReport } from 'messages/messages';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import ReportRow from './ReportRow';

function BoilerLossRows({ reportData, reportDataLastYear }) {
  const steamBoilerLossSumBySteamBoiler = [];

  for (let i = 0; i < reportData.length; i += 1) {
    const reportItem = reportData[i];
    const { steamBoilerLossAndMeter } = reportItem;

    for (let j = 0; j < steamBoilerLossAndMeter.length; j += 1) {
      const lossAndMeter = steamBoilerLossAndMeter[j];
      const steamBoilerIndex = steamBoilerLossSumBySteamBoiler.findIndex(
        boilerItem => boilerItem.steamBoilerId === lossAndMeter.steamBoilerId
      );

      if (steamBoilerIndex < 0) {
        steamBoilerLossSumBySteamBoiler.push({
          steamBoiler: lossAndMeter.steamBoiler,
          steamBoilerId: lossAndMeter.steamBoilerId,
          lossCurrent: lossAndMeter.loss,
          lossLast: undefined
        });
      } else {
        steamBoilerLossSumBySteamBoiler[steamBoilerIndex].lossCurrent += lossAndMeter.loss;
      }
    }
  }

  for (let i = 0; i < reportDataLastYear.length; i += 1) {
    const reportItem = reportDataLastYear[i];
    const { steamBoilerLossAndMeter } = reportItem;

    for (let j = 0; j < steamBoilerLossAndMeter.length; j += 1) {
      const lossAndMeter = steamBoilerLossAndMeter[j];
      const steamBoilerIndex = steamBoilerLossSumBySteamBoiler.findIndex(
        boilerItem => boilerItem.steamBoilerId === lossAndMeter.steamBoilerId
      );

      if (steamBoilerIndex < 0) {
        steamBoilerLossSumBySteamBoiler.push({
          steamBoiler: lossAndMeter.steamBoiler,
          steamBoilerId: lossAndMeter.steamBoilerId,
          lossCurrent: undefined,
          lossLast: lossAndMeter.loss
        });
      } else {
        if (steamBoilerLossSumBySteamBoiler[steamBoilerIndex].lossLast === undefined) {
          steamBoilerLossSumBySteamBoiler[steamBoilerIndex].lossLast = 0;
        }
        steamBoilerLossSumBySteamBoiler[steamBoilerIndex].lossLast += lossAndMeter.loss;
      }
    }
  }

  return steamBoilerLossSumBySteamBoiler.map(boilerItem => (
    <ReportRow
      label={intl.get(messagesReport.reportBoilerLossWithName.id, { ...boilerItem })}
      sum={boilerItem.lossCurrent}
      sumLast={boilerItem.lossLast}
      key={`report-row-boiler-loss-${boilerItem.steamBoilerId}`}
      unit="t"
      round={true}
      digits={1}
      reportData={reportData}
      reportDataLastYear={reportDataLastYear}
    />
  ));
}

BoilerLossRows.propTypes = {
  reportData: PropTypes.any.isRequired,
  reportDataLastYear: PropTypes.any.isRequired
};

export default BoilerLossRows;
