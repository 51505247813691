import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';

const StyledPopupWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const StyledBackDrop = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 999;
  opacity: ${props => (props.show ? 0.4 : 0)};
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
`;

function Popup({
  size,
  show,
  title,
  message,
  confirmText,
  abortText,
  confirmColor,
  confirmAction,
  abortAction
}) {
  const closeOnEscKey = useCallback(
    event => {
      if (event.keyCode === 27) {
        abortAction();
      }
    },
    [abortAction]
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', closeOnEscKey, false);

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', closeOnEscKey, false);
    };
  }, [closeOnEscKey]);

  if (!show) {
    return null;
  }

  return (
    <StyledPopupWrapper>
      <Box
        zIndex={1001}
        position="fixed"
        height="100%"
        width="100%"
        left="0"
        display="flex"
        justify="center"
        alignItems="center"
      >
        <Box width={size} background="white" padding={5}>
          <Text size="lg" bold>
            {title}
          </Text>
          <Box marginY={5}>
            <Text size="md">{message}</Text>
          </Box>
          <Box display="flex" justify="flex-end">
            <Box>
              <Button
                text={confirmText}
                onClick={confirmAction}
                background={confirmColor}
                minWidth="70"
              />
            </Box>

            <Box marginLeft={3}>
              <Button text={abortText} onClick={abortAction} background="blueLight" minWidth="70" />
            </Box>
          </Box>
        </Box>
      </Box>
      <StyledBackDrop show={show} onClick={abortAction} />
    </StyledPopupWrapper>
  );
}

Popup.propTypes = {
  size: PropTypes.number,
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmColor: PropTypes.string,
  confirmText: PropTypes.string,
  abortText: PropTypes.string,
  confirmAction: PropTypes.func,
  abortAction: PropTypes.func
};

Popup.defaultProps = {
  size: 250,
  show: false,
  title: '',
  message: '',
  confirmColor: 'blue',
  confirmText: '',
  abortText: 'Abbrechen',
  confirmAction: null,
  abortAction: null
};

export default Popup;
