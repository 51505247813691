import intl from 'react-intl-universal';

function useNumberFormat(number, digits, round) {
  const { currentLocale } = intl.options;

  let formatted = number;

  if (round === true) {
    formatted = number.toFixed(digits);
  }

  formatted = new Intl.NumberFormat(currentLocale, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  }).format(number);

  return formatted;
}

export default useNumberFormat;
