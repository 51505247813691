import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import { BOILER_BOOK_DEFAULT_FILTER } from 'constants/Form';
import { ComponentTypeConfig } from 'constants/SteamComponent';
import useGetParameterUnit from 'hooks/useGetParameterUnit';
import { ReactComponent as IconExport } from 'images/Export.svg';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon } from 'messages/messages';
import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import CsvExportService from 'services/CsvExportService';
import FirebaseService from 'services/FirebaseService';
import ParameterService from 'services/ParameterService';
import { sortComponents } from 'utils/SortComponents';
import { sortParameterOfComponents } from 'utils/SortParameters';
import ChangeOverlay from './ChangeOverlay';
import ComponentValueNameColumns from './ComponentValueNameColumns';
import ExportOverlay from './ExportOverlay';
import HeaderColumns from './HeaderColumns';
import ValueRow from './ValueRow';
import ValueUnitRangeColumns from './ValueUnitRangeColumns';

function BoilerBook() {
  const history = useHistory();
  const { getUnitForParameter } = useGetParameterUnit();

  const [measurements, setMeasurements] = useState([]);
  const [components, setComponents] = useState([]);
  const [costFactors, setCostFactors] = useState([]);
  const [activeComponentValues, setActiveComponentValues] = useState([]);
  const [meetsRequirement, setMeetsRequirement] = useState(null);
  const [loadingMeasurement, setLoadingMeasurement] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [valueFilter, setValueFilter] = useState(BOILER_BOOK_DEFAULT_FILTER);

  const [showChangeOverlay, setShowChangeOverlay] = useState(false);
  const [showExportOverlay, setShowExportOverlay] = useState(false);
  const [changeMeasurement, setChangeMeasurement] = useState(null);

  /**
   * Callback function to load measurements when dates change
   */
  const loadMeasurements = useCallback(async () => {
    // populate the last measurement data inside the measurement
    // and remove all references to provide a flat data structure used in worker file
    const populateSetState = async queryDocs => {
      const loadLastData = queryDocs.map(async queryDoc => {
        const doc = queryDoc.data();
        let lastMeasurement = null;

        if (doc.lastMeasurement) {
          const lastMeasurementRef = await doc.lastMeasurement.get();
          lastMeasurement = {
            id: lastMeasurementRef.id,
            ...lastMeasurementRef.data()
          };
        }

        const item = {
          id: queryDoc.id,
          ref: queryDoc,
          data: queryDoc.data()
        };
        item.lastMeasurement = lastMeasurement;

        return item;
      });

      const measurementsWithLastData = await Promise.all(loadLastData);

      setMeasurements(measurementsWithLastData);
      setLoadingMeasurement(false);
    };

    setLoadingMeasurement(true);
    const querySnapshot = await FirebaseService.getMeasurementsOfLocationsRef(
      AppStateService.location.ref,
      {}
    ).get();

    populateSetState(querySnapshot.docs);
  }, []);

  // Effect to load measurements on load
  useEffect(() => {
    loadMeasurements();
  }, [loadMeasurements]);

  // Effect to load components and keep them up to date
  useEffect(() => {
    const load = async () => {
      setLoadingData(true);
      const componentDocs = [];
      const activeValues = [];
      const costFactorDocs = [];

      const costFactorSnapshot = await FirebaseService.queryCostFactorOfLocationRef(
        AppStateService.location.ref
      );

      costFactorSnapshot.forEach(doc => {
        const item = {
          id: doc.id,
          ...doc.data()
        };
        costFactorDocs.push(item);
      });

      // Query Components of location
      const componentQuerySnapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );

      // Deconstruct snapshot data and build a component based active value map
      componentQuerySnapshot.forEach(doc => {
        const data = doc.data();
        componentDocs.push({
          id: doc.id,
          ref: doc.ref,
          data
        });

        activeValues[doc.id] = {
          waterValues: data.waterValues ? data.waterValues.filter(value => value.active) : [],
          operatingValues: data.operatingValues
            ? data.operatingValues.filter(value => value.active)
            : [],
          meterValues: data.meterValues ? data.meterValues.filter(value => value.active) : []
        };
      });

      // Sort components & parameters
      const sorted = sortComponents(componentDocs);
      const allParams = ParameterService.allParameter;
      const sortedActiveParameterValues = sortParameterOfComponents(activeValues, allParams);

      setActiveComponentValues(sortedActiveParameterValues);
      setComponents(sorted);
      setCostFactors(costFactorDocs);
      setLoadingData(false);
    };

    load();
  }, []);

  // Effect to check if by configuration all required components have been created
  useEffect(() => {
    let hasRequired = true;
    const componentTypes = Object.keys(ComponentTypeConfig);

    componentTypes.forEach(type => {
      const config = ComponentTypeConfig[type];

      if (config.requiresOne) {
        const hasComponentsOfType = components.filter(c => c.data.type === type).length > 0;
        hasRequired = hasRequired && hasComponentsOfType;
      }
    });

    setMeetsRequirement(hasRequired);
  }, [components]);

  // prevent no value for param type beeing selected
  // => comment filter is execptional
  const updateFilterValue = (key, value) => {
    const update = { ...valueFilter };
    update[key] = value;

    if (
      key === 'withComments' ||
      update.waterValues ||
      update.operatingValues ||
      update.meterValues
    ) {
      setValueFilter(update);
    }
  };

  const openChangeOverlay = measurement => {
    setChangeMeasurement(measurement);
    setShowChangeOverlay(true);
  };

  const exportToCsv = () => {
    CsvExportService.exportMeasurementData({
      customer: AppStateService.customer.data,
      location: AppStateService.location.data,
      measurements,
      components,
      costFactors,
      activeComponentParameter: activeComponentValues,
      getUnitFunc: getUnitForParameter
    });
    return true;
  };

  return (
    <Box>
      <Box marginX={7} display="flex" direction="column" alignItems="left" justify="center">
        <Box display="flex">
          <Heading level="1" size="xl">
            {intl.get(messagesCommon.boilerBook.id)}
          </Heading>
          {meetsRequirement === true && (
            <>
              <Box marginLeft={3}>
                <Button
                  text={intl.get(messagesCommon.newEntry.id)}
                  Icon={IconPlus}
                  inline
                  textSize="sm"
                  type="button"
                  size="sm"
                  onClick={() => history.push('/boiler-book/entry/new')}
                />
              </Box>
              <Box marginLeft={3}>
                <Button
                  disabled={measurements.length === 0}
                  text={intl.get(messagesCommon.dataExport.id)}
                  Icon={IconExport}
                  inline
                  textSize="sm"
                  type="button"
                  size="sm"
                  onClick={() => setShowExportOverlay(true)}
                />
              </Box>
              {AppStateService.isSuperAdmin() ? (
                <Box marginLeft={3}>
                  <Button
                    disabled={measurements.length === 0}
                    text={intl.get(messagesCommon.dataExportCsv.id)}
                    Icon={IconExport}
                    inline
                    textSize="sm"
                    type="button"
                    size="sm"
                    onClick={exportToCsv}
                  />
                </Box>
              ) : null}
            </>
          )}
        </Box>

        <Box display="flex" marginTop={5}>
          <Alert
            title={intl.get(messagesCommon.notReadyBoilerBook.id)}
            type="error"
            isVisible={!loadingMeasurement && !loadingData && meetsRequirement === false}
          />
        </Box>
      </Box>

      <LoadingBox
        loading={loadingMeasurement || loadingData}
        renderChildren={meetsRequirement}
        marginTop={4}
      >
        <Box overflow="auto" background="white" marginX={7} marginTop={4} maxHeight="75vh">
          <table className="boiler-book">
            <tbody>
              <tr className="head">
                <HeaderColumns
                  components={components}
                  activeComponentValues={activeComponentValues}
                  valueFilter={valueFilter}
                  onFilterValueChange={updateFilterValue}
                />
              </tr>
              <tr className="rotate">
                <ComponentValueNameColumns
                  components={components}
                  activeComponentValues={activeComponentValues}
                  valueFilter={valueFilter}
                />
              </tr>
              <tr className="subhead">
                <ValueUnitRangeColumns
                  components={components}
                  activeComponentValues={activeComponentValues}
                  valueFilter={valueFilter}
                  costFactors={costFactors}
                />
              </tr>
              {measurements.map(measurement => (
                <ValueRow
                  key={`measurement-row-${measurement.id}`}
                  measurementRef={measurement}
                  components={components}
                  activeComponentValues={activeComponentValues}
                  valueFilter={valueFilter}
                  onRowClick={() => history.push(`/boiler-book/entry/${measurement.id}`)}
                  onRowViewChangesClick={openChangeOverlay}
                />
              ))}
            </tbody>
          </table>
        </Box>

        {showChangeOverlay && (
          <ChangeOverlay
            measurement={changeMeasurement}
            components={components}
            onClose={() => setShowChangeOverlay(false)}
          />
        )}

        {showExportOverlay && (
          <ExportOverlay
            measurements={measurements}
            components={components}
            costFactors={costFactors}
            activeComponentValues={activeComponentValues}
            getUnitFunc={getUnitForParameter}
            onClose={() => setShowExportOverlay(false)}
          />
        )}
      </LoadingBox>
    </Box>
  );
}

export default BoilerBook;
