import Box from 'components/Box';
import Panel from 'components/Panel';
import { ROLES } from 'constants/Model';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';
import UserFormEdit from './UserFormEdit';

function PanelUser({ user, onClose, servicePartner, selectedLocations }) {
  const formValidator = useFormValidation();
  const [userData, setUserData] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isCreateMode] = useState(!user);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const data = { ...userData };

    if (data && data.firstname && data.lastname) {
      data.tag = createInitial(`${data.firstname} ${data.lastname}`);
    }

    const validData = formValidator.validateUser(data);

    setValid(validData);
  }, [userData, formValidator]);

  useEffect(() => {
    let data = user ? user.data : null;

    if (!data) {
      data = {};
    }

    data.role = ROLES.ServicePartner;
    setUserData(data);
  }, [user]);

  const handleFormFieldChange = (key, value) => {
    const update = userData === null ? {} : { ...userData };
    update[key] = value;
    setUserData(update);
  };

  const createInitial = name => {
    return name
      .split(' ')
      .map(x => x.charAt(0))
      .join('')
      .substr(0, 3)
      .toUpperCase();
  };

  const save = async () => {
    try {
      setSaveInProgress(true);
      const userDataToSave = { ...userData };
      const id = isCreateMode ? userDataToSave.email : user.id;

      if (isCreateMode) {
        userDataToSave.isDeleted = false;
        userDataToSave.tag = createInitial(`${userData.firstname} ${userData.lastname}`);
        userDataToSave.customer = servicePartner.ref;
        userDataToSave.role = ROLES.ServicePartner;
      }

      await FirebaseService.updateUser(id, userDataToSave);
      onClose();
    } catch (e) {
      console.log('User could not be inserted or updated');
      console.log(e);
    }
  };

  return (
    <Panel
      title={isCreateMode ? intl.get(messagesCrud.userAdd.id) : intl.get(messagesCrud.userEdit.id)}
      buttonPrimaryAction={save}
      buttonPrimaryDisabled={!valid}
      onClose={onClose}
      showSpinner={saveInProgress}
      isOpen
    >
      <Box marginTop={5} style={{ overflowY: 'auto', height: 'calc(100% - 130px)' }}>
        <UserFormEdit
          user={userData}
          isCreateMode={isCreateMode}
          onChange={handleFormFieldChange}
          selectedLocations={selectedLocations}
        />
      </Box>
    </Panel>
  );
}

PanelUser.propTypes = {
  user: PropTypes.any,
  servicePartner: PropTypes.any.isRequired,
  selectedLocations: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

PanelUser.defaultProps = {
  user: null
};

export default PanelUser;
