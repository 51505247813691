import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Label from 'components/Label';
import LoadingBox from 'components/LoadingBox';
import Text from 'components/Text';
import useFormOptions from 'hooks/useFormOptions';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import { messagesCommon } from 'messages/messages';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import FirebaseService from 'services/FirebaseService';
import LocationList from './LocationList';
import PanelLocation from './PanelLocation';
import PanelUser from './PanelUser';
import UserList from './UserList';

function ServicePartner() {
  const { id } = useParams();
  const history = useHistory();
  const formOptions = useFormOptions();

  const [loading, setLoading] = useState(true);
  const [loadingUsers, setloadingUsers] = useState(true);

  const [servicePartner, setServicePartner] = useState(null);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);

  const [panelOpen, setPanelOpen] = useState(null);
  const [panelData, setPanelData] = useState(null);

  // Effect to load service partner
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const servicePartnerDoc = await FirebaseService.getServicePartnerById(id);
      const servicePartner = servicePartnerDoc.data();

      setServicePartner({
        id: servicePartnerDoc.id,
        ref: servicePartnerDoc.ref,
        data: servicePartner
      });
      setLoading(false);
    };

    load();
  }, [id]);

  // Effect to load users and keep them up to date
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const spLocations = [];
      const locationLoading = [];
      if (servicePartner.data.locations && servicePartner.data.locations.length > 0) {
        for (let i = 0; i < servicePartner.data.locations.length; i += 1) {
          const ref = servicePartner.data.locations[i];
          locationLoading.push(ref.get());
        }

        const docResults = await Promise.all(locationLoading);

        docResults.map(doc => {
          spLocations.push({
            id: doc.id,
            ref: doc.ref,
            data: doc.data()
          });
        });
      }

      setLocations(spLocations);
      setLoading(false);
    };

    if (servicePartner) {
      load();
    }
  }, [servicePartner]);

  // Effect to load users and keep them up to date
  useEffect(() => {
    const updateCollection = querySnapshot => {
      setloadingUsers(true);
      const docs = [];
      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      setUsers(docs);
      setloadingUsers(false);
    };
    let unsub;

    if (servicePartner) {
      unsub = FirebaseService.getUsersOfCustomerRef(servicePartner.ref).onSnapshot(
        updateCollection
      );
    }

    return () => {
      if (unsub !== undefined) {
        unsub();
      }
    };
  }, [servicePartner]);

  const openPanel = (type, data) => {
    setPanelData(data);
    setPanelOpen(type);
  };

  const closePanel = () => {
    setPanelOpen(false);
    setPanelData(null);
  };

  const removeLocation = async location => {
    const servicePartnerUpdate = { ...servicePartner.data };

    servicePartnerUpdate.locations = servicePartner.data.locations.filter(spLocation => {
      return spLocation.id !== location.id;
    });

    setLoading(true);

    await FirebaseService.updateServicePartner(servicePartner.id, servicePartnerUpdate);

    setServicePartner({
      id: servicePartner.id,
      ref: servicePartner.ref,
      data: servicePartnerUpdate
    });

    setLoading(false);
  };

  const addLocation = async location => {
    setLoading(true);

    const servicePartnerUpdate = { ...servicePartner.data };

    if (!servicePartnerUpdate.locations) {
      servicePartnerUpdate.locations = [];
    }

    servicePartnerUpdate.locations.push(location.ref);

    await FirebaseService.updateServicePartner(servicePartner.id, servicePartnerUpdate);

    setServicePartner({
      id: servicePartner.id,
      ref: servicePartner.ref,
      data: servicePartnerUpdate
    });
    setLoading(false);
  };

  const navigateToServicePartnerOverview = () => {
    history.push(`/service-partner-overview`);
  };

  return (
    <LoadingBox loading={loading || loadingUsers} renderChildren={!loading && !loadingUsers}>
      <Box maxWidth="1600px">
        <Box marginX={7} display="flex">
          <Heading level="1" size="xl">
            Service Partner - {`${servicePartner ? servicePartner.data.name : ''}`}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.backToOverview.id)}
              onClick={() => navigateToServicePartnerOverview()}
              width={200}
              size="sm"
              inline
            />
          </Box>
        </Box>

        <Box marginTop={8} marginX={7} display="flex">
          <Heading level="1" size="md">
            {intl.get(messagesCommon.overview.id)}
          </Heading>
        </Box>

        {servicePartner && (
          <Box background="white" marginX={7} marginTop={5} paddingY={2}>
            <Box display="flex" wrap="wrap">
              <Box marginX={5} display="flex" wrap="wrap" width="100%">
                <Box marginY={3} width="33%">
                  <Label htmlFor="company-name">{intl.get(messagesCommon.companyName.id)}</Label>
                  <Text size="sm">{servicePartner.data.name}</Text>
                </Box>
                <Box marginY={3} width="33%">
                  <Label htmlFor="street">{intl.get(messagesCommon.street.id)}</Label>
                  <Text size="sm">{servicePartner.data.street}</Text>
                </Box>
                <Box marginY={3} width="33%">
                  <Label htmlFor="country">{intl.get(messagesCommon.country.id)}</Label>
                  <Text size="sm">{servicePartner.data.country}</Text>
                </Box>

                <Box marginY={3} width="33%">
                  <Label htmlFor="zip">{intl.get(messagesCommon.zip.id)}</Label>
                  <Text size="sm">{servicePartner.data.zip}</Text>
                </Box>
                <Box marginY={3} width="33%">
                  <Label htmlFor="city">{intl.get(messagesCommon.city.id)}</Label>
                  <Text size="sm">{servicePartner.data.city}</Text>
                </Box>
                <Box marginY={3} width="33%">
                  <Label htmlFor="business">{intl.get(messagesCommon.business.id)}</Label>
                  <Text size="sm">
                    {
                      formOptions.businessSP.filter(
                        option => option.value === servicePartner.data.business
                      )[0].label
                    }
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box marginTop={8} marginX={7} display="flex">
          <Heading level="1" size="md">
            {intl.get(messagesCommon.locations.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => openPanel('location', servicePartner)}
            />
          </Box>
        </Box>
        <Box background="white" marginX={7} marginTop={3} display="flex">
          <LocationList locations={locations} onRemoveLocation={removeLocation} />
        </Box>

        <Box marginTop={8} marginX={7} display="flex">
          <Heading level="1" size="md">
            {intl.get(messagesCommon.users.id)}
          </Heading>
          <Box marginLeft={3}>
            <Button
              text={intl.get(messagesCommon.add.id)}
              Icon={IconPlus}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => openPanel('user', null)}
            />
          </Box>
        </Box>
        <Box background="white" marginX={7} marginTop={3} display="flex">
          <UserList users={users} openPanel={openPanel} />
        </Box>
      </Box>

      {panelOpen && panelOpen === 'user' && (
        <PanelUser
          user={panelData}
          servicePartner={servicePartner}
          selectedLocations={locations}
          onClose={closePanel}
        />
      )}

      {panelOpen && panelOpen === 'location' && (
        <PanelLocation
          onLocationAdd={addLocation}
          selectedLocations={locations}
          onClose={result => {
            closePanel();
            // trigger update
            if (result && result === true) {
              setServicePartner(servicePartner);
            }
          }}
        />
      )}
    </LoadingBox>
  );
}

export default ServicePartner;
