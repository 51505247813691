import { defineMessages } from 'react-intl';

export const messagesCommon = defineMessages({
  paramNameDuplicateError: {
    id: 'common.paramNameDuplicateError',
    defaultMessage: 'Dieser Bezeichner ist bereits vergeben !'
  },
  paramSearchPlaceholder: {
    id: 'common.paramSearchPlaceholder',
    defaultMessage: 'Name oder Bezeichner'
  },

  paramInternal: {
    id: 'common.paramInternal',
    defaultMessage: 'Bezeichner'
  },
  paramSorting: {
    id: 'common.paramSorting',
    defaultMessage: 'Sortierungsgewicht'
  },
  paramName: {
    id: 'common.paramName',
    defaultMessage: 'Name'
  },
  paramUnit: {
    id: 'common.paramUnit',
    defaultMessage: 'Einheit'
  },
  paramCategory: {
    id: 'common.paramCategory',
    defaultMessage: 'Kategorie'
  },
  paramComponents: {
    id: 'common.paramComponents',
    defaultMessage: 'Komponenten'
  },

  paramAlwaysOn: {
    id: 'common.paramAlwaysOn',
    defaultMessage: 'Immer aktiv'
  },
  paramMinVal: {
    id: 'common.paramMinVal',
    defaultMessage: 'Min. Wert'
  },
  paramMaxVal: {
    id: 'common.paramMaxVal',
    defaultMessage: 'Max. Wert'
  },

  paramFormElement: {
    id: 'common.paramFormElement',
    defaultMessage: 'Eingabe Typ'
  },

  paramFormElementSpinner: {
    id: 'common.paramFormElement.spinner',
    defaultMessage: 'Zahlen Walze'
  },
  paramFormElementPlusMinus: {
    id: 'common.paramFormElement.plusMinus',
    defaultMessage: '+/- Drücker'
  },

  paramFormElementSteps: {
    id: 'common.paramFormElement.steps',
    defaultMessage: 'Schrittweite'
  },
  paramFormElementRange: {
    id: 'common.paramFormElement.range',
    defaultMessage: 'Zahlenbereich'
  },

  archiveAllTitle: {
    id: 'common.archiveAllTitle',
    defaultMessage: 'Alle Archivieren'
  },
  archiveAllText: {
    id: 'common.archiveAllText',
    defaultMessage: 'Möchten Sie wirklich alle Hinweise archivieren ?'
  },
  package: {
    id: 'common.package',
    defaultMessage: 'Paket'
  },
  packageBasic: {
    id: 'common.packageBasic',
    defaultMessage: 'Basic'
  },
  packageEssential: {
    id: 'common.packageEssential',
    defaultMessage: 'Essential'
  },
  yes: {
    id: 'common.yes',
    defaultMessage: 'Ja'
  },
  no: {
    id: 'common.no',
    defaultMessage: 'Nein'
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Speichern'
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Bearbeiten'
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Abbrechen'
  },
  select: {
    id: 'common.select',
    defaultMessage: 'Auswählen'
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Hinzufügen'
  },
  newEntry: {
    id: 'common.newEntry',
    defaultMessage: 'Neuer Eintrag'
  },
  choose: {
    id: 'common.choose',
    defaultMessage: 'Bitte wählen'
  },
  options: {
    id: 'common.options',
    defaultMessage: 'Optionen'
  },
  dateSelect: {
    id: 'common.dateSelect',
    defaultMessage: 'Datumsauswahl'
  },
  dateRange: {
    id: 'common.dateRange',
    defaultMessage: 'Zeitraum'
  },
  emailAddress: {
    id: 'common.emailAddress',
    defaultMessage: 'E-Mail'
  },
  password: {
    id: 'common.password',
    defaultMessage: 'Passwort'
  },
  languageGerman: {
    id: 'common.language.german',
    defaultMessage: 'Deutsch'
  },
  languageEnglish: {
    id: 'common.language.english',
    defaultMessage: 'Englisch'
  },
  boilerBook: {
    id: 'common.boilerBook',
    defaultMessage: 'Betriebsbuch'
  },
  waterConditioning: {
    id: 'common.waterConditioning',
    defaultMessage: 'Wasseraufbereitung'
  },
  component: {
    id: 'common.component',
    defaultMessage: 'Komponente'
  },
  condensate: {
    id: 'common.condensate',
    defaultMessage: 'Kondensat'
  },
  feedwater: {
    id: 'common.feedwater',
    defaultMessage: 'Speisewasser'
  },
  boilerWater: {
    id: 'common.boilerWater',
    defaultMessage: 'Kesselwasser'
  },
  comment: {
    id: 'common.comment',
    defaultMessage: 'Kommentar'
  },
  conductivity: {
    id: 'common.conductivity',
    defaultMessage: 'Leitfähigkeit'
  },
  totalHardness: {
    id: 'common.totalHardness',
    defaultMessage: 'Gesamthärte'
  },
  hardnessUnit: {
    id: 'common.hardnessUnit',
    defaultMessage: 'Härtegrad Einheit'
  },
  degreeOfHardness: {
    id: 'common.degreeOfHardness',
    defaultMessage: 'Härtegrad'
  },
  desaltingRate: {
    id: 'common.desaltingRate',
    defaultMessage: 'Absalzrate'
  },
  steamCost: {
    id: 'common.steamCost',
    defaultMessage: 'Dampfkosten'
  },
  dosingRate: {
    id: 'common.dosingRate',
    defaultMessage: 'Dosierrate'
  },
  dosingDiligence: {
    id: 'common.dosingDiligence',
    defaultMessage: 'Dosiersorgfalt'
  },
  alkalinityUnit: {
    id: 'common.alkalinityUnit',
    defaultMessage: 'Alkalität Einheit'
  },
  pHNumber: {
    id: 'common.pHNumber',
    defaultMessage: 'ph-Wert'
  },
  iron: {
    id: 'common.iron',
    defaultMessage: 'Eisen'
  },
  tannin: {
    id: 'common.tannin',
    defaultMessage: 'Tannin'
  },
  date: {
    id: 'common.date',
    defaultMessage: 'Datum'
  },
  abbreviation: {
    id: 'common.abbreviation',
    defaultMessage: 'Kürzel'
  },
  company: {
    id: 'common.company',
    defaultMessage: 'Unternehmen'
  },
  settings: {
    id: 'common.settings',
    defaultMessage: 'Einstellungen'
  },
  overview: {
    id: 'common.overview',
    defaultMessage: 'Übersicht'
  },
  locations: {
    id: 'common.locations',
    defaultMessage: 'Standorte'
  },
  manageableLocations: {
    id: 'common.manageableLocations',
    defaultMessage: 'Verwaltbare Standorte'
  },
  users: {
    id: 'common.users',
    defaultMessage: 'Benutzer'
  },
  costFactors: {
    id: 'common.costFactors',
    defaultMessage: 'Kostenfaktoren'
  },
  costs: {
    id: 'common.costs',
    defaultMessage: 'Kosten'
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Name'
  },
  unit: {
    id: 'common.unit',
    defaultMessage: 'Einheit'
  },
  companyName: {
    id: 'common.companyName',
    defaultMessage: 'Firmenname'
  },
  city: {
    id: 'common.city',
    defaultMessage: 'Ort'
  },
  street: {
    id: 'common.street',
    defaultMessage: 'Straße'
  },
  business: {
    id: 'common.business',
    defaultMessage: 'Branche'
  },
  zip: {
    id: 'common.zip',
    defaultMessage: 'PLZ'
  },
  country: {
    id: 'common.country',
    defaultMessage: 'Land'
  },
  employeeCount: {
    id: 'common.employeeCount',
    defaultMessage: 'Mitarbeiteranzahl'
  },
  notifications: {
    id: 'common.notifications',
    defaultMessage: 'Benachrichtigungen'
  },
  current: {
    id: 'common.current',
    defaultMessage: 'Aktuell'
  },
  future: {
    id: 'common.future',
    defaultMessage: 'Zükünftige'
  },
  archive: {
    id: 'common.archive',
    defaultMessage: 'Archiv'
  },
  archiveAction: {
    id: 'common.archive.action',
    defaultMessage: 'Archivieren'
  },
  address: {
    id: 'common.address',
    defaultMessage: 'Anschrift'
  },
  firstname: {
    id: 'common.firstname',
    defaultMessage: 'Vorname'
  },
  lastname: {
    id: 'common.lastname',
    defaultMessage: 'Nachname'
  },
  role: {
    id: 'common.role',
    defaultMessage: 'Rolle'
  },
  external: {
    id: 'common.external',
    defaultMessage: 'Extern'
  },
  phone: {
    id: 'common.phone',
    defaultMessage: 'Telefon'
  },
  pdfExport: {
    id: 'common.pdfExport',
    defaultMessage: 'PDF Export'
  },
  type: {
    id: 'common.type',
    defaultMessage: 'Typ'
  },
  manufacturerOrType: {
    id: 'common.manufacturerOrType',
    defaultMessage: 'Hersteller / Typ'
  },
  internalLabel: {
    id: 'common.internalLabel',
    defaultMessage: 'Interne Bezeichnung'
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Bezeichnung'
  },
  notes: {
    id: 'common.notes',
    defaultMessage: 'Notizen'
  },
  tradename: {
    id: 'common.tradename',
    defaultMessage: 'Dosiermittel'
  },
  dosingChemicals: {
    id: 'common.dosingChemicals',
    defaultMessage: 'Schutzstoffe'
  },
  defaultLocation: {
    id: 'common.defaultLocation',
    defaultMessage: 'Hauptstandort'
  },
  density: {
    id: 'common.density',
    defaultMessage: 'Dichte'
  },
  operatedBy: {
    id: 'common.operatedBy',
    defaultMessage: 'Firma'
  },
  startDate: {
    id: 'common.startDate',
    defaultMessage: 'Startdatum'
  },
  nextDueDate: {
    id: 'common.nextDueDate',
    defaultMessage: 'Nächste Fälligkeit'
  },
  interval: {
    id: 'common.interval',
    defaultMessage: 'Intervall'
  },
  status: {
    id: 'common.status',
    defaultMessage: 'Status'
  },
  dataExport: {
    id: 'common.dataExport',
    defaultMessage: 'Daten exportieren'
  },
  dataExportCsv: {
    id: 'common.dataExportCsv',
    defaultMessage: 'CSV Export'
  },
  waterValues: {
    id: 'common.waterValues',
    defaultMessage: 'Wasserparameter'
  },
  operatingValues: {
    id: 'common.operatingValues',
    defaultMessage: 'Betriebsdaten'
  },
  dataSets: {
    id: 'common.dataSets',
    defaultMessage: 'Datensätze'
  },
  waterAnalysis: {
    id: 'common.waterAnalysis',
    defaultMessage: 'Wasseranalysen'
  },
  counter: {
    id: 'common.counter',
    defaultMessage: 'Zähler'
  },
  general: {
    id: 'common.general',
    defaultMessage: 'Allgemein'
  },
  externalCheck: {
    id: 'common.externalCheck',
    defaultMessage: 'Externe Überprüfung'
  },
  meterValues: {
    id: 'common.meterValues',
    defaultMessage: 'Zähler'
  },
  steamPlantAlert: {
    id: 'common.steamPlantAlert',
    defaultMessage:
      'Diese Dampfanlage ist noch nicht komplett konfiguriert, bitte stellen Sie sicher das die Pflichtkomponenten eingerichtet sind.'
  },
  boundary: {
    id: 'common.boundary',
    defaultMessage: 'Sollwerte'
  },
  boundarySingle: {
    id: 'common.boundarySingle',
    defaultMessage: 'Sollwert'
  },
  checkLastYear: {
    id: 'common.checkLastYear',
    defaultMessage: 'Vergleich Vorjahr'
  },
  containerSize: {
    id: 'common.containerSize',
    defaultMessage: 'Füllmenge'
  },
  confirmLeaveTitle: {
    id: 'common.confirmLeave.title',
    defaultMessage: 'Ungespeicherte Änderungen'
  },
  confirmLeaveMessage: {
    id: 'common.confirmLeave.message',
    defaultMessage: 'Möchten Sie die geänderten Werte verwerfen?'
  },
  externalCheckType: {
    id: 'common.externalCheck.type',
    defaultMessage: 'Art'
  },
  externalCheckTypeLong: {
    id: 'common.externalCheck.type.long',
    defaultMessage: 'Art der Prüfung / Wartung'
  },
  externalCheckDueDate: {
    id: 'common.externalCheck.due',
    defaultMessage: 'Nächste Prüfung'
  },
  withComments: {
    id: 'common.withComments',
    defaultMessage: 'Mit Kommentar'
  },
  thermicGasLoss: {
    id: 'common.thermicGasLoss',
    defaultMessage: 'Das Speisewasser wird thermisch entgast'
  },
  steamProductionInterference: {
    id: 'common.steamProductionInterference',
    defaultMessage: 'Der Dampf kommt mit dem Erzeugnis in Berührung'
  },
  choosePeriod: {
    id: 'common.choosePeriod',
    defaultMessage: 'Zeitraum auswählen'
  },
  noData: {
    id: 'common.noData',
    defaultMessage: 'Keine Daten vorhanden'
  },
  noValue: {
    id: 'common.noValue',
    defaultMessage: 'Kein Wert'
  },
  blowdown: {
    id: 'common.blowdown',
    defaultMessage: 'Abschlammung'
  },
  notReadyBoilerBook: {
    id: 'common.notReadyBoilerBook',
    defaultMessage:
      'Die Dampfanlage an diesem Standort ist noch nicht komplett konfiguriert, bitte stellen Sie sicher das die Pflichtkomponenten eingerichtet sind bevor Sie das Betriebsbuch pflegen.'
  },
  updated: {
    id: 'common.updated',
    defaultMessage: 'Aktualisiert'
  },
  updatedAtBy: {
    id: 'common.updatedByAt',
    defaultMessage: 'Aktualisiert am {time} durch {by}'
  },
  viewUpdates: {
    id: 'common.viewUpdates',
    defaultMessage: 'Änderungen im Detail anschauen'
  },
  noDetailChanges: {
    id: 'common.noDetailChanges',
    defaultMessage: 'Keine Detail Einträge gefunden'
  },
  noLocationNotice: {
    id: 'common.noLocationNotice',
    defaultMessage: 'Bitte erstellen Sie für diesen Kunden einen Standort'
  },
  invalidMail: {
    id: 'common.invalidMail',
    defaultMessage: 'Ungültige E-Mail'
  },
  mailAlreadyInUse: {
    id: 'common.mailAlreadyInUse',
    defaultMessage: 'E-Mail ist bereits vergeben'
  },
  createLocationPopupTitle: {
    id: 'common.createLocationPopupTitle',
    defaultMessage: 'Standort erstellen'
  },
  createLocationPopupText: {
    id: 'common.createLocationPopupText',
    defaultMessage: 'Möchten Sie jetzt einen Standort für diesen Kunden erstellen ?'
  },
  today: {
    id: 'common.today',
    defaultMessage: 'Heute'
  },
  isDueIn: {
    id: 'common.isDueIn',
    defaultMessage: 'in {days} Tagen'
  },
  wasDueAt: {
    id: 'common.wasDueAt',
    defaultMessage: 'vor {days} Tagen'
  },
  lastValues: {
    id: 'common.lastValues',
    defaultMessage: 'Letzte Werte'
  },
  currentValues: {
    id: 'common.currentValues',
    defaultMessage: 'Aktuelle Werte'
  },
  steamComponentMultipleNotice: {
    id: 'common.steamComponentMultipleNotice',
    defaultMessage:
      'Diese Option kann erst gewählt werden wenn mehr als ein Dampfkessel Konfiguriert wurde'
  },
  steamComponentMultipleWarning: {
    id: 'common.steamComponentMultipleWarning',
    defaultMessage: 'Bitte konfigurieren Sie die notwendigen Zähler an den Dampfkessel Komponenten'
  },
  steamComponentMultipleLabel: {
    id: 'common.steamComponentMultipleLabel',
    defaultMessage: 'Jeder Kessel hat einen eigenen Speisewasserzähler'
  },
  reset: {
    id: 'common.reset',
    defaultMessage: 'Zurücksetzen'
  },
  steamComponentFormNotice: {
    id: 'common.steamComponentFormNotice',
    defaultMessage: 'Hier die gewünschten Parameter und Sollwerte auswählen'
  },
  sum: {
    id: 'common.sum',
    defaultMessage: 'Summe'
  },
  dilutedDosing: {
    id: 'common.dilutedDosing',
    defaultMessage: 'Es wird verdünnt dosiert'
  },
  config: {
    id: 'common.config',
    defaultMessage: 'Konfiguration'
  },
  priceInEuroKwh: {
    id: 'common.priceInEuroKwh',
    defaultMessage: 'Preis in €/kWh'
  },
  components: {
    id: 'common.components',
    defaultMessage: 'Komponenten'
  },
  previousYearShort: {
    id: 'common.previousYearShort',
    defaultMessage: 'VJ'
  },
  backToOverview: {
    id: 'common.backToOverview',
    defaultMessage: 'Zurück zur Übersicht'
  },
  results: {
    id: 'common.results',
    defaultMessage: 'Ergebnisse'
  },
  locationSearchPlaceholder: {
    id: 'common.locationSearchPlaceholder',
    defaultMessage: 'Name, PLZ, Stadt'
  }
});

export const messagesAnalysisFilter = defineMessages({
  analysisFilterSteam: {
    id: 'analysisFilter.steam',
    defaultMessage: 'Dampfabnahme'
  },
  analysisFilterFreshWater: {
    id: 'analysisFilter.freshWater',
    defaultMessage: 'Frischwasser'
  },
  analysisFilterClearedWater: {
    id: 'analysisFilter.clearedWater',
    defaultMessage: 'Aufbereitetes Wasser'
  },
  analysisFilterFeedWater: {
    id: 'analysisFilter.feedWater',
    defaultMessage: 'Speisewasser'
  },
  analysisFilterCondensate: {
    id: 'analysisFilter.condensate',
    defaultMessage: 'Dampfabnahme'
  }
});

export const messagesCrud = defineMessages({
  paramAdd: {
    id: 'crud.param.add',
    defaultMessage: 'Neuer Parameter'
  },
  paramEdit: {
    id: 'crud.param.edit',
    defaultMessage: 'Parameter bearbeiten'
  },
  newComponentAdd: {
    id: 'crud.newcomponent.add',
    defaultMessage: 'Neue Komponente hinzufügen'
  },
  boilerAdd: {
    id: 'crud.boiler.add',
    defaultMessage: 'Dampfkessel hinzufügen'
  },
  boilerEdit: {
    id: 'crud.boiler.edit',
    defaultMessage: 'Dampfkessel bearbeiten'
  },
  watertreatmentAdd: {
    id: 'crud.watertreatment.add',
    defaultMessage: 'Wasseraufbereitung hinzufügen'
  },
  watertreatmentEdit: {
    id: 'crud.watertreatment.edit',
    defaultMessage: 'Wasseraufbereitung bearbeiten'
  },
  feedwaterAdd: {
    id: 'crud.feedwater.add',
    defaultMessage: 'Speisewasserbehälter hinzufügen'
  },
  feedwaterEdit: {
    id: 'crud.feedwater.edit',
    defaultMessage: 'Speisewasserbehälter bearbeiten'
  },
  dosingAdd: {
    id: 'crud.dosing.add',
    defaultMessage: 'Dosierpumpe hinzufügen'
  },
  dosingEdit: {
    id: 'crud.dosing.edit',
    defaultMessage: 'Dosierpumpe bearbeiten'
  },
  customAdd: {
    id: 'crud.custom.add',
    defaultMessage: 'Individuelle Komponente hinzufügen'
  },
  customEdit: {
    id: 'crud.custom.edit',
    defaultMessage: 'Individuelle Komponente bearbeiten'
  },
  condensateAdd: {
    id: 'crud.condensate.add',
    defaultMessage: 'Kondensatbehälter hinzufügen'
  },
  condensateEdit: {
    id: 'crud.condensate.edit',
    defaultMessage: 'Kondensatbehälter bearbeiten'
  },
  burnerAdd: {
    id: 'crud.burner.add',
    defaultMessage: 'Brenner hinzufügen'
  },
  burnerEdit: {
    id: 'crud.burner.edit',
    defaultMessage: 'Brenner bearbeiten'
  },
  customerAdd: {
    id: 'crud.customer.add',
    defaultMessage: 'Kunde hinzufügen'
  },
  customerEdit: {
    id: 'crud.customer.edit',
    defaultMessage: 'Kunde bearbeiten'
  },
  servicePartnerAdd: {
    id: 'crud.servicepartner.add',
    defaultMessage: 'Servicepartner hinzufügen'
  },
  servicePartnerEdit: {
    id: 'crud.servicepartner.edit',
    defaultMessage: 'Servicepartner bearbeiten'
  },
  costFactorAdd: {
    id: 'crud.costfactor.add',
    defaultMessage: 'Kostenfaktor hinzufügen'
  },
  costFactorEdit: {
    id: 'crud.costfactor.edit',
    defaultMessage: 'Kostenfaktor bearbeiten'
  },
  locationAdd: {
    id: 'crud.location.add',
    defaultMessage: 'Standort hinzufügen'
  },
  locationEdit: {
    id: 'crud.location.edit',
    defaultMessage: 'Standort bearbeiten'
  },
  userAdd: {
    id: 'crud.user.add',
    defaultMessage: 'Benutzer hinzufügen'
  },
  userEdit: {
    id: 'crud.user.edit',
    defaultMessage: 'Benutzer bearbeiten'
  },
  checkAdd: {
    id: 'crud.check.add',
    defaultMessage: 'Überprüfung hinzufügen'
  },
  checkEdit: {
    id: 'crud.check.edit',
    defaultMessage: 'Überprüfung bearbeiten'
  },
  measurementAdd: {
    id: 'crud.measurement.add',
    defaultMessage: 'Eintrag zum Betriebsbuch hinzufügen'
  },
  measurementEdit: {
    id: 'crud.measurement.edit',
    defaultMessage: 'Eintrag im Betriebsbuch bearbeiten'
  }
});

export const messagesFormConstants = defineMessages({
  countryOptionsDE: {
    id: 'constants.countryOptions.de',
    defaultMessage: 'Deutschland'
  },
  countryOptionsAT: {
    id: 'constants.countryOptions.at',
    defaultMessage: 'Österreich'
  },
  countryOptionsCH: {
    id: 'constants.countryOptions.ch',
    defaultMessage: 'Schweiz'
  },
  hardnessOptionsDE: {
    id: 'constants.hardnessOptions.de',
    defaultMessage: 'Gesamthärte (°dH)'
  },
  hardnessOptionsDEShort: {
    id: 'constants.hardnessOptions.de.short',
    defaultMessage: '°dH'
  },
  hardnessOptionsFR: {
    id: 'constants.hardnessOptions.fr',
    defaultMessage: 'Gesamthärte (°fH)'
  },
  hardnessOptionsFRShort: {
    id: 'constants.hardnessOptions.fr.short',
    defaultMessage: '°fH'
  },
  hardnessOptionsMMOL: {
    id: 'constants.hardnessOptions.mmol',
    defaultMessage: 'Erdalkalien (mmol/l)'
  },
  hardnessOptionsMMOLShort: {
    id: 'constants.hardnessOptions.mmol.short',
    defaultMessage: 'mmol/l'
  },
  fuelOptionsGas: {
    id: 'constants.fuelOptions.gas',
    defaultMessage: 'Gas'
  },
  fuelOptionsOil: {
    id: 'constants.fuelOptions.oil',
    defaultMessage: 'Öl'
  },
  fuelOptionsCombi: {
    id: 'constants.fuelOptions.combi',
    defaultMessage: 'Kombibrenner Gas/Öl'
  },
  fuelOptionsBio: {
    id: 'constants.fuelOptions.bio',
    defaultMessage: 'Biomasse'
  },
  fuelOptionsWaste: {
    id: 'constants.fuelOptions.waste',
    defaultMessage: 'Müll'
  },
  fuelOptionsCoal: {
    id: 'constants.fuelOptions.coal',
    defaultMessage: 'Kohle'
  },
  intervalOptionsDaily: {
    id: 'constants.intervalOptions.daily',
    defaultMessage: 'Täglich'
  },
  intervalOptionsWeekly: {
    id: 'constants.intervalOptions.weekly',
    defaultMessage: 'Wöchentlich'
  },
  intervalOptionsTwoWeekly: {
    id: 'constants.intervalOptions.twoweekly',
    defaultMessage: 'Zweiwöchentlich'
  },
  intervalOptionsMonthly: {
    id: 'constants.intervalOptions.monthly',
    defaultMessage: 'Monatlich'
  },
  intervalOptionsHalfYear: {
    id: 'constants.intervalOptions.halfyear',
    defaultMessage: 'Halbjährlich'
  },
  intervalOptionsYear: {
    id: 'constants.intervalOptions.year',
    defaultMessage: 'Jährlich'
  },
  intervalOptionsAll3Years: {
    id: 'constants.intervalOptions.all3years',
    defaultMessage: 'Alle 3 Jahre'
  },
  intervalOptionsAll9Years: {
    id: 'constants.intervalOptions.all9years',
    defaultMessage: 'Alle 9 Jahre'
  },
  statusOptionsActive: {
    id: 'constants.statusOptions.active',
    defaultMessage: 'Aktiv'
  },
  statusOptionsInactive: {
    id: 'constants.statusOptions.inactive',
    defaultMessage: 'Inaktiv'
  },
  roleOptionsSuperAdmin: {
    id: 'constants.roleOptions.sadmin',
    defaultMessage: 'Super Admin'
  },
  roleOptionsAdmin: {
    id: 'constants.roleOptions.admin',
    defaultMessage: 'Admin'
  },
  roleOptionsServicePartner: {
    id: 'constants.roleOptions.servicepartner',
    defaultMessage: 'ServicePartner'
  },
  roleOptionsExternal: {
    id: 'constants.roleOptions.external',
    defaultMessage: 'Extern'
  },
  roleOptionsBoilerAttendant: {
    id: 'constants.roleOptions.boilerattendant',
    defaultMessage: 'Kesselwärter'
  },
  roleOptionsUser: {
    id: 'constants.roleOptions.user',
    defaultMessage: 'Benutzer'
  },
  dateRangeOptionsCurrentMonth: {
    id: 'constants.dateRangeOptions.currentMonth',
    defaultMessage: 'Aktueller Monat'
  },
  dateRangeOptionsLastMonth: {
    id: 'constants.dateRangeOptions.lastMonth',
    defaultMessage: 'Letzer Monat'
  },
  dateRangeOptionsCurrentYear: {
    id: 'constants.dateRangeOptions.currentYear',
    defaultMessage: 'Aktuelles Jahr'
  },
  dateRangeOptionsLastYear: {
    id: 'constants.dateRangeOptions.lastYear',
    defaultMessage: 'Letztes Jahr'
  },
  dateRangeOptionsCustom: {
    id: 'constants.dateRangeOptions.custom',
    defaultMessage: 'Individueller Zeitraum'
  },
  dateRangeOptionsLast30: {
    id: 'constants.dateRangeOptions.last30',
    defaultMessage: 'Letzte 30 Tage'
  },
  dateRangeOptionsLast180: {
    id: 'constants.dateRangeOptions.last180',
    defaultMessage: 'Letzte 180 Tage'
  },
  dateRangeOptionsLast365: {
    id: 'constants.dateRangeOptions.last365',
    defaultMessage: 'Letzte 365 Tage'
  },

  businessOptionCar: {
    id: 'constants.businessOption.car',
    defaultMessage: 'Automobil'
  },
  businessOptionBakery: {
    id: 'constants.businessOption.bakery',
    defaultMessage: 'Bäckerei'
  },
  businessOptionBuilding: {
    id: 'constants.businessOption.building',
    defaultMessage: 'Baustoffe'
  },
  businessOptionBrewery: {
    id: 'constants.businessOption.brewery',
    defaultMessage: 'Brauerei'
  },
  businessOptionChemistry: {
    id: 'constants.businessOption.chemistry',
    defaultMessage: 'Chemie'
  },
  businessOptionFertilizer: {
    id: 'constants.businessOption.fertilizer',
    defaultMessage: 'Düngemittel'
  },
  businessOptionEnergy: {
    id: 'constants.businessOption.energy',
    defaultMessage: 'Energieversorger'
  },
  businessOptionGoods: {
    id: 'constants.businessOption.goods',
    defaultMessage: 'Fertigwaren'
  },
  businessOptionFish: {
    id: 'constants.businessOption.fish',
    defaultMessage: 'Fischverarbeitung'
  },
  businessOptionMeat: {
    id: 'constants.businessOption.meat',
    defaultMessage: 'Fleischverarbeitung'
  },
  businessOptionJuice: {
    id: 'constants.businessOption.juice',
    defaultMessage: 'Fruchtsaft'
  },
  businessOptionJelly: {
    id: 'constants.businessOption.jelly',
    defaultMessage: 'Gummiwaren'
  },
  businessOptionWood: {
    id: 'constants.businessOption.wood',
    defaultMessage: 'Holzverarbeitung'
  },
  businessOptionHotel: {
    id: 'constants.businessOption.hotel',
    defaultMessage: 'Hotel'
  },
  businessOptionCheese: {
    id: 'constants.businessOption.cheese',
    defaultMessage: 'Käserei'
  },
  businessOptionPlastic: {
    id: 'constants.businessOption.plastic',
    defaultMessage: 'Kunststoff'
  },
  businessOptionHospital: {
    id: 'constants.businessOption.hospital',
    defaultMessage: 'Krankenhaus'
  },
  businessOptionMetal: {
    id: 'constants.businessOption.metal',
    defaultMessage: 'Metalverarbeitung'
  },
  businessOptionMilk: {
    id: 'constants.businessOption.milk',
    defaultMessage: 'Molkerei'
  },
  businessOptionFood: {
    id: 'constants.businessOption.food',
    defaultMessage: 'Nahrungsmittel'
  },
  businessOptionPaper: {
    id: 'constants.businessOption.paper',
    defaultMessage: 'Papier und Verpackung'
  },
  businessOptionPharma: {
    id: 'constants.businessOption.pharma',
    defaultMessage: 'Pharma'
  },
  businessOptionRaffinery: {
    id: 'constants.businessOption.raffinery',
    defaultMessage: 'Raffinerie'
  },
  businessOptionFabric: {
    id: 'constants.businessOption.fabric',
    defaultMessage: 'Textil und Bekleidung'
  },
  businessOptionAnimalFood: {
    id: 'constants.businessOption.animalfood',
    defaultMessage: 'Tierfutter'
  },
  businessOptionLaundry: {
    id: 'constants.businessOption.landry',
    defaultMessage: 'Wäscherei'
  },
  businessOptionSugar: {
    id: 'constants.businessOption.sugar',
    defaultMessage: 'Zucker'
  },
  businessOptionOther: {
    id: 'constants.businessOption.other',
    defaultMessage: 'Andere'
  },

  businessOptionSpInstallation: {
    id: 'constants.businessOption.sp.installation',
    defaultMessage: 'Anlagenbauer'
  },
  businessOptionSpBurner: {
    id: 'constants.businessOption.sp.burner',
    defaultMessage: 'Brennerhersteller'
  },
  businessOptionSpEnergy: {
    id: 'constants.businessOption.sp.energy',
    defaultMessage: 'Energieversorger'
  },
  businessOptionSpBoilerM: {
    id: 'constants.businessOption.sp.boilerm',
    defaultMessage: 'Kesselhersteller'
  },
  businessOptionSpBoilerS: {
    id: 'constants.businessOption.sp.boilers',
    defaultMessage: 'Kesselservice'
  },
  businessOptionSpTesting: {
    id: 'constants.businessOption.sp.testing',
    defaultMessage: 'Prüforgan'
  },
  businessOptionSpElectrical: {
    id: 'constants.businessOption.sp.electrical',
    defaultMessage: 'Steuerungsfirma / BOSB'
  },
  businessOptionSpWaterTreatment: {
    id: 'constants.businessOption.sp.watertreatment',
    defaultMessage: 'Wasseraufbereiter'
  },
  businessOptionSpWater: {
    id: 'constants.businessOption.sp.water',
    defaultMessage: 'Wasserwerk'
  },
  businessOptionSpOther: {
    id: 'constants.businessOption.sp.other',
    defaultMessage: 'Sonstige'
  }
});

export const messagesNavigation = defineMessages({
  dashboard: {
    id: 'navigation.item.dashboard',
    defaultMessage: 'Dashboard'
  },
  parameters: {
    id: 'navigation.item.parameters',
    defaultMessage: 'Parameter'
  },
  steamPlant: {
    id: 'navigation.item.steamPlant',
    defaultMessage: 'Dampfanlage'
  },
  boilerBook: {
    id: 'navigation.item.boilerBook',
    defaultMessage: 'Betriebsbuch'
  },
  externalChecks: {
    id: 'navigation.item.externalChecks',
    defaultMessage: 'Externe Prüfungen'
  },
  analyses: {
    id: 'navigation.item.analyses',
    defaultMessage: 'Auswertungen'
  },
  reporting: {
    id: 'navigation.item.reporting',
    defaultMessage: 'Dampfkosten'
  },
  factoryData: {
    id: 'navigation.item.factoryData',
    defaultMessage: 'Betriebsdaten'
  },
  waterParameter: {
    id: 'navigation.item.waterParameter',
    defaultMessage: 'Wasserparameter'
  },
  settings: {
    id: 'navigation.item.settings',
    defaultMessage: 'Konfiguration'
  },
  company: {
    id: 'navigation.item.company',
    defaultMessage: 'Einstellungen'
  },
  steamCosts: {
    id: 'navigation.item.steamCosts',
    defaultMessage: 'Kostenfaktoren'
  },
  notifications: {
    id: 'navigation.item.notifications',
    defaultMessage: 'Benachrichtigungen'
  },
  logout: {
    id: 'navigation.item.logout',
    defaultMessage: 'Abmelden'
  },
  changePassword: {
    id: 'navigation.item.changePassword',
    defaultMessage: 'Passwort ändern'
  },
  admin: {
    id: 'navigation.item.admin',
    defaultMessage: 'Admin'
  },
  customers: {
    id: 'navigation.item.customers',
    defaultMessage: 'Kunden'
  },
  servicePartners: {
    id: 'navigation.item.servicePartners',
    defaultMessage: 'Service-Partner'
  }
});

export const messagesNoMatch = defineMessages({
  headline: {
    id: 'noMatch.headline',
    defaultMessage: 'Nicht gefunden'
  },
  text: {
    id: 'noMatch.text',
    defaultMessage: 'Die aufgerufene Seite existiert nicht'
  }
});

export const messagesUserDelete = defineMessages({
  headline: {
    id: 'delete.user.headline',
    defaultMessage: 'Benutzer löschen'
  },
  text: {
    id: 'delete.user.text',
    defaultMessage: 'Sind Sie sich sicher das Sie den Benutzer löschen wollen?'
  },
  action: {
    id: 'delete.user.action',
    defaultMessage: 'Jetzt löschen'
  }
});

export const messagesLocationDelete = defineMessages({
  headline: {
    id: 'delete.location.headline',
    defaultMessage: 'Standort löschen'
  },
  text: {
    id: 'delete.location.text',
    defaultMessage: 'Sind Sie sich sicher das Sie den Standort löschen wollen?'
  },
  action: {
    id: 'delete.location.action',
    defaultMessage: 'Jetzt löschen'
  }
});

export const messagesParamDelete = defineMessages({
  headline: {
    id: 'delete.parameter.headline',
    defaultMessage: 'Parameter löschen'
  },
  text: {
    id: 'delete.parameter.text',
    defaultMessage: 'Sind Sie sich sicher das Sie den Parameter löschen wollen?'
  },
  action: {
    id: 'delete.parameter.action',
    defaultMessage: 'Jetzt löschen'
  }
});

export const messagesSteamPlant = defineMessages({
  steamBoilerMissingBurner: {
    id: 'steamPlant.steamBoilerMissing.burner.message',
    defaultMessage:
      'Der Dampfkessel "{boiler}" wurde gelöscht. Bitte den Brenner "{burner}" anpassen.'
  },
  steamBoilerMissingFeedWater: {
    id: 'steamPlant.steamBoilerMissing.feedWater.message',
    defaultMessage:
      'Der Dampfkessel "{boiler}" wurde gelöscht. Bitte den Speisewasserbehälter "{feedWater}" anpassen.'
  }
});

export const messagesServicePartnerDelete = defineMessages({
  headline: {
    id: 'delete.servicepartner.headline',
    defaultMessage: 'Service Partner löschen'
  },
  text: {
    id: 'delete.servicepartner.text',
    defaultMessage: 'Sind Sie sich sicher das Sie diesen Service Partner löschen wollen?'
  },
  action: {
    id: 'delete.servicepartner.action',
    defaultMessage: 'Jetzt löschen'
  }
});

export const messagesExternalCheckDelete = defineMessages({
  headline: {
    id: 'delete.check.headline',
    defaultMessage: 'Überprüfung löschen'
  },
  text: {
    id: 'delete.check.text',
    defaultMessage: 'Sind Sie sich sicher das Sie diese Überprüfung löschen wollen?'
  },
  action: {
    id: 'delete.check.action',
    defaultMessage: 'Jetzt löschen'
  }
});

export const messagesCustomerDelete = defineMessages({
  headline: {
    id: 'delete.customer.headline',
    defaultMessage: 'Kunde löschen'
  },
  text: {
    id: 'delete.customer.text',
    defaultMessage:
      'Sind Sie sich sicher das Sie diesen Kunden löschen wollen? Alle Standorte und zugehörigen Benutzer werden ebenfalls deaktiviert.'
  },
  action: {
    id: 'delete.customer.action',
    defaultMessage: 'Jetzt löschen'
  }
});

export const messagesPlaceholder = defineMessages({
  emailAddress: {
    id: 'placeholder.emailAddress',
    defaultMessage: 'E-Mail'
  },
  password: {
    id: 'placeholder.password',
    defaultMessage: 'Passwort'
  },
  dateInput: {
    id: 'placeholder.dateInput',
    defaultMessage: 'dd.mm.yyyy'
  }
});

export const messagesLogin = defineMessages({
  button: {
    id: 'login.button.label',
    defaultMessage: 'Anmelden'
  },
  forgotPassword: {
    id: 'login.link.forgotPassword',
    defaultMessage: 'Passwort vergessen?'
  },
  wrongData: {
    id: 'login.error.wrongData',
    defaultMessage: 'Falsche Anmeldedaten'
  },
  resetPassword: {
    id: 'login.button.resetPassword',
    defaultMessage: 'Passwort zurücksetzen'
  },
  resetPasswordError: {
    id: 'login.reset.error',
    defaultMessage: 'Fehler beim Zurücksetzen des Passworts'
  },
  resetPasswordSuccess: {
    id: 'login.reset.success',
    defaultMessage: 'Wir haben Ihnen eine E-Mail zum zurücksetzen Ihres Passoworts geschickt.'
  }
});

export const messagesChangeLocation = defineMessages({
  headline: {
    id: 'changeLocation.headline',
    defaultMessage: 'Standort auswählen'
  }
});

export const messagesCostFactor = defineMessages({
  costFactorGas: {
    id: 'costFactor.gas',
    defaultMessage: 'Erdgas (m³)'
  },
  costFactorGasN: {
    id: 'costFactor.gasn',
    defaultMessage: 'Erdgas (m³n)'
  },
  costFactorBioGas: {
    id: 'costFactor.biogas',
    defaultMessage: 'Biogas (m³)'
  },
  costFactorBioGasN: {
    id: 'costFactor.biogasn',
    defaultMessage: 'Biogas (m³n)'
  },
  costFactorOil: {
    id: 'costFactor.oil',
    defaultMessage: 'Heizöl'
  },
  costFactorWater: {
    id: 'costFactor.water',
    defaultMessage: 'Wasser'
  },
  costFactorWaterDirty: {
    id: 'costFactor.waterdirty',
    defaultMessage: 'Abwasser'
  },
  costFactorChemical: {
    id: 'costFactor.chemical',
    defaultMessage: 'Chemie'
  },
  costFactorBiomass: {
    id: 'costFactor.biomass',
    defaultMessage: 'Biomasse'
  },
  costFactorGargabe: {
    id: 'costFactor.garbage',
    defaultMessage: 'Abfall'
  },
  costFactorCoal: {
    id: 'costFactor.coal',
    defaultMessage: 'Kohle'
  },
  costFactorCustom: {
    id: 'costFactor.custom',
    defaultMessage: 'Individuell'
  },
  costFactorDosing: {
    id: 'costFactor.dosing',
    defaultMessage: 'Dosiermittel'
  },
  costFactorBurnMaterial: {
    id: 'costFactor.burnMaterial',
    defaultMessage: 'Brennstoff'
  },
  value: {
    id: 'costFactor.value',
    defaultMessage: 'Preis in €'
  }
});

export const messagesRole = defineMessages({
  roleSuperAdmin: {
    id: 'role.ROLE_SUPER_ADMIN',
    defaultMessage: 'Super Admin'
  },
  roleAdmin: {
    id: 'role.ROLE_ADMIN',
    defaultMessage: 'Admin'
  },
  roleServicePartner: {
    id: 'role.ROLE_SERVICE_PARTNER',
    defaultMessage: 'Service Partner'
  },
  roleBoilerAttendant: {
    id: 'role.ROLE_BOILER_ATTENDANT',
    defaultMessage: 'Kesselwärter'
  },
  roleExternal: {
    id: 'role.ROLE_EXTERNAL',
    defaultMessage: 'Extern'
  },
  roleUser: {
    id: 'role.ROLE_USER',
    defaultMessage: 'Benutzer'
  }
});

export const messagesComponents = defineMessages({
  componentWaterTreatment: {
    id: 'component.type.waterTreatment',
    defaultMessage: 'Wasseraufbereitung'
  },
  componentFeedWater: {
    id: 'component.type.feedWater',
    defaultMessage: 'Speisewasserbehälter'
  },
  componentSteamBoiler: {
    id: 'component.type.steamBoiler',
    defaultMessage: 'Dampfkessel'
  },
  componentDosing: {
    id: 'component.type.dosing',
    defaultMessage: 'Dosierpumpe'
  },
  componentBurner: {
    id: 'component.type.burner',
    defaultMessage: 'Brenner'
  },
  componentCondensate: {
    id: 'component.type.condensate',
    defaultMessage: 'Kondensatbehälter'
  },
  componentCustom: {
    id: 'component.type.custom',
    defaultMessage: 'Individuelle Komponente'
  }
});

export const messagesComponentFields = defineMessages({
  size: {
    id: 'component.fields.size',
    defaultMessage: 'Größe'
  },
  power: {
    id: 'component.fields.power',
    defaultMessage: 'Leistung'
  },
  allowedOperatingPressure: {
    id: 'component.fields.allowedOperatingPressure',
    defaultMessage: 'Zulässiger Betriebsdruck'
  },
  buildYear: {
    id: 'component.fields.buildYear',
    defaultMessage: 'Baujahr'
  },
  steamBoiler: {
    id: 'component.fields.steamBoiler',
    defaultMessage: 'Zugehöriger Dampfkessel'
  },
  burnMaterial1: {
    id: 'component.fields.burnMaterial1',
    defaultMessage: 'Brennstoff 1'
  },
  burnMaterial2: {
    id: 'component.fields.burnMaterial2',
    defaultMessage: 'Brennstoff 2 (falls Kombibrenner)'
  }
});

export const messagesChangePassword = defineMessages({
  headline: {
    id: 'changePassword.headline',
    defaultMessage: 'Passwort ändern'
  },
  currentPassword: {
    id: 'changePassword.currentPassword',
    defaultMessage: 'Aktuelles Passwort'
  },
  newPassword: {
    id: 'changePassword.newPassword',
    defaultMessage: 'Neues Passwort'
  },
  newPasswordRepeat: {
    id: 'changePassword.newPasswordRepeat',
    defaultMessage: 'Neues Passwort wiederholen'
  },
  invalidLength: {
    id: 'forgotpassword.invalid.length',
    defaultMessage: 'Das neue Passwort muss mindestens 6 Zeichen haben.'
  },
  invalidNoMatch: {
    id: 'forgotpassword.invalid.nomatch',
    defaultMessage: 'Das neue Passwort stimmt nicht überein.'
  },
  invalidCurrent: {
    id: 'forgotpassword.invalid.current',
    defaultMessage: 'Das aktuelle Passwort ist nicht korrekt.'
  },
  success: {
    id: 'forgotpassword.sucess',
    defaultMessage: 'Passwort würde erfolgreich geändert.'
  },
  error: {
    id: 'forgotpassword.error',
    defaultMessage: 'Passwort konnte nicht geändert werden.'
  }
});

export const messagesAnalysis = defineMessages({
  headlineWaterParameter: {
    id: 'analysis.headline.waterParameter',
    defaultMessage: 'Auswertung Wasserparameter'
  },
  headlineOperationalData: {
    id: 'analysis.headline.operationalData',
    defaultMessage: 'Auswertung Betriebsdaten'
  },
  headlineReports: {
    id: 'analysis.headline.reports',
    defaultMessage: 'Auswertung Dampfkosten'
  },
  chartNoticeDefault: {
    id: 'analysis.chart.notice.default',
    defaultMessage:
      'Bitte wählen Sie einen Zeitraum, einen Parameter und eine Komponente für die Auswertung'
  },
  chartNoticeDefaultOperational: {
    id: 'analysis.chart.notice.default.operational',
    defaultMessage: 'Bitte wählen Sie einen Zeitraum und einen Datensatz für die Auswertung'
  },
  chartNoticeEmpty: {
    id: 'analysis.chart.notice.empty',
    defaultMessage: 'Keine Daten zu der gewählten Konfiguration'
  }
});

export const messagesReport = defineMessages({
  reportCurrentHeadline: {
    id: 'report.reportCurrentHeadline',
    defaultMessage: 'IST'
  },
  reportDateRangeFilter1: {
    id: 'report.reportDateRangeFilter1',
    defaultMessage: 'Aktuelles Jahr'
  },
  reportDateRangeFilter2: {
    id: 'report.reportDateRangeFilter2',
    defaultMessage: 'Letzte 30 Tage'
  },
  reportDateRangeFilter3: {
    id: 'report.reportDateRangeFilter3',
    defaultMessage: 'Letzte 90 Tage'
  },
  reportDateRangeFilter4: {
    id: 'report.reportDateRangeFilter4',
    defaultMessage: 'Letzte 365 Tage'
  },
  reportTableHeadlineSteamCosts: {
    id: 'report.reportTableHeadlineSteamCosts',
    defaultMessage: 'Dampfkosten'
  },
  reportTableHeadlineDateRange: {
    id: 'report.reportTableHeadlineDateRange',
    defaultMessage: 'Zeitraum'
  },
  reportTableHeadlineDailyAvg: {
    id: 'report.reportTableHeadlineDailyAvg',
    defaultMessage: 'Tag'
  },
  reportTableHeadlineHourlyAvg: {
    id: 'report.reportTableHeadlineHourlyAvg',
    defaultMessage: 'Std'
  },
  reportTableHeadlineConsumptions: {
    id: 'report.reportTableHeadlineConsumptions',
    defaultMessage: 'Verbräuche'
  },
  reportTableHeadlineWaterLoss: {
    id: 'report.reportTableHeadlineWaterLoss',
    defaultMessage: 'Wasserverluste'
  },
  reportTableHeadlineEnergyLoss: {
    id: 'report.reportTableHeadlineEnergyLoss',
    defaultMessage: 'Energieverluste'
  },
  reportRowWater: {
    id: 'report.row.waterValue',
    defaultMessage: 'Wasser'
  },
  reportRowCondensate: {
    id: 'report.row.condensate',
    defaultMessage: 'Kondensatrückführung'
  },
  reportRowWasteWater: {
    id: 'report.row.wasteWaterValue',
    defaultMessage: 'Abwasser'
  },
  reportRowDosing: {
    id: 'report.row.dosingValue',
    defaultMessage: 'Dosiermittel'
  },
  reportRowBurnMaterial: {
    id: 'report.row.burnMaterialValue',
    defaultMessage: 'Brennstoff'
  },
  reportRowSum: {
    id: 'report.row.priceSum',
    defaultMessage: 'Summe'
  },
  reportRowSumRounded: {
    id: 'report.row.priceSumRounded',
    defaultMessage: 'Summe'
  },
  reportRowSteamConsumption: {
    id: 'report.row.steamConsumption',
    defaultMessage: 'Dampfabnahme'
  },
  reportRowSteamCost: {
    id: 'report.row.steamCost',
    defaultMessage: 'Dampfkosten'
  },
  reportRowFreshWater: {
    id: 'report.row.freshWater',
    defaultMessage: 'Frischwasser'
  },
  reportRowTreatedWater: {
    id: 'report.row.treatedWater',
    defaultMessage: 'Aufbereitetes Wasser'
  },
  reportRowFeedWater: {
    id: 'report.row.feedWater',
    defaultMessage: 'Speisewasser'
  },
  reportRowWaterLoss: {
    id: 'report.row.waterLoss',
    defaultMessage: 'Durch Wasseraufbereitung'
  },
  reportRowThermicGasLoss: {
    id: 'report.row.thermicGasLoss',
    defaultMessage: 'Durch thermische Entgasung'
  },
  reportRowProductionLoss: {
    id: 'report.row.productionLoss',
    defaultMessage: 'Durch Produktion'
  },
  reportRowEnergyBoilerLoss: {
    id: 'report.row.energyBoilerLoss',
    defaultMessage: 'Durch Abschlammung'
  },
  reportRowEnergyThermicLoss: {
    id: 'report.row.energyThermicLoss',
    defaultMessage: 'Durch thermische Entgasung'
  },
  reportRowEnergyProductionLoss: {
    id: 'report.row.energyProductionLoss',
    defaultMessage: 'Durch Produktion'
  },
  reportDosingWithName: {
    id: 'report.dosingwithname',
    defaultMessage: 'Dosiermittel {chemical}'
  },
  reportBoilerLossWithName: {
    id: 'report.boilerLossWithName',
    defaultMessage: 'Durch Abschlammung {steamBoiler}'
  },
  reportBurnMaterialForBoilerWithName: {
    id: 'report.burnMaterialForBoilerWithName',
    defaultMessage: '{material} {steamBoiler}'
  }
});

export const messagesComponentArchive = defineMessages({
  title: {
    id: 'component.archive.title',
    defaultMessage: 'Komponente archivieren'
  },
  message: {
    id: 'component.archive.message',
    defaultMessage: 'Soll diese Komponente wirklich archiviert werden?'
  },
  action: {
    id: 'component.archive.action',
    defaultMessage: 'Jetzt archivieren'
  }
});

export const messagesNotification = defineMessages({
  statusActionRequired: {
    id: 'notification.actionRequired',
    defaultMessage: 'Aktion erfoderlich'
  },
  statusNotice: {
    id: 'notification.notice',
    defaultMessage: 'Hinweis'
  },
  outOfBounds: {
    id: 'notification.outOfBounds',
    defaultMessage:
      'Der Wert für {param} bei {component} liegt mit {value} {unit} außerhalb des Sollbereichs.'
  },
  outOfBoundsError: {
    id: 'notification.outOfBoundsError',
    defaultMessage: 'Sollwert Fehler'
  },
  interval: {
    id: 'notification.interval',
    defaultMessage: 'Die Prüfung {check} bei {component} ist fällig'
  },
  intervalError: {
    id: 'notification.intervalError',
    defaultMessage: 'Überfällige Prüfung'
  },
  intervalNotice: {
    id: 'notification.intervalNotice',
    defaultMessage: 'Prüfung'
  },
  archive: {
    id: 'notification.archive',
    defaultMessage: 'Archivieren'
  },
  archivedAt: {
    id: 'notification.archivedAt',
    defaultMessage: 'Archiviert am'
  },
  openMeasurement: {
    id: 'notification.openMeasurement',
    defaultMessage: 'Eintrag aufrufen'
  },
  openConfig: {
    id: 'notification.openConfig',
    defaultMessage: 'Konfiguration öffnen'
  }
});

export const messagesNotificationActions = defineMessages({
  waterTreatmentHardness: {
    id: 'notification.action.waterTreatment.hardness',
    defaultMessage:
      'Jetzt das Rohwasser überprüfen, eine manuelle Regeneration starten und anschließend erneut kontrollieren.'
  },
  waterTreatmentConductivity: {
    id: 'notification.action.waterTreatment.conductivity',
    defaultMessage:
      'Jetzt das Rohwasser überprüfen, den Anlagendruck und die Permeatleistung kontrollieren und anschließend erneut kontrollieren.'
  },
  feedWaterPh: {
    id: 'notification.action.feedWater.ph',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter die Dosierung schrittweise anpassen.'
  },
  feedWaterConductivity: {
    id: 'notification.action.feedWater.conductivity',
    defaultMessage:
      'Jetzt die Funktion der Wasseraufbereitung und die Dosierung überprüfen, das Kondensat auf Leitfähigkeit und Härtegrad analysieren oder den Wasseraufbereiter kontaktieren.'
  },
  feedWaterHardness: {
    id: 'notification.action.feedWater.hardness',
    defaultMessage:
      'Jetzt die Wasseraufbereitung und das Kondensat auf Härteeintrag überprüfen oden den Wasseraufbereiter kontaktieren.'
  },
  feedWaterP: {
    id: 'notification.action.feedWater.p',
    defaultMessage:
      'Jetzt die Dosierung zur Alkalisierung schrittweise erhöhen oder den Wasseraufbereiter kontaktieren.'
  },
  feedWaterPhosphat: {
    id: 'notification.action.feedWater.phosphat',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter die Dosierung schrittweise erhöhen.'
  },
  feedWaterIron: {
    id: 'notification.action.feedWater.iron',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter Wege der Korrosionshemmung besprechen.'
  },
  steamBoilerPh: {
    id: 'notification.action.steamBoiler.ph',
    defaultMessage:
      'Jetzt den pH-Wert im Speisewasser überprüfen und in Abstimmung mit dem Wasseraufbereiter die Dosierung schrittweise anpassen.'
  },
  steamBoilerConductivity: {
    id: 'notification.action.steamBoiler.conductivity',
    defaultMessage:
      'Jetzt die Funktion des Absalz- und Abschlammventils überprüfen, das Absalzlimit anpassen oder die Leitwertsonde im Kessel kalibrieren.'
  },
  steamBoilerHardness: {
    id: 'notification.action.steamBoiler.hardness',
    defaultMessage:
      'Jetzt Härteeintrag durch das Kondensat oder die Wasseraufbereitung überprüfen oder Wasseraufbereiter kontaktieren.'
  },
  steamBoilerP: {
    id: 'notification.action.steamBoiler.p',
    defaultMessage:
      'Jetzt Abschlammung manuell vornehmen und bei mehrmaligem Vorkommen Wasseraufbereiter kontaktieren'
  },
  steamBoilerM: {
    id: 'notification.action.steamBoiler.m',
    defaultMessage:
      'Jetzt Abschlammung manuell vornehmen und bei mehrmaligem Vorkommen Wasseraufbereiter kontaktieren.'
  },
  steamBoilerPhosphat: {
    id: 'notification.action.steamBoiler.phosphat',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter die Dosierung schrittweise anpassen.'
  },
  steamBoilerSulfit: {
    id: 'notification.action.steamBoiler.sulfit',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter die Dosierung schrittweise anpassen.'
  },
  steamBoilerTannin: {
    id: 'notification.action.steamBoiler.tannin',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter die Dosierung schrittweise anpassen.'
  },
  steamBoilerAmin: {
    id: 'notification.action.steamBoiler.amin',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter die Dosierung schrittweise anpassen.'
  },
  steamBoilerIron: {
    id: 'notification.action.steamBoiler.iron',
    defaultMessage:
      'Jetzt in Abstimmung mit dem Wasseraufbereiter Wege der Korrosionshemmung besprechen.'
  },
  condensateConductivity: {
    id: 'notification.action.condensate.conductivity',
    defaultMessage:
      'Jetzt Ursachenforschung möglicher Einträge anstellen und in Abstimmung mit Wasseraufbereiter den pH-Wert und Härtegrad messen.'
  }
});

export const messagesSteamProductionInterference = defineMessages({
  complete: {
    id: 'steamProductionInterference.Complete',
    defaultMessage: 'Ja, nahezu alles'
  },
  majority: {
    id: 'steamProductionInterference.Majority',
    defaultMessage: 'Ja, großteils'
  },
  minority: {
    id: 'steamProductionInterference.Minority',
    defaultMessage: 'Ja, aber nur teilweise'
  },
  none: {
    id: 'steamProductionInterference.None',
    defaultMessage: 'Nein, gar nicht'
  }
});

export const messagesMeasurement = defineMessages({
  placeholder: {
    id: 'measurement.placeholder',
    defaultMessage: 'Bitte wert eintragen'
  },
  meterWarning: {
    id: 'measurement.meterWarning',
    defaultMessage: 'Warnung: Der neue Zählerstand sollte größer als der vorherige sein.'
  },
  create: {
    id: 'measurement.create',
    defaultMessage: 'Eintrag erstellen'
  },
  edit: {
    id: 'measurement.edit',
    defaultMessage: 'Eintrag bearbeiten'
  },
  back: {
    id: 'measurement.back',
    defaultMessage: 'Zurück zum Betriebsbuch'
  }
});

export const messagesDashboard = defineMessages({
  last3Entries: {
    id: 'dashboard.last3Entries',
    defaultMessage: 'letzte 3 Einträge'
  },
  gaugeHeadline: {
    id: 'dashboard.gaugeHeadline',
    defaultMessage: 'Leistungskennzahlen'
  },
  gaugeInfo: {
    id: 'dashboard.gaugeInfo',
    defaultMessage:
      'Basierend auf dem letzten Eintrag, verglichen mit den vorangegangen 3 Einträgen'
  },
  gaugeSteam: {
    id: 'dashboard.gaugeSteam',
    defaultMessage: 'Dampfkosten'
  },
  gaugeEnergy: {
    id: 'dashboard.gaugeEnergy',
    defaultMessage: 'Energieverbrauch'
  },
  gaugeDosing: {
    id: 'dashboard.gaugeDosing',
    defaultMessage: 'Dosierrate'
  },
  gaugeThicc: {
    id: 'dashboard.gaugeThicc',
    defaultMessage: 'Eindickungsfaktor'
  },
  steamChartsHeadline: {
    id: 'dashboard.steamChartsHeadline',
    defaultMessage: 'Control Center'
  },
  steamChartsSince: {
    id: 'dashboard.steamChartsSince',
    defaultMessage: 'seit 1.1.'
  },
  steamChartsInfo: {
    id: 'dashboard.steamChartsInfo',
    defaultMessage:
      'Basierend auf den Einträgen des aktuellen Jahres, verglichen mit den Einträgen aus dem vorherigen Jahr'
  },
  steamChartsCosts: {
    id: 'dashboard.steamChartsCosts',
    defaultMessage: 'Dampfkosten (kEUR)'
  },
  steamChartsCunsumption: {
    id: 'dashboard.steamChartsCunsumption',
    defaultMessage: 'Dampfabnahme (t)'
  },
  steamChartsCostOverTime: {
    id: 'dashboard.steamChartsCostOverTime',
    defaultMessage: 'Kostenentwicklung (kEUR)'
  },
  performanceHeadline: {
    id: 'dashboard.performanceHeadline',
    defaultMessage: 'Performance Überwachung'
  },
  performanceInfo: {
    id: 'dashboard.performanceInfo',
    defaultMessage:
      'Basierend auf den Einträgen der letzten 30 Tage, verglichen mit den Einträgen im gleichen Zeitraum des Vorjahres'
  },
  performanceCondensate: {
    id: 'dashboard.performanceCondensate',
    defaultMessage: 'Kosteneinsparung durch Kondensatrückführung'
  },
  performanceFreshWater: {
    id: 'dashboard.performanceFreshWater',
    defaultMessage: 'Kosten von neu aufbereitetem Wasser'
  },
  performanceFeedWater: {
    id: 'dashboard.performanceFeedWater',
    defaultMessage: 'Speisewasserkosten'
  },
  performanceCondensateReturn: {
    id: 'dashboard.performanceCondensateReturn',
    defaultMessage: 'Kondensat Rückführung'
  },
  performanceEmission: {
    id: 'dashboard.performanceEmission',
    defaultMessage: 'Jährlicher CO₂ Ausstoß'
  },
  total: {
    id: 'dashboard.total',
    defaultMessage: 'Gesamt'
  }
});
