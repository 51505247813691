import { ROLES } from 'constants/Model';

const AppStateService = {
  user: null,
  customer: null,
  location: null,

  /**
   * Set user
   */
  setUser(user) {
    this.user = user;
  },

  /**
   * Remove current user
   */
  removeUser() {
    this.user = null;
    this.customer = null;
    this.location = null;

    localStorage.removeItem('customer');
    localStorage.removeItem('location');
  },

  /**
   * Check if a user is present
   */
  hasUser() {
    return this.user !== null;
  },

  /**
   * Check if a user is super admin
   */
  isSuperAdmin() {
    return this.user.data.role === ROLES.SuperAdmin;
  },

  /**
   * Check if a user is service partner user
   */
  isServicePartner() {
    return this.user.data.role === ROLES.ServicePartner;
  },

  /**
   * Check if a user is customer admin
   */
  isAdmin() {
    return this.user.data.role === ROLES.Admin;
  },

  /**
   * Set customer
   */
  setCustomer(customer) {
    this.customer = customer;
    localStorage.setItem('customer', customer.id);
  },

  /**
   * Set location
   */
  setLocation(location) {
    this.location = location;

    if (location && location.id) {
      localStorage.setItem('location', location.id);
    } else {
      localStorage.removeItem('location');
    }
  }
};

export default AppStateService;
