import Box from 'components/Box';
import Text from 'components/Text';
import { ReactComponent as IconError } from 'images/AlertWarning.svg';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { paddingOrMarginToRem } from 'utils/Utils';

const StyledAlert = styled(Box)`
  width: 100%;
  background-color: ${props => {
    let color = 'green';
    if (props.type === 'error') {
      color = 'red';
    } else if (props.type === 'warning') {
      color = 'orange';
    }
    return props.theme.colors[color];
  }};
  display: ${props => !props.isVisible && 'none'};
  padding: 17px 15px;
  ${props =>
    props.marginX &&
    props.marginY &&
    `margin: ${paddingOrMarginToRem(props.marginY)} ${paddingOrMarginToRem(props.marginX)}`};
  ${props => props.marginTop && `margin-top: ${paddingOrMarginToRem(props.marginTop)}`};
  ${props => props.marginBottom && `margin-bottom: ${paddingOrMarginToRem(props.marginBottom)}`};
  ${props => props.marginLeft && `margin-left: ${paddingOrMarginToRem(props.marginLeft)}`};
  ${props => props.marginRight && `margin-right: ${paddingOrMarginToRem(props.marginRight)}`}
`;

const StyledIcon = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 100%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 14px;
    height: 14px;
  }
`;

function Alert({ title, type, isVisible, ...rest }) {
  return (
    <StyledAlert
      type={type}
      borderRadius="4"
      display="flex"
      alignItems="center"
      isVisible={isVisible}
      {...rest}
    >
      <StyledIcon>
        <IconError />
      </StyledIcon>
      <Text color="white" size="sm">
        {title}
      </Text>
    </StyledAlert>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  isVisible: PropTypes.bool
};

Alert.defaultProps = {
  title: '',
  type: 'success',
  isVisible: false
};

export default Alert;
