import Box from 'components/Box';
import Button from 'components/Button';
import Popup from 'components/Popup';
import Text from 'components/Text';
import useFormOptions from 'hooks/useFormOptions';
import { ReactComponent as Edit } from 'images/EditAlt.svg';
import { ReactComponent as Remove } from 'images/Remove.svg';
import { messagesCommon, messagesCustomerDelete } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';

function CustomerList({ customers, openPanel }) {
  const history = useHistory();
  const formOptions = useFormOptions();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [customer, setCustomer] = useState();

  const onShowDeletePopup = customer => {
    setShowDeletePopup(true);
    setCustomer(customer);
  };

  const onHideDeletePopup = () => setShowDeletePopup(false);

  const onDeleteCustomer = async () => {
    const customerData = { ...customer.data, isDeleted: true };

    try {
      await FirebaseService.updateCustomer(customer.id, customerData);
      setShowDeletePopup(false);
    } catch (e) {
      console.log('Customer could not be deleted');
      console.log(e);
      setShowDeletePopup(false);
    }
  };

  const selectAsCurrent = async customer => {
    AppStateService.setCustomer({
      id: customer.id,
      ref: customer.ref.ref, // looks weird but this is a query snapshot and we need the document ref
      data: customer.data
    });

    const locationsSnapshot = await FirebaseService.queryLocationsOfCustomer(customer.ref.ref);

    if (locationsSnapshot.docs.length > 0) {
      const location = locationsSnapshot.docs[0];
      AppStateService.setLocation({
        id: location.id,
        ref: location.ref,
        data: location.data()
      });
      history.push('/');
    } else {
      AppStateService.setLocation(null);
      history.push('/company?create-location=true');
    }
  };

  const isCurrentCustomer = customer => {
    return AppStateService.customer ? customer.id === AppStateService.customer.id : false;
  };

  const renderRow = customer => {
    const customerData = customer.data;
    const isCurrent = isCurrentCustomer(customer);
    const textColor = isCurrent ? 'green' : null;

    return (
      <tr key={customer.id}>
        <td>
          <Text size="sm" color={textColor}>
            {customerData.name}
          </Text>
        </td>
        <td>
          <Text size="sm" color={textColor}>
            {customerData.city}
          </Text>
        </td>
        <td>
          <Text size="sm" color={textColor}>
            {customerData.zip}
          </Text>
        </td>
        <td>
          <Text size="sm" color={textColor}>
            {customerData.country}
          </Text>
        </td>
        <td>
          <Text size="sm" color={textColor}>
            {formOptions.business.filter(option => option.value === customerData.business)[0].label}
          </Text>
        </td>
        <td style={{ textAlign: 'right' }}>
          <Box display="inline" position="relative" bottom={1}>
            <Button
              text={intl.get(messagesCommon.select.id)}
              inline
              textSize="sm"
              type="button"
              size="sm"
              onClick={() => selectAsCurrent(customer)}
            />
          </Box>

          <Edit
            onClick={() => openPanel(customer)}
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              marginRight: '15px',
              marginLeft: '15px'
            }}
          />
          <Remove onClick={() => onShowDeletePopup(customer)} style={{ cursor: 'pointer' }} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{intl.get(messagesCommon.companyName.id)}</th>
            <th>{intl.get(messagesCommon.city.id)}</th>
            <th>{intl.get(messagesCommon.zip.id)}</th>
            <th>{intl.get(messagesCommon.country.id)}</th>
            <th>{intl.get(messagesCommon.business.id)}</th>
            <th width="215" />
          </tr>
        </thead>
        <tbody>{customers.map(c => renderRow(c))}</tbody>
      </table>
      {showDeletePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesCustomerDelete.headline.id)}
          message={intl.get(messagesCustomerDelete.text.id)}
          confirmColor="red"
          confirmText={intl.get(messagesCustomerDelete.action.id)}
          confirmAction={onDeleteCustomer}
          abortAction={onHideDeletePopup}
          abortText={intl.get(messagesCommon.cancel.id)}
        />
      )}
    </>
  );
}

CustomerList.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  openPanel: PropTypes.func
};

CustomerList.defaultProps = {
  openPanel: null
};

export default CustomerList;
