import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import { PackageTypes } from 'constants/Packages';
import { messagesChangeLocation, messagesCommon } from 'messages/messages';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
// import { useHistory } from 'react-router-dom';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import { sortLocationDocs } from 'utils/Utils';

function ChangeLocation() {
  // const history = useHistory();
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  // Effect to load locations on startup
  useEffect(() => {
    const loadLocations = async () => {
      setLoadingLocations(true);

      const docs = [];
      const querySnapshot = await FirebaseService.queryLocationsOfCustomer(
        AppStateService.customer.ref
      );

      querySnapshot.forEach(doc => {
        docs.push({
          id: doc.id,
          ref: doc,
          data: doc.data()
        });
      });

      const sorted = sortLocationDocs(docs);

      setLocations(sorted);
      setLoadingLocations(false);
    };

    const loadUserLocations = async () => {
      setLoadingLocations(true);
      const docs = [];
      const locationLoading = [];
      let userLocations = AppStateService.user.data.locations;

      if (!userLocations) {
        userLocations = [];
      }

      for (let i = 0; i < userLocations.length; i += 1) {
        const ref = userLocations[i];
        locationLoading.push(ref.get());
      }

      const docResults = await Promise.all(locationLoading);

      docResults.map(doc => {
        const docData = doc.data();
        if (docData.isDeleted === undefined || docData.isDeleted === false) {
          docs.push({
            id: doc.id,
            ref: doc.ref,
            data: docData
          });
        }
      });

      const sorted = sortLocationDocs(docs);

      setLocations(sorted);
      setLoadingLocations(false);
    };

    if (AppStateService.isAdmin() || AppStateService.isSuperAdmin()) {
      loadLocations();
    } else {
      loadUserLocations();
    }
  }, []);

  /**
   * Change the current active location
   * @param {*} location
   */
  const changeLocation = async locationDoc => {
    setLoading(true);
    const location = { id: locationDoc.id, ref: locationDoc.ref.ref, data: locationDoc.data };

    if (AppStateService.isServicePartner()) {
      const customerDoc = await location.data.customer.get();
      const customer = {
        id: customerDoc.id,
        ref: customerDoc.ref,
        data: customerDoc.data()
      };
      AppStateService.setCustomer(customer);
    }

    AppStateService.setLocation(location);

    setTimeout(() => {
      window.location = window.location.origin;
    }, 1500);
    // @NOTE cant find the bug why location is not updated through all pages
    // this is why we reload
    // probably a rewrite to get location from a hook with internal storage would be best

    // history.push('/');
  };

  /**
   * Check if given location is current location
   * @param {*} location
   */
  const isCurrentLocation = location => {
    let current = false;
    if (AppStateService.location && location.id === AppStateService.location.id) {
      current = true;
    }
    return current;
  };

  /**
   * Render location row
   * @param {*} location
   */
  const renderLocationRow = locationRef => {
    const location = locationRef.data;
    const isCurrent = isCurrentLocation(locationRef);
    const textColor = isCurrent ? 'green' : null;
    const locationString = `${location.street}, ${location.zip} ${location.city}, ${location.country}`;

    let packageString = '-';

    if (location.package) {
      packageString =
        location.package === PackageTypes.BASIC
          ? intl.get(messagesCommon.packageBasic.id)
          : intl.get(messagesCommon.packageEssential.id);
    }

    return (
      <tr key={locationRef.id}>
        <td>
          <Text size="sm" color={textColor}>
            {location.name}
          </Text>
        </td>
        <td>
          <Text size="sm" color={textColor}>
            {locationString}
          </Text>
        </td>
        <td>
          <Text size="sm" color={textColor}>
            {packageString}
          </Text>
        </td>
        <td>
          <Button
            text={intl.get(messagesCommon.select.id)}
            inline
            textSize="sm"
            type="button"
            size="sm"
            onClick={() => changeLocation(locationRef)}
          />
        </td>
      </tr>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        alignItems="center"
        justify="center"
        position="absolute"
        left="0"
        top="0"
        zIndex="999"
        background="white"
      >
        <Spinner noMargin />
      </Box>
    );
  }

  return (
    <Box maxWidth="1600px">
      <Box marginX={7}>
        <Heading level="1" size="xl">
          {intl.get(messagesChangeLocation.headline.id)}
        </Heading>
      </Box>

      {loadingLocations ? (
        <Box display="flex" justify="center">
          <Spinner noMargin />
        </Box>
      ) : (
        <Box background="white" marginX={7} marginTop={4}>
          <table>
            <thead>
              <tr>
                <th>{intl.get(messagesCommon.name.id)}</th>
                <th>{intl.get(messagesCommon.address.id)}</th>
                <th>{intl.get(messagesCommon.package.id)}</th>
                <th width="100" />
              </tr>
            </thead>
            <tbody>{locations.map(l => renderLocationRow(l))}</tbody>
          </table>
        </Box>
      )}
    </Box>
  );
}

export default ChangeLocation;
