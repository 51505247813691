import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { paddingOrMarginToRem } from 'utils/Utils';

const pixelOrGivenValue = value => {
  if (value === 'auto') {
    return 'auto';
  }
  return typeof value === 'number' ? `${value}px` : value;
};

const StyledBox = styled.div`
  background-color: ${props =>
    props.background ? props.theme.colors[props.background] : 'transparent'};
  width: ${props => (props.width ? `${pixelOrGivenValue(props.width)}` : 'auto')};
  max-width: ${props => (props.maxWidth ? `${pixelOrGivenValue(props.maxWidth)}` : null)};
  min-width: ${props => (props.minWidth ? `${pixelOrGivenValue(props.minWidth)}` : null)};
  height: ${props => (props.height ? `${pixelOrGivenValue(props.height)}` : 'auto')};
  max-height: ${props => (props.maxHeight ? `${pixelOrGivenValue(props.maxHeight)}` : null)};
  position: ${props => (props.position ? props.position : 'relative')};
  display: ${props => (props.display ? `${props.display}` : 'block')};
  border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : '4px')};
  opacity: ${props => (props.opacity ? props.opacity : 1)};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};

  ${props => props.padding && `padding: ${paddingOrMarginToRem(props.padding)}`};
  ${props =>
    props.paddingY && !props.paddingX && `padding: ${paddingOrMarginToRem(props.paddingY)} 0`};
  ${props =>
    props.paddingX && !props.paddingY && `padding: 0 ${paddingOrMarginToRem(props.paddingX)}`};
  ${props =>
    props.paddingX &&
    props.paddingY &&
    `padding: ${paddingOrMarginToRem(props.paddingY)} ${paddingOrMarginToRem(props.paddingX)}`};
  ${props => props.paddingTop && `padding-top: ${paddingOrMarginToRem(props.paddingTop)}`};
  ${props => props.paddingBottom && `padding-bottom: ${paddingOrMarginToRem(props.paddingBottom)}`};
  ${props => props.paddingLeft && `padding-left: ${paddingOrMarginToRem(props.paddingLeft)}`};
  ${props => props.paddingRight && `padding-right: ${paddingOrMarginToRem(props.paddingRight)}`};

  ${props => props.margin && `margin: ${paddingOrMarginToRem(props.margin)}`};
  ${props => props.marginY && !props.marginX && `margin: ${paddingOrMarginToRem(props.marginY)} 0`};
  ${props => props.marginX && !props.marginY && `margin: 0 ${paddingOrMarginToRem(props.marginX)}`};
  ${props =>
    props.marginX &&
    props.marginY &&
    `margin: ${paddingOrMarginToRem(props.marginY)} ${paddingOrMarginToRem(props.marginX)}`};
  ${props => props.marginTop && `margin-top: ${paddingOrMarginToRem(props.marginTop)}`};
  ${props => props.marginBottom && `margin-bottom: ${paddingOrMarginToRem(props.marginBottom)}`};
  ${props => props.marginLeft && `margin-left: ${paddingOrMarginToRem(props.marginLeft)}`};
  ${props => props.marginRight && `margin-right: ${paddingOrMarginToRem(props.marginRight)}`};

  ${props => (props.alignItems ? `align-items: ${props.alignItems}` : 'align-items: center')};
  ${props => props.bottom && `bottom: ${paddingOrMarginToRem(props.bottom)}`};
  ${props => props.left && `left: ${paddingOrMarginToRem(props.left)}`};
  ${props => props.top && `top: ${paddingOrMarginToRem(props.top)}`};
  ${props => props.right && `right: ${paddingOrMarginToRem(props.right)}`};
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.flex && `flex: ${props.flex}`};
  ${props => props.flexFlow && `flex-flow: ${props.flexFlow}`};
  ${props => props.flexGrow && `flex-grow: ${props.flexGrow}`};
  ${props => props.flexShrink && `flex-shrink: ${props.flexShrink}`};
  ${props => props.alignSelf && `align-self: ${props.alignSelf}`};
  ${props => props.wrap && `flex-wrap: ${props.wrap}`};
  ${props => props.justify && `justify-content: ${props.justify}`};
  ${props => props.overflow && `overflow: ${props.overflow}`};
  ${props => props.border && `border: 1px solid ${props.theme.colors.border}`};
  ${props => props.borderRight && `border-right: 1px solid ${props.theme.colors.border}`};
  ${props => props.borderBottom && `border-bottom: 1px solid ${props.theme.colors.border}`};
  ${props => props.noBorderRadius && `border-radius: 0`};
  ${props => props.zIndex && `z-index: ${props.zIndex}`};
  ${props => props.noPointer && `pointer-events: none`};

  ${props =>
    !props.noElevation && props.background && `box-shadow: 4px 4px 17px 0 rgba(0, 0, 0, 0.07)`};
`;

const Box = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledBox {...props} ref={ref}>
      {children}
    </StyledBox>
  );
});

Box.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

Box.defaultProps = {
  children: null
};

export default Box;
