import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import SteamPlantComponent from 'components/SteamPlantComponent';
import Text from 'components/Text';
import { ComponentTypeConfig, ComponentTypes } from 'constants/SteamComponent';
import { ReactComponent as IconPlus } from 'images/Plus.svg';
import {
  messagesCommon,
  messagesComponents,
  messagesNavigation,
  messagesSteamPlant
} from 'messages/messages';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import { sortComponents, updateSorting } from 'utils/SortComponents';
import { arrayMove } from 'utils/Utils';
import PanelNewComponent from './PanelNewComponent';
import SteamComponentSpecifics from './SteamComponentSpecifics';
import SteamPlantPanel from './SteamPlantPanel';

function SteamPlant() {
  const location = useLocation();

  const [components, setComponents] = useState([]);
  const [componentCounts, setComponentCounts] = useState([]);
  const [activeComponentValues, setActiveComponentValues] = useState([]);
  const [externalChecksOfComponents, setExternalChecksOfComponents] = useState([]);
  const [meetsRequirement, setMeetsRequirement] = useState(null);
  const [hasDeleteRefWarnings, setHasDeleteRefWarnings] = useState([]);
  const [panelOpen, setPanelOpen] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [panelNewComponentOpen, setPanelNewComponentOpen] = useState(false);

  const componentsRef = useRef();

  useEffect(() => {
    componentsRef.current = components;
  }, [components]);

  const checkIfSteamBoilerIsDeleted = useCallback(
    async component => {
      if (component.steamBoiler) {
        const steamBoiler = await FirebaseService.getComponentById(component.steamBoiler.id);
        const steamBoilerData = steamBoiler.data();
        if (steamBoilerData.isDeleted) {
          const typeName = ComponentTypeConfig[component.type].name;
          const filterCriteria = warning => warning.id === `alert-${component.id}`;
          const warningExists = hasDeleteRefWarnings.filter(filterCriteria);
          if (!warningExists.length) {
            setHasDeleteRefWarnings([
              ...hasDeleteRefWarnings,
              {
                id: `alert-${component.id}`,
                message: intl.get(messagesSteamPlant[`steamBoilerMissing${typeName}`].id, {
                  boiler: steamBoilerData.internalName,
                  burner: component.internalName
                })
              }
            ]);
          }
        }
      }
    },
    [hasDeleteRefWarnings]
  );

  // Effect to open component edit when url search param is present
  useEffect(() => {
    const searchString = location.search ? location.search.replace('?', '') : null;

    if (searchString) {
      const searchParams = new URLSearchParams(searchString);
      if (searchParams.has('componentId')) {
        const component = components.filter(comp => comp.id === searchParams.get('componentId'))[0];
        if (component) {
          openPanel(component.data.type, component);
        }
      }
    }
  }, [components, location]);

  // Effect to load components and keep them up to date
  useEffect(() => {
    const updateCollection = async querySnapshot => {
      setIsLoading(true);
      setHasDeleteRefWarnings([]);

      const docs = [];
      const counts = [];
      const active = [];
      const externalChecks = [];
      const costFactors = [];
      const externalToComponentMap = [];

      // Boostrap an array of all types with 0
      Object.keys(ComponentTypeConfig).map(type => {
        counts[type] = 0;
        return true;
      });

      // load external checks
      const externalQuerySnapshot = await FirebaseService.getExternalChecksForLocation(
        AppStateService.location.ref
      ).get();

      externalQuerySnapshot.forEach(externalDoc => {
        externalChecks.push({
          id: externalDoc.id,
          ref: externalDoc,
          data: externalDoc.data()
        });
      });

      // load costfactors
      const costFactorSnapshot = await FirebaseService.queryCostFactorOfLocationRef(
        AppStateService.location.ref
      );

      // @NOTE Could be better to only fetch the single ref for data.type == chemical => data.chemical.get()
      costFactorSnapshot.forEach(costFactor => {
        costFactors.push({
          id: costFactor.id,
          ref: costFactor,
          data: costFactor.data()
        });
      });

      // load component data
      // gather active component parameter values
      // count component by type
      querySnapshot.forEach(doc => {
        const data = doc.data();
        let dosingChemical = null;

        // add one to component type count
        const { type } = data;
        counts[type] += 1;

        // add active component parameter values
        active[doc.id] = {
          waterValues: data.waterValues ? data.waterValues.filter(value => value.active) : [],
          operatingValues: data.operatingValues
            ? data.operatingValues.filter(value => value.active)
            : [],
          meterValues: data.meterValues ? data.meterValues.filter(value => value.active) : []
        };

        // add external check for component
        externalToComponentMap[doc.id] = externalChecks.filter(
          externalCheck => externalCheck.data.component.id === doc.id
        );

        // load dosing chemical for type === dosing
        if (data.type === ComponentTypes.Dosing) {
          const chemical = costFactors.filter(costFactor => costFactor.id === data.chemical.id)[0];
          dosingChemical = chemical !== undefined ? chemical : null;
        }

        docs.push({
          id: doc.id,
          ref: doc,
          dosingChemical,
          data
        });
      });

      const sortedComponentDocs = sortComponents(docs);

      setComponentCounts(counts);
      setActiveComponentValues(active);
      setExternalChecksOfComponents(externalToComponentMap);
      setComponents(sortedComponentDocs);
      setIsLoading(false);
    };

    const unsub = FirebaseService.getComponentsOfLocationRef(
      AppStateService.location.ref
    ).onSnapshot(updateCollection);

    const updateSortingOnCleanup = () => {
      const componentsState = [...componentsRef.current];
      updateSorting(componentsState);
    };

    window.addEventListener('beforeunload', updateSortingOnCleanup);

    return () => {
      unsub();
      window.removeEventListener('beforeunload', updateSortingOnCleanup);
      updateSortingOnCleanup();
    };
  }, []);

  // Effect to check if steam boiler of burner isDeleted
  useEffect(() => {
    if (components.length) {
      const filterCriteria = component => component.data.type === ComponentTypes.Burner;
      const burnerComponents = components.filter(filterCriteria);
      burnerComponents.map(burner => checkIfSteamBoilerIsDeleted(burner.data));
    }
  }, [components, checkIfSteamBoilerIsDeleted]);

  // Effect to check if by configuration all required components have been created
  useEffect(() => {
    let hasRequired = true;
    const componentTypes = Object.keys(ComponentTypeConfig);

    componentTypes.forEach(type => {
      const config = ComponentTypeConfig[type];

      if (config.requiresOne) {
        const hasComponentsOfType = components.filter(c => c.data.type === type).length > 0;
        hasRequired = hasRequired && hasComponentsOfType;
      }
    });

    setMeetsRequirement(hasRequired);
  }, [components]);

  const renderComponentsOfType = type => {
    const componentsOfType = components.filter(c => c.data.type === type);
    let render = null;

    if (componentsOfType.length) {
      render = componentsOfType.map(c => renderComponent(c));
    } else {
      const componentConfig = ComponentTypeConfig[type];
      if (componentConfig.requiresOne) {
        return renderRequiredEmptyComponent(type);
      }
    }

    return render;
  };

  const updateSortingOnEnd = ({ oldIndex, newIndex }) => {
    const componentSortingUpdate = [...components];
    const updated = arrayMove(componentSortingUpdate, oldIndex, newIndex);

    setComponents(updated);
  };

  const openPanel = (type, data) => {
    setPanelData(data);
    setPanelOpen(type);
  };

  const closePanel = () => setPanelOpen(null);

  const getI18nComponentName = type => {
    const messageName = ComponentTypeConfig[type].name;
    const message = messagesComponents[`component${messageName}`];
    return intl.get(message.id);
  };

  const renderComponent = component => {
    const { data } = component;
    const componentName = getI18nComponentName(data.type);
    const title = `${componentName} ${data.componentNumber}`;

    return (
      <div key={component.id}>
        <SteamPlantComponent
          title={title}
          margin={3}
          onClickEdit={() => openPanel(data.type, component)}
        >
          <Text size="sm" bold opacity=".5">
            {intl.get(messagesCommon.manufacturerOrType.id)}
          </Text>
          <Text size="sm">{data.productType ? data.productType : ''}</Text>
          <br />
          <Text size="sm" bold opacity=".5">
            {intl.get(messagesCommon.internalLabel.id)}
          </Text>
          <Text size="sm">{data.internalName}</Text>
          <SteamComponentSpecifics
            componentDoc={component}
            activeValues={activeComponentValues}
            externalChecks={externalChecksOfComponents}
          />
        </SteamPlantComponent>
      </div>
    );
  };

  const renderRequiredEmptyComponent = type => {
    const title = getI18nComponentName(type);
    return (
      <SteamPlantComponent
        key={type}
        title={title}
        margin={3}
        incomplete={true}
        onClickCreate={() => openPanel(type, null)}
      />
    );
  };

  const SortableItem = SortableElement(({ value }) => renderComponent(value));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        {items.map((component, index) => (
          <SortableItem key={`item_${component.id}`} index={index} value={component} />
        ))}
      </div>
    );
  });

  return (
    <Box maxWidth="1600px">
      <Box marginX={7} display="flex" direction="column" alignItems="left" justify="left">
        <Box display="flex">
          <Heading level="1" size="xl">
            {intl.get(messagesNavigation.steamPlant.id)}
          </Heading>
          {meetsRequirement === true && (
            <Box marginLeft={3}>
              <Button
                text={intl.get(messagesCommon.add.id)}
                Icon={IconPlus}
                inline
                textSize="sm"
                type="button"
                size="sm"
                onClick={() => setPanelNewComponentOpen(true)}
              />
            </Box>
          )}
        </Box>

        <Box display="flex" marginTop={5}>
          <Alert
            title={intl.get(messagesCommon.steamPlantAlert.id)}
            type="error"
            isVisible={!isLoading && !meetsRequirement}
          />
        </Box>

        {hasDeleteRefWarnings.map(alert => (
          <Box display="flex" marginTop={5}>
            <Alert
              key={alert.id}
              title={alert.message}
              type="warning"
              isVisible={true}
              marginTop={2}
            />
          </Box>
        ))}
      </Box>

      <LoadingBox loading={isLoading} renderChildren={!isLoading}>
        <Box display="flex" wrap="wrap" marginX={4} marginTop={5}>
          {/* if requirements are met, apply sorting and apply drag and drop */}
          {/* if requirements are not met, default type sorting and required empty boxes are rendered */}
          {meetsRequirement ? (
            <SortableList axis="xy" items={components} onSortEnd={updateSortingOnEnd} />
          ) : (
            Object.keys(ComponentTypes).map(componentName =>
              renderComponentsOfType(ComponentTypes[componentName])
            )
          )}
        </Box>
      </LoadingBox>

      {/* Edit Panel */}
      <SteamPlantPanel
        open={panelOpen !== null}
        type={panelOpen}
        component={panelData}
        allComponents={components}
        componentCounts={componentCounts}
        onClose={closePanel}
      />

      {/* Create Panel */}
      {panelNewComponentOpen && (
        <PanelNewComponent
          componentCounts={componentCounts}
          onTypeSelected={type => {
            setPanelNewComponentOpen(false);
            openPanel(type, null);
          }}
          onClose={() => setPanelNewComponentOpen(false)}
        />
      )}
    </Box>
  );
}

export default SteamPlant;
