import Popup from 'components/Popup';
import Text from 'components/Text';
import { ComponentTypeConfig } from 'constants/SteamComponent';
import { ReactComponent as Edit } from 'images/EditAlt.svg';
import { ReactComponent as Remove } from 'images/Remove.svg';
import { messagesCommon, messagesComponents, messagesExternalCheckDelete } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import NotificationService from 'services/NotificationService';

function CheckList({ checks, openPanel, onCheckDelete }) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [checkToDelete, setCheckToDelete] = useState(null);

  const onShowDeletePopup = check => {
    setShowDeletePopup(true);
    setCheckToDelete(check);
  };

  const onHideDeletePopup = () => setShowDeletePopup(false);

  const renderRow = check => {
    const checkData = check.data;
    const checkComponentData = check.componentData;
    const componentType = ComponentTypeConfig[checkComponentData.type].name;
    const componentTypeNameMessage = messagesComponents[`component${componentType}`];

    const daysToNextDueDate = NotificationService.getDaysToNextDue(checkData);
    const isDue = NotificationService.isExternalCheckDue(checkData);

    let dueText = messagesCommon.today;

    if (daysToNextDueDate !== 0) {
      dueText = isDue ? messagesCommon.wasDueAt : messagesCommon.isDueIn;
    }

    return (
      <tr key={check.id}>
        <td>
          <Text size="sm">
            {intl.get(componentTypeNameMessage.id)} ({checkComponentData.internalName})
          </Text>
        </td>
        <td>
          <Text size="sm">{checkData.name}</Text>
        </td>
        <td>
          <Text size="sm">{checkData.operatedBy}</Text>
        </td>
        <td>
          <Text size="sm" color={isDue ? 'red' : ''}>
            {intl.get(dueText.id, { days: Math.abs(daysToNextDueDate) })}
          </Text>
        </td>
        <td>
          <Edit
            onClick={() => openPanel(check)}
            style={{ cursor: 'pointer', display: 'inline-block', marginRight: '15px' }}
          />
          <Remove onClick={() => onShowDeletePopup(check)} style={{ cursor: 'pointer' }} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{intl.get(messagesCommon.component.id)}</th>
            <th>{intl.get(messagesCommon.externalCheckType.id)}</th>
            <th>{intl.get(messagesCommon.operatedBy.id)}</th>
            <th>{intl.get(messagesCommon.nextDueDate.id)}</th>
            <th width="100" />
          </tr>
        </thead>
        <tbody>{checks.map(c => renderRow(c))}</tbody>
      </table>
      {showDeletePopup && (
        <Popup
          show
          size={380}
          title={intl.get(messagesExternalCheckDelete.headline.id)}
          message={intl.get(messagesExternalCheckDelete.text.id)}
          confirmColor="red"
          confirmText={intl.get(messagesExternalCheckDelete.action.id)}
          confirmAction={() => {
            setShowDeletePopup(false);
            onCheckDelete(checkToDelete);
          }}
          abortAction={onHideDeletePopup}
        />
      )}
    </>
  );
}

CheckList.propTypes = {
  checks: PropTypes.arrayOf(PropTypes.object).isRequired,
  openPanel: PropTypes.func,
  onCheckDelete: PropTypes.func
};

CheckList.defaultProps = {
  openPanel: () => {},
  onCheckDelete: () => {}
};

export default CheckList;
