import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledSpinnerDots = styled.div`
  margin: ${props => (props.noMargin ? '0' : '100px auto 0')};
  width: 70px;
  text-align: center;

  > div {
    width: 12px;
    height: 12px;
    background-color: ${props => props.theme.colors.blue};
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.8s infinite ease-in-out both;
  }

  .dot1 {
    animation-delay: -0.4s;
    margin-right: 3px;
  }

  .dot2 {
    animation-delay: -0.24s;
    margin-right: 3px;
  }

  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

function Spinner({ noMargin }) {
  return (
    <StyledSpinnerDots noMargin={noMargin}>
      <div className="dot1" />
      <div className="dot2" />
      <div className="dot3" />
    </StyledSpinnerDots>
  );
}

Spinner.propTypes = {
  noMargin: PropTypes.bool
};

Spinner.defaultProps = {
  noMargin: false
};

export default Spinner;
