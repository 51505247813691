import Box from 'components/Box';
import Panel from 'components/Panel';
import useFormValidation from 'hooks/useFormValidation';
import { messagesCrud } from 'messages/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FirebaseService from 'services/FirebaseService';
import ParameterForm from './ParameterForm';

function PanelParameter({ parameter, onClose }) {
  const formValidator = useFormValidation();
  const [paramData, setParamData] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isCreateMode] = useState(!parameter);
  const [valid, setValid] = useState(false);
  const [invalidName, setInvalidName] = useState(false);

  useEffect(() => {
    const validData = formValidator.validateParameter(paramData);
    setValid(validData);
  }, [paramData, formValidator]);

  useEffect(() => {
    setParamData(parameter && parameter.data);
  }, [parameter]);

  const handleFormFieldChange = (key, value) => {
    const update = paramData === null ? {} : { ...paramData };
    update[key] = value;

    setParamData(update);
  };

  const save = async () => {
    try {
      setSaveInProgress(true);
      setInvalidName(false);

      const dataToSave = { ...paramData };
      // use internal Name is ID to make it unique
      const id = isCreateMode ? dataToSave.name : parameter.id;

      // make sure app form steps and app number range are saved as number
      if (dataToSave.appNumberRange !== undefined) {
        dataToSave.appNumberRange = parseInt(dataToSave.appNumberRange, 10);
      }

      if (dataToSave.appFormSteps !== undefined) {
        dataToSave.appFormSteps = parseFloat(dataToSave.appFormSteps);
      }

      if (isCreateMode) {
        // perform search for param with internal name
        const existing = await FirebaseService.getParameter(id);

        if (existing === false) {
          await FirebaseService.updateParameter(id, dataToSave);
          onClose();
        } else {
          // show error because of name
          setSaveInProgress(false);
          setValid(false);
          setInvalidName(true);
        }
      } else {
        await FirebaseService.updateParameter(id, dataToSave);
        onClose();
      }
    } catch (e) {
      console.log('Parameter could not be inserted or updated');
      console.log(e);
    }
  };

  return (
    <Panel
      title={
        isCreateMode ? intl.get(messagesCrud.paramAdd.id) : intl.get(messagesCrud.paramEdit.id)
      }
      buttonPrimaryAction={save}
      buttonPrimaryDisabled={!valid}
      onClose={onClose}
      showSpinner={saveInProgress}
      isOpen
    >
      <Box marginTop={5} style={{ overflowY: 'auto', height: 'calc(100% - 110px)' }}>
        <ParameterForm
          isCreateMode={isCreateMode}
          parameter={paramData}
          onChange={handleFormFieldChange}
          invalidName={invalidName}
        />
      </Box>
    </Panel>
  );
}

PanelParameter.propTypes = {
  parameter: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

PanelParameter.defaultProps = {
  parameter: null
};

export default PanelParameter;
