import Box from 'components/Box';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const StyledStatus = styled(Box)`
  span {
    text-align: center;
  }
`;

function Status({ text, color, display }) {
  return (
    <StyledStatus background={color} display={display} paddingX={3} paddingY={1} opacity=".8">
      <Text size="xs" color="white">
        {text}
      </Text>
    </StyledStatus>
  );
}

Status.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  display: PropTypes.string
};

Status.defaultProps = {
  text: null,
  color: 'red',
  display: 'block'
};

export default Status;
