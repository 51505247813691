import Box from 'components/Box';
import Heading from 'components/Heading';
import LoadingBox from 'components/LoadingBox';
import Text from 'components/Text';
import { ComponentTypes } from 'constants/SteamComponent';
import useFlattenFirebaseDoc from 'hooks/useFlattenFirebaseDoc';
import { messagesAnalysis } from 'messages/messages';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import AppStateService from 'services/AppStateService';
import FirebaseService from 'services/FirebaseService';
import ParameterService from 'services/ParameterService';
import WorkerService from 'services/WorkerService';
import Chart from './Chart';
import ChartFilter from './ChartFilter';

function AnalysisOperationalData() {
  const { flattenMeasurement, flattenComponent, flattenCostFactor } = useFlattenFirebaseDoc();

  // Loading States
  const [loadingBasicData, setLoadingBasicData] = useState(true);
  const [loadingMeasurements, setLoadingMeasurements] = useState(false);
  const [calculatingValues, setCalculatingValues] = useState(false);
  const [calculatingReport, setCalculatingReport] = useState(false);

  // Entities
  const [components, setComponents] = useState([]);
  const [costFactors, setCostFactors] = useState([]);
  const [measurements, setMeasurements] = useState({ current: [], last: [] });

  // Reports based on measurements
  const [report, setReport] = useState({ current: [], last: [] });

  // Chart Filter Values
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [dateRangeOption, setDateRangeOption] = useState('');
  const [showLastYear, setShowLastYear] = useState(false);

  // Chart Config
  const [chartConfig, setChartConfig] = useState({
    chartData: [],
    lineConfig: []
  });

  /**
   * Callback function to load measurements when date range changes
   * @NOTE for better perfomance not all current year and last year should always be loaded
   */
  const loadMeasurements = useCallback(async () => {
    // Reset Report and Chart Config
    setReport({ current: [], last: [] });
    setChartConfig({ chartData: [], lineConfig: [] });

    // populate the last measurement data inside the measurement
    // and remove all references to provide a flat data structure used in worker file
    const populateFlattenAndSetState = async queryDocs => {
      const loadLastData = queryDocs.map(async queryDoc => {
        const doc = queryDoc.data();
        let lastMeasurement = null;

        if (doc.lastMeasurement) {
          const lastMeasurementRef = await doc.lastMeasurement.get();
          lastMeasurement = flattenMeasurement({
            id: lastMeasurementRef.id,
            ...lastMeasurementRef.data()
          });
        }
        const item = flattenMeasurement({ ...doc });
        item.lastMeasurement = lastMeasurement;

        return item;
      });

      let measurementsWithLastData = await Promise.all(loadLastData);
      // only use measurements that actually have last data
      measurementsWithLastData = measurementsWithLastData.filter(
        item => item.lastMeasurement !== null
      );

      return measurementsWithLastData;
    };

    let queryDateFrom = null;
    let queryDateTo = null;
    let queryDateToLastYear = null;
    let queryDateFromLastYear = null;

    // bit hacky ... but since all options are this way it should be ok
    if (dateRangeOption) {
      const dateRangeDays = parseInt(dateRangeOption.replace('last', ''), 10);

      queryDateTo = moment();
      queryDateFrom = moment().subtract(dateRangeDays, 'days');

      queryDateToLastYear = queryDateTo.clone();
      queryDateFromLastYear = queryDateFrom.clone();

      queryDateToLastYear.subtract(1, 'years');
      queryDateFromLastYear.subtract(1, 'years');
    }

    if (queryDateFrom !== null && queryDateTo !== null) {
      setLoadingMeasurements(true);
      const querySnapshot = await FirebaseService.getMeasurementsOfLocationsRef(
        AppStateService.location.ref,
        {
          dateFrom: queryDateFrom.toDate(),
          dateTo: queryDateTo.toDate()
        },
        'asc'
      ).get();

      const querySnapshotLastYear = await FirebaseService.getMeasurementsOfLocationsRef(
        AppStateService.location.ref,
        {
          dateFrom: queryDateFromLastYear.toDate(),
          dateTo: queryDateToLastYear.toDate()
        },
        'asc'
      ).get();

      const current = await populateFlattenAndSetState(querySnapshot.docs);
      const last = await populateFlattenAndSetState(querySnapshotLastYear.docs);

      setMeasurements({ current, last });
      setLoadingMeasurements(false);
    }
  }, [dateRangeOption]);

  // Effect to load measurements on load
  useEffect(() => {
    loadMeasurements();
  }, [loadMeasurements]);

  // Effect to calculate the report using a web worker
  useEffect(() => {
    const generateReport = async () => {
      setCalculatingReport(true);
      const { current, last } = measurements;

      const currentYearReport = await spawnReportWorker(current);
      const lastYearReport = await spawnReportWorker(last);

      setReport({ current: currentYearReport, last: lastYearReport });
      setCalculatingReport(false);
    };

    generateReport();
  }, [measurements]);

  // Effect to genereate a chart config based on the selected parameter
  useEffect(() => {
    const generateChartData = async () => {
      setCalculatingValues(true);
      const workerData = {
        report,
        showLastYear,
        selectedParameter,
        costFactors
      };
      const chartData = await WorkerService.spawnOperationalWorker(workerData);

      setChartConfig(chartData);
      setCalculatingValues(false);
    };

    generateChartData();
  }, [report, selectedParameter, showLastYear]);

  // Effect to load basic data on page load
  // components and costFactors
  useEffect(() => {
    const load = async () => {
      setLoadingBasicData(true);

      const componentDocs = [];
      const costFactorDocs = [];

      const componentSnapshot = await FirebaseService.queryComponentsOfLocation(
        AppStateService.location.ref
      );

      const costFactorSnapshot = await FirebaseService.queryCostFactorOfLocationRef(
        AppStateService.location.ref
      );

      componentSnapshot.forEach(doc => {
        const item = flattenComponent({
          id: doc.id,
          ...doc.data()
        });

        componentDocs.push(item);
      });

      costFactorSnapshot.forEach(doc => {
        const item = flattenCostFactor({
          id: doc.id,
          ...doc.data()
        });
        costFactorDocs.push(item);
      });

      setCostFactors(costFactorDocs);
      setComponents(componentDocs);
      setLoadingBasicData(false);
    };

    load();
  }, []);

  const spawnReportWorker = async workerMeasurements => {
    const reportParams = ParameterService.getParameterForReport();

    const workerData = {
      meterValueConfig: reportParams.meterParameter,
      operatingValueConfig: reportParams.operationalParameter,
      waterValueConfig: reportParams.waterValues,
      componentTypes: ComponentTypes,
      components,
      measurements: workerMeasurements
    };
    return WorkerService.spawnReportWorker(workerData);
  };

  const renderChart = () => {
    if (dateRangeOption === '' || selectedParameter === null) {
      return renderInitialChartOverlay();
    }

    if (report.current.length === 0 || chartConfig.chartData.length === 0) {
      return renderNoDateChartOverlay();
    }

    return <Chart config={chartConfig} />;
  };

  const renderInitialChartOverlay = () => {
    return (
      <Box display="flex" justify="center" alignItems="center" height="400px" paddingX={4}>
        <Text align="center">{intl.get(messagesAnalysis.chartNoticeDefaultOperational.id)}</Text>
      </Box>
    );
  };

  const renderNoDateChartOverlay = () => {
    return (
      <Box display="flex" justify="center" alignItems="center" height="400px" paddingX={4}>
        <Text align="center">{intl.get(messagesAnalysis.chartNoticeEmpty.id)}</Text>
      </Box>
    );
  };

  return (
    <LoadingBox loading={loadingBasicData}>
      <Box marginX={7}>
        <Heading level="1" size="xl">
          {intl.get(messagesAnalysis.headlineOperationalData.id)}
        </Heading>
      </Box>

      <Box display="flex" marginTop={5} paddingX={7} maxWidth="1600px">
        <ChartFilter
          report={report}
          dateRangeOption={dateRangeOption}
          showLastYear={showLastYear}
          components={components}
          selectedParameter={selectedParameter}
          onDateRangeChange={value => setDateRangeOption(value)}
          onShowLastYearChange={value => setShowLastYear(value)}
          onSelectedParameterChange={value => setSelectedParameter(value)}
        />
        <Box
          background="white"
          width="100%"
          marginLeft={7}
          paddingTop={7}
          paddingBottom={7}
          alignSelf="stretch"
        >
          <LoadingBox loading={loadingMeasurements || calculatingReport || calculatingValues}>
            {renderChart()}
          </LoadingBox>
        </Box>
      </Box>
    </LoadingBox>
  );
}

export default AnalysisOperationalData;
