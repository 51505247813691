import React from 'react';

export const IconComponent = ({ stroke, ...rest }) => {
  return (
    <svg {...rest} viewBox="0 0 32 32">
      <path
        strokeWidth="4"
        fill="#fff"
        stroke={stroke}
        d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
      />
    </svg>
  );
};
