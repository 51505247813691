import Number from 'components/Number';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';

function ReportRow({
  label,
  reportField,
  rowKey,
  unit,
  rowClass,
  round,
  digits,
  reportData,
  reportDataLastYear
}) {
  const reportRowKey = rowKey !== undefined ? rowKey : `report-row-${reportField}`;
  let labelText = '';

  let rowSum = 0;
  let rowSumLast = 0;

  // Steam Costs are not displayed as the usual sum but always the relation between the sum of all costs and the consumption
  const priceSumKey = 'priceSumRounded';
  const consumptionKey = 'steamConsumption';

  let priceSum = 0;
  let consumptionSum = 0;
  let priceSumLast = 0;
  let consumptionSumLast = 0;

  for (let i = 0; i < reportData.length; i += 1) {
    const reportItem = reportData[i];
    priceSum += reportItem[priceSumKey];
    consumptionSum += reportItem[consumptionKey];
  }

  for (let i = 0; i < reportDataLastYear.length; i += 1) {
    const reportItem = reportDataLastYear[i];
    priceSumLast += reportItem[priceSumKey];
    consumptionSumLast += reportItem[consumptionKey];
  }

  rowSum = priceSum / parseInt(consumptionSum.toFixed(0), 10);
  rowSumLast = priceSumLast / parseInt(consumptionSumLast.toFixed(0), 10);

  if (label === true) {
    labelText = intl.get(`report.row.${reportField}`);
  } else if (label.length > 0) {
    labelText = label;
  }

  const calculatePercentageDiff = (rowSum, rowSumLast) => {
    let percent = null;

    if (rowSum && rowSumLast) {
      try {
        percent = ((rowSum - rowSumLast) / rowSumLast) * 100;
      } catch {
        percent = null;
      }
    }
    return percent;
  };

  const percentageDiff = calculatePercentageDiff(rowSum, rowSumLast);

  return (
    <tr key={reportRowKey} className={rowClass !== undefined ? rowClass : null}>
      <td className="padded">{labelText}</td>
      <td className="center">{unit}</td>
      <td>
        <Number number={rowSumLast} digits={digits} round={round} size="sm" align="center" />
      </td>
      <td>
        <Number number={rowSum} digits={digits} round={round} size="sm" align="center" />
      </td>
      <td>
        <Number number={rowSum} digits={digits} round={round} size="sm" align="center" />
      </td>
      <td>
        <Number number={rowSum} digits={digits} round={round} size="sm" align="center" />
      </td>
      <td>
        <Text size="sm" align="center">
          <Text size="sm" inline>
            {percentageDiff !== null && Math.round(percentageDiff) > 0 ? '+' : null}
            {percentageDiff !== null && Math.round(percentageDiff) < 0 ? '-' : null}
          </Text>
          <Number
            number={Math.abs(percentageDiff)}
            digits={0}
            round={true}
            suffix="%"
            size="sm"
            inline
          />
        </Text>
      </td>
    </tr>
  );
}
ReportRow.propTypes = {
  reportData: PropTypes.any,
  reportDataLastYear: PropTypes.any,
  reportField: PropTypes.any,
  label: PropTypes.any,
  rowKey: PropTypes.any,
  unit: PropTypes.any,
  rowClass: PropTypes.string,
  round: PropTypes.bool,
  digits: PropTypes.number
};

ReportRow.defaultProps = {
  reportData: [],
  reportDataLastYear: [],
  label: true,
  reportField: undefined,
  rowKey: undefined,
  rowClass: undefined,
  unit: '',
  round: false,
  digits: 2
};

export default ReportRow;
