import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.black};
  outline: 0;
  width: 100%;
  line-height: 1.5;
  border-radius: 4px;
  padding: 10px 14px;
  resize: none;
  font-size: 14px;
`;

function TextArea({
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  disabled,
  id,
  name,
  rows,
  placeholder,
  value
}) {
  return (
    <>
      <StyledTextArea
        disabled={disabled}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        name={name}
        placeholder={placeholder}
        rows={rows}
        value={value}
      />
    </>
  );
}

TextArea.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

TextArea.defaultProps = {
  disabled: false,
  name: '',
  placeholder: '',
  rows: 3,
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {}
};

export default TextArea;
